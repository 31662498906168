import { gql } from '@apollo/client/index.js';
import * as Apollo from '@apollo/client/index.js';
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An enum value that has been humanized */
  HumanizedEnum: { input: any; output: any };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
};

/** Autogenerated input type of AbortTreatmentCycle */
export type AbortTreatmentCycleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the treatment cycle that has been aborted */
  cycleId: Scalars['ID']['input'];
  /** Notes */
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AbortTreatmentCycle. */
export type AbortTreatmentCyclePayload = {
  __typename?: 'AbortTreatmentCyclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatmentCycle: Cycle;
};

export enum ActionVariantEnum {
  Ghost = 'GHOST',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

/** Autogenerated input type of ActivateUserAccount */
export type ActivateUserAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user to be activated */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ActivateUserAccount. */
export type ActivateUserAccountPayload = {
  __typename?: 'ActivateUserAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
  user: Maybe<User>;
};

/** Represents an Acuity account. */
export type AcuityAccount = {
  __typename?: 'AcuityAccount';
  acuityApiKey: Scalars['String']['output'];
  acuityUserId: Scalars['ID']['output'];
  brand: Brand;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** An Acuity appointment */
export type AcuityAppointment = JourneyItem & {
  __typename?: 'AcuityAppointment';
  acuityAppointmentId: Maybe<Scalars['Int']['output']>;
  appointmentType: Maybe<AppointmentType>;
  appointmentTypeId: Maybe<Scalars['Int']['output']>;
  appointmentTypeName: Maybe<Scalars['String']['output']>;
  bookingReason: Maybe<Scalars['String']['output']>;
  bookingReasonDescription: Maybe<Scalars['String']['output']>;
  brand: Maybe<Brand>;
  calendar: Maybe<AcuityCalendar>;
  calendarId: Maybe<Scalars['Int']['output']>;
  calendarName: Maybe<Scalars['String']['output']>;
  cancelOperation: Operation;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy: Maybe<SimpleUser>;
  consult: Maybe<Consult>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  durationInMinutes: Scalars['Int']['output'];
  events: Array<AcuityAppointmentEvent>;
  forms: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  lateCancellationPrice: Maybe<Price>;
  lateReschedulePrice: Maybe<Price>;
  localDate: Maybe<Scalars['String']['output']>;
  localTime: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  patient: Maybe<SimpleUser>;
  positionedAt: Scalars['ISO8601DateTime']['output'];
  postConsultWorkflowStep: Maybe<WorkflowStep>;
  reasonForAppointment: Maybe<Scalars['String']['output']>;
  rescheduleOperation: Operation;
  resetPostConsultFormOperation: Operation;
  scheduledAt: Scalars['ISO8601DateTime']['output'];
  timezone: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  videoEnabled: Scalars['Boolean']['output'];
  workflowSteps: Array<WorkflowStep>;
};

/** The connection type for AcuityAppointment. */
export type AcuityAppointmentConnection = {
  __typename?: 'AcuityAppointmentConnection';
  /** A list of edges. */
  edges: Array<AcuityAppointmentEdge>;
  /** A list of nodes. */
  nodes: Array<AcuityAppointment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AcuityAppointmentEdge = {
  __typename?: 'AcuityAppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AcuityAppointment;
};

/** An booking event in Acuity (ie; scheduled, re-scheduled, canceled) */
export type AcuityAppointmentEvent = JourneyItem & {
  __typename?: 'AcuityAppointmentEvent';
  appointment: Maybe<AcuityAppointment>;
  brand: Maybe<Brand>;
  calendar: Maybe<AcuityCalendar>;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  clinician: Maybe<SimpleUser>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy: Maybe<SimpleUser>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  junoAppointmentId: Maybe<Scalars['ID']['output']>;
  patient: User;
  positionedAt: Scalars['ISO8601DateTime']['output'];
  processedInEmr: Maybe<Scalars['Boolean']['output']>;
  rawJson: Maybe<Scalars['JSON']['output']>;
  scheduledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Filter criteria for Acuity appointments */
export type AcuityAppointmentFilter = {
  /** Only return appointments for this brand */
  brandId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments for this calendar */
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments for this clinician */
  clinicianId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments for this patient */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments within this province */
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments taking place after this date */
  scheduledAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Only return appointments taking place before this date */
  scheduledBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** An Acuity appointment type */
export type AcuityAppointmentType = {
  __typename?: 'AcuityAppointmentType';
  acuityAccount: Maybe<AcuityAccount>;
  acuityAppointmentTypeId: Scalars['ID']['output'];
  availableContactTypes: Array<AppointmentContactType>;
  bookingType: AcuityBooking;
  brand: Maybe<Brand>;
  contactTypeSelectionEnabled: Scalars['Boolean']['output'];
  durationInMinutes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lateReschedulePrice: Maybe<Price>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Maybe<Price>;
  province: Maybe<ProvinceState>;
};

/** The type of an acuity appointment booking */
export enum AcuityBooking {
  /** A longer appointment time. Most often used for initial consultations with a patient */
  Extended = 'extended',
  /** A shorter appointment time used for quick followups with a patient */
  Followup = 'followup',
  FollowupAssessment = 'followup_assessment',
  FollowupMd = 'followup_md',
  FollowupOther = 'followup_other',
  /** Appointment type for booking into an RN's calendar (an MD is not required) */
  FollowupRn = 'followup_rn',
  FollowupStable = 'followup_stable',
  FollowupTitration = 'followup_titration',
  /** An initial appointment type that will take place with an MD */
  InitialMdAssessment = 'initial_md_assessment',
  /** Appointment type for booking into an RN's calendar (an MD is not required) */
  InitialRn = 'initial_rn',
  /** Appointment type for booking an initial appointment into an RN's calendar */
  IntialRnConsult = 'intial_rn_consult',
}

/** An Acuity calendar that can be booked into by a patient. Owned by a provider/clinician */
export type AcuityCalendar = {
  __typename?: 'AcuityCalendar';
  acuityAccount: Maybe<AcuityAccount>;
  /** @deprecated Use appointments instead */
  acuityAppointments: AcuityAppointmentConnection;
  acuityCalendarId: Scalars['ID']['output'];
  appointments: AppointmentConnection;
  clinician: SimpleUser;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  province: ProvinceState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** An Acuity calendar that can be booked into by a patient. Owned by a provider/clinician */
export type AcuityCalendarAcuityAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An Acuity calendar that can be booked into by a patient. Owned by a provider/clinician */
export type AcuityCalendarAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for AcuityCalendar. */
export type AcuityCalendarConnection = {
  __typename?: 'AcuityCalendarConnection';
  /** A list of edges. */
  edges: Array<AcuityCalendarEdge>;
  /** A list of nodes. */
  nodes: Array<AcuityCalendar>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AcuityCalendarEdge = {
  __typename?: 'AcuityCalendarEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AcuityCalendar;
};

/** Filter criteria for Acuity calendars */
export type AcuityCalendarFilter = {
  /** Only return calendars for a clinician */
  clinicianId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return calendars within a province */
  provinceId?: InputMaybe<Scalars['ID']['input']>;
};

/** Represents a user-triggered event between a Freddie user and Acuity */
export type AcuityEvent = {
  __typename?: 'AcuityEvent';
  acuityId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  response: Scalars['JSON']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SimpleUser;
};

export enum AddressTypeEnum {
  Commercial = 'COMMERCIAL',
  PostOffice = 'POST_OFFICE',
  Residential = 'RESIDENTIAL',
}

/** An agreement that requires user consent */
export type Agreement =
  | AgreementClient
  | AgreementFreddieAsync
  | AgreementFreddieDoxyPepAsync
  | AgreementPrivacy
  | AgreementTermsOfUse;

/** A client agreement that requires user consent */
export type AgreementClient = {
  __typename?: 'AgreementClient';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  version: Scalars['ISO8601DateTime']['output'];
};

/** A client agreement for Freddie patients to participate in the async flow for prep */
export type AgreementFreddieAsync = {
  __typename?: 'AgreementFreddieAsync';
  agreementContent: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  version: Scalars['ISO8601DateTime']['output'];
};

/** A client agreement for Freddie patients to participate in the async flow for doxy pep */
export type AgreementFreddieDoxyPepAsync = {
  __typename?: 'AgreementFreddieDoxyPepAsync';
  agreementContent: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  version: Scalars['ISO8601DateTime']['output'];
};

/** A privacy agreement that requires user consent */
export type AgreementPrivacy = {
  __typename?: 'AgreementPrivacy';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
  version: Scalars['ISO8601DateTime']['output'];
};

/** A terms of use agreement that requires user consent */
export type AgreementTermsOfUse = {
  __typename?: 'AgreementTermsOfUse';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
  version: Scalars['ISO8601DateTime']['output'];
};

/** An appointment */
export type Appointment = JourneyItem & {
  __typename?: 'Appointment';
  acuityAppointmentId: Maybe<Scalars['Int']['output']>;
  appointmentTypeId: Maybe<Scalars['Int']['output']>;
  appointmentTypeName: Maybe<Scalars['String']['output']>;
  bookingReason: Maybe<Scalars['String']['output']>;
  bookingReasonDescription: Maybe<Scalars['String']['output']>;
  brand: Maybe<Brand>;
  calendar: Maybe<AcuityCalendar>;
  calendarId: Maybe<Scalars['Int']['output']>;
  calendarName: Maybe<Scalars['String']['output']>;
  cancelOperation: Operation;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy: Maybe<SimpleUser>;
  consult: Maybe<Consult>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  durationInMinutes: Scalars['Int']['output'];
  forms: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  lateCancellationPrice: Maybe<Price>;
  lateReschedulePrice: Maybe<Price>;
  location: Maybe<Scalars['String']['output']>;
  patient: Maybe<SimpleUser>;
  positionedAt: Scalars['ISO8601DateTime']['output'];
  postConsultWorkflowStep: Maybe<WorkflowStep>;
  reasonForAppointment: Maybe<Scalars['String']['output']>;
  rescheduleOperation: Operation;
  scheduledAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  videoEnabled: Scalars['Boolean']['output'];
  workflowSteps: Array<WorkflowStep>;
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  /** A list of edges. */
  edges: Array<AppointmentEdge>;
  /** A list of nodes. */
  nodes: Array<Appointment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An appointment type's contact type (video/phone) */
export enum AppointmentContactType {
  Phone = 'phone',
  Video = 'video',
}

/** An edge in a connection. */
export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Appointment;
};

/** Appointments filter criteria */
export type AppointmentFilter = {
  /** Only return appointments for this brand */
  brandId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments for this calendar */
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments for this clinician */
  clinicianId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments for this country */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** Only return appointments for this patient */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments within this province */
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return appointments taking place after this date */
  scheduledAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Only return appointments taking place before this date */
  scheduledBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** An appointment type */
export type AppointmentType = {
  __typename?: 'AppointmentType';
  acuityAccount: Maybe<AcuityAccount>;
  acuityAppointmentTypeId: Scalars['ID']['output'];
  availableContactTypes: Array<AppointmentContactType>;
  bookingType: AcuityBooking;
  brand: Maybe<Brand>;
  contactTypeSelectionEnabled: Scalars['Boolean']['output'];
  durationInMinutes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lateReschedulePrice: Maybe<Price>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Maybe<Price>;
  province: Maybe<ProvinceState>;
};

export type Assessment = {
  __typename?: 'Assessment';
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  data: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  maxPossibleScore: Scalars['Int']['output'];
  patientScore: Scalars['Int']['output'];
  result: AssessmentResult;
  score: Maybe<Scalars['JSON']['output']>;
  scoringErrors: Maybe<Scalars['JSON']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type AssessmentAnswer = {
  __typename?: 'AssessmentAnswer';
  question: Scalars['String']['output'];
  response: Scalars['String']['output'];
  score: Scalars['Int']['output'];
};

/** The connection type for Assessment. */
export type AssessmentConnection = {
  __typename?: 'AssessmentConnection';
  /** A list of edges. */
  edges: Array<AssessmentEdge>;
  /** A list of nodes. */
  nodes: Array<Assessment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AssessmentEdge = {
  __typename?: 'AssessmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Assessment;
};

export type AssessmentOrderInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
};

export type AssessmentResult = {
  __typename?: 'AssessmentResult';
  sections: Array<AssessmentSection>;
  totalScore: Scalars['Int']['output'];
};

export type AssessmentSection = {
  __typename?: 'AssessmentSection';
  answers: Array<AssessmentAnswer>;
  name: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  skipped: Scalars['Boolean']['output'];
};

/** The different assessment types a patient can fill out */
export enum AssessmentType {
  Asrs = 'asrs',
  Gad7 = 'gad7',
  MedicationResponse = 'medication_response',
  Phq9 = 'phq9',
  Wfirs = 'wfirs',
}

export type AssessmentTypeFilter = {
  /** Filter assessments by type */
  assessmentType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter assessments by patient id */
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  deleteOperation: Operation;
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Filter Acuity appointment dates/times by province and day/month */
export type AvailabilityTypeFilter = {
  /** Filter available appointment dates/times by an acuity appointment type */
  appointmentTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter available appointments by the booking reason */
  bookingReason?: InputMaybe<Scalars['String']['input']>;
  /** Filter available appointments by the type of the booking */
  bookingType?: InputMaybe<AcuityBooking>;
  /** Filter available appointments by a specific clinician's calendar */
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  /** An ISO 8601 date that will be used to filter by month or day depending on the query */
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Filter available appointments by province */
  province?: InputMaybe<Province>;
  /** Filter available appointment dates/times by the default booking type of a workflow step */
  workflowStepId?: InputMaybe<Scalars['ID']['input']>;
};

export type AvailableAppointmentDate = {
  __typename?: 'AvailableAppointmentDate';
  date: Scalars['ISO8601Date']['output'];
  times: Maybe<Array<AvailableAppointmentTime>>;
};

export type AvailableAppointmentTime = {
  __typename?: 'AvailableAppointmentTime';
  providerId: Maybe<Scalars['Int']['output']>;
  slotsAvailable: Maybe<Scalars['Int']['output']>;
  time: Scalars['ISO8601DateTime']['output'];
};

export type AvailableBookingTypes = {
  __typename?: 'AvailableBookingTypes';
  bookingType: Maybe<AcuityBooking>;
  id: Scalars['ID']['output'];
  workflowStepId: Maybe<Scalars['String']['output']>;
};

/** A blood pressure measurement */
export type BloodPressure = Vital & {
  __typename?: 'BloodPressure';
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  details: Scalars['JSON']['output'];
  diastolic: Scalars['Int']['output'];
  enteredBy: SimpleUser;
  id: Scalars['ID']['output'];
  isBaseline: Scalars['Boolean']['output'];
  measuredAt: Maybe<Scalars['ISO8601Date']['output']>;
  patient: SimpleUser;
  systolic: Scalars['Int']['output'];
  timingWithMedication: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of BookAppointment */
export type BookAppointmentInput = {
  /** The reason for the appointment */
  bookingReason?: InputMaybe<Scalars['String']['input']>;
  /** An optional description of the reason for the appointment */
  bookingReasonDescription?: InputMaybe<Scalars['String']['input']>;
  /** The booking type requested */
  bookingType?: InputMaybe<AcuityBooking>;
  /** The id of a specific acuity calendar to book the appointment into */
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The user's first name. Will use the first name on the user model if this is empty. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The local health number of the patient. Will use the health number on the user's profile if this is empty. */
  healthNumber?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. Will use the last name on the user model if this is empty. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /**
   * The type of meeting requested
   * @deprecated Not in use
   */
  meetingType?: InputMaybe<Meeting>;
  /** The user's phone number. This may be called by a physician. Will use the phone number on the user model if this is empty. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The province code for the booking (will be mapped to the correct calendar in Acuity) */
  province?: InputMaybe<Province>;
  /** The timeslot for the booking (from availableAppointmentTimes query) */
  timeSlot: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of BookAppointment. */
export type BookAppointmentPayload = {
  __typename?: 'BookAppointmentPayload';
  acuityResponse: Maybe<Scalars['JSON']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

/** Autogenerated input type of BookAppointmentWithBookingSession */
export type BookAppointmentWithBookingSessionInput = {
  bookingSessionId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Appointment contact type */
  contactType?: InputMaybe<ContactTypeEnum>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  /** The timeslot to book into */
  timeSlot: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of BookAppointmentWithBookingSession. */
export type BookAppointmentWithBookingSessionPayload = {
  __typename?: 'BookAppointmentWithBookingSessionPayload';
  /** @deprecated Use bookingSession.acuityAppointment instead */
  appointment: Maybe<AcuityAppointment>;
  bookingSession: BookingSession;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

/** Represents an appointment booking session. */
export type BookingSession = {
  __typename?: 'BookingSession';
  /** @deprecated Use appointment instead */
  acuityAppointment: Maybe<AcuityAppointment>;
  /** @deprecated Use appointmentType instead */
  acuityAppointmentType: AcuityAppointmentType;
  acuityErrors: Maybe<Scalars['JSON']['output']>;
  appointment: Maybe<AcuityAppointment>;
  appointmentType: AcuityAppointmentType;
  availableDates: Array<Scalars['ISO8601Date']['output']>;
  bookingReason: Maybe<Scalars['String']['output']>;
  bookingReasonDescription: Maybe<Scalars['String']['output']>;
  brand: Brand;
  coupon: Maybe<Coupon>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  paymentMethods: Array<PaymentMethod>;
  price: Maybe<Price>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SimpleUser;
  workflowStep: Maybe<WorkflowStep>;
};

/** Represents an appointment booking session. */
export type BookingSessionAvailableDatesArgs = {
  numberOfMonths?: InputMaybe<Scalars['Int']['input']>;
};

export type Brand = {
  __typename?: 'Brand';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type BrandMembership = {
  __typename?: 'BrandMembership';
  affirmInitialLoanId: Maybe<Scalars['String']['output']>;
  availableBookingTypes: Array<AvailableBookingTypes>;
  brand: Brand;
  canUseClinicalMessages: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  initialPaymentReceivedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  initialPrice: Maybe<InitialPrice>;
  name: Scalars['String']['output'];
  refillProcess: RefillProcessEnum;
  screenedOutStep: Maybe<WorkflowStep>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SimpleUser;
};

/** Autogenerated input type of CancelAppointment */
export type CancelAppointmentInput = {
  /**
   * The Acuity appointment id of the appointment that is to be canceled
   * @deprecated Use purposemed_appointment_id instead
   */
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the appointment was canceled because of a no-show. Defaults to false. */
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  /** A message to send with cancellation notifications. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** The PurposeMed appointment id of the appointment that is to be canceled */
  purposemedAppointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** The reason why the patient wants to cancel this appointment */
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CancelAppointment. */
export type CancelAppointmentPayload = {
  __typename?: 'CancelAppointmentPayload';
  /** @deprecated This will go away, use errors */
  acuityResponse: Maybe<Scalars['JSON']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

export type ClinicalTrial = {
  __typename?: 'ClinicalTrial';
  active: Scalars['Boolean']['output'];
  brand: Brand;
  createdAt: Scalars['ISO8601DateTime']['output'];
  endsAt: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startsAt: Scalars['ISO8601Date']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of CompleteAssessment */
export type CompleteAssessmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  /** The id of the assessment to be completed */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteAssessment. */
export type CompleteAssessmentPayload = {
  __typename?: 'CompleteAssessmentPayload';
  assessment: Assessment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CompleteInitialPaymentAndBooking */
export type CompleteInitialPaymentAndBookingInput = {
  /** The booking session Id */
  bookingSessionId?: InputMaybe<Scalars['ID']['input']>;
  /** The booking type requested */
  bookingType?: InputMaybe<AcuityBooking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Appointment contact type */
  contactType?: InputMaybe<ContactTypeEnum>;
  /** The timeslot for the booking (from availableAppointmentTimes query) */
  timeSlot: Scalars['ISO8601DateTime']['input'];
  /** Whether video or phone consultation is selected */
  videoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the workflow step to complete */
  workflowStepId: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteInitialPaymentAndBooking. */
export type CompleteInitialPaymentAndBookingPayload = {
  __typename?: 'CompleteInitialPaymentAndBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

/** Autogenerated input type of CompleteTreatmentCycle */
export type CompleteTreatmentCycleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the treatment cycle that has been completed */
  cycleId: Scalars['ID']['input'];
  /** Notes */
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CompleteTreatmentCycle. */
export type CompleteTreatmentCyclePayload = {
  __typename?: 'CompleteTreatmentCyclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatmentCycle: Cycle;
};

/** Autogenerated input type of CompleteWorkflowStep */
export type CompleteWorkflowStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Free-form params that will be passed to the workflow step when it is completed */
  completionParams?: InputMaybe<Scalars['JSON']['input']>;
  /** Notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The id of the workflow step that has been completed */
  workflowStepId: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteWorkflowStep. */
export type CompleteWorkflowStepPayload = {
  __typename?: 'CompleteWorkflowStepPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  workflowStep: WorkflowStep;
};

/** Autogenerated input type of CompleteWorkflowSteps */
export type CompleteWorkflowStepsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Free-form params that will be passed to the workflow step when it is completed */
  completionParams?: InputMaybe<Scalars['JSON']['input']>;
  /** Notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The ids of the workflow steps that has been completed */
  workflowStepIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of CompleteWorkflowSteps. */
export type CompleteWorkflowStepsPayload = {
  __typename?: 'CompleteWorkflowStepsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  workflowSteps: Array<WorkflowStep>;
};

/** A meeting between a clinician and a patient */
export type Consult = {
  __typename?: 'Consult';
  acuityAppointmentId: Scalars['ID']['output'];
  additionalInfo: Maybe<Array<Scalars['String']['output']>>;
  attended: Scalars['Boolean']['output'];
  bridgingNeeded: Maybe<Scalars['Boolean']['output']>;
  consultCompletedStatusReason: Maybe<Scalars['String']['output']>;
  consultNotCompletedDescription: Maybe<Scalars['String']['output']>;
  consultType: Maybe<ConsultTypeEnum>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  expectedLabDate: Maybe<Scalars['ISO8601DateTime']['output']>;
  financialExpectation: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initialConsult: Maybe<Scalars['Boolean']['output']>;
  labReqNextStep: Maybe<Scalars['String']['output']>;
  nextStep: Maybe<NextStepEnum>;
  notes: Maybe<Scalars['String']['output']>;
  otherNextStep: Maybe<Scalars['String']['output']>;
  otherRxNextStep: Maybe<Array<Scalars['String']['output']>>;
  prepOnDemand: Maybe<Scalars['String']['output']>;
  prepRxNextStep: Maybe<Scalars['String']['output']>;
  prepStatus: Maybe<Scalars['Boolean']['output']>;
  prepStatusReason: Maybe<Array<Scalars['String']['output']>>;
  prepTypeLikelyToPrescribe: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export enum ConsultTypeEnum {
  Async = 'async',
  Phone = 'phone',
}

export enum ContactTypeEnum {
  InPerson = 'in_person',
  Message = 'message',
  Phone = 'phone',
  Video = 'video',
}

export type Conversation = {
  __typename?: 'Conversation';
  /** The admin/clinician who is looking after this patient conversation */
  assignedTo: Maybe<SimpleUser>;
  brand: Brand;
  confidential: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The most recent message in the conversation */
  lastMessage: Message;
  /** The time the most recent message was sent */
  lastMessageSentAt: Scalars['ISO8601DateTime']['output'];
  /** The most recent time the conversation was read by the patient */
  lastReadByPatientAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** A list of messages, ordered most recent first */
  messages: Array<Message>;
  /** The number of messages in this conversation */
  messagesCount: Scalars['Int']['output'];
  /**
   * The most recent message in the conversation
   * @deprecated Use lastMessage instead
   */
  mostRecentMessage: Message;
  notes: Maybe<Scalars['String']['output']>;
  /** The first message in the conversation */
  parentMessage: Message;
  /** All the participants in the conversation */
  participants: Array<SimpleUser>;
  /** Whether the current user has read the messages in this conversation */
  readByCurrentUser: Scalars['Boolean']['output'];
  /** Whether the patient has read all the messages in this conversation */
  readByPatient: Scalars['Boolean']['output'];
  /** Whether a clinician (or other admin/moa/etc) has read all the messages in this conversation */
  readByProvider: Scalars['Boolean']['output'];
  /** Whether the patient can reply to messages in the conversation */
  readOnly: Scalars['Boolean']['output'];
  resolvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The user who has marked the conversation as resolved */
  resolvedBy: Maybe<SimpleUser>;
  /** The status of the conversation */
  status: Scalars['HumanizedEnum']['output'];
  subject: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Conversation. */
export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  /** A list of edges. */
  edges: Array<ConversationEdge>;
  /** A list of nodes. */
  nodes: Array<Conversation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Conversation;
};

export type ConversationFilter = {
  /** Only return conversations where any anyParticipantIds are part of the conversation */
  anyParticipantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only return conversations where this user has been assigned to the conversation */
  assignedToId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Returns conversations with the specified brand
   * @deprecated Use brandIds instead
   */
  brandId?: InputMaybe<Scalars['ID']['input']>;
  /** Returns conversations with the specified brands */
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Returns conversations that are confidential (or not) */
  confidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only return conversations where all participantIDs are a part of the conversation */
  participantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * Returns conversations with that have a participant in the given province
   * @deprecated Use provinceIds instead
   */
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  /** Returns conversations with that have a participant in the given provinces */
  provinceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Returns conversations that haven't been fully read by the patient */
  readByPatient?: InputMaybe<Scalars['Boolean']['input']>;
  /** Returns conversations that haven't been fully read by a provider/admin */
  readByProvider?: InputMaybe<Scalars['Boolean']['input']>;
  /** Return conversations that have (or have not) been resolved */
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Return conversations that have been resolved by this user. Pass in `null` to get unresolved conversations */
  resolvedById?: InputMaybe<Scalars['ID']['input']>;
  /** Returns conversations with participants in the given roles */
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The status of the conversation */
  status?: InputMaybe<Array<ConversationStatus>>;
  /** Returns conversations that haven't been read by this user */
  unreadById?: InputMaybe<Scalars['ID']['input']>;
};

export type ConversationOrderInput = {
  lastMessageSentAt?: InputMaybe<SortOrderEnum>;
};

export type ConversationStatistics = {
  __typename?: 'ConversationStatistics';
  myUnreadCount: Scalars['Int']['output'];
  /** @deprecated This will be removed */
  myUnresolvedCount: Scalars['Int']['output'];
};

export enum ConversationStatus {
  /** Conversation is in progress */
  InProgress = 'in_progress',
  /** Conversation is new */
  Recent = 'recent',
  /** Conversation is resolved */
  Resolved = 'resolved',
  /** Waiting for patient response */
  WaitingOnPatient = 'waiting_on_patient',
}

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  provinces: Array<ProvinceState>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CountryFilter = {
  /** Only return provinces for the given country code */
  code?: InputMaybe<Scalars['String']['input']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
};

/** Autogenerated input type of CreateAcuityAppointment */
export type CreateAcuityAppointmentInput = {
  /** The id of acuity account (Freddie/Frida/etc.) */
  acuityAccountId: Scalars['ID']['input'];
  /** The id of the appointment type in acuity */
  acuityAppointmentTypeId: Scalars['ID']['input'];
  /** The booking type */
  bookingType: AcuityBooking;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The price for late reschedules of appointments using this appointment type */
  lateReschedulePriceId?: InputMaybe<Scalars['ID']['input']>;
  /** The locale for this appointment type */
  locale: Scalars['String']['input'];
  /** The price that will be applied to appointments using this appointment type */
  priceId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the province for this appointment type */
  provinceId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAcuityAppointment. */
export type CreateAcuityAppointmentPayload = {
  __typename?: 'CreateAcuityAppointmentPayload';
  acuityAppointmentType: Maybe<AcuityAppointmentType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAcuityCalendar */
export type CreateAcuityCalendarInput = {
  /** The id of acuity account (Freddie/Frida/etc.) */
  acuityAccountId: Scalars['ID']['input'];
  /** The id of acuity calendar */
  acuityCalendarId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the province where this calendar is valid */
  provinceId: Scalars['ID']['input'];
  /** The id of the clinician who the calendar is for. */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAcuityCalendar. */
export type CreateAcuityCalendarPayload = {
  __typename?: 'CreateAcuityCalendarPayload';
  acuityCalendar: Maybe<AcuityCalendar>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAssessment */
export type CreateAssessmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  type: AssessmentType;
};

/** Autogenerated return type of CreateAssessment. */
export type CreateAssessmentPayload = {
  __typename?: 'CreateAssessmentPayload';
  assessment: Maybe<Assessment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateConsult */
export type CreateConsultInput = {
  /** The id of acuity appointment */
  acuityAppointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Additional info needed by the patient */
  additionalInfo?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Whether the patient attended the appoinment */
  attended: Scalars['Boolean']['input'];
  /** Whether the patient requires bridging temporarily */
  bridgingNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether patient answered phone after no-show */
  consultCompletedStatusReason?: InputMaybe<Scalars['String']['input']>;
  /** Incomplete consult reason description */
  consultNotCompletedDescription?: InputMaybe<Scalars['String']['input']>;
  /** The type of consult */
  consultType?: InputMaybe<ConsultTypeEnum>;
  /** Expected lab date */
  expectedLabDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Financial expectation */
  financialExpectation?: InputMaybe<Scalars['String']['input']>;
  /** Whether this is the patient's first consult */
  initialConsult?: InputMaybe<Scalars['Boolean']['input']>;
  /** Next lab request needed immediately */
  labReqNextStep?: InputMaybe<Scalars['String']['input']>;
  /** Next step to take */
  nextStep?: InputMaybe<NextStepEnum>;
  /** Notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Other next step (next step is free text) */
  otherNextStep?: InputMaybe<Scalars['String']['input']>;
  /** Other treatment messaging needed immediately */
  otherRxNextStep?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Whether the patient initiated a conversation about PrEP on Demand */
  prepOnDemand?: InputMaybe<Scalars['String']['input']>;
  /** Next PrEP prescription messaging needed immediately */
  prepRxNextStep?: InputMaybe<Scalars['String']['input']>;
  /** Whether the patient is proceeding with PrEP treatment */
  prepStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reason(s) for discontinuing PrEP treatment */
  prepStatusReason?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The type of PrEP most likely to be prescribed to the patient */
  prepTypeLikelyToPrescribe?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateConsult. */
export type CreateConsultPayload = {
  __typename?: 'CreateConsultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  consult: Maybe<Consult>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateCredential */
export type CreateCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The designation name of the credential */
  designation: Scalars['String']['input'];
  /** The id of the province where this credential is valid */
  provinceId: Scalars['ID']['input'];
  /** The registration number of the credential */
  registrationNumber: Scalars['String']['input'];
  /**
   * The optional id of the user who the credential is for. Will add the credential
   * to the current user if this field is omitted.
   */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateCredential. */
export type CreateCredentialPayload = {
  __typename?: 'CreateCredentialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  credential: Maybe<Credential>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateEvent */
export type CreateEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  properties: Scalars['JSON']['input'];
  source: Source;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEvent. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateFridaPrescriptions */
export type CreateFridaPrescriptionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  prescriptions: Array<FridaPrescription>;
  /** Recommended date for next follow-up appointment */
  recommendedNextAppointment?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user who the requisition is for */
  userId: Scalars['ID']['input'];
  /** The id of a workflow step that is related to these prescriptions */
  workflowStepId?: InputMaybe<Scalars['ID']['input']>;
  /** Params to be passed to complete the workflow step */
  workflowStepParams?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of CreateFridaPrescriptions. */
export type CreateFridaPrescriptionsPayload = {
  __typename?: 'CreateFridaPrescriptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  prescriptions: Maybe<Array<Prescription>>;
};

/** Autogenerated input type of CreateInitialPaymentTransaction */
export type CreateInitialPaymentTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The id associated to the payment method type. For affirm this is the checkout
   * ID and for stripe it is the payment method ID
   */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** The service provider this payment method is for */
  paymentMethodType: PaymentMethodTypeEnum;
};

/** Autogenerated return type of CreateInitialPaymentTransaction. */
export type CreateInitialPaymentTransactionPayload = {
  __typename?: 'CreateInitialPaymentTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  paymentTransaction: Maybe<PaymentTransaction>;
};

/** Autogenerated input type of CreateInsuranceCard */
export type CreateInsuranceCardInput = {
  /** The signed blob id of the back image */
  backImage?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The signed blob id of the front image */
  frontImage: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

/** Autogenerated return type of CreateInsuranceCard. */
export type CreateInsuranceCardPayload = {
  __typename?: 'CreateInsuranceCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  insuranceCard: Maybe<InsuranceCard>;
};

/** Autogenerated input type of CreateLabRequisition */
export type CreateLabRequisitionInput = {
  /** The signed blob ids of the attachments */
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The optional id of a cycle that this lab req should be grouped into */
  cycleId?: InputMaybe<Scalars['ID']['input']>;
  /** Content for the secure message that is sent to the patient */
  messageContent?: InputMaybe<Scalars['String']['input']>;
  /** Subject for the secure message that is sent to the patient */
  messageSubject?: InputMaybe<Scalars['String']['input']>;
  /**
   * The notes for the lab requisition
   * @deprecated Replaced by autogenerated lab requisition feature
   */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The type of requisition */
  requisitionType: Scalars['String']['input'];
  /** The date the lab requisition was sent */
  sentAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The name of the message template */
  templateName?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user who the requisition is for */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateLabRequisition. */
export type CreateLabRequisitionPayload = {
  __typename?: 'CreateLabRequisitionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  labRequisition: Maybe<LabRequisition>;
};

/** Autogenerated input type of CreateMessage */
export type CreateMessageInput = {
  /** Whether the message is an initial async consult */
  asyncConsult?: InputMaybe<Scalars['Boolean']['input']>;
  /** The signed blob ids of the attachments */
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The id of the brand this message's conversation will belong to */
  brandId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The body text */
  content: Scalars['String']['input'];
  /** The id of the conversation of which this message will belong */
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the parent message */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the message's recipient user */
  recipientId: Scalars['ID']['input'];
  /** The id of the user who sent the message */
  senderId: Scalars['ID']['input'];
  /** The subject of the message */
  subject: Scalars['String']['input'];
};

/** Autogenerated return type of CreateMessage. */
export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  message: Maybe<Message>;
};

/** Autogenerated input type of CreatePassword */
export type CreatePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  responderUuid: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePassword. */
export type CreatePasswordPayload = {
  __typename?: 'CreatePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreatePasswordResetUrl */
export type CreatePasswordResetUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The reason why the URL was created */
  reason: Scalars['String']['input'];
  /** The ID of the user for which we are generating the URL */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePasswordResetUrl. */
export type CreatePasswordResetUrlPayload = {
  __typename?: 'CreatePasswordResetUrlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreatePatientAgreements */
export type CreatePatientAgreementsInput = {
  agreementIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePatientAgreements. */
export type CreatePatientAgreementsPayload = {
  __typename?: 'CreatePatientAgreementsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreatePrescription */
export type CreatePrescriptionInput = {
  /** Additional treatments */
  additionalTreatments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The signed blob ids of the attachments */
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Whether the prescription is a bridge prescription
   * @deprecated Removed from the form
   */
  bridging?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The optional id of a cycle that this prescription should be grouped into */
  cycleId?: InputMaybe<Scalars['ID']['input']>;
  /** The prescribed dosing regimen */
  dosingRegimen?: InputMaybe<DosingRegimenEnum>;
  /** The method of fulfillment for the prescription */
  fulfillmentMethod: Scalars['String']['input'];
  /** The medication to be prescribed */
  medication?: InputMaybe<Scalars['String']['input']>;
  /** Content for the secure message that is sent to the patient */
  messageContent?: InputMaybe<Scalars['String']['input']>;
  /** Subject for the secure message that is sent to the patient */
  messageSubject?: InputMaybe<Scalars['String']['input']>;
  /** Additional notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The type or brand of PrEP being prescribed */
  prepType?: InputMaybe<Scalars['String']['input']>;
  /** The date the prescription was prescribed */
  prescribedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The length of time in days for which this prescription is valid */
  prescriptionLength?: InputMaybe<Scalars['Int']['input']>;
  /** The type of prescription */
  prescriptionType?: InputMaybe<Scalars['String']['input']>;
  /** The name of the message template */
  templateName?: InputMaybe<Scalars['String']['input']>;
  /** The date the treatment is scheduled to start */
  treatmentStartAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The id of the user who the requisition is for */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePrescription. */
export type CreatePrescriptionPayload = {
  __typename?: 'CreatePrescriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  prescription: Maybe<Prescription>;
};

/** Autogenerated input type of CreateProvider */
export type CreateProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateProvider. */
export type CreateProviderPayload = {
  __typename?: 'CreateProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  provider: Maybe<User>;
};

/** Autogenerated input type of CreateShippingAddress */
export type CreateShippingAddressInput = {
  addressType?: InputMaybe<AddressTypeEnum>;
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
  recipientName?: InputMaybe<Scalars['String']['input']>;
  /** Primary street address */
  street: Scalars['String']['input'];
  /** Secondary address details (unit #, suite, etc.) */
  street2?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateShippingAddress. */
export type CreateShippingAddressPayload = {
  __typename?: 'CreateShippingAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  shippingAddress: Maybe<ShippingAddress>;
};

/** Autogenerated input type of CreateStripeInvoice */
export type CreateStripeInvoiceInput = {
  /** The id of the brand the user will be charged under */
  brandId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user who will be sent the invoice. */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateStripeInvoice. */
export type CreateStripeInvoicePayload = {
  __typename?: 'CreateStripeInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  invoiceId: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user who needs a stripe setup intent. Will only be used if the caller is a provider */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateStripeSetupIntent. */
export type CreateStripeSetupIntentPayload = {
  __typename?: 'CreateStripeSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  clientSecret: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateStripeVerificationSession */
export type CreateStripeVerificationSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateStripeVerificationSession. */
export type CreateStripeVerificationSessionPayload = {
  __typename?: 'CreateStripeVerificationSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  clientSecret: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateVital */
export type CreateVitalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: Scalars['JSON']['input'];
  /** The date the measurement was taken */
  measuredAt: Scalars['ISO8601Date']['input'];
  /** The optional id of the patient the vital will belong to. For providers only. */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  type: VitalType;
};

/** Autogenerated return type of CreateVital. */
export type CreateVitalPayload = {
  __typename?: 'CreateVitalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  vital: Maybe<Vital>;
};

export type Credential = {
  __typename?: 'Credential';
  createdAt: Scalars['ISO8601DateTime']['output'];
  designation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  province: ProvinceState;
  registrationNumber: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SimpleUser;
};

export type Cycle = JourneyItem & {
  __typename?: 'Cycle';
  abortedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  abortedBy: Maybe<SimpleUser>;
  brand: Brand;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy: Maybe<SimpleUser>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  labRequisition: Maybe<LabRequisition>;
  /** @deprecated Replaced by autogenerated lab requisition feature */
  notes: Scalars['String']['output'];
  patient: SimpleUser;
  positionedAt: Scalars['ISO8601DateTime']['output'];
  treatmentType: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workflowSteps: Array<WorkflowStep>;
};

/** The connection type for Cycle. */
export type CycleConnection = {
  __typename?: 'CycleConnection';
  /** A list of edges. */
  edges: Array<CycleEdge>;
  /** A list of nodes. */
  nodes: Array<Cycle>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CycleEdge = {
  __typename?: 'CycleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Cycle;
};

export type CycleFilter = {
  /** Filter by who aborted a cycle */
  abortedById?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by who completed a cycle */
  completedById?: InputMaybe<Scalars['ID']['input']>;
  /** Filter cycles by patient */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter cycles by province */
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by the treatment type */
  treatmentType?: InputMaybe<Scalars['String']['input']>;
};

export type CycleOrderInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type DashboardItem = {
  __typename?: 'DashboardItem';
  name: Maybe<Scalars['String']['output']>;
  treatmentType: Maybe<TreatmentType>;
  workflowStep: Maybe<WorkflowStep>;
};

/** Autogenerated input type of DeactivateUserAccount */
export type DeactivateUserAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user to be de-activated */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeactivateUserAccount. */
export type DeactivateUserAccountPayload = {
  __typename?: 'DeactivateUserAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
  user: Maybe<User>;
};

/** Autogenerated input type of DeleteAcuityAppointment */
export type DeleteAcuityAppointmentInput = {
  /** The id of the appointment type to delete. */
  acuityAppointmentTypeId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteAcuityAppointment. */
export type DeleteAcuityAppointmentPayload = {
  __typename?: 'DeleteAcuityAppointmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAcuityCalendar */
export type DeleteAcuityCalendarInput = {
  /** The id of the calendar to delete. */
  acuityCalendarId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteAcuityCalendar. */
export type DeleteAcuityCalendarPayload = {
  __typename?: 'DeleteAcuityCalendarPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAttachment */
export type DeleteAttachmentInput = {
  /** The id of the attachment to be deleted */
  attachmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The reason why the attachment was deleted */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteAttachment. */
export type DeleteAttachmentPayload = {
  __typename?: 'DeleteAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteCredential */
export type DeleteCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the credential that is to be deleted */
  credentialId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCredential. */
export type DeleteCredentialPayload = {
  __typename?: 'DeleteCredentialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteInsuranceCard */
export type DeleteInsuranceCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the insurance card to be deleted */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInsuranceCard. */
export type DeleteInsuranceCardPayload = {
  __typename?: 'DeleteInsuranceCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

/** Autogenerated input type of DeleteLabRequisition */
export type DeleteLabRequisitionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the lab requisition to be deleted */
  labRequisitionId: Scalars['ID']['input'];
  /** The reason the lab requisition is being deleted */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteLabRequisition. */
export type DeleteLabRequisitionPayload = {
  __typename?: 'DeleteLabRequisitionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteMessage */
export type DeleteMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the message to be deleted */
  id: Scalars['ID']['input'];
  /** The reason for deleting the message */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteMessage. */
export type DeleteMessagePayload = {
  __typename?: 'DeleteMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of DeletePaymentMethod */
export type DeletePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the payment method to be deleted */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePaymentMethod. */
export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

/** Autogenerated input type of DeletePrescription */
export type DeletePrescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the credential that is to be deleted */
  prescriptionId: Scalars['ID']['input'];
  /** The reason why the prescription was deleted */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of DeletePrescription. */
export type DeletePrescriptionPayload = {
  __typename?: 'DeletePrescriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteShippingAddress */
export type DeleteShippingAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the shipping address to be deleted */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteShippingAddress. */
export type DeleteShippingAddressPayload = {
  __typename?: 'DeleteShippingAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

export enum DeliveryPreferenceEnum {
  DeliverToShippingAddress = 'DELIVER_TO_SHIPPING_ADDRESS',
  Pickup = 'PICKUP',
}

export enum DosingRegimenEnum {
  Daily = 'daily',
  OnDemand = 'on_demand',
}

export type EmailValidation = {
  __typename?: 'EmailValidation';
  local: Maybe<Scalars['String']['output']>;
  suggestion: Maybe<Scalars['String']['output']>;
  verdict: Maybe<Scalars['String']['output']>;
};

/** A FHIR medication request */
export type FhirMedicationRequest = {
  __typename?: 'FHIRMedicationRequest';
  authoredOn: Maybe<Scalars['ISO8601Date']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  intent: Maybe<Scalars['String']['output']>;
  medication: Scalars['String']['output'];
  quantity: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export type FeatureConfiguration = {
  __typename?: 'FeatureConfiguration';
  id: Scalars['ID']['output'];
  inPersonCare: Scalars['Boolean']['output'];
};

export type FridaPrescription = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  /** Dosage in mg for the prescription */
  dosage?: InputMaybe<Scalars['String']['input']>;
  /** The temporary front-end id of the input */
  frontEndId: Scalars['ID']['input'];
  /** The method of fulfillment for the prescription */
  fulfillmentMethod: Scalars['String']['input'];
  /** The medication being prescribed */
  medication: Scalars['String']['input'];
  /** Additional notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Number of pills prescribed */
  numOfPills?: InputMaybe<Scalars['Int']['input']>;
  /** The type of prescription */
  prescriptionType?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of GenerateLoanLink */
export type GenerateLoanLinkInput = {
  cancelRedirectUrl: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  successRedirectUrl: Scalars['String']['input'];
};

/** Autogenerated return type of GenerateLoanLink. */
export type GenerateLoanLinkPayload = {
  __typename?: 'GenerateLoanLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['JSON']['output']>>;
  response: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of GoBackToPreviousQuestionnaireStep */
export type GoBackToPreviousQuestionnaireStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  responseId: Scalars['ID']['input'];
};

/** Autogenerated return type of GoBackToPreviousQuestionnaireStep. */
export type GoBackToPreviousQuestionnaireStepPayload = {
  __typename?: 'GoBackToPreviousQuestionnaireStepPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patientResponse: QuestionnaireResponse;
};

export type InitialPrice = {
  __typename?: 'InitialPrice';
  amountInCents: Scalars['Int']['output'];
  brandId: Scalars['Int']['output'];
  effectiveEndDate: Maybe<Scalars['ISO8601DateTime']['output']>;
  effectiveStartDate: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  stripePriceId: Scalars['String']['output'];
};

/** Autogenerated input type of InitiateRefill */
export type InitiateRefillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of InitiateRefill. */
export type InitiateRefillPayload = {
  __typename?: 'InitiateRefillPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type InsuranceCard = {
  __typename?: 'InsuranceCard';
  backImage: Maybe<Attachment>;
  frontImage: Attachment;
  id: Scalars['ID']['output'];
  isDefaultCard: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type JourneyItem = {
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy: Maybe<SimpleUser>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  positionedAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type JourneyItemFilter = {
  /** Set to 'true' to excludes appointment events */
  excludeAppointmentEvents?: InputMaybe<Scalars['Boolean']['input']>;
  /** Set to 'true' to excludes appointments */
  excludeAppointments?: InputMaybe<Scalars['Boolean']['input']>;
  /** Set to 'true' to excludes cycles */
  excludeCycles?: InputMaybe<Scalars['Boolean']['input']>;
  /** Set to 'true' to excludes workflow steps */
  excludeWorkflowSteps?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only return journey items positioned after this date */
  positionedAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Only return journey items positioned before this date */
  positionedBefore?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type JunoFormSubmission = {
  __typename?: 'JunoFormSubmission';
  fdid: Maybe<Scalars['Int']['output']>;
  html: Maybe<Scalars['String']['output']>;
};

export type LabRequisition = {
  __typename?: 'LabRequisition';
  attachments: Array<Attachment>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deleteOperation: Operation;
  feedbackStep: Maybe<WorkflowStep>;
  formLink: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCurrent: Scalars['Boolean']['output'];
  message: Maybe<Message>;
  /** @deprecated Replaced by autogenerated lab requisition feature */
  notes: Maybe<Scalars['String']['output']>;
  readByPatientAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  requisitionType: Maybe<Scalars['String']['output']>;
  sentAt: Scalars['ISO8601Date']['output'];
  sentBy: SimpleUser;
  updateOperation: Operation;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SimpleUser;
};

/** The connection type for LabRequisition. */
export type LabRequisitionConnection = {
  __typename?: 'LabRequisitionConnection';
  /** A list of edges. */
  edges: Array<LabRequisitionEdge>;
  /** A list of nodes. */
  nodes: Array<LabRequisition>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LabRequisitionEdge = {
  __typename?: 'LabRequisitionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LabRequisition;
};

export type LabRequisitionFilter = {
  /** Returns compeleted lab reqs when true and pending lab reqs when false */
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of Login */
export type LoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  oneTimeAccessToken?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Login. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
  jwt: Maybe<Scalars['String']['output']>;
  user: Maybe<User>;
};

/** Autogenerated input type of MarkConversationAsRead */
export type MarkConversationAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The conversation to mark as read (by the current user) */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of MarkConversationAsRead. */
export type MarkConversationAsReadPayload = {
  __typename?: 'MarkConversationAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  conversation: Maybe<Conversation>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of MarkMessageAsRead */
export type MarkMessageAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** List of message ids that will be marked as having been read */
  messageIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of MarkMessageAsRead. */
export type MarkMessageAsReadPayload = {
  __typename?: 'MarkMessageAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  messages: Maybe<Array<Message>>;
};

/** Autogenerated input type of MarkTreatmentIneligible */
export type MarkTreatmentIneligibleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eligibility: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MarkTreatmentIneligible. */
export type MarkTreatmentIneligiblePayload = {
  __typename?: 'MarkTreatmentIneligiblePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatmentEligibility: Maybe<TreatmentEligibility>;
};

export type MedicalHistoryFilter = {
  /** The amount of medical history versions to return */
  numberOfMedicalRecords?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents changes that were made to a patient's medical history */
export type MedicalHistoryVersion = {
  __typename?: 'MedicalHistoryVersion';
  allergies: Maybe<Scalars['String']['output']>;
  medInfoConfirmedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  medicalConditions: Maybe<Scalars['String']['output']>;
  medications: Maybe<Scalars['String']['output']>;
};

export enum MedicationStatusEnum {
  Active = 'active',
  Ineligible = 'ineligible',
  NeedsClarification = 'needs_clarification',
  Onboarding = 'onboarding',
  Paused = 'paused',
  Stopped = 'stopped',
}

export enum Meeting {
  /** Physician will call the patient on their phone */
  Phone = 'Phone',
  /** Patient will to to app.gofreddie.com for a video chat with a physician */
  Video = 'Video',
}

export type Message = {
  __typename?: 'Message';
  asyncConsult: Maybe<Consult>;
  attachments: Array<Attachment>;
  children: Maybe<Array<Message>>;
  content: Scalars['String']['output'];
  conversation: Conversation;
  conversationId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deleteOperation: Operation;
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  parent: Maybe<Message>;
  readAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  readReceipts: Array<ReadReceipt>;
  recipient: SimpleUser;
  sender: SimpleUser;
  subject: Scalars['String']['output'];
  templateName: Scalars['String']['output'];
  unreadCount: Scalars['Int']['output'];
  updateOperation: Operation;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of edges. */
  edges: Array<MessageEdge>;
  /** A list of nodes. */
  nodes: Array<Message>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Message;
};

/** Attributes for creating or updating a message */
export type MessageInput = {
  /** The body text */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The id of the parent message */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the message's recipient user */
  recipientId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the user who sent the message */
  senderId?: InputMaybe<Scalars['ID']['input']>;
  /** The subject of the message */
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type MessageStatistics = {
  __typename?: 'MessageStatistics';
  conversations: ConversationStatistics;
  /** @deprecated This will be removed */
  receivedCount: Scalars['Int']['output'];
  /** @deprecated This will be removed */
  sentCount: Scalars['Int']['output'];
  /** @deprecated This will be removed */
  unreadCount: Scalars['Int']['output'];
};

export type MessageTypeFilter = {
  /** Only return conversations where this user is the sender or recipient */
  otherParticipantId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return conversations where this user is the recipient */
  recipientId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return conversations where this user is the sender */
  senderId?: InputMaybe<Scalars['ID']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mark a cycle as aborted */
  abortTreatmentCycle: Maybe<AbortTreatmentCyclePayload>;
  /** Activates a user account that had been previously de-activated. */
  activateUserAccount: Maybe<ActivateUserAccountPayload>;
  /** Books an appointment in Acuity for the current user */
  bookAppointment: Maybe<BookAppointmentPayload>;
  /**
   * Books or reschedules an appointment in Acuity for the current user using a
   * booking session.If the booking session has already been assigned an
   * appointment then the appointment will be rescheduled
   */
  bookAppointmentWithBookingSession: Maybe<BookAppointmentWithBookingSessionPayload>;
  /** Cancels an appointment in Acuity for the current user */
  cancelAppointment: Maybe<CancelAppointmentPayload>;
  /** Complete an assessment */
  completeAssessment: Maybe<CompleteAssessmentPayload>;
  /** Processes payment and books an initial assessment in Acuity for the current user */
  completeInitialPaymentAndBooking: Maybe<CompleteInitialPaymentAndBookingPayload>;
  /** Mark a cycle as completed */
  completeTreatmentCycle: Maybe<CompleteTreatmentCyclePayload>;
  /** Mark a workflow step as completed */
  completeWorkflowStep: Maybe<CompleteWorkflowStepPayload>;
  /** Mark multiple workflow steps as completed */
  completeWorkflowSteps: Maybe<CompleteWorkflowStepsPayload>;
  /** Creates a new appointment type */
  createAcuityAppointmentType: Maybe<CreateAcuityAppointmentPayload>;
  /** Creates a new clinician calendar */
  createAcuityCalendar: Maybe<CreateAcuityCalendarPayload>;
  /** Creates a new blank assessment for the current user */
  createAssessment: Maybe<CreateAssessmentPayload>;
  /** Creates a consult */
  createConsult: Maybe<CreateConsultPayload>;
  /** Creates a new clinician credential */
  createCredential: Maybe<CreateCredentialPayload>;
  /** Creates an event for analytics */
  createEvent: Maybe<CreateEventPayload>;
  /** Allows a provider to create multiple prescriptions for a Frida patient */
  createFridaPrescriptions: Maybe<CreateFridaPrescriptionsPayload>;
  /** Creates a new payment method and payment transaction for completing an initial charge */
  createInitialPaymentTransaction: Maybe<CreateInitialPaymentTransactionPayload>;
  /** Creates a new insurance card for the current user */
  createInsuranceCard: Maybe<CreateInsuranceCardPayload>;
  /** Creates a lab requisition */
  createLabRequisition: Maybe<CreateLabRequisitionPayload>;
  /** Creates a new message */
  createMessage: Maybe<CreateMessagePayload>;
  /** Creates a password for a user's account */
  createPassword: Maybe<CreatePasswordPayload>;
  /** Creates a password reset URL for a user */
  createPasswordResetUrl: Maybe<CreatePasswordResetUrlPayload>;
  createPatientAgreements: Maybe<CreatePatientAgreementsPayload>;
  /** Allows a provider to create a prescription for a patient */
  createPrescription: Maybe<CreatePrescriptionPayload>;
  /** Creates a new provider */
  createProvider: Maybe<CreateProviderPayload>;
  /** Creates a new shipping address for the current user */
  createShippingAddress: Maybe<CreateShippingAddressPayload>;
  /** Creates, charges, and sends an invoice to a user */
  createStripeInvoice: Maybe<CreateStripeInvoicePayload>;
  /** Creates a SetupIntent in Stripe for the patient's customer account. */
  createStripeSetupIntent: Maybe<CreateStripeSetupIntentPayload>;
  /** Creates or retrieves a VerificationSession in Stripe for the patient's account. */
  createStripeVerificationSession: Maybe<CreateStripeVerificationSessionPayload>;
  /** Creates a new vital for the current user. Providers can create vitals for any patient. */
  createVital: Maybe<CreateVitalPayload>;
  /** Deactivates a user account so they can no longer log in. Will delete the user after a certain period of time (7 days) */
  deactivateUserAccount: Maybe<DeactivateUserAccountPayload>;
  /** Deletes an appointment type */
  deleteAcuityAppointmentType: Maybe<DeleteAcuityAppointmentPayload>;
  /** Deletes a clinician calendar */
  deleteAcuityCalendar: Maybe<DeleteAcuityCalendarPayload>;
  /** Deletes an existing attachment */
  deleteAttachment: Maybe<DeleteAttachmentPayload>;
  /** Deletes an existing clinician credential */
  deleteCredential: Maybe<DeleteCredentialPayload>;
  /** Deletes an existing insurance card */
  deleteInsuranceCard: Maybe<DeleteInsuranceCardPayload>;
  /** Delete a treatment cycle */
  deleteLabRequisition: Maybe<DeleteLabRequisitionPayload>;
  /** Deletes a message */
  deleteMessage: Maybe<DeleteMessagePayload>;
  /** Deletes an existing payment method */
  deletePaymentMethod: Maybe<DeletePaymentMethodPayload>;
  /** Deletes an existing prescription */
  deletePrescription: Maybe<DeletePrescriptionPayload>;
  /** Deletes an existing shipping address */
  deleteShippingAddress: Maybe<DeleteShippingAddressPayload>;
  /** Affirm redirect link generation for the provided user */
  generateLoanLink: Maybe<GenerateLoanLinkPayload>;
  /** Go back to the previous completed step in a questionnaire */
  goBackToPreviousQuestionnaireStep: Maybe<GoBackToPreviousQuestionnaireStepPayload>;
  initiateRefill: Maybe<InitiateRefillPayload>;
  /**
   * Trades a set of credentials for a JWT. An email is required, as well as one of
   * either a password or a one-time access token
   */
  login: Maybe<LoginPayload>;
  /** Marks a conversation as read and adds read receipts to all associated messages */
  markConversationAsRead: Maybe<MarkConversationAsReadPayload>;
  /** Marks a message and its child messages as being read by the current user */
  markMessageAsRead: Maybe<MarkMessageAsReadPayload>;
  /** Updates the eligibility of a patient to ineligible */
  markTreatmentIneligible: Maybe<MarkTreatmentIneligiblePayload>;
  /** Creates a new additional treatment in an onboarding state for the current user */
  onboardAdditionalTreatment: Maybe<OnboardAdditionalTreatmentPayload>;
  /**
   * Allows a provider to override a completed step. The step will be re-completed
   * with the new completion_params passed to this mutation.
   */
  overrideWorkflowStep: Maybe<OverrideWorkflowStepPayload>;
  /** Pauses a treatment */
  pauseTreatment: Maybe<PauseTreatmentPayload>;
  /** Send an email with password reset instructions */
  recoverPassword: Maybe<RecoverPasswordPayload>;
  /** Register a user for a referral program */
  referralProgramRegister: Maybe<ReferralProgramRegisterPayload>;
  /** Refund a successful paid payment transaction for the provided transaction ID */
  refundPaymentTransaction: Maybe<RefundPaymentTransactionPayload>;
  /** Re-schedule an existing acuity appointment */
  rescheduleAppointment: Maybe<RescheduleAppointmentPayload>;
  /** Update a user's password when a valid recovery token is included */
  resetPassword: Maybe<ResetPasswordPayload>;
  /** Resets the Post consult form */
  resetPostConsultForm: Maybe<ResetPostConsultFormPayload>;
  /** Creates a new treatment by type in an onboarding state and starts a questionnaire for a patient */
  resetTreatmentAndQuestionnaire: Maybe<ResetTreatmentAndQuestionnairePayload>;
  /** Marks a workflow step that was previously completed as incomplete */
  resetWorkflowStep: Maybe<ResetWorkflowStepPayload>;
  /**
   * Restarts treatment for a patient that has previously stopped treatment.
   * @deprecated Use startTreatment instead
   */
  restartTreatment: Maybe<RestartTreatmentPayload>;
  /** Manually screens in a Frida patient */
  screenInPatient: Maybe<ScreenInPatientPayload>;
  /** Send a verification code to the given phone number */
  sendPhoneNumberVerificationCode: Maybe<SendPhoneNumberVerificationCodePayload>;
  /** Signs up a new patient */
  signupPatient: Maybe<SignupPatientPayload>;
  /** Start a new booking session with then end goal of booking or rescheduling an appointment. */
  startBookingSession: Maybe<StartBookingSessionPayload>;
  /** Starts the refill process for a patient for a treatment */
  startPatientRefill: Maybe<StartPatientRefillPayload>;
  /** Start or resume a questionnaire for the current user. */
  startQuestionnaire: Maybe<StartQuestionnairePayload>;
  /** Starts treatment for the current user. Will restart if the treatment was previously stopped. */
  startTreatment: Maybe<StartTreatmentPayload>;
  /** Stops treatment for the current user. */
  stopTreatment: Maybe<StopTreatmentPayload>;
  /** Submit answers to questions on a single questionnaire page */
  submitQuestionnaireAnswers: Maybe<SubmitQuestionnaireAnswersPayload>;
  /** Updates an appointment type */
  updateAcuityAppointmentType: Maybe<UpdateAcuityAppointmentPayload>;
  /** Updates an existing clinician calendar */
  updateAcuityCalendar: Maybe<UpdateAcuityCalendarPayload>;
  /** Updates an existing assessment for the current user */
  updateAssessment: Maybe<UpdateAssessmentPayload>;
  /** Updates a conversation */
  updateConversation: Maybe<UpdateConversationPayload>;
  /** Updates an existing clinician credential */
  updateCredential: Maybe<UpdateCredentialPayload>;
  /** Updates the passed in insurance card */
  updateInsuranceCard: Maybe<UpdateInsuranceCardPayload>;
  /** Updates a patients lab requisition */
  updateLabRequisition: Maybe<UpdateLabRequisitionPayload>;
  /** Updates when a patient has viewed their lab requisition */
  updateLabRequisitionViewedAt: Maybe<UpdateLabRequisitionViewedAtPayload>;
  /** Updates a message */
  updateMessage: Maybe<UpdateMessagePayload>;
  /** Updates the current patient's info */
  updatePatient: Maybe<UpdatePatientPayload>;
  /** Updates the pill counter for a patient */
  updatePillCounter: Maybe<UpdatePillCounterPayload>;
  /** Allows a provider to update a prescription */
  updatePrescription: Maybe<UpdatePrescriptionPayload>;
  /** Updates a provider */
  updateProvider: Maybe<UpdateProviderPayload>;
  /** Updates the passed in shipping address */
  updateShippingAddress: Maybe<UpdateShippingAddressPayload>;
  /** Updates the treatment eligibility */
  updateTreatmentEligibility: Maybe<UpdateTreatmentEligibilityPayload>;
  /** Update a single vital */
  updateVital: Maybe<UpdateVitalPayload>;
  /** Updates a workflow step */
  updateWorkflowStep: Maybe<UpdateWorkflowStepPayload>;
  /** Validates a email address */
  validateEmail: Maybe<ValidateEmailPayload>;
  /** Verify that a phone number verification code is correct */
  verifyPhoneNumberVerificationCode: Maybe<VerifyPhoneNumberVerificationCodePayload>;
  /** Void/cancel payment for the provided transaction id */
  voidPayment: Maybe<VoidPaymentPayload>;
};

export type MutationAbortTreatmentCycleArgs = {
  input: AbortTreatmentCycleInput;
};

export type MutationActivateUserAccountArgs = {
  input: ActivateUserAccountInput;
};

export type MutationBookAppointmentArgs = {
  input: BookAppointmentInput;
};

export type MutationBookAppointmentWithBookingSessionArgs = {
  input: BookAppointmentWithBookingSessionInput;
};

export type MutationCancelAppointmentArgs = {
  input: CancelAppointmentInput;
};

export type MutationCompleteAssessmentArgs = {
  input: CompleteAssessmentInput;
};

export type MutationCompleteInitialPaymentAndBookingArgs = {
  input: CompleteInitialPaymentAndBookingInput;
};

export type MutationCompleteTreatmentCycleArgs = {
  input: CompleteTreatmentCycleInput;
};

export type MutationCompleteWorkflowStepArgs = {
  input: CompleteWorkflowStepInput;
};

export type MutationCompleteWorkflowStepsArgs = {
  input: CompleteWorkflowStepsInput;
};

export type MutationCreateAcuityAppointmentTypeArgs = {
  input: CreateAcuityAppointmentInput;
};

export type MutationCreateAcuityCalendarArgs = {
  input: CreateAcuityCalendarInput;
};

export type MutationCreateAssessmentArgs = {
  input: CreateAssessmentInput;
};

export type MutationCreateConsultArgs = {
  input: CreateConsultInput;
};

export type MutationCreateCredentialArgs = {
  input: CreateCredentialInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateFridaPrescriptionsArgs = {
  input: CreateFridaPrescriptionsInput;
};

export type MutationCreateInitialPaymentTransactionArgs = {
  input: CreateInitialPaymentTransactionInput;
};

export type MutationCreateInsuranceCardArgs = {
  input: CreateInsuranceCardInput;
};

export type MutationCreateLabRequisitionArgs = {
  input: CreateLabRequisitionInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreatePasswordArgs = {
  input: CreatePasswordInput;
};

export type MutationCreatePasswordResetUrlArgs = {
  input: CreatePasswordResetUrlInput;
};

export type MutationCreatePatientAgreementsArgs = {
  input: CreatePatientAgreementsInput;
};

export type MutationCreatePrescriptionArgs = {
  input: CreatePrescriptionInput;
};

export type MutationCreateProviderArgs = {
  input: CreateProviderInput;
};

export type MutationCreateShippingAddressArgs = {
  input: CreateShippingAddressInput;
};

export type MutationCreateStripeInvoiceArgs = {
  input: CreateStripeInvoiceInput;
};

export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};

export type MutationCreateStripeVerificationSessionArgs = {
  input: CreateStripeVerificationSessionInput;
};

export type MutationCreateVitalArgs = {
  input: CreateVitalInput;
};

export type MutationDeactivateUserAccountArgs = {
  input: DeactivateUserAccountInput;
};

export type MutationDeleteAcuityAppointmentTypeArgs = {
  input: DeleteAcuityAppointmentInput;
};

export type MutationDeleteAcuityCalendarArgs = {
  input: DeleteAcuityCalendarInput;
};

export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput;
};

export type MutationDeleteCredentialArgs = {
  input: DeleteCredentialInput;
};

export type MutationDeleteInsuranceCardArgs = {
  input: DeleteInsuranceCardInput;
};

export type MutationDeleteLabRequisitionArgs = {
  input: DeleteLabRequisitionInput;
};

export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};

export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

export type MutationDeletePrescriptionArgs = {
  input: DeletePrescriptionInput;
};

export type MutationDeleteShippingAddressArgs = {
  input: DeleteShippingAddressInput;
};

export type MutationGenerateLoanLinkArgs = {
  input: GenerateLoanLinkInput;
};

export type MutationGoBackToPreviousQuestionnaireStepArgs = {
  input: GoBackToPreviousQuestionnaireStepInput;
};

export type MutationInitiateRefillArgs = {
  input: InitiateRefillInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationMarkConversationAsReadArgs = {
  input: MarkConversationAsReadInput;
};

export type MutationMarkMessageAsReadArgs = {
  input: MarkMessageAsReadInput;
};

export type MutationMarkTreatmentIneligibleArgs = {
  input: MarkTreatmentIneligibleInput;
};

export type MutationOnboardAdditionalTreatmentArgs = {
  input: OnboardAdditionalTreatmentInput;
};

export type MutationOverrideWorkflowStepArgs = {
  input: OverrideWorkflowStepInput;
};

export type MutationPauseTreatmentArgs = {
  input: PauseTreatmentInput;
};

export type MutationRecoverPasswordArgs = {
  input: RecoverPasswordInput;
};

export type MutationReferralProgramRegisterArgs = {
  input: ReferralProgramRegisterInput;
};

export type MutationRefundPaymentTransactionArgs = {
  input: RefundPaymentTransactionInput;
};

export type MutationRescheduleAppointmentArgs = {
  input: RescheduleAppointmentInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationResetPostConsultFormArgs = {
  input: ResetPostConsultFormInput;
};

export type MutationResetTreatmentAndQuestionnaireArgs = {
  input: ResetTreatmentAndQuestionnaireInput;
};

export type MutationResetWorkflowStepArgs = {
  input: ResetWorkflowStepInput;
};

export type MutationRestartTreatmentArgs = {
  input: RestartTreatmentInput;
};

export type MutationScreenInPatientArgs = {
  input: ScreenInPatientInput;
};

export type MutationSendPhoneNumberVerificationCodeArgs = {
  input: SendPhoneNumberVerificationCodeInput;
};

export type MutationSignupPatientArgs = {
  input: SignupPatientInput;
};

export type MutationStartBookingSessionArgs = {
  input: StartBookingSessionInput;
};

export type MutationStartPatientRefillArgs = {
  input: StartPatientRefillInput;
};

export type MutationStartQuestionnaireArgs = {
  input: StartQuestionnaireInput;
};

export type MutationStartTreatmentArgs = {
  input: StartTreatmentInput;
};

export type MutationStopTreatmentArgs = {
  input: StopTreatmentInput;
};

export type MutationSubmitQuestionnaireAnswersArgs = {
  input: SubmitQuestionnaireAnswersInput;
};

export type MutationUpdateAcuityAppointmentTypeArgs = {
  input: UpdateAcuityAppointmentInput;
};

export type MutationUpdateAcuityCalendarArgs = {
  input: UpdateAcuityCalendarInput;
};

export type MutationUpdateAssessmentArgs = {
  input: UpdateAssessmentInput;
};

export type MutationUpdateConversationArgs = {
  input: UpdateConversationInput;
};

export type MutationUpdateCredentialArgs = {
  input: UpdateCredentialInput;
};

export type MutationUpdateInsuranceCardArgs = {
  input: UpdateInsuranceCardInput;
};

export type MutationUpdateLabRequisitionArgs = {
  input: UpdateLabRequisitionInput;
};

export type MutationUpdateLabRequisitionViewedAtArgs = {
  input: UpdateLabRequisitionViewedAtInput;
};

export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
};

export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
};

export type MutationUpdatePillCounterArgs = {
  input: UpdatePillCounterInput;
};

export type MutationUpdatePrescriptionArgs = {
  input: UpdatePrescriptionInput;
};

export type MutationUpdateProviderArgs = {
  input: UpdateProviderInput;
};

export type MutationUpdateShippingAddressArgs = {
  input: UpdateShippingAddressInput;
};

export type MutationUpdateTreatmentEligibilityArgs = {
  input: UpdateTreatmentEligibilityInput;
};

export type MutationUpdateVitalArgs = {
  input: UpdateVitalInput;
};

export type MutationUpdateWorkflowStepArgs = {
  input: UpdateWorkflowStepInput;
};

export type MutationValidateEmailArgs = {
  input: ValidateEmailInput;
};

export type MutationVerifyPhoneNumberVerificationCodeArgs = {
  input: VerifyPhoneNumberVerificationCodeInput;
};

export type MutationVoidPaymentArgs = {
  input: VoidPaymentInput;
};

export enum NextStepEnum {
  FollowUp = 'FOLLOW_UP',
  IneligibleForPrep = 'INELIGIBLE_FOR_PREP',
  Nothing = 'NOTHING',
  Other = 'OTHER',
  SendLabReq = 'SEND_LAB_REQ',
  StiManagement = 'STI_MANAGEMENT',
}

/** Autogenerated input type of OnboardAdditionalTreatment */
export type OnboardAdditionalTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentType: TreatmentType;
};

/** Autogenerated return type of OnboardAdditionalTreatment. */
export type OnboardAdditionalTreatmentPayload = {
  __typename?: 'OnboardAdditionalTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  questionnaireStepId: Maybe<Scalars['ID']['output']>;
  treatment: Maybe<Treatment>;
};

export type Operation = {
  __typename?: 'Operation';
  canPerform: Scalars['Boolean']['output'];
  invalidReasons: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of OverrideWorkflowStep */
export type OverrideWorkflowStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Free-form json params. Leave blank to keep these the same. */
  completionParams?: InputMaybe<Scalars['JSON']['input']>;
  /** The id of the workflow step that is to be overridden */
  workflowStepId: Scalars['ID']['input'];
};

/** Autogenerated return type of OverrideWorkflowStep. */
export type OverrideWorkflowStepPayload = {
  __typename?: 'OverrideWorkflowStepPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  workflowStep: WorkflowStep;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

export type PatientChapterCover = {
  __typename?: 'PatientChapterCover';
  /** The type of chapter flow for the cover */
  type: Maybe<Scalars['String']['output']>;
  /** The relevant workflow step */
  workflowStep: Maybe<WorkflowStep>;
};

/** Fields to populate the patient's treatment overview */
export type PatientTreatmentOverview = {
  __typename?: 'PatientTreatmentOverview';
  /** Whether the user has completed onboarding */
  onboardingComplete: Scalars['Boolean']['output'];
  /** Dashboard items relating to onboarding a treatment */
  onboardingDashboardItems: Maybe<Array<DashboardItem>>;
  /** Dashboard items relating to a users treatment statuses */
  treatmentDashboardItems: Maybe<Array<DashboardItem>>;
  /** The verification card details */
  verificationDashboardItem: Maybe<DashboardItem>;
};

/** Autogenerated input type of PauseTreatment */
export type PauseTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  consentToFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  consentToPause?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Use treatmentType instead */
  id?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  treatmentType?: InputMaybe<TreatmentType>;
};

/** Autogenerated return type of PauseTreatment. */
export type PauseTreatmentPayload = {
  __typename?: 'PauseTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatment: Maybe<Treatment>;
};

export enum PaymentChargeTypeEnum {
  Initial = 'initial',
  Subscription = 'subscription',
}

/** A payment method */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brand: Maybe<PaymentMethodBrandsTypeEnum>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  expMonth: Maybe<Scalars['String']['output']>;
  expYear: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  last4: Maybe<Scalars['String']['output']>;
  paymentMethodType: PaymentMethodTypeEnum;
  profile: Profile;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum PaymentMethodBrandsTypeEnum {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Unionpay = 'unionpay',
  Unknown = 'unknown',
  Visa = 'visa',
}

export enum PaymentMethodTypeEnum {
  Affirm = 'affirm',
  Stripe = 'stripe',
}

export enum PaymentStatusEnum {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Refunded = 'refunded',
  Void = 'void',
}

/** A payment transaction */
export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  chargeType: PaymentChargeTypeEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  externalId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentMethod: PaymentMethod;
  status: PaymentStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Phone = {
  __typename?: 'Phone';
  areaCode: Scalars['String']['output'];
  e164: Scalars['String']['output'];
  rawNumber: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

/** Roughly track the number of pills a patient has left */
export type PillCount = {
  __typename?: 'PillCount';
  remainingPills: Scalars['Int']['output'];
  runsOutOn: Scalars['ISO8601Date']['output'];
};

/** Represents application configuration for use in client applications */
export type PlatformSettings = {
  __typename?: 'PlatformSettings';
  /** The number of hours before an appointment that a patient can cancel */
  cancellationThresholdHours: Scalars['Int']['output'];
  /** The number of hours before an appointment that a patient can reschedule */
  rescheduleThresholdHours: Scalars['Int']['output'];
};

export type PostConsultActionFilter = {
  /** Filter by who completed a step */
  completedById?: InputMaybe<Scalars['ID']['input']>;
  /** Filter out steps by their attended status */
  consultAttended?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter steps with a due date before the given date time */
  dueBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter steps by province */
  provinceId?: InputMaybe<Scalars['ID']['input']>;
};

export type Prescription = {
  __typename?: 'Prescription';
  additionalInfo: Maybe<Scalars['JSON']['output']>;
  additionalTreatments: Maybe<Array<Scalars['String']['output']>>;
  attachments: Array<Attachment>;
  brand: Maybe<Brand>;
  /** @deprecated Deprecated, don't use me */
  bridging: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /**
   * The estimated number of days left for the prescription
   * @deprecated Deprecated, don't use me
   */
  daysLeft: Scalars['Int']['output'];
  deleteOperation: Operation;
  dosage: Maybe<Scalars['String']['output']>;
  dosingRegimen: Maybe<DosingRegimenEnum>;
  /** The due_at value of the workflow step this prescription belongs to */
  dueAt: Maybe<Scalars['ISO8601Date']['output']>;
  fulfillmentMethod: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isBpMonitorNeeded: Maybe<Scalars['Boolean']['output']>;
  medication: Maybe<Scalars['String']['output']>;
  message: Maybe<Message>;
  notes: Maybe<Scalars['String']['output']>;
  numOfPills: Maybe<Scalars['Int']['output']>;
  prepType: Maybe<Scalars['String']['output']>;
  prescribedDate: Maybe<Scalars['ISO8601Date']['output']>;
  prescriptionLength: Maybe<Scalars['Int']['output']>;
  prescriptionType: Maybe<Scalars['String']['output']>;
  readByPatientAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  sentAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  sentBy: Maybe<SimpleUser>;
  treatmentStartAt: Maybe<Scalars['ISO8601Date']['output']>;
  updateOperation: Operation;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SimpleUser;
};

/** The connection type for Prescription. */
export type PrescriptionConnection = {
  __typename?: 'PrescriptionConnection';
  /** A list of edges. */
  edges: Array<PrescriptionEdge>;
  /** A list of nodes. */
  nodes: Array<Prescription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PrescriptionEdge = {
  __typename?: 'PrescriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Prescription;
};

export type PrescriptionFilter = {
  /** Returns prescriptions with the specified brand */
  brandId?: InputMaybe<Scalars['ID']['input']>;
  /** Only return prescriptions that are PrEP */
  onlyPrep?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Price = {
  __typename?: 'Price';
  amountInCents: Scalars['Int']['output'];
  brandId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  stripePriceId: Scalars['String']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  allergies: Maybe<Scalars['String']['output']>;
  asyncPrep: Scalars['Boolean']['output'];
  ccLastFour: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfBirth: Maybe<Scalars['ISO8601Date']['output']>;
  dateVerified: Maybe<Scalars['ISO8601Date']['output']>;
  deliveryPreference: DeliveryPreferenceEnum;
  desiredTreatments: Array<TreatmentTypeEnum>;
  financialExpectation: Maybe<Scalars['String']['output']>;
  hasPaymentMethod: Scalars['Boolean']['output'];
  healthCareNumber: Maybe<Scalars['String']['output']>;
  healthCareProvince: Maybe<ProvinceState>;
  healthSpendingAccount: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  insuranceCardConfirmedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Photos to be stored in InsuranceCard */
  insuranceCardPhotos: Array<Attachment>;
  insuranceCards: Maybe<Array<InsuranceCard>>;
  lastAffirmPaymentMethod: Maybe<PaymentMethod>;
  lastStripePaymentMethod: Maybe<PaymentMethod>;
  medInfoConfirmedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  medicalConditions: Maybe<Scalars['String']['output']>;
  medicalHistoryVersions: Array<MedicalHistoryVersion>;
  medications: Maybe<Scalars['String']['output']>;
  paymentMethods: Array<PaymentMethod>;
  phoneVerifiedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  province: Maybe<ProvinceState>;
  screenInOperation: Operation;
  shippingAddresses: Maybe<Array<ShippingAddress>>;
  shippingConfirmedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  stripeCustomerId: Maybe<Scalars['String']['output']>;
  stripeVerificationSessionId: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  verificationStatus: Maybe<StripeVerificationSessionStatusEnum>;
};

export type ProfileMedicalHistoryVersionsArgs = {
  filter?: InputMaybe<MedicalHistoryFilter>;
};

export enum Province {
  /** Alberta */
  Ab = 'AB',
  /** British Columbia */
  Bc = 'BC',
  /** Manitoba */
  Mb = 'MB',
  /** New Brunswick */
  Nb = 'NB',
  /** Newfoundland and Labrador */
  Nl = 'NL',
  /** Nova Scotia */
  Ns = 'NS',
  /** Northwest Territories */
  Nt = 'NT',
  /** Nunavut */
  Nu = 'NU',
  /** Ontario */
  On = 'ON',
  /** Prince Edward Island */
  Pe = 'PE',
  /** Quebec */
  Qc = 'QC',
  /** Saskatchewan */
  Sk = 'SK',
  /** Yukon */
  Yt = 'YT',
}

export type ProvinceState = {
  __typename?: 'ProvinceState';
  code: Scalars['String']['output'];
  country: Country;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  supported: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Retrieves all Acuity accounts. */
  acuityAccounts: Array<AcuityAccount>;
  /** Finds Acuity appointment types. */
  acuityAppointmentTypes: Array<AcuityAppointmentType>;
  /**
   * Finds Acuity appointments based on the filter criteria.
   * @deprecated Use appointments instead
   */
  acuityAppointments: Maybe<AcuityAppointmentConnection>;
  /** Finds Acuity calendars based on the filter criteria. */
  acuityCalendars: Maybe<AcuityCalendarConnection>;
  /** Find a single appointment by id */
  appointment: AcuityAppointment;
  /** Finds appointments based on the filter criteria. */
  appointments: Maybe<AppointmentConnection>;
  /** Gets all the assessments based on the filter */
  assessments: AssessmentConnection;
  /** Get a list of available appointment dates from Acuity */
  availableAppointmentDates: Array<AvailableAppointmentDate>;
  /** Get a list of available appointment times from Acuity for a given date */
  availableAppointmentTimes: Array<AvailableAppointmentTime>;
  /** Get a list of available time slots for a given date */
  availableTimeSlotsForBookingSession: Array<AvailableAppointmentTime>;
  /** All the brands we currently support */
  brands: Array<Brand>;
  /** Find a single conversation by id */
  conversation: Maybe<Conversation>;
  /** Find conversations */
  conversations: ConversationConnection;
  /** Find conversations */
  conversationsNoMerging: ConversationConnection;
  /** All existing countries */
  countries: Array<Country>;
  /** Get a list of feature configurations */
  featureConfigurations: FeatureConfiguration;
  /** All existing Juno form submissions for the current user */
  junoFormSubmission: Maybe<JunoFormSubmission>;
  /** Gets the currently authenticated user */
  me: Maybe<User>;
  /** Gets all medication requests for the patient */
  medicationRequests: Maybe<Array<FhirMedicationRequest>>;
  /** Find a single message by id */
  message: Maybe<Message>;
  /** Message statistics for the current user */
  messageStatistics: MessageStatistics;
  /** Gets a patient's chapter cover for a specific treatment */
  patientChapterCover: Maybe<PatientChapterCover>;
  /** Gets a patient's treatment overview for all available treatments */
  patientTreatmentOverview: Maybe<PatientTreatmentOverview>;
  /** Retrieve payment transactions for a user */
  paymentTransactions: Maybe<Array<PaymentTransaction>>;
  /** Platform Settings */
  platformSettings: PlatformSettings;
  /** A filterable list of PostConsultAction steps used for triaging patients */
  postConsultActions: WorkflowStepConnection;
  /** Find a prescription by ID. */
  prescription: Maybe<Prescription>;
  /** Retrieves all prices. */
  prices: Array<Price>;
  /** Gets a questionnaire response by id */
  questionnaireResponse: Maybe<QuestionnaireResponse>;
  /** Gets a list of questionnaire responses */
  questionnaireResponses: QuestionnaireResponseConnection;
  /** All existing roles */
  roles: Array<Role>;
  /** Stripe verification session for the provided user */
  stripeVerificationSession: Maybe<StripeVerificationSession>;
  /** Get the latest treatment for the user by treatment type. */
  treatment: Maybe<Treatment>;
  /** A filterable list of treatment cycles used for triaging patients */
  treatmentCycles: CycleConnection;
  /** Find a user by id */
  user: Maybe<User>;
  /** Find users accessible to the current user */
  users: UserConnection;
  /** Find a single workflow step by its id */
  workflowStep: WorkflowStep;
  /** A filterable list of workflow steps used for triaging patients */
  workflowSteps: WorkflowStepConnection;
};

export type QueryAcuityAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AcuityAppointmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAcuityCalendarsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AcuityCalendarFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAppointmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppointmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAssessmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: AssessmentTypeFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssessmentOrderInput>;
  recentScales?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAvailableAppointmentDatesArgs = {
  filter?: InputMaybe<AvailabilityTypeFilter>;
  numberOfMonths?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAvailableAppointmentTimesArgs = {
  filter: AvailabilityTypeFilter;
};

export type QueryAvailableTimeSlotsForBookingSessionArgs = {
  bookingDate: Scalars['ISO8601Date']['input'];
  bookingSessionId: Scalars['ID']['input'];
};

export type QueryConversationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ConversationOrderInput>;
};

export type QueryConversationsNoMergingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ConversationOrderInput>;
};

export type QueryCountriesArgs = {
  filter?: InputMaybe<CountryFilter>;
};

export type QueryJunoFormSubmissionArgs = {
  fdid: Scalars['Int']['input'];
};

export type QueryMedicationRequestsArgs = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

export type QueryMessageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPatientChapterCoverArgs = {
  treatmentType: TreatmentType;
};

export type QueryPaymentTransactionsArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryPostConsultActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostConsultActionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPrescriptionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryQuestionnaireResponseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryQuestionnaireResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: QuestionnaireResponseTypeFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryStripeVerificationSessionArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryTreatmentArgs = {
  treatmentType: TreatmentType;
};

export type QueryTreatmentCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CycleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CycleOrderInput>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderInput>;
};

export type QueryWorkflowStepArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWorkflowStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<WorkflowStepFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  brand: Maybe<Brand>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  results: Scalars['JSON']['output'];
  submitted: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** An answer that a patient can give in response to a question */
export type QuestionnaireAcceptableAnswer = {
  __typename?: 'QuestionnaireAcceptableAnswer';
  label: Scalars['String']['output'];
  /** The value that should be submitted if this answer is selected. If the value is null then any value may be submitted. */
  value: Maybe<Scalars['String']['output']>;
};

/** An action to be taken as a result of a questionnaire step */
export type QuestionnaireAction = {
  __typename?: 'QuestionnaireAction';
  label: Scalars['String']['output'];
  to: Scalars['String']['output'];
  /** The ordinal-numeric intent of an action (i.e., PRIMARY, SECONDARY) */
  variant: ActionVariantEnum;
};

/** A patient's answer to a question */
export type QuestionnaireAnswer = {
  __typename?: 'QuestionnaireAnswer';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  question: QuestionnaireQuestion;
  response: QuestionnaireResponse;
  values: Array<Scalars['String']['output']>;
};

/** An answer to a question */
export type QuestionnaireAnswerInput = {
  questionId: Scalars['ID']['input'];
  values: Array<Scalars['String']['input']>;
};

export type QuestionnaireCheckboxQuestion = QuestionnaireQuestion & {
  __typename?: 'QuestionnaireCheckboxQuestion';
  acceptableAnswers: Array<QuestionnaireAcceptableAnswer>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  minSelections: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

export type QuestionnaireDateInputQuestion = QuestionnaireQuestion & {
  __typename?: 'QuestionnaireDateInputQuestion';
  allowsFutureDates: Scalars['Boolean']['output'];
  allowsPastDates: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

/** The top-level type that describes a questionnaire */
export type QuestionnaireDefinition = QuestionnaireItem & {
  __typename?: 'QuestionnaireDefinition';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

/** Interface describing common functionality of questionnaire types */
export type QuestionnaireItem = {
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

export type QuestionnaireNumberInputQuestion = QuestionnaireQuestion & {
  __typename?: 'QuestionnaireNumberInputQuestion';
  allowsDecimals: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  max: Maybe<Scalars['Int']['output']>;
  min: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

/** The outcome of a questionnaire */
export type QuestionnaireOutcome = QuestionnaireStep & {
  __typename?: 'QuestionnaireOutcome';
  actions: Array<QuestionnaireAction>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  redirectTo: Maybe<Scalars['String']['output']>;
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

/** Represents the current page of an in-progress questionnaire */
export type QuestionnairePage = QuestionnaireStep & {
  __typename?: 'QuestionnairePage';
  actions: Array<QuestionnaireAction>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questions: Array<QuestionnaireQuestion>;
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

/** A single question in a questionnaire */
export type QuestionnaireQuestion = {
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

/** A resource that is referenced by a questionnaire question */
export type QuestionnaireQuestionResource = {
  __typename?: 'QuestionnaireQuestionResource';
  label: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  type: QuestionnaireQuestionResourceTypeEnum;
  value: Scalars['String']['output'];
};

export enum QuestionnaireQuestionResourceTypeEnum {
  Card = 'CARD',
  Component = 'COMPONENT',
  Img = 'IMG',
  Link = 'LINK',
  Modal = 'MODAL',
}

export type QuestionnaireRadioQuestion = QuestionnaireQuestion & {
  __typename?: 'QuestionnaireRadioQuestion';
  acceptableAnswers: Array<QuestionnaireAcceptableAnswer>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

/** Represents a patient's response to a questionnaire */
export type QuestionnaireResponse = {
  __typename?: 'QuestionnaireResponse';
  answers: Array<QuestionnaireAnswer>;
  canGoBack: Scalars['Boolean']['output'];
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completed: Scalars['Boolean']['output'];
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentStep: Maybe<QuestionnaireStep>;
  definition: QuestionnaireDefinition;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workflowStep: Maybe<WorkflowStep>;
};

/** The connection type for QuestionnaireResponse. */
export type QuestionnaireResponseConnection = {
  __typename?: 'QuestionnaireResponseConnection';
  /** A list of edges. */
  edges: Array<QuestionnaireResponseEdge>;
  /** A list of nodes. */
  nodes: Array<QuestionnaireResponse>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuestionnaireResponseEdge = {
  __typename?: 'QuestionnaireResponseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: QuestionnaireResponse;
};

export type QuestionnaireResponseTypeFilter = {
  /** Only return responses for specific definitions */
  definitionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only return responses for this patient */
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** Interface describing common functionality of questionnaire steps */
export type QuestionnaireStep = {
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

export type QuestionnaireTextAreaQuestion = QuestionnaireQuestion & {
  __typename?: 'QuestionnaireTextAreaQuestion';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxLength: Scalars['Int']['output'];
  minLength: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  supportingText: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type QuestionnaireTextInputQuestion = QuestionnaireQuestion & {
  __typename?: 'QuestionnaireTextInputQuestion';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxLength: Scalars['Int']['output'];
  minLength: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  resources: Maybe<Array<QuestionnaireQuestionResource>>;
  title: Maybe<Scalars['String']['output']>;
};

export type ReadReceipt = {
  __typename?: 'ReadReceipt';
  id: Scalars['ID']['output'];
  readAt: Scalars['ISO8601DateTime']['output'];
  readBy: SimpleUser;
};

/** Autogenerated input type of RecoverPassword */
export type RecoverPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of RecoverPassword. */
export type RecoverPasswordPayload = {
  __typename?: 'RecoverPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of ReferralProgramRegister */
export type ReferralProgramRegisterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ReferralProgramRegister. */
export type ReferralProgramRegisterPayload = {
  __typename?: 'ReferralProgramRegisterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  referralCode: Scalars['String']['output'];
};

export enum RefillProcessEnum {
  /** The patient is responsible for contacting us when they need a refill */
  PatientDirected = 'PatientDirected',
  /** We'll notify the patient when we think they need a refill */
  PurposeMedDirected = 'PurposeMedDirected',
}

/** Autogenerated input type of RefundPaymentTransaction */
export type RefundPaymentTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentTransactionId: Scalars['String']['input'];
};

/** Autogenerated return type of RefundPaymentTransaction. */
export type RefundPaymentTransactionPayload = {
  __typename?: 'RefundPaymentTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export enum RemainingMedicationAmount {
  HalfBottle = 'half_bottle',
  LessThanHalfBottle = 'less_than_half_bottle',
  MoreThanTwoBottles = 'more_than_two_bottles',
  NoBottles = 'no_bottles',
  OneAndHalfBottle = 'one_and_half_bottle',
  OneBottle = 'one_bottle',
  TwoBottles = 'two_bottles',
}

/** Autogenerated input type of RescheduleAppointment */
export type RescheduleAppointmentInput = {
  /** The Acuity appointment id of the appointment to be re-scheduled */
  appointmentId: Scalars['ID']['input'];
  /** The reason for the appointment */
  bookingReason?: InputMaybe<Scalars['String']['input']>;
  /** The id of the calendar to be booked into (optional) */
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The new timeslot for the booking (from availableAppointmentTimes query) */
  timeSlot: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of RescheduleAppointment. */
export type RescheduleAppointmentPayload = {
  __typename?: 'RescheduleAppointmentPayload';
  acuityResponse: Maybe<Scalars['JSON']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['JSON']['output']>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of ResetPostConsultForm */
export type ResetPostConsultFormInput = {
  /** The id of the Acuity appointment that has the commpleted post consult form */
  acuityAppointmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Free-form json params. Leave blank to keep these the same. */
  completionParams?: InputMaybe<Scalars['JSON']['input']>;
  /** The reason the Post consult form is being reset */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPostConsultForm. */
export type ResetPostConsultFormPayload = {
  __typename?: 'ResetPostConsultFormPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  workflowStep: WorkflowStep;
};

/** Autogenerated input type of ResetTreatmentAndQuestionnaire */
export type ResetTreatmentAndQuestionnaireInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  definitionName: Scalars['String']['input'];
  treatmentType: TreatmentType;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of ResetTreatmentAndQuestionnaire. */
export type ResetTreatmentAndQuestionnairePayload = {
  __typename?: 'ResetTreatmentAndQuestionnairePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  response: Maybe<QuestionnaireResponse>;
};

/** Autogenerated input type of ResetWorkflowStep */
export type ResetWorkflowStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Free-form json params. Leave blank to keep these the same. */
  completionParams?: InputMaybe<Scalars['JSON']['input']>;
  /** The id of the workflow step that is to be reset */
  workflowStepId: Scalars['ID']['input'];
};

/** Autogenerated return type of ResetWorkflowStep. */
export type ResetWorkflowStepPayload = {
  __typename?: 'ResetWorkflowStepPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  workflowStep: WorkflowStep;
};

/** Autogenerated input type of RestartTreatment */
export type RestartTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RestartTreatment. */
export type RestartTreatmentPayload = {
  __typename?: 'RestartTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatment: Maybe<Treatment>;
};

export type Role = {
  __typename?: 'Role';
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of ScreenInPatient */
export type ScreenInPatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the patient to be screened in */
  patientId: Scalars['ID']['input'];
  /** Reason for screening in the patient */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of ScreenInPatient. */
export type ScreenInPatientPayload = {
  __typename?: 'ScreenInPatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of SendPhoneNumberVerificationCode */
export type SendPhoneNumberVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The phone number to be verified. Will accept any format as long as there is 10
   * or 11 digits (ie; e164: +14031234567, 4031234567, (403) 123-4567, etc
   */
  phoneNumber: Scalars['String']['input'];
};

/** Autogenerated return type of SendPhoneNumberVerificationCode. */
export type SendPhoneNumberVerificationCodePayload = {
  __typename?: 'SendPhoneNumberVerificationCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  addressType: Maybe<AddressTypeEnum>;
  city: Scalars['String']['output'];
  deliveryInstructions: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefaultAddress: Scalars['Boolean']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
  recipientName: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2: Maybe<Scalars['String']['output']>;
};

export type ShortQuestionnaire = {
  __typename?: 'ShortQuestionnaire';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  results: Scalars['JSON']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of SignupPatient */
export type SignupPatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601Date']['input']>;
  email: Scalars['String']['input'];
  financialExpectation?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  healthCareNumber?: InputMaybe<Scalars['String']['input']>;
  healthCareProvinceId?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The locale of the user */
  locale?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  provinceId: Scalars['ID']['input'];
  /** Referring patients code */
  referralCode?: InputMaybe<Scalars['String']['input']>;
  /** Whether the joiner has accepted the referral consent */
  referralConsented?: InputMaybe<Scalars['Boolean']['input']>;
  /** Questionnaire results */
  results: Scalars['JSON']['input'];
  /** Screening results */
  screeningResults?: InputMaybe<Scalars['JSON']['input']>;
  /** UTM parameters */
  utm?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of SignupPatient. */
export type SignupPatientPayload = {
  __typename?: 'SignupPatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  hints: Maybe<Array<Scalars['String']['output']>>;
  jwt: Maybe<Scalars['String']['output']>;
  patient: Maybe<User>;
};

export type SimpleUser = {
  __typename?: 'SimpleUser';
  /** A list of clinical credentials this user has obtained */
  credentials: Array<Credential>;
  email: Scalars['String']['output'];
  emrId: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
  phone: Maybe<Phone>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  preferredName: Maybe<Scalars['String']['output']>;
  /** Prescriptions for this user */
  prescriptions: PrescriptionConnection;
  profile: Maybe<Profile>;
  questionnaires: Maybe<Array<Questionnaire>>;
  role: Role;
  voicemailConsent: Scalars['Boolean']['output'];
};

export type SimpleUserPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum SortOrderEnum {
  /** asc */
  Asc = 'ASC',
  /** desc */
  Desc = 'DESC',
}

/** The source of an event */
export enum Source {
  Backend = 'backend',
  Freddie = 'freddie',
  Frida = 'frida',
  Purposemed = 'purposemed',
}

/** Autogenerated input type of StartBookingSession */
export type StartBookingSessionInput = {
  /** The (purposemed) id of an appointment. When this is provided it is assumed that this appointment is being rescheduled. */
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** The reason the appointment is being booked */
  bookingReason?: InputMaybe<Scalars['String']['input']>;
  /** The description of the booking reason */
  bookingReasonDescription?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the workflow step that triggered the booking flow */
  workflowStepId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of StartBookingSession. */
export type StartBookingSessionPayload = {
  __typename?: 'StartBookingSessionPayload';
  bookingSession: Maybe<BookingSession>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of StartPatientRefill */
export type StartPatientRefillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentType: TreatmentType;
};

/** Autogenerated return type of StartPatientRefill. */
export type StartPatientRefillPayload = {
  __typename?: 'StartPatientRefillPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  questionnaireStepId: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of StartQuestionnaire */
export type StartQuestionnaireInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  definitionName: Scalars['String']['input'];
  workflowStepId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of StartQuestionnaire. */
export type StartQuestionnairePayload = {
  __typename?: 'StartQuestionnairePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patientResponse: Maybe<QuestionnaireResponse>;
};

/** Autogenerated input type of StartTreatment */
export type StartTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentType: TreatmentType;
};

/** Autogenerated return type of StartTreatment. */
export type StartTreatmentPayload = {
  __typename?: 'StartTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatment: Maybe<Treatment>;
};

/** Autogenerated input type of StopTreatment */
export type StopTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  consentToFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  consentToStop?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Use treatmentType instead */
  id?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  treatmentType?: InputMaybe<TreatmentType>;
};

/** Autogenerated return type of StopTreatment. */
export type StopTreatmentPayload = {
  __typename?: 'StopTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatment: Maybe<Treatment>;
};

export type StripeVerificationSession = {
  __typename?: 'StripeVerificationSession';
  id: Scalars['ID']['output'];
  images: Array<Scalars['String']['output']>;
  status: StripeVerificationSessionStatusEnum;
};

export enum StripeVerificationSessionStatusEnum {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresInput = 'requires_input',
  Verified = 'verified',
}

/** Autogenerated input type of SubmitQuestionnaireAnswers */
export type SubmitQuestionnaireAnswersInput = {
  answers: Array<QuestionnaireAnswerInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  responseId: Scalars['ID']['input'];
  stepId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitQuestionnaireAnswers. */
export type SubmitQuestionnaireAnswersPayload = {
  __typename?: 'SubmitQuestionnaireAnswersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patientResponse: QuestionnaireResponse;
};

/** A patient treatment */
export type Treatment = {
  __typename?: 'Treatment';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eligibility: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  medicationStatus: MedicationStatusEnum;
  stoppedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  stoppedBy: Maybe<User>;
  stoppedReason: Maybe<Scalars['String']['output']>;
  type: TreatmentType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
};

/** A patient's eligibility for a treatment */
export type TreatmentEligibility = {
  __typename?: 'TreatmentEligibility';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eligibility: TreatmentEligibilityEnum;
  id: Scalars['ID']['output'];
  reason: Maybe<Scalars['String']['output']>;
  treatmentId: Maybe<Scalars['ID']['output']>;
  treatmentType: TreatmentType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export enum TreatmentEligibilityEnum {
  Eligible = 'eligible',
  NeedsClarification = 'needs_clarification',
  PermanentlyIneligible = 'permanently_ineligible',
  TemporarilyIneligible = 'temporarily_ineligible',
}

/** The different treatment types */
export enum TreatmentType {
  AdhdTreatment = 'adhd_treatment',
  DoxyPepTreatment = 'doxy_pep_treatment',
  HivTreatment = 'hiv_treatment',
  PrepTreatment = 'prep_treatment',
}

export enum TreatmentTypeEnum {
  StiPrevention = 'sti_prevention',
}

export type Unhandled = {
  __typename?: 'Unhandled';
  id: Scalars['ID']['output'];
  subjectType: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAcuityAppointment */
export type UpdateAcuityAppointmentInput = {
  /** The id of acuity account (Freddie/Frida/etc.) */
  acuityAccountId: Scalars['ID']['input'];
  /** Acuity's id for this appointment type */
  acuityAppointmentTypeId: Scalars['ID']['input'];
  /** The booking type */
  bookingType: AcuityBooking;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The internal (not acuity's) id of the appointment to be updated */
  id: Scalars['ID']['input'];
  /** The price for late reschedules of appointments using this appointment type */
  lateReschedulePriceId?: InputMaybe<Scalars['ID']['input']>;
  /** The locale for this appointment type */
  locale: Scalars['String']['input'];
  /** The price that will be applied to appointments using this appointment type */
  priceId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the province for this appointment type */
  provinceId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAcuityAppointment. */
export type UpdateAcuityAppointmentPayload = {
  __typename?: 'UpdateAcuityAppointmentPayload';
  acuityAppointmentType: Maybe<AcuityAppointmentType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAcuityCalendar */
export type UpdateAcuityCalendarInput = {
  /** The id of acuity account (Freddie/Frida/etc.) */
  acuityAccountId: Scalars['ID']['input'];
  /** The id of acuity calendar */
  acuityCalendarId: Scalars['ID']['input'];
  /** The id of the calendar to be updated. */
  calendarId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the province where this calendar is valid */
  provinceId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAcuityCalendar. */
export type UpdateAcuityCalendarPayload = {
  __typename?: 'UpdateAcuityCalendarPayload';
  acuityCalendar: Maybe<AcuityCalendar>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAssessment */
export type UpdateAssessmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['JSON']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAssessment. */
export type UpdateAssessmentPayload = {
  __typename?: 'UpdateAssessmentPayload';
  assessment: Maybe<Assessment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateConversation */
export type UpdateConversationInput = {
  /** The id of the user who will be assigned to resolving this conversation */
  assignedToId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the conversation */
  id: Scalars['ID']['input'];
  /** Notes about this conversation, not visible to the patient */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user who is resolving this conversation */
  resolvedById?: InputMaybe<Scalars['ID']['input']>;
  /** The status of this conversation */
  status?: InputMaybe<ConversationStatus>;
};

/** Autogenerated return type of UpdateConversation. */
export type UpdateConversationPayload = {
  __typename?: 'UpdateConversationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  conversation: Maybe<Conversation>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateCredential */
export type UpdateCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the credential to be updated. */
  credentialId: Scalars['ID']['input'];
  /** The designation name of the credential */
  designation: Scalars['String']['input'];
  /** The id of the province where this credential is valid */
  provinceId: Scalars['ID']['input'];
  /** The registration number of the credential */
  registrationNumber: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateCredential. */
export type UpdateCredentialPayload = {
  __typename?: 'UpdateCredentialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  credential: Maybe<Credential>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateInsuranceCard */
export type UpdateInsuranceCardInput = {
  /** The signed blob id of the back image */
  backImage?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deleteBackImage?: InputMaybe<Scalars['Boolean']['input']>;
  /** The signed blob id of the front image */
  frontImage?: InputMaybe<Scalars['String']['input']>;
  /** The id of the insurance card that will be updated */
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateInsuranceCard. */
export type UpdateInsuranceCardPayload = {
  __typename?: 'UpdateInsuranceCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  insuranceCard: Maybe<InsuranceCard>;
};

/** Autogenerated input type of UpdateLabRequisition */
export type UpdateLabRequisitionInput = {
  /** The signed blob ids of the attachments */
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /**
   * The notes for the lab requisition
   * @deprecated Replaced by autogenerated lab requisition feature
   */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The reason the lab requisition is being updated */
  reason: Scalars['String']['input'];
  /** The type of requisition */
  requisitionType?: InputMaybe<Scalars['String']['input']>;
  /** The date the lab requisition was sent */
  sentAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The date the lab requisition was viewed */
  viewedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated return type of UpdateLabRequisition. */
export type UpdateLabRequisitionPayload = {
  __typename?: 'UpdateLabRequisitionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  labRequisition: Maybe<LabRequisition>;
};

/** Autogenerated input type of UpdateLabRequisitionViewedAt */
export type UpdateLabRequisitionViewedAtInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** The date the lab requisition was viewed */
  viewedAt: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of UpdateLabRequisitionViewedAt. */
export type UpdateLabRequisitionViewedAtPayload = {
  __typename?: 'UpdateLabRequisitionViewedAtPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateMessage */
export type UpdateMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  params: MessageInput;
  /** The reason for updating the message */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateMessage. */
export type UpdateMessagePayload = {
  __typename?: 'UpdateMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  message: Maybe<Message>;
};

/** Autogenerated input type of UpdatePatient */
export type UpdatePatientInput = {
  allergies?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601Date']['input']>;
  defaultInsuranceCardId?: InputMaybe<Scalars['ID']['input']>;
  defaultShippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  deliveryPreference?: InputMaybe<DeliveryPreferenceEnum>;
  email?: InputMaybe<Scalars['String']['input']>;
  emrId?: InputMaybe<Scalars['String']['input']>;
  financialExpectation?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  healthCareNumber?: InputMaybe<Scalars['String']['input']>;
  healthCareProvinceId?: InputMaybe<Scalars['ID']['input']>;
  healthSpendingAccount?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The signed blob ids of insurance card photos */
  insuranceCardPhotos?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  medicalConditions?: InputMaybe<Scalars['String']['input']>;
  medications?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  /** DEPRECATED, use provinceId instead */
  province?: InputMaybe<Province>;
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  refillProcess?: InputMaybe<RefillProcessEnum>;
  /** Questionnaire results */
  results?: InputMaybe<Scalars['JSON']['input']>;
  smsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  voicemailConsent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdatePatient. */
export type UpdatePatientPayload = {
  __typename?: 'UpdatePatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  patient: Maybe<User>;
};

/** Autogenerated input type of UpdatePillCounter */
export type UpdatePillCounterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  roughAmount: RemainingMedicationAmount;
};

/** Autogenerated return type of UpdatePillCounter. */
export type UpdatePillCounterPayload = {
  __typename?: 'UpdatePillCounterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  pillCounter: PillCount;
};

/** Autogenerated input type of UpdatePrescription */
export type UpdatePrescriptionInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  /** @deprecated Removed from the form */
  bridging?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Dosage in mg for the prescription */
  dosage?: InputMaybe<Scalars['String']['input']>;
  dosingRegimen?: InputMaybe<DosingRegimenEnum>;
  /** The method of fulfillment for the prescription */
  fulfillmentMethod?: InputMaybe<Scalars['String']['input']>;
  /** The id of the prescription that wil be updated */
  id: Scalars['ID']['input'];
  /** The medication being prescribed */
  medication?: InputMaybe<Scalars['String']['input']>;
  /** Additional notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Number of pills prescribed */
  numOfPills?: InputMaybe<Scalars['Int']['input']>;
  prepType?: InputMaybe<Scalars['String']['input']>;
  prescribedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  prescriptionLength?: InputMaybe<Scalars['Int']['input']>;
  /** The type of prescription */
  prescriptionType?: InputMaybe<Scalars['String']['input']>;
  /** The reason why this prescription needed to be updated */
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of UpdatePrescription. */
export type UpdatePrescriptionPayload = {
  __typename?: 'UpdatePrescriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  prescription: Maybe<Prescription>;
};

/** Autogenerated input type of UpdateProvider */
export type UpdateProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateProvider. */
export type UpdateProviderPayload = {
  __typename?: 'UpdateProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  provider: Maybe<User>;
};

/** Autogenerated input type of UpdateShippingAddress */
export type UpdateShippingAddressInput = {
  addressType?: InputMaybe<AddressTypeEnum>;
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  /** The id of the shipping address that will be updated */
  id: Scalars['ID']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
  recipientName?: InputMaybe<Scalars['String']['input']>;
  /** Primary street address */
  street: Scalars['String']['input'];
  /** Secondary address details (unit #, suite, etc.) */
  street2?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateShippingAddress. */
export type UpdateShippingAddressPayload = {
  __typename?: 'UpdateShippingAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  shippingAddress: Maybe<ShippingAddress>;
};

/** Autogenerated input type of UpdateTreatmentEligibility */
export type UpdateTreatmentEligibilityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eligibility: TreatmentEligibilityEnum;
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateTreatmentEligibility. */
export type UpdateTreatmentEligibilityPayload = {
  __typename?: 'UpdateTreatmentEligibilityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  treatmentEligibility: Maybe<TreatmentEligibility>;
};

/** Autogenerated input type of UpdateVital */
export type UpdateVitalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  /** The date the measurement was taken */
  measuredAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The id of the vital that is being updated */
  vitalId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateVital. */
export type UpdateVitalPayload = {
  __typename?: 'UpdateVitalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  vital: Maybe<Vital>;
};

/** Autogenerated input type of UpdateWorkflowStep */
export type UpdateWorkflowStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Free-form params */
  completionParams?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWorkflowStep. */
export type UpdateWorkflowStepPayload = {
  __typename?: 'UpdateWorkflowStepPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  workflowStep: Maybe<WorkflowStep>;
};

export type User = {
  __typename?: 'User';
  /** Acuity events (for scheduled, canceled, rescheduled appointments) */
  acuityAppointmentEvents: Array<AcuityAppointmentEvent>;
  /**
   * Appointments where this user is a patient
   * @deprecated Use appointments instead
   */
  acuityAppointments: AcuityAppointmentConnection;
  /** Acuity calendars used for appointment bookings that are owned by this user */
  acuityCalendars: Array<AcuityCalendar>;
  acuityEvents: Array<AcuityEvent>;
  additionalTreatmentsAvailable: Scalars['Boolean']['output'];
  /** Appointments where this user is a patient */
  appointments: AppointmentConnection;
  /** A list of of the user's assessments */
  assessments: AssessmentConnection;
  /** A list of brands the user has signed up for */
  brands: Array<BrandMembership>;
  /** The clinical trials this user is enrolled in. Will be scoped based on the current brand of the request */
  clinicalTrials: Maybe<Array<ClinicalTrial>>;
  /**
   * Parent messages to/from this user
   * @deprecated Use myConversations instead
   */
  conversations: MessageConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** A list of clinical credentials this user has obtained */
  credentials: Array<Credential>;
  /** Gets the brand membership for the current brand (ie; membership matching the brand header) */
  currentBrandMembership: BrandMembership;
  deactivatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['String']['output'];
  emrId: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  hasAttendedConsult: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** List of incomplete workflow steps, scoped to the current brand if the current user is a patient */
  incompleteWorkflowSteps: Array<WorkflowStep>;
  /** An un-ordered list of events that have happened in this user's patient journey */
  journeyItems: Array<JourneyItem>;
  /** Lab requisitions for this user */
  labRequisitions: LabRequisitionConnection;
  lastName: Maybe<Scalars['String']['output']>;
  /** The latest treatments of each type for this user */
  lastTreatments: Array<Treatment>;
  locale: Scalars['String']['output'];
  /** Conversations this user is participating in */
  myConversations: ConversationConnection;
  /** The pending agreements for this user */
  pendingAgreements: Array<Agreement>;
  phone: Phone;
  phoneNumber: Maybe<Scalars['String']['output']>;
  preferredName: Maybe<Scalars['String']['output']>;
  /** Prescriptions for this user */
  prescriptions: PrescriptionConnection;
  profile: Maybe<Profile>;
  questionnaires: Maybe<Array<Questionnaire>>;
  referralCode: Maybe<Scalars['String']['output']>;
  role: Role;
  shortQuestionnaires: Array<ShortQuestionnaire>;
  smsEnabled: Scalars['Boolean']['output'];
  /** A list of treatment cycles */
  treatmentCycles: CycleConnection;
  /** The eligibility status of treatments for this user */
  treatmentEligibilities: Array<TreatmentEligibility>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** A list of of the user's vitals */
  vitals: VitalConnection;
  voicemailConsent: Scalars['Boolean']['output'];
  /** List of all workflow steps, scoped to the current brand if the current user is a patient */
  workflowSteps: Array<WorkflowStep>;
  zendeskId: Maybe<Scalars['ID']['output']>;
};

export type UserAcuityAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AcuityAppointmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppointmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAssessmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessageTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserJourneyItemsArgs = {
  filter?: InputMaybe<JourneyItemFilter>;
};

export type UserLabRequisitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LabRequisitionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMyConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PrescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserTreatmentCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CycleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserVitalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

export type UserOrderInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  firstName?: InputMaybe<SortOrderEnum>;
  lastName?: InputMaybe<SortOrderEnum>;
};

export type UserTypeFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  brandId?: InputMaybe<Scalars['ID']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  province?: InputMaybe<Scalars['String']['input']>;
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ValidateEmail */
export type ValidateEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of ValidateEmail. */
export type ValidateEmailPayload = {
  __typename?: 'ValidateEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  result: Maybe<EmailValidation>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  frontEndId: Scalars['ID']['output'];
  messages: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of VerifyPhoneNumberVerificationCode */
export type VerifyPhoneNumberVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The phone number to be verified. Will accept any format as long as there is 10
   * or 11 digits (ie; e164: +14031234567, 4031234567, (403) 123-4567, etc
   */
  phoneNumber: Scalars['String']['input'];
  /** The reason why the phone number is being verified */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** The 6-digit code that was sent to the patient's phone number */
  verificationCode: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyPhoneNumberVerificationCode. */
export type VerifyPhoneNumberVerificationCodePayload = {
  __typename?: 'VerifyPhoneNumberVerificationCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type Vital = {
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  details: Scalars['JSON']['output'];
  enteredBy: SimpleUser;
  id: Scalars['ID']['output'];
  measuredAt: Maybe<Scalars['ISO8601Date']['output']>;
  patient: SimpleUser;
  type: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for Vital. */
export type VitalConnection = {
  __typename?: 'VitalConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<VitalEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Vital>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VitalEdge = {
  __typename?: 'VitalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Vital>;
};

/** The different vital types */
export enum VitalType {
  BloodPressure = 'blood_pressure',
}

/** Autogenerated input type of VoidPayment */
export type VoidPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['String']['input'];
};

/** Autogenerated return type of VoidPayment. */
export type VoidPaymentPayload = {
  __typename?: 'VoidPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['JSON']['output']>>;
  response: Maybe<Scalars['JSON']['output']>;
};

export type WorkflowStep = JourneyItem & {
  __typename?: 'WorkflowStep';
  brand: Brand;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  children: Array<WorkflowStep>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy: Maybe<SimpleUser>;
  completionParams: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dueAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  parentStep: Maybe<WorkflowStep>;
  positionedAt: Scalars['ISO8601DateTime']['output'];
  subject: Maybe<WorkflowStepSubject>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SimpleUser;
  workflowStepRelations: Array<WorkflowStepRelation>;
};

/** The connection type for WorkflowStep. */
export type WorkflowStepConnection = {
  __typename?: 'WorkflowStepConnection';
  /** A list of edges. */
  edges: Array<WorkflowStepEdge>;
  /** A list of nodes. */
  nodes: Array<WorkflowStep>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkflowStepEdge = {
  __typename?: 'WorkflowStepEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: WorkflowStep;
};

export type WorkflowStepFilter = {
  /** Filter by who completed a step */
  completedById?: InputMaybe<Scalars['ID']['input']>;
  /** Filter steps with a due date after the given date time */
  dueAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter steps with a due date before the given date time */
  dueBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter steps by patient */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter steps by province */
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by the workflow step type */
  stepType?: InputMaybe<Scalars['String']['input']>;
  /** Only include steps containing the given completion param */
  withCompletionParam?: InputMaybe<Scalars['String']['input']>;
  /** Filter steps that have a due date */
  withDueDate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkflowStepRelation = {
  __typename?: 'WorkflowStepRelation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workflowable: WorkflowStepSubject;
};

/** The subject object of a workflow step */
export type WorkflowStepSubject =
  | AcuityAppointment
  | Assessment
  | LabRequisition
  | Prescription
  | Questionnaire
  | ShortQuestionnaire
  | Unhandled;

export type AcuityAccountFieldsFragment = {
  __typename?: 'AcuityAccount';
  id: string;
  email: string;
  brand: { __typename?: 'Brand'; id: string; name: string };
};

export type AcuityAppointmentTypeFragment = {
  __typename?: 'AcuityAppointmentType';
  id: string;
  acuityAppointmentTypeId: string;
  bookingType: AcuityBooking;
  locale: string;
  province: { __typename?: 'ProvinceState'; id: string; code: string } | null;
  brand: { __typename?: 'Brand'; id: string; name: string } | null;
  acuityAccount: { __typename?: 'AcuityAccount'; id: string } | null;
};

export type AcuityCalendarFragment = {
  __typename?: 'AcuityCalendar';
  id: string;
  acuityCalendarId: string;
  province: {
    __typename?: 'ProvinceState';
    id: string;
    name: string;
    code: string;
    supported: boolean;
  };
  acuityAccount: {
    __typename?: 'AcuityAccount';
    id: string;
    email: string;
    brand: { __typename?: 'Brand'; id: string; name: string };
  } | null;
};

export type AgreementClientPartsFragment = {
  __typename?: 'AgreementClient';
  id: string;
  version: string;
  content: string;
};

export type AgreementPrivacyPartsFragment = {
  __typename?: 'AgreementPrivacy';
  id: string;
  version: string;
  url: string;
};

export type AgreementTermsOfUsePartsFragment = {
  __typename?: 'AgreementTermsOfUse';
  id: string;
  version: string;
  url: string;
};

export type AgreementFreddieAsyncPartsFragment = {
  __typename?: 'AgreementFreddieAsync';
  id: string;
  version: string;
  agreementContent: any;
};

export type AgreementFreddieDoxyPepAsyncPartsFragment = {
  __typename?: 'AgreementFreddieDoxyPepAsync';
  id: string;
  version: string;
  agreementContent: any;
};

export type PatientViewAssessmentFragment = {
  __typename?: 'Assessment';
  id: string;
  type: string | null;
  completedAt: string | null;
  data: any | null;
  score: any | null;
  scoringErrors: any | null;
  maxPossibleScore: number;
  patientScore: number;
};

export type AttachmentFragment = {
  __typename?: 'Attachment';
  id: string;
  downloadUrl: string;
  filename: string;
};

export type BrandFieldsFragment = {
  __typename?: 'Brand';
  id: string;
  name: string;
};

export type CoreConversationFieldsFragment = {
  __typename?: 'Conversation';
  id: string;
  subject: string;
  readByPatient: boolean;
  readByCurrentUser: boolean;
  confidential: boolean;
  status: any;
  resolvedAt: string | null;
  lastMessageSentAt: string;
  lastReadByPatientAt: string | null;
  resolvedBy: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
  lastMessage: {
    __typename?: 'Message';
    createdAt: string;
    sender: {
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
      preferredName: string | null;
      role: { __typename?: 'Role'; id: string; code: string };
    };
    recipient: {
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
      preferredName: string | null;
      role: { __typename?: 'Role'; id: string; code: string };
    };
  };
  brand: { __typename?: 'Brand'; id: string; name: string };
};

export type CoreParticipantFieldsFragment = {
  __typename?: 'SimpleUser';
  id: string;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  role: { __typename?: 'Role'; id: string; code: string };
};

export type CoreMessageFieldsFragment = {
  __typename?: 'Message';
  id: string;
  subject: string;
  content: string;
  unreadCount: number;
  createdAt: string;
  deletedAt: string | null;
  updatedAt: string;
  attachments: Array<{
    __typename?: 'Attachment';
    id: string;
    filename: string;
    downloadUrl: string;
    deleteOperation: {
      __typename?: 'Operation';
      canPerform: boolean;
      invalidReasons: Array<string>;
    };
  }>;
  deleteOperation: {
    __typename?: 'Operation';
    canPerform: boolean;
    invalidReasons: Array<string>;
  };
  updateOperation: {
    __typename?: 'Operation';
    canPerform: boolean;
    invalidReasons: Array<string>;
  };
  sender: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    preferredName: string | null;
    role: { __typename?: 'Role'; id: string; code: string };
  };
  recipient: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    preferredName: string | null;
    role: { __typename?: 'Role'; id: string; code: string };
  };
  readReceipts: Array<{
    __typename?: 'ReadReceipt';
    readBy: { __typename?: 'SimpleUser'; id: string };
  }>;
};

export type LabRequisitionFragment = {
  __typename?: 'LabRequisition';
  id: string;
  readByPatientAt: string | null;
  requisitionType: string | null;
  sentAt: string;
  notes: string | null;
  createdAt: string;
  formLink: string | null;
  attachments: Array<{
    __typename?: 'Attachment';
    id: string;
    downloadUrl: string;
    filename: string;
  }>;
  sentBy: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
  };
  feedbackStep: { __typename?: 'WorkflowStep'; id: string } | null;
  updateOperation: {
    __typename?: 'Operation';
    canPerform: boolean;
    invalidReasons: Array<string>;
  };
  deleteOperation: {
    __typename?: 'Operation';
    canPerform: boolean;
    invalidReasons: Array<string>;
  };
};

export type LabRequisitionConnectionFragment = {
  __typename?: 'LabRequisitionConnection';
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    endCursor: string | null;
  };
  edges: Array<{
    __typename?: 'LabRequisitionEdge';
    node: {
      __typename?: 'LabRequisition';
      id: string;
      readByPatientAt: string | null;
      requisitionType: string | null;
      sentAt: string;
      notes: string | null;
      createdAt: string;
      formLink: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id: string;
        downloadUrl: string;
        filename: string;
      }>;
      sentBy: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
      };
      feedbackStep: { __typename?: 'WorkflowStep'; id: string } | null;
      updateOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
      deleteOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
    };
  }>;
};

export type MedicalHistoryVersionFragment = {
  __typename?: 'MedicalHistoryVersion';
  allergies: string | null;
  medications: string | null;
  medicalConditions: string | null;
  medInfoConfirmedAt: string | null;
};

export type PlatformSettingsFragment = {
  __typename?: 'PlatformSettings';
  cancellationThresholdHours: number;
  rescheduleThresholdHours: number;
};

export type PrescriptionFragment = {
  __typename?: 'Prescription';
  id: string;
  createdAt: string;
  dueAt: string | null;
  readByPatientAt: string | null;
  fulfillmentMethod: string | null;
  prescriptionType: string | null;
  prescriptionLength: number | null;
  prepType: string | null;
  medication: string | null;
  treatmentStartAt: string | null;
  prescribedDate: string | null;
  bridging: boolean | null;
  dosage: string | null;
  dosingRegimen: DosingRegimenEnum | null;
  numOfPills: number | null;
  additionalInfo: any | null;
  sentAt: string | null;
  updateOperation: {
    __typename?: 'Operation';
    canPerform: boolean;
    invalidReasons: Array<string>;
  };
  deleteOperation: {
    __typename?: 'Operation';
    canPerform: boolean;
    invalidReasons: Array<string>;
  };
  brand: { __typename?: 'Brand'; id: string; name: string } | null;
  sentBy: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
  message: {
    __typename?: 'Message';
    id: string;
    conversationId: string;
  } | null;
};

export type ProvinceFieldsFragment = {
  __typename?: 'ProvinceState';
  id: string;
  code: string;
};

export type QuestionnaireFragment = {
  __typename?: 'Questionnaire';
  id: string;
  results: any;
};

export type RoleFragment = {
  __typename?: 'Role';
  id: string;
  code: string;
  name: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
};

export type InitiateRefillMutationVariables = Exact<{
  input: InitiateRefillInput;
}>;

export type InitiateRefillMutation = {
  __typename?: 'Mutation';
  initiateRefill: {
    __typename?: 'InitiateRefillPayload';
    errors: Array<string>;
  } | null;
};

export type OnboardAdditionalTreatmentMutationVariables = Exact<{
  input: OnboardAdditionalTreatmentInput;
}>;

export type OnboardAdditionalTreatmentMutation = {
  __typename?: 'Mutation';
  onboardAdditionalTreatment: {
    __typename?: 'OnboardAdditionalTreatmentPayload';
    errors: Array<string>;
    questionnaireStepId: string | null;
  } | null;
};

export type ReferralProgramRegisterMutationVariables = Exact<{
  input: ReferralProgramRegisterInput;
}>;

export type ReferralProgramRegisterMutation = {
  __typename?: 'Mutation';
  referralProgramRegister: {
    __typename?: 'ReferralProgramRegisterPayload';
    referralCode: string;
    errors: Array<string>;
  } | null;
};

export type ResetTreatmentAndQuestionnaireMutationVariables = Exact<{
  input: ResetTreatmentAndQuestionnaireInput;
}>;

export type ResetTreatmentAndQuestionnaireMutation = {
  __typename?: 'Mutation';
  resetTreatmentAndQuestionnaire: {
    __typename?: 'ResetTreatmentAndQuestionnairePayload';
    errors: Array<string>;
    response: { __typename?: 'QuestionnaireResponse'; id: string } | null;
  } | null;
};

export type StartPatientRefillMutationVariables = Exact<{
  input: StartPatientRefillInput;
}>;

export type StartPatientRefillMutation = {
  __typename?: 'Mutation';
  startPatientRefill: {
    __typename?: 'StartPatientRefillPayload';
    errors: Array<string>;
    questionnaireStepId: string | null;
  } | null;
};

export type GetAcuityAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAcuityAccountsQuery = {
  __typename?: 'Query';
  acuityAccounts: Array<{
    __typename?: 'AcuityAccount';
    id: string;
    email: string;
    brand: { __typename?: 'Brand'; id: string; name: string };
  }>;
};

export type GetAcuityAppointmentTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAcuityAppointmentTypesQuery = {
  __typename?: 'Query';
  acuityAppointmentTypes: Array<{
    __typename?: 'AcuityAppointmentType';
    id: string;
    acuityAppointmentTypeId: string;
    bookingType: AcuityBooking;
    locale: string;
    province: { __typename?: 'ProvinceState'; id: string; code: string } | null;
    brand: { __typename?: 'Brand'; id: string; name: string } | null;
    acuityAccount: { __typename?: 'AcuityAccount'; id: string } | null;
  }>;
};

export type DeleteAcuityAppointmentTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteAcuityAppointmentTypeMutation = {
  __typename?: 'Mutation';
  deleteAcuityAppointmentType: {
    __typename?: 'DeleteAcuityAppointmentPayload';
    errors: Array<string>;
  } | null;
};

export type CreateAcuityAppointmentTypeMutationVariables = Exact<{
  input: CreateAcuityAppointmentInput;
}>;

export type CreateAcuityAppointmentTypeMutation = {
  __typename?: 'Mutation';
  createAcuityAppointmentType: {
    __typename?: 'CreateAcuityAppointmentPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateAcuityAppointmentTypeMutationVariables = Exact<{
  input: UpdateAcuityAppointmentInput;
}>;

export type UpdateAcuityAppointmentTypeMutation = {
  __typename?: 'Mutation';
  updateAcuityAppointmentType: {
    __typename?: 'UpdateAcuityAppointmentPayload';
    errors: Array<string>;
  } | null;
};

export type GetCalendarsForUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCalendarsForUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    email: string;
    acuityCalendars: Array<{
      __typename?: 'AcuityCalendar';
      id: string;
      acuityCalendarId: string;
      province: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      };
      acuityAccount: {
        __typename?: 'AcuityAccount';
        id: string;
        email: string;
        brand: { __typename?: 'Brand'; id: string; name: string };
      } | null;
    }>;
  } | null;
};

export type CreateCalendarMutationVariables = Exact<{
  input: CreateAcuityCalendarInput;
}>;

export type CreateCalendarMutation = {
  __typename?: 'Mutation';
  createAcuityCalendar: {
    __typename?: 'CreateAcuityCalendarPayload';
    errors: Array<string>;
  } | null;
};

export type DeleteCalendarMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCalendarMutation = {
  __typename?: 'Mutation';
  deleteAcuityCalendar: {
    __typename?: 'DeleteAcuityCalendarPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateCalendarMutationVariables = Exact<{
  input: UpdateAcuityCalendarInput;
}>;

export type UpdateCalendarMutation = {
  __typename?: 'Mutation';
  updateAcuityCalendar: {
    __typename?: 'UpdateAcuityCalendarPayload';
    errors: Array<string>;
  } | null;
};

export type PendingAgreementsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingAgreementsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    pendingAgreements: Array<
      | {
          __typename: 'AgreementClient';
          id: string;
          version: string;
          content: string;
        }
      | {
          __typename: 'AgreementFreddieAsync';
          id: string;
          version: string;
          agreementContent: any;
        }
      | {
          __typename: 'AgreementFreddieDoxyPepAsync';
          id: string;
          version: string;
          agreementContent: any;
        }
      | {
          __typename: 'AgreementPrivacy';
          id: string;
          version: string;
          url: string;
        }
      | {
          __typename: 'AgreementTermsOfUse';
          id: string;
          version: string;
          url: string;
        }
    >;
  } | null;
};

export type CreatePatientAgreementsMutationVariables = Exact<{
  input: CreatePatientAgreementsInput;
}>;

export type CreatePatientAgreementsMutation = {
  __typename?: 'Mutation';
  createPatientAgreements: {
    __typename?: 'CreatePatientAgreementsPayload';
    errors: Array<string>;
  } | null;
};

export type GetAvailableAppointmentDatesQueryVariables = Exact<{
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  province: Province;
  bookingReason?: InputMaybe<Scalars['String']['input']>;
  workflowStepId?: InputMaybe<Scalars['ID']['input']>;
  appointmentTypeId?: InputMaybe<Scalars['ID']['input']>;
  numberOfMonths?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAvailableAppointmentDatesQuery = {
  __typename?: 'Query';
  availableAppointmentDates: Array<{
    __typename?: 'AvailableAppointmentDate';
    date: string;
    times: Array<{
      __typename?: 'AvailableAppointmentTime';
      slotsAvailable: number | null;
      time: string;
      providerId: number | null;
    }> | null;
  }>;
};

export type GetAvailableAppointmentTimesQueryVariables = Exact<{
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  province?: InputMaybe<Province>;
  bookingReason?: InputMaybe<Scalars['String']['input']>;
  appointmentTypeId?: InputMaybe<Scalars['ID']['input']>;
  workflowStepId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAvailableAppointmentTimesQuery = {
  __typename?: 'Query';
  availableAppointmentTimes: Array<{
    __typename?: 'AvailableAppointmentTime';
    slotsAvailable: number | null;
    time: string;
    providerId: number | null;
  }>;
};

export type AppointmentNodeFragment = {
  __typename?: 'Appointment';
  id: string;
  durationInMinutes: number;
  scheduledAt: string;
  reasonForAppointment: string | null;
  bookingReason: string | null;
  bookingReasonDescription: string | null;
  calendar: {
    __typename?: 'AcuityCalendar';
    id: string;
    province: {
      __typename?: 'ProvinceState';
      id: string;
      name: string;
      code: string;
    };
    clinician: { __typename?: 'SimpleUser'; id: string };
  } | null;
  patient: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    locale: string;
    emrId: string | null;
    phoneNumber: string | null;
    preferredName: string | null;
    voicemailConsent: boolean;
    phone: {
      __typename?: 'Phone';
      rawNumber: string | null;
      e164: string;
    } | null;
    profile: {
      __typename?: 'Profile';
      dateOfBirth: string | null;
      financialExpectation: string | null;
      dateVerified: string | null;
      verificationStatus: StripeVerificationSessionStatusEnum | null;
      healthCareNumber: string | null;
      province: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
      healthCareProvince: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
    } | null;
    questionnaires: Array<{
      __typename?: 'Questionnaire';
      id: string;
      results: any;
    }> | null;
  } | null;
  brand: { __typename?: 'Brand'; id: string } | null;
  consult: {
    __typename?: 'Consult';
    id: string;
    attended: boolean;
    initialConsult: boolean | null;
    nextStep: NextStepEnum | null;
    otherNextStep: string | null;
    expectedLabDate: string | null;
    financialExpectation: string | null;
    notes: string | null;
    updatedAt: string;
    prepStatus: boolean | null;
    prepStatusReason: Array<string> | null;
    prepOnDemand: string | null;
    labReqNextStep: string | null;
    prepRxNextStep: string | null;
    otherRxNextStep: Array<string> | null;
    bridgingNeeded: boolean | null;
    additionalInfo: Array<string> | null;
    prepTypeLikelyToPrescribe: string | null;
    consultCompletedStatusReason: string | null;
    consultNotCompletedDescription: string | null;
  } | null;
  workflowSteps: Array<{
    __typename?: 'WorkflowStep';
    id: string;
    type: string;
    children: Array<{
      __typename?: 'WorkflowStep';
      id: string;
      type: string;
      completedAt: string | null;
      completionParams: any | null;
    }>;
  }>;
  postConsultWorkflowStep: {
    __typename?: 'WorkflowStep';
    id: string;
    type: string;
    completedAt: string | null;
  } | null;
};

export type GetAppointmentsQueryVariables = Exact<{
  scheduledBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  scheduledAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  clinicianId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAppointmentsQuery = {
  __typename?: 'Query';
  appointments: {
    __typename?: 'AppointmentConnection';
    edges: Array<{
      __typename?: 'AppointmentEdge';
      node: {
        __typename?: 'Appointment';
        id: string;
        durationInMinutes: number;
        scheduledAt: string;
        reasonForAppointment: string | null;
        bookingReason: string | null;
        bookingReasonDescription: string | null;
        calendar: {
          __typename?: 'AcuityCalendar';
          id: string;
          province: {
            __typename?: 'ProvinceState';
            id: string;
            name: string;
            code: string;
          };
          clinician: { __typename?: 'SimpleUser'; id: string };
        } | null;
        patient: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          locale: string;
          emrId: string | null;
          phoneNumber: string | null;
          preferredName: string | null;
          voicemailConsent: boolean;
          phone: {
            __typename?: 'Phone';
            rawNumber: string | null;
            e164: string;
          } | null;
          profile: {
            __typename?: 'Profile';
            dateOfBirth: string | null;
            financialExpectation: string | null;
            dateVerified: string | null;
            verificationStatus: StripeVerificationSessionStatusEnum | null;
            healthCareNumber: string | null;
            province: {
              __typename?: 'ProvinceState';
              id: string;
              name: string;
              code: string;
              supported: boolean;
            } | null;
            healthCareProvince: {
              __typename?: 'ProvinceState';
              id: string;
              name: string;
              code: string;
              supported: boolean;
            } | null;
          } | null;
          questionnaires: Array<{
            __typename?: 'Questionnaire';
            id: string;
            results: any;
          }> | null;
        } | null;
        brand: { __typename?: 'Brand'; id: string } | null;
        consult: {
          __typename?: 'Consult';
          id: string;
          attended: boolean;
          initialConsult: boolean | null;
          nextStep: NextStepEnum | null;
          otherNextStep: string | null;
          expectedLabDate: string | null;
          financialExpectation: string | null;
          notes: string | null;
          updatedAt: string;
          prepStatus: boolean | null;
          prepStatusReason: Array<string> | null;
          prepOnDemand: string | null;
          labReqNextStep: string | null;
          prepRxNextStep: string | null;
          otherRxNextStep: Array<string> | null;
          bridgingNeeded: boolean | null;
          additionalInfo: Array<string> | null;
          prepTypeLikelyToPrescribe: string | null;
          consultCompletedStatusReason: string | null;
          consultNotCompletedDescription: string | null;
        } | null;
        workflowSteps: Array<{
          __typename?: 'WorkflowStep';
          id: string;
          type: string;
          children: Array<{
            __typename?: 'WorkflowStep';
            id: string;
            type: string;
            completedAt: string | null;
            completionParams: any | null;
          }>;
        }>;
        postConsultWorkflowStep: {
          __typename?: 'WorkflowStep';
          id: string;
          type: string;
          completedAt: string | null;
        } | null;
      };
    }>;
  } | null;
};

export type MiniAppointmentNodeFragment = {
  __typename?: 'Appointment';
  id: string;
  appointmentTypeName: string | null;
  durationInMinutes: number;
  scheduledAt: string;
  videoEnabled: boolean;
  calendar: {
    __typename?: 'AcuityCalendar';
    id: string;
    province: { __typename?: 'ProvinceState'; code: string };
    clinician: { __typename?: 'SimpleUser'; id: string };
  } | null;
  patient: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
  brand: { __typename?: 'Brand'; id: string } | null;
  consult: { __typename?: 'Consult'; id: string } | null;
  postConsultWorkflowStep: {
    __typename?: 'WorkflowStep';
    id: string;
    completedAt: string | null;
  } | null;
};

export type GetAppointmentsListQueryVariables = Exact<{
  scheduledBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  scheduledAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  clinicianId?: InputMaybe<Scalars['ID']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppointmentsListQuery = {
  __typename?: 'Query';
  appointments: {
    __typename?: 'AppointmentConnection';
    edges: Array<{
      __typename?: 'AppointmentEdge';
      node: {
        __typename?: 'Appointment';
        id: string;
        appointmentTypeName: string | null;
        durationInMinutes: number;
        scheduledAt: string;
        videoEnabled: boolean;
        calendar: {
          __typename?: 'AcuityCalendar';
          id: string;
          province: { __typename?: 'ProvinceState'; code: string };
          clinician: { __typename?: 'SimpleUser'; id: string };
        } | null;
        patient: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        } | null;
        brand: { __typename?: 'Brand'; id: string } | null;
        consult: { __typename?: 'Consult'; id: string } | null;
        postConsultWorkflowStep: {
          __typename?: 'WorkflowStep';
          id: string;
          completedAt: string | null;
        } | null;
      };
    }>;
  } | null;
};

export type GetAvailableTimeslotsForBookingSessionQueryVariables = Exact<{
  bookingSessionId: Scalars['ID']['input'];
  bookingDate: Scalars['ISO8601Date']['input'];
}>;

export type GetAvailableTimeslotsForBookingSessionQuery = {
  __typename?: 'Query';
  availableTimeSlotsForBookingSession: Array<{
    __typename?: 'AvailableAppointmentTime';
    time: string;
    providerId: number | null;
  }>;
};

export type BookAppointmentMutationVariables = Exact<{
  input: BookAppointmentInput;
}>;

export type BookAppointmentMutation = {
  __typename?: 'Mutation';
  bookAppointment: {
    __typename?: 'BookAppointmentPayload';
    errors: Array<any>;
  } | null;
};

export type CancelAppointmentMutationVariables = Exact<{
  input: CancelAppointmentInput;
}>;

export type CancelAppointmentMutation = {
  __typename?: 'Mutation';
  cancelAppointment: {
    __typename?: 'CancelAppointmentPayload';
    errors: Array<any>;
  } | null;
};

export type RescheduleAppointmentMutationVariables = Exact<{
  input: RescheduleAppointmentInput;
}>;

export type RescheduleAppointmentMutation = {
  __typename?: 'Mutation';
  rescheduleAppointment: {
    __typename?: 'RescheduleAppointmentPayload';
    errors: Array<any>;
  } | null;
};

export type StartBookingSessionMutationVariables = Exact<{
  input: StartBookingSessionInput;
}>;

export type StartBookingSessionMutation = {
  __typename?: 'Mutation';
  startBookingSession: {
    __typename?: 'StartBookingSessionPayload';
    errors: Array<string>;
    bookingSession: {
      __typename?: 'BookingSession';
      id: string;
      availableDates: Array<string>;
      coupon: {
        __typename?: 'Coupon';
        name: string;
        description: string;
      } | null;
      appointmentType: {
        __typename?: 'AcuityAppointmentType';
        durationInMinutes: number;
        name: string;
        availableContactTypes: Array<AppointmentContactType>;
        contactTypeSelectionEnabled: boolean;
        price: { __typename?: 'Price'; amountInCents: number } | null;
      };
      appointment: {
        __typename?: 'AcuityAppointment';
        id: string;
        appointmentTypeId: number | null;
        acuityAppointmentId: number | null;
        durationInMinutes: number;
        location: string | null;
        reasonForAppointment: string | null;
        scheduledAt: string;
        videoEnabled: boolean;
        appointmentType: {
          __typename?: 'AppointmentType';
          lateReschedulePrice: {
            __typename?: 'Price';
            id: string;
            amountInCents: number;
          } | null;
        } | null;
        brand: { __typename?: 'Brand'; id: string; name: string } | null;
        cancelOperation: { __typename?: 'Operation'; canPerform: boolean };
        rescheduleOperation: { __typename?: 'Operation'; canPerform: boolean };
        lateReschedulePrice: {
          __typename?: 'Price';
          id: string;
          amountInCents: number;
        } | null;
        lateCancellationPrice: {
          __typename?: 'Price';
          id: string;
          amountInCents: number;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BookAppointmentWithBookingSessionMutationVariables = Exact<{
  input: BookAppointmentWithBookingSessionInput;
}>;

export type BookAppointmentWithBookingSessionMutation = {
  __typename?: 'Mutation';
  bookAppointmentWithBookingSession: {
    __typename?: 'BookAppointmentWithBookingSessionPayload';
    errors: Array<any>;
    bookingSession: {
      __typename?: 'BookingSession';
      appointment: { __typename?: 'AcuityAppointment'; id: string } | null;
    };
  } | null;
};

export type PatientAppointmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PatientAppointmentQuery = {
  __typename?: 'Query';
  appointment: {
    __typename?: 'AcuityAppointment';
    id: string;
    appointmentTypeId: number | null;
    acuityAppointmentId: number | null;
    durationInMinutes: number;
    location: string | null;
    reasonForAppointment: string | null;
    scheduledAt: string;
    videoEnabled: boolean;
    appointmentType: {
      __typename?: 'AppointmentType';
      lateReschedulePrice: {
        __typename?: 'Price';
        id: string;
        amountInCents: number;
      } | null;
    } | null;
    brand: { __typename?: 'Brand'; id: string; name: string } | null;
    cancelOperation: { __typename?: 'Operation'; canPerform: boolean };
    rescheduleOperation: { __typename?: 'Operation'; canPerform: boolean };
    lateReschedulePrice: {
      __typename?: 'Price';
      id: string;
      amountInCents: number;
    } | null;
    lateCancellationPrice: {
      __typename?: 'Price';
      id: string;
      amountInCents: number;
    } | null;
  };
  platformSettings: {
    __typename?: 'PlatformSettings';
    cancellationThresholdHours: number;
    rescheduleThresholdHours: number;
  };
};

export type PatientAppointmentPartsFragment = {
  __typename?: 'AcuityAppointment';
  id: string;
  appointmentTypeId: number | null;
  acuityAppointmentId: number | null;
  durationInMinutes: number;
  location: string | null;
  reasonForAppointment: string | null;
  scheduledAt: string;
  videoEnabled: boolean;
  appointmentType: {
    __typename?: 'AppointmentType';
    lateReschedulePrice: {
      __typename?: 'Price';
      id: string;
      amountInCents: number;
    } | null;
  } | null;
  brand: { __typename?: 'Brand'; id: string; name: string } | null;
  cancelOperation: { __typename?: 'Operation'; canPerform: boolean };
  rescheduleOperation: { __typename?: 'Operation'; canPerform: boolean };
  lateReschedulePrice: {
    __typename?: 'Price';
    id: string;
    amountInCents: number;
  } | null;
  lateCancellationPrice: {
    __typename?: 'Price';
    id: string;
    amountInCents: number;
  } | null;
};

export type BookingSessionFragment = {
  __typename?: 'BookingSession';
  id: string;
  availableDates: Array<string>;
  coupon: { __typename?: 'Coupon'; name: string; description: string } | null;
  appointmentType: {
    __typename?: 'AcuityAppointmentType';
    durationInMinutes: number;
    name: string;
    availableContactTypes: Array<AppointmentContactType>;
    contactTypeSelectionEnabled: boolean;
    price: { __typename?: 'Price'; amountInCents: number } | null;
  };
  appointment: {
    __typename?: 'AcuityAppointment';
    id: string;
    appointmentTypeId: number | null;
    acuityAppointmentId: number | null;
    durationInMinutes: number;
    location: string | null;
    reasonForAppointment: string | null;
    scheduledAt: string;
    videoEnabled: boolean;
    appointmentType: {
      __typename?: 'AppointmentType';
      lateReschedulePrice: {
        __typename?: 'Price';
        id: string;
        amountInCents: number;
      } | null;
    } | null;
    brand: { __typename?: 'Brand'; id: string; name: string } | null;
    cancelOperation: { __typename?: 'Operation'; canPerform: boolean };
    rescheduleOperation: { __typename?: 'Operation'; canPerform: boolean };
    lateReschedulePrice: {
      __typename?: 'Price';
      id: string;
      amountInCents: number;
    } | null;
    lateCancellationPrice: {
      __typename?: 'Price';
      id: string;
      amountInCents: number;
    } | null;
  } | null;
};

export type AssessmentScalePartsFragment = {
  __typename?: 'Assessment';
  id: string;
  type: string | null;
  completedAt: string | null;
  patientScore: number;
  maxPossibleScore: number;
  score: any | null;
  result: {
    __typename?: 'AssessmentResult';
    totalScore: number;
    sections: Array<{
      __typename?: 'AssessmentSection';
      name: string;
      score: number;
      skipped: boolean;
      answers: Array<{
        __typename?: 'AssessmentAnswer';
        question: string;
        response: string;
        score: number;
      }>;
    }>;
  };
};

export type GetRecentAssessmentScalesQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
}>;

export type GetRecentAssessmentScalesQuery = {
  __typename?: 'Query';
  assessments: {
    __typename?: 'AssessmentConnection';
    nodes: Array<{
      __typename?: 'Assessment';
      id: string;
      type: string | null;
      completedAt: string | null;
      patientScore: number;
      maxPossibleScore: number;
      score: any | null;
      result: {
        __typename?: 'AssessmentResult';
        totalScore: number;
        sections: Array<{
          __typename?: 'AssessmentSection';
          name: string;
          score: number;
          skipped: boolean;
          answers: Array<{
            __typename?: 'AssessmentAnswer';
            question: string;
            response: string;
            score: number;
          }>;
        }>;
      };
    }>;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginPayload';
    jwt: string | null;
    errors: Array<any>;
    user: { __typename?: 'User'; id: string } | null;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: {
    __typename?: 'ResetPasswordPayload';
    errors: Array<string>;
  } | null;
};

export type CreatePasswordResetUrlMutationVariables = Exact<{
  input: CreatePasswordResetUrlInput;
}>;

export type CreatePasswordResetUrlMutation = {
  __typename?: 'Mutation';
  createPasswordResetUrl: {
    __typename?: 'CreatePasswordResetUrlPayload';
    url: string | null;
    errors: Array<string>;
  } | null;
};

export type RecoverPasswordMutationVariables = Exact<{
  input: RecoverPasswordInput;
}>;

export type RecoverPasswordMutation = {
  __typename?: 'Mutation';
  recoverPassword: {
    __typename?: 'RecoverPasswordPayload';
    errors: Array<string>;
  } | null;
};

export type CreatePasswordMutationVariables = Exact<{
  input: CreatePasswordInput;
}>;

export type CreatePasswordMutation = {
  __typename?: 'Mutation';
  createPassword: {
    __typename?: 'CreatePasswordPayload';
    errors: Array<string>;
  } | null;
};

export type CreateConsultMutationVariables = Exact<{
  input: CreateConsultInput;
}>;

export type CreateConsultMutation = {
  __typename?: 'Mutation';
  createConsult: {
    __typename?: 'CreateConsultPayload';
    errors: Array<string>;
  } | null;
};

export type PostConsultWorkflowStepFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  dueAt: string | null;
  user: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      financialExpectation: string | null;
      province: { __typename?: 'ProvinceState'; name: string } | null;
    } | null;
  };
  subject:
    | {
        __typename?: 'AcuityAppointment';
        id: string;
        scheduledAt: string;
        consult: {
          __typename?: 'Consult';
          id: string;
          attended: boolean;
          nextStep: NextStepEnum | null;
          otherNextStep: string | null;
          initialConsult: boolean | null;
          notes: string | null;
          additionalInfo: Array<string> | null;
          consultNotCompletedDescription: string | null;
          labReqNextStep: string | null;
          prepRxNextStep: string | null;
          otherRxNextStep: Array<string> | null;
        } | null;
        calendar: {
          __typename?: 'AcuityCalendar';
          clinician: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        } | null;
      }
    | { __typename?: 'Assessment' }
    | { __typename?: 'LabRequisition' }
    | { __typename?: 'Prescription' }
    | { __typename?: 'Questionnaire' }
    | { __typename?: 'ShortQuestionnaire' }
    | { __typename?: 'Unhandled' }
    | null;
};

export type GetPostConsultActionsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  dueBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  consultAttended?: InputMaybe<Scalars['Boolean']['input']>;
  provinceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetPostConsultActionsQuery = {
  __typename?: 'Query';
  postConsultActions: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        dueAt: string | null;
        user: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            financialExpectation: string | null;
            province: { __typename?: 'ProvinceState'; name: string } | null;
          } | null;
        };
        subject:
          | {
              __typename?: 'AcuityAppointment';
              id: string;
              scheduledAt: string;
              consult: {
                __typename?: 'Consult';
                id: string;
                attended: boolean;
                nextStep: NextStepEnum | null;
                otherNextStep: string | null;
                initialConsult: boolean | null;
                notes: string | null;
                additionalInfo: Array<string> | null;
                consultNotCompletedDescription: string | null;
                labReqNextStep: string | null;
                prepRxNextStep: string | null;
                otherRxNextStep: Array<string> | null;
              } | null;
              calendar: {
                __typename?: 'AcuityCalendar';
                clinician: {
                  __typename?: 'SimpleUser';
                  id: string;
                  firstName: string | null;
                  lastName: string | null;
                };
              } | null;
            }
          | { __typename?: 'Assessment' }
          | { __typename?: 'LabRequisition' }
          | { __typename?: 'Prescription' }
          | { __typename?: 'Questionnaire' }
          | { __typename?: 'ShortQuestionnaire' }
          | { __typename?: 'Unhandled' }
          | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type GetConversationsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  unreadById?: InputMaybe<Scalars['ID']['input']>;
  resolvedById?: InputMaybe<Scalars['ID']['input']>;
  confidential?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  brandIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  provinceIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  participantIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  anyParticipantIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  status?: InputMaybe<Array<ConversationStatus> | ConversationStatus>;
}>;

export type GetConversationsQuery = {
  __typename?: 'Query';
  conversations: {
    __typename?: 'ConversationConnection';
    edges: Array<{
      __typename?: 'ConversationEdge';
      node: {
        __typename?: 'Conversation';
        id: string;
        subject: string;
        readByPatient: boolean;
        readByCurrentUser: boolean;
        confidential: boolean;
        status: any;
        resolvedAt: string | null;
        lastMessageSentAt: string;
        lastReadByPatientAt: string | null;
        resolvedBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        } | null;
        lastMessage: {
          __typename?: 'Message';
          createdAt: string;
          sender: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
          recipient: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
        };
        brand: { __typename?: 'Brand'; id: string; name: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type GetConversationsWithModifiedPaginationQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  beforeCursor?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  unreadById?: InputMaybe<Scalars['ID']['input']>;
  resolvedById?: InputMaybe<Scalars['ID']['input']>;
  confidential?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  brandIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  provinceIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  participantIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  anyParticipantIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  status?: InputMaybe<Array<ConversationStatus> | ConversationStatus>;
}>;

export type GetConversationsWithModifiedPaginationQuery = {
  __typename?: 'Query';
  conversationsNoMerging: {
    __typename?: 'ConversationConnection';
    edges: Array<{
      __typename?: 'ConversationEdge';
      node: {
        __typename?: 'Conversation';
        id: string;
        subject: string;
        readByPatient: boolean;
        readByCurrentUser: boolean;
        confidential: boolean;
        status: any;
        resolvedAt: string | null;
        lastMessageSentAt: string;
        lastReadByPatientAt: string | null;
        resolvedBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        } | null;
        lastMessage: {
          __typename?: 'Message';
          createdAt: string;
          sender: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
          recipient: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
        };
        brand: { __typename?: 'Brand'; id: string; name: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
  };
};

export type GetPersonalConversationsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  unreadById?: InputMaybe<Scalars['ID']['input']>;
  brandIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  provinceIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  participantIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  anyParticipantIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  status?: InputMaybe<Array<ConversationStatus> | ConversationStatus>;
}>;

export type GetPersonalConversationsQuery = {
  __typename?: 'Query';
  conversations: {
    __typename?: 'ConversationConnection';
    edges: Array<{
      __typename?: 'ConversationEdge';
      node: {
        __typename?: 'Conversation';
        id: string;
        subject: string;
        readByPatient: boolean;
        readByCurrentUser: boolean;
        confidential: boolean;
        status: any;
        resolvedAt: string | null;
        lastMessageSentAt: string;
        lastReadByPatientAt: string | null;
        resolvedBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        } | null;
        lastMessage: {
          __typename?: 'Message';
          createdAt: string;
          sender: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
          recipient: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
        };
        brand: { __typename?: 'Brand'; id: string; name: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type GetConversationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetConversationQuery = {
  __typename?: 'Query';
  conversation: {
    __typename?: 'Conversation';
    id: string;
    subject: string;
    readByPatient: boolean;
    readByCurrentUser: boolean;
    confidential: boolean;
    status: any;
    resolvedAt: string | null;
    lastMessageSentAt: string;
    lastReadByPatientAt: string | null;
    messages: Array<{
      __typename?: 'Message';
      id: string;
      subject: string;
      content: string;
      unreadCount: number;
      createdAt: string;
      deletedAt: string | null;
      updatedAt: string;
      attachments: Array<{
        __typename?: 'Attachment';
        id: string;
        filename: string;
        downloadUrl: string;
        deleteOperation: {
          __typename?: 'Operation';
          canPerform: boolean;
          invalidReasons: Array<string>;
        };
      }>;
      deleteOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
      updateOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
      sender: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
        preferredName: string | null;
        role: { __typename?: 'Role'; id: string; code: string };
      };
      recipient: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
        preferredName: string | null;
        role: { __typename?: 'Role'; id: string; code: string };
      };
      readReceipts: Array<{
        __typename?: 'ReadReceipt';
        readBy: { __typename?: 'SimpleUser'; id: string };
      }>;
    }>;
    participants: Array<{
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
      preferredName: string | null;
      role: { __typename?: 'Role'; id: string; code: string };
    }>;
    resolvedBy: {
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
    } | null;
    lastMessage: {
      __typename?: 'Message';
      createdAt: string;
      sender: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
        preferredName: string | null;
        role: { __typename?: 'Role'; id: string; code: string };
      };
      recipient: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
        preferredName: string | null;
        role: { __typename?: 'Role'; id: string; code: string };
      };
    };
    brand: { __typename?: 'Brand'; id: string; name: string };
  } | null;
};

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage: {
    __typename?: 'CreateMessagePayload';
    errors: Array<string>;
    message: {
      __typename?: 'Message';
      id: string;
      conversationId: string;
    } | null;
  } | null;
};

export type MarkConversationAsReadMutationVariables = Exact<{
  input: MarkConversationAsReadInput;
}>;

export type MarkConversationAsReadMutation = {
  __typename?: 'Mutation';
  markConversationAsRead: {
    __typename?: 'MarkConversationAsReadPayload';
    errors: Array<string>;
  } | null;
};

export type ReadMessageMutationVariables = Exact<{
  input: MarkMessageAsReadInput;
}>;

export type ReadMessageMutation = {
  __typename?: 'Mutation';
  markMessageAsRead: {
    __typename?: 'MarkMessageAsReadPayload';
    errors: Array<string>;
  } | null;
};

export type DeleteAttachmentMutationVariables = Exact<{
  input: DeleteAttachmentInput;
}>;

export type DeleteAttachmentMutation = {
  __typename?: 'Mutation';
  deleteAttachment: {
    __typename?: 'DeleteAttachmentPayload';
    errors: Array<string>;
  } | null;
};

export type GetMessageStatisticsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMessageStatisticsQuery = {
  __typename?: 'Query';
  messageStatistics: {
    __typename?: 'MessageStatistics';
    conversations: {
      __typename?: 'ConversationStatistics';
      myUnreadCount: number;
    };
  };
};

export type GetPatientConversationsQueryVariables = Exact<{
  otherParticipantId: Scalars['ID']['input'];
  brandIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type GetPatientConversationsQuery = {
  __typename?: 'Query';
  conversations: {
    __typename?: 'ConversationConnection';
    edges: Array<{
      __typename?: 'ConversationEdge';
      node: {
        __typename?: 'Conversation';
        id: string;
        subject: string;
        readByPatient: boolean;
        readByCurrentUser: boolean;
        confidential: boolean;
        status: any;
        resolvedAt: string | null;
        lastMessageSentAt: string;
        lastReadByPatientAt: string | null;
        resolvedBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        } | null;
        lastMessage: {
          __typename?: 'Message';
          createdAt: string;
          sender: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
          recipient: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
            preferredName: string | null;
            role: { __typename?: 'Role'; id: string; code: string };
          };
        };
        brand: { __typename?: 'Brand'; id: string; name: string };
      };
    }>;
  };
};

export type GetMyConversationsQueryVariables = Exact<{
  unreadById?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<ConversationStatus> | ConversationStatus>;
}>;

export type GetMyConversationsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    myConversations: {
      __typename?: 'ConversationConnection';
      edges: Array<{
        __typename?: 'ConversationEdge';
        node: {
          __typename?: 'Conversation';
          id: string;
          subject: string;
          readByPatient: boolean;
          readByCurrentUser: boolean;
          confidential: boolean;
          status: any;
          resolvedAt: string | null;
          lastMessageSentAt: string;
          lastReadByPatientAt: string | null;
          resolvedBy: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
          } | null;
          lastMessage: {
            __typename?: 'Message';
            createdAt: string;
            sender: {
              __typename?: 'SimpleUser';
              id: string;
              firstName: string | null;
              lastName: string | null;
              preferredName: string | null;
              role: { __typename?: 'Role'; id: string; code: string };
            };
            recipient: {
              __typename?: 'SimpleUser';
              id: string;
              firstName: string | null;
              lastName: string | null;
              preferredName: string | null;
              role: { __typename?: 'Role'; id: string; code: string };
            };
          };
          brand: { __typename?: 'Brand'; id: string; name: string };
        };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor: string | null;
        hasNextPage: boolean;
      };
    };
  } | null;
};

export type UpdateConversationMutationVariables = Exact<{
  input: UpdateConversationInput;
}>;

export type UpdateConversationMutation = {
  __typename?: 'Mutation';
  updateConversation: {
    __typename?: 'UpdateConversationPayload';
    errors: Array<string>;
  } | null;
};

export type GetMessageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetMessageQuery = {
  __typename?: 'Query';
  message: {
    __typename?: 'Message';
    id: string;
    conversationId: string;
  } | null;
};

export type DeleteMessageMutationVariables = Exact<{
  input: DeleteMessageInput;
}>;

export type DeleteMessageMutation = {
  __typename?: 'Mutation';
  deleteMessage: {
    __typename?: 'DeleteMessagePayload';
    errors: Array<string>;
  } | null;
};

export type UpdateMessageMutationVariables = Exact<{
  input: UpdateMessageInput;
}>;

export type UpdateMessageMutation = {
  __typename?: 'Mutation';
  updateMessage: {
    __typename?: 'UpdateMessagePayload';
    errors: Array<string>;
  } | null;
};

export type ProvinceFragment = {
  __typename?: 'ProvinceState';
  id: string;
  name: string;
  code: string;
  supported: boolean;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
    code: string;
    provinces: Array<{
      __typename?: 'ProvinceState';
      id: string;
      name: string;
      code: string;
      supported: boolean;
    }>;
  }>;
};

export type GetCredentialsForUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCredentialsForUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    email: string;
    credentials: Array<{
      __typename?: 'Credential';
      id: string;
      designation: string;
      registrationNumber: string;
      province: { __typename?: 'ProvinceState'; code: string };
    }>;
  } | null;
};

export type CreateCredentialMutationVariables = Exact<{
  input: CreateCredentialInput;
}>;

export type CreateCredentialMutation = {
  __typename?: 'Mutation';
  createCredential: {
    __typename?: 'CreateCredentialPayload';
    errors: Array<string>;
  } | null;
};

export type DeleteCredentialMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCredentialMutation = {
  __typename?: 'Mutation';
  deleteCredential: {
    __typename?: 'DeleteCredentialPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateCredentialMutationVariables = Exact<{
  input: UpdateCredentialInput;
}>;

export type UpdateCredentialMutation = {
  __typename?: 'Mutation';
  updateCredential: {
    __typename?: 'UpdateCredentialPayload';
    errors: Array<string>;
  } | null;
};

export type CreateInsuranceCardMutationVariables = Exact<{
  input: CreateInsuranceCardInput;
}>;

export type CreateInsuranceCardMutation = {
  __typename?: 'Mutation';
  createInsuranceCard: {
    __typename?: 'CreateInsuranceCardPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateInsuranceCardMutationVariables = Exact<{
  input: UpdateInsuranceCardInput;
}>;

export type UpdateInsuranceCardMutation = {
  __typename?: 'Mutation';
  updateInsuranceCard: {
    __typename?: 'UpdateInsuranceCardPayload';
    errors: Array<string>;
  } | null;
};

export type DeleteInsuranceCardMutationVariables = Exact<{
  input: DeleteInsuranceCardInput;
}>;

export type DeleteInsuranceCardMutation = {
  __typename?: 'Mutation';
  deleteInsuranceCard: {
    __typename?: 'DeleteInsuranceCardPayload';
    errors: Array<any>;
  } | null;
};

export type GetJunoFormSubmissionQueryVariables = Exact<{
  fdid: Scalars['Int']['input'];
}>;

export type GetJunoFormSubmissionQuery = {
  __typename?: 'Query';
  junoFormSubmission: {
    __typename?: 'JunoFormSubmission';
    fdid: number | null;
    html: string | null;
  } | null;
};

export type CreateLabRequisitionMutationVariables = Exact<{
  input: CreateLabRequisitionInput;
}>;

export type CreateLabRequisitionMutation = {
  __typename?: 'Mutation';
  createLabRequisition: {
    __typename?: 'CreateLabRequisitionPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateLabRequisitionMutationVariables = Exact<{
  input: UpdateLabRequisitionInput;
}>;

export type UpdateLabRequisitionMutation = {
  __typename?: 'Mutation';
  updateLabRequisition: {
    __typename?: 'UpdateLabRequisitionPayload';
    errors: Array<string>;
    labRequisition: {
      __typename?: 'LabRequisition';
      id: string;
      readByPatientAt: string | null;
      requisitionType: string | null;
      sentAt: string;
      notes: string | null;
      createdAt: string;
      formLink: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id: string;
        downloadUrl: string;
        filename: string;
      }>;
      sentBy: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
      };
      feedbackStep: { __typename?: 'WorkflowStep'; id: string } | null;
      updateOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
      deleteOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
    } | null;
  } | null;
};

export type UpdateLabRequisitionViewedAtMutationVariables = Exact<{
  input: UpdateLabRequisitionViewedAtInput;
}>;

export type UpdateLabRequisitionViewedAtMutation = {
  __typename?: 'Mutation';
  updateLabRequisitionViewedAt: {
    __typename?: 'UpdateLabRequisitionViewedAtPayload';
    errors: Array<string>;
  } | null;
};

export type DeleteLabRequisitionMutationVariables = Exact<{
  input: DeleteLabRequisitionInput;
}>;

export type DeleteLabRequisitionMutation = {
  __typename?: 'Mutation';
  deleteLabRequisition: {
    __typename?: 'DeleteLabRequisitionPayload';
    errors: Array<string>;
  } | null;
};

export type CoreLabRequisitionFragment = {
  __typename?: 'LabRequisition';
  id: string;
  createdAt: string;
  requisitionType: string | null;
};

export type LabRequisitionWorkflowStepFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  dueAt: string | null;
  parentStep: { __typename?: 'WorkflowStep'; id: string } | null;
  user: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
    } | null;
    prescriptions: {
      __typename?: 'PrescriptionConnection';
      nodes: Array<{ __typename?: 'Prescription'; createdAt: string }>;
    };
  };
  subject:
    | { __typename?: 'AcuityAppointment' }
    | { __typename?: 'Assessment' }
    | {
        __typename?: 'LabRequisition';
        id: string;
        createdAt: string;
        requisitionType: string | null;
      }
    | { __typename?: 'Prescription' }
    | { __typename?: 'Questionnaire' }
    | { __typename?: 'ShortQuestionnaire' }
    | { __typename?: 'Unhandled' }
    | null;
};

export type GetFulfillLabRequisitionStepsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFulfillLabRequisitionStepsQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        dueAt: string | null;
        parentStep: { __typename?: 'WorkflowStep'; id: string } | null;
        user: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            dateOfBirth: string | null;
          } | null;
          prescriptions: {
            __typename?: 'PrescriptionConnection';
            nodes: Array<{ __typename?: 'Prescription'; createdAt: string }>;
          };
        };
        subject:
          | { __typename?: 'AcuityAppointment' }
          | { __typename?: 'Assessment' }
          | {
              __typename?: 'LabRequisition';
              id: string;
              createdAt: string;
              requisitionType: string | null;
            }
          | { __typename?: 'Prescription' }
          | { __typename?: 'Questionnaire' }
          | { __typename?: 'ShortQuestionnaire' }
          | { __typename?: 'Unhandled' }
          | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type LabRequisitionWorflowStepFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  dueAt: string | null;
  parentStep: { __typename?: 'WorkflowStep'; id: string } | null;
  user: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
    } | null;
    prescriptions: {
      __typename?: 'PrescriptionConnection';
      nodes: Array<{ __typename?: 'Prescription'; createdAt: string }>;
    };
  };
  subject:
    | { __typename?: 'AcuityAppointment' }
    | { __typename?: 'Assessment' }
    | {
        __typename?: 'LabRequisition';
        id: string;
        createdAt: string;
        requisitionType: string | null;
      }
    | { __typename?: 'Prescription' }
    | { __typename?: 'Questionnaire' }
    | { __typename?: 'ShortQuestionnaire' }
    | { __typename?: 'Unhandled' }
    | null;
};

export type GetReviewLabRequisitionStepsQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['ID']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetReviewLabRequisitionStepsQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        dueAt: string | null;
        parentStep: { __typename?: 'WorkflowStep'; id: string } | null;
        user: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            dateOfBirth: string | null;
          } | null;
          prescriptions: {
            __typename?: 'PrescriptionConnection';
            nodes: Array<{ __typename?: 'Prescription'; createdAt: string }>;
          };
        };
        subject:
          | { __typename?: 'AcuityAppointment' }
          | { __typename?: 'Assessment' }
          | {
              __typename?: 'LabRequisition';
              id: string;
              createdAt: string;
              requisitionType: string | null;
            }
          | { __typename?: 'Prescription' }
          | { __typename?: 'Questionnaire' }
          | { __typename?: 'ShortQuestionnaire' }
          | { __typename?: 'Unhandled' }
          | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type LabFeedbackWorkflowStepFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  completionParams: any | null;
  createdAt: string;
  completedAt: string | null;
  parentStep: {
    __typename?: 'WorkflowStep';
    id: string;
    createdAt: string;
    completedAt: string | null;
    completionParams: any | null;
  } | null;
  user: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
    } | null;
  };
};

export type GetLabFeedbacksQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetLabFeedbacksQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        completionParams: any | null;
        createdAt: string;
        completedAt: string | null;
        parentStep: {
          __typename?: 'WorkflowStep';
          id: string;
          createdAt: string;
          completedAt: string | null;
          completionParams: any | null;
        } | null;
        user: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            dateOfBirth: string | null;
          } | null;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type GetCopyLabFeedbackStepsQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['ID']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCopyLabFeedbackStepsQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        completionParams: any | null;
        createdAt: string;
        completedAt: string | null;
        parentStep: {
          __typename?: 'WorkflowStep';
          id: string;
          createdAt: string;
          completedAt: string | null;
          completionParams: any | null;
        } | null;
        user: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            dateOfBirth: string | null;
          } | null;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type MedicationRequestFragment = {
  __typename?: 'FHIRMedicationRequest';
  id: string | null;
  status: string | null;
  intent: string | null;
  medication: string;
  authoredOn: string | null;
  quantity: string | null;
};

export type GetFhirMedicationRequestsQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
}>;

export type GetFhirMedicationRequestsQuery = {
  __typename?: 'Query';
  medicationRequests: Array<{
    __typename?: 'FHIRMedicationRequest';
    id: string | null;
    status: string | null;
    intent: string | null;
    medication: string;
    authoredOn: string | null;
    quantity: string | null;
  }> | null;
};

export type QuestionnaireResponsePartsFragment = {
  __typename?: 'QuestionnaireResponse';
  id: string;
  createdAt: string;
  completedAt: string | null;
  canceledAt: string | null;
  definition: {
    __typename?: 'QuestionnaireDefinition';
    id: string;
    name: string;
    title: string | null;
    description: string | null;
  };
  answers: Array<{
    __typename?: 'QuestionnaireAnswer';
    id: string;
    values: Array<string>;
    question:
      | {
          __typename?: 'QuestionnaireCheckboxQuestion';
          minSelections: number;
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          acceptableAnswers: Array<{
            __typename?: 'QuestionnaireAcceptableAnswer';
            label: string;
            value: string | null;
          }>;
          resources: Array<{
            __typename?: 'QuestionnaireQuestionResource';
            value: string;
            type: QuestionnaireQuestionResourceTypeEnum;
            label: string | null;
            title: string | null;
          }> | null;
        }
      | {
          __typename?: 'QuestionnaireDateInputQuestion';
          allowsFutureDates: boolean;
          allowsPastDates: boolean;
          required: boolean;
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          resources: Array<{
            __typename?: 'QuestionnaireQuestionResource';
            value: string;
            type: QuestionnaireQuestionResourceTypeEnum;
            label: string | null;
            title: string | null;
          }> | null;
        }
      | {
          __typename?: 'QuestionnaireNumberInputQuestion';
          allowsDecimals: boolean;
          max: number | null;
          min: number | null;
          required: boolean;
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          resources: Array<{
            __typename?: 'QuestionnaireQuestionResource';
            value: string;
            type: QuestionnaireQuestionResourceTypeEnum;
            label: string | null;
            title: string | null;
          }> | null;
        }
      | {
          __typename?: 'QuestionnaireRadioQuestion';
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          acceptableAnswers: Array<{
            __typename?: 'QuestionnaireAcceptableAnswer';
            label: string;
            value: string | null;
          }>;
          resources: Array<{
            __typename?: 'QuestionnaireQuestionResource';
            value: string;
            type: QuestionnaireQuestionResourceTypeEnum;
            label: string | null;
            title: string | null;
          }> | null;
        }
      | {
          __typename?: 'QuestionnaireTextAreaQuestion';
          maxLength: number;
          minLength: number;
          supportingText: string | null;
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          resources: Array<{
            __typename?: 'QuestionnaireQuestionResource';
            value: string;
            type: QuestionnaireQuestionResourceTypeEnum;
            label: string | null;
            title: string | null;
          }> | null;
        }
      | {
          __typename?: 'QuestionnaireTextInputQuestion';
          maxLength: number;
          minLength: number;
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          resources: Array<{
            __typename?: 'QuestionnaireQuestionResource';
            value: string;
            type: QuestionnaireQuestionResourceTypeEnum;
            label: string | null;
            title: string | null;
          }> | null;
        };
  }>;
};

export type PatientQuestionnairesQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
}>;

export type PatientQuestionnairesQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    nodes: Array<{
      __typename?: 'WorkflowStep';
      id: string;
      completionParams: any | null;
      createdAt: string;
      completedAt: string | null;
      parentStep: {
        __typename?: 'WorkflowStep';
        id: string;
        createdAt: string;
        completedAt: string | null;
        completionParams: any | null;
      } | null;
      user: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          dateOfBirth: string | null;
        } | null;
      };
    }>;
  };
  questionnaireResponses: {
    __typename?: 'QuestionnaireResponseConnection';
    nodes: Array<{
      __typename?: 'QuestionnaireResponse';
      id: string;
      createdAt: string;
      completedAt: string | null;
      canceledAt: string | null;
      definition: {
        __typename?: 'QuestionnaireDefinition';
        id: string;
        name: string;
        title: string | null;
        description: string | null;
      };
      answers: Array<{
        __typename?: 'QuestionnaireAnswer';
        id: string;
        values: Array<string>;
        question:
          | {
              __typename?: 'QuestionnaireCheckboxQuestion';
              minSelections: number;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              acceptableAnswers: Array<{
                __typename?: 'QuestionnaireAcceptableAnswer';
                label: string;
                value: string | null;
              }>;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireDateInputQuestion';
              allowsFutureDates: boolean;
              allowsPastDates: boolean;
              required: boolean;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireNumberInputQuestion';
              allowsDecimals: boolean;
              max: number | null;
              min: number | null;
              required: boolean;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireRadioQuestion';
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              acceptableAnswers: Array<{
                __typename?: 'QuestionnaireAcceptableAnswer';
                label: string;
                value: string | null;
              }>;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireTextAreaQuestion';
              maxLength: number;
              minLength: number;
              supportingText: string | null;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireTextInputQuestion';
              maxLength: number;
              minLength: number;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            };
      }>;
    }>;
  };
  user: {
    __typename?: 'User';
    questionnaires: Array<{
      __typename?: 'Questionnaire';
      id: string;
      createdAt: string;
      updatedAt: string;
      submitted: boolean;
      results: any;
    }> | null;
    shortQuestionnaires: Array<{
      __typename?: 'ShortQuestionnaire';
      id: string;
      createdAt: string;
      results: any;
    }>;
  } | null;
};

export type CreateStripeSetupIntentMutationVariables = Exact<{
  input: CreateStripeSetupIntentInput;
}>;

export type CreateStripeSetupIntentMutation = {
  __typename?: 'Mutation';
  createStripeSetupIntent: {
    __typename?: 'CreateStripeSetupIntentPayload';
    clientSecret: string | null;
    errors: Array<string>;
  } | null;
};

export type CompleteInitialPaymentAndBookingMutationVariables = Exact<{
  input: CompleteInitialPaymentAndBookingInput;
}>;

export type CompleteInitialPaymentAndBookingMutation = {
  __typename?: 'Mutation';
  completeInitialPaymentAndBooking: {
    __typename?: 'CompleteInitialPaymentAndBookingPayload';
    errors: Array<any>;
  } | null;
};

export type CreateStripeInvoiceMutationVariables = Exact<{
  input: CreateStripeInvoiceInput;
}>;

export type CreateStripeInvoiceMutation = {
  __typename?: 'Mutation';
  createStripeInvoice: {
    __typename?: 'CreateStripeInvoicePayload';
    invoiceId: string | null;
    errors: Array<string>;
  } | null;
};

export type GenerateLoanLinkMutationVariables = Exact<{
  input: GenerateLoanLinkInput;
}>;

export type GenerateLoanLinkMutation = {
  __typename?: 'Mutation';
  generateLoanLink: {
    __typename?: 'GenerateLoanLinkPayload';
    response: any | null;
    errors: Array<any> | null;
  } | null;
};

export type PaymentMethodPartsFragment = {
  __typename?: 'PaymentMethod';
  id: string;
  isDefault: boolean;
  isExpired: boolean;
  externalId: string;
  paymentMethodType: PaymentMethodTypeEnum;
  brand: PaymentMethodBrandsTypeEnum | null;
  expMonth: string | null;
  expYear: string | null;
  last4: string | null;
};

export type PaymentTransactionPartsFragment = {
  __typename?: 'PaymentTransaction';
  id: string;
  externalId: string | null;
  status: PaymentStatusEnum;
  chargeType: PaymentChargeTypeEnum;
  paymentMethod: {
    __typename?: 'PaymentMethod';
    id: string;
    isDefault: boolean;
    isExpired: boolean;
    externalId: string;
    paymentMethodType: PaymentMethodTypeEnum;
    brand: PaymentMethodBrandsTypeEnum | null;
    expMonth: string | null;
    expYear: string | null;
    last4: string | null;
  };
};

export type CreateInitialPaymentTransactionMutationVariables = Exact<{
  input: CreateInitialPaymentTransactionInput;
}>;

export type CreateInitialPaymentTransactionMutation = {
  __typename?: 'Mutation';
  createInitialPaymentTransaction: {
    __typename?: 'CreateInitialPaymentTransactionPayload';
    errors: Array<string>;
    paymentTransaction: {
      __typename?: 'PaymentTransaction';
      id: string;
      externalId: string | null;
      status: PaymentStatusEnum;
      chargeType: PaymentChargeTypeEnum;
      paymentMethod: {
        __typename?: 'PaymentMethod';
        id: string;
        isDefault: boolean;
        isExpired: boolean;
        externalId: string;
        paymentMethodType: PaymentMethodTypeEnum;
        brand: PaymentMethodBrandsTypeEnum | null;
        expMonth: string | null;
        expYear: string | null;
        last4: string | null;
      };
    } | null;
  } | null;
};

export type GetMyPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyPaymentMethodsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      lastStripePaymentMethod: {
        __typename?: 'PaymentMethod';
        id: string;
        isDefault: boolean;
        isExpired: boolean;
        externalId: string;
        paymentMethodType: PaymentMethodTypeEnum;
        brand: PaymentMethodBrandsTypeEnum | null;
        expMonth: string | null;
        expYear: string | null;
        last4: string | null;
      } | null;
      paymentMethods: Array<{
        __typename?: 'PaymentMethod';
        id: string;
        isDefault: boolean;
        isExpired: boolean;
        externalId: string;
        paymentMethodType: PaymentMethodTypeEnum;
        brand: PaymentMethodBrandsTypeEnum | null;
        expMonth: string | null;
        expYear: string | null;
        last4: string | null;
      }>;
    } | null;
    currentBrandMembership: {
      __typename?: 'BrandMembership';
      affirmInitialLoanId: string | null;
      initialPaymentReceivedAt: string | null;
      initialPrice: {
        __typename?: 'InitialPrice';
        amountInCents: number;
      } | null;
    };
  } | null;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  input: DeletePaymentMethodInput;
}>;

export type DeletePaymentMethodMutation = {
  __typename?: 'Mutation';
  deletePaymentMethod: {
    __typename?: 'DeletePaymentMethodPayload';
    errors: Array<any>;
  } | null;
};

export type AppointmentPaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AppointmentPaymentMethodsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      lastStripePaymentMethod: {
        __typename?: 'PaymentMethod';
        id: string;
        isDefault: boolean;
        isExpired: boolean;
        externalId: string;
        paymentMethodType: PaymentMethodTypeEnum;
        brand: PaymentMethodBrandsTypeEnum | null;
        expMonth: string | null;
        expYear: string | null;
        last4: string | null;
      } | null;
    } | null;
  } | null;
  platformSettings: {
    __typename?: 'PlatformSettings';
    cancellationThresholdHours: number;
    rescheduleThresholdHours: number;
  };
};

export type SendPhoneNumberVerificationCodeMutationVariables = Exact<{
  input: SendPhoneNumberVerificationCodeInput;
}>;

export type SendPhoneNumberVerificationCodeMutation = {
  __typename?: 'Mutation';
  sendPhoneNumberVerificationCode: {
    __typename?: 'SendPhoneNumberVerificationCodePayload';
    errors: Array<string>;
  } | null;
};

export type VerifyPhoneNumberVerificationCodeMutationVariables = Exact<{
  input: VerifyPhoneNumberVerificationCodeInput;
}>;

export type VerifyPhoneNumberVerificationCodeMutation = {
  __typename?: 'Mutation';
  verifyPhoneNumberVerificationCode: {
    __typename?: 'VerifyPhoneNumberVerificationCodePayload';
    errors: Array<string>;
  } | null;
};

export type GetPlatformSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlatformSettingsQuery = {
  __typename?: 'Query';
  platformSettings: {
    __typename?: 'PlatformSettings';
    cancellationThresholdHours: number;
    rescheduleThresholdHours: number;
  };
};

export type CreatePrescriptionMutationVariables = Exact<{
  input: CreatePrescriptionInput;
}>;

export type CreatePrescriptionMutation = {
  __typename?: 'Mutation';
  createPrescription: {
    __typename?: 'CreatePrescriptionPayload';
    errors: Array<string>;
  } | null;
};

export type GetPrescriptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPrescriptionQuery = {
  __typename?: 'Query';
  prescription: {
    __typename?: 'Prescription';
    id: string;
    createdAt: string;
    dueAt: string | null;
    readByPatientAt: string | null;
    fulfillmentMethod: string | null;
    prescriptionType: string | null;
    prescriptionLength: number | null;
    prepType: string | null;
    medication: string | null;
    treatmentStartAt: string | null;
    prescribedDate: string | null;
    bridging: boolean | null;
    dosage: string | null;
    dosingRegimen: DosingRegimenEnum | null;
    numOfPills: number | null;
    additionalInfo: any | null;
    sentAt: string | null;
    updateOperation: {
      __typename?: 'Operation';
      canPerform: boolean;
      invalidReasons: Array<string>;
    };
    deleteOperation: {
      __typename?: 'Operation';
      canPerform: boolean;
      invalidReasons: Array<string>;
    };
    brand: { __typename?: 'Brand'; id: string; name: string } | null;
    sentBy: {
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
    } | null;
    message: {
      __typename?: 'Message';
      id: string;
      conversationId: string;
    } | null;
  } | null;
};

export type PrescriptionWorkflowStepFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  dueAt: string | null;
  user: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
    } | null;
  };
  subject:
    | { __typename?: 'AcuityAppointment' }
    | { __typename?: 'Assessment' }
    | { __typename?: 'LabRequisition' }
    | {
        __typename?: 'Prescription';
        id: string;
        createdAt: string;
        prescriptionType: string | null;
        prescribedDate: string | null;
        prescriptionLength: number | null;
      }
    | { __typename?: 'Questionnaire' }
    | { __typename?: 'ShortQuestionnaire' }
    | { __typename?: 'Unhandled' }
    | null;
};

export type GetPrescriptionStepsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetPrescriptionStepsQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        dueAt: string | null;
        user: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            dateOfBirth: string | null;
          } | null;
        };
        subject:
          | { __typename?: 'AcuityAppointment' }
          | { __typename?: 'Assessment' }
          | { __typename?: 'LabRequisition' }
          | {
              __typename?: 'Prescription';
              id: string;
              createdAt: string;
              prescriptionType: string | null;
              prescribedDate: string | null;
              prescriptionLength: number | null;
            }
          | { __typename?: 'Questionnaire' }
          | { __typename?: 'ShortQuestionnaire' }
          | { __typename?: 'Unhandled' }
          | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type CreateFridaPrescriptionsMutationVariables = Exact<{
  input: CreateFridaPrescriptionsInput;
}>;

export type CreateFridaPrescriptionsMutation = {
  __typename?: 'Mutation';
  createFridaPrescriptions: {
    __typename?: 'CreateFridaPrescriptionsPayload';
    errors: Array<{
      __typename?: 'ValidationError';
      frontEndId: string;
      messages: Array<string>;
    }>;
    prescriptions: Array<{ __typename?: 'Prescription'; id: string }> | null;
  } | null;
};

export type UpdatePrescriptionMutationVariables = Exact<{
  input: UpdatePrescriptionInput;
}>;

export type UpdatePrescriptionMutation = {
  __typename?: 'Mutation';
  updatePrescription: {
    __typename?: 'UpdatePrescriptionPayload';
    errors: Array<string>;
    prescription: {
      __typename?: 'Prescription';
      id: string;
      createdAt: string;
      dueAt: string | null;
      readByPatientAt: string | null;
      fulfillmentMethod: string | null;
      prescriptionType: string | null;
      prescriptionLength: number | null;
      prepType: string | null;
      medication: string | null;
      treatmentStartAt: string | null;
      prescribedDate: string | null;
      bridging: boolean | null;
      dosage: string | null;
      dosingRegimen: DosingRegimenEnum | null;
      numOfPills: number | null;
      additionalInfo: any | null;
      sentAt: string | null;
      updateOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
      deleteOperation: {
        __typename?: 'Operation';
        canPerform: boolean;
        invalidReasons: Array<string>;
      };
      brand: { __typename?: 'Brand'; id: string; name: string } | null;
      sentBy: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
      message: {
        __typename?: 'Message';
        id: string;
        conversationId: string;
      } | null;
    } | null;
  } | null;
};

export type DeletePrescriptionMutationVariables = Exact<{
  input: DeletePrescriptionInput;
}>;

export type DeletePrescriptionMutation = {
  __typename?: 'Mutation';
  deletePrescription: {
    __typename?: 'DeletePrescriptionPayload';
    errors: Array<string>;
  } | null;
};

export type GetPatientProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetPatientProfileQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    preferredName: string | null;
    locale: string;
    createdAt: string;
    phoneNumber: string | null;
    brands: Array<{
      __typename?: 'BrandMembership';
      id: string;
      name: string;
      brand: { __typename?: 'Brand'; id: string; name: string };
    }>;
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
      hasPaymentMethod: boolean;
      financialExpectation: string | null;
      stripeCustomerId: string | null;
      deliveryPreference: DeliveryPreferenceEnum;
      allergies: string | null;
      asyncPrep: boolean;
      medications: string | null;
      medicalConditions: string | null;
      verificationStatus: StripeVerificationSessionStatusEnum | null;
      healthSpendingAccount: boolean | null;
      medicalHistoryVersions: Array<{
        __typename?: 'MedicalHistoryVersion';
        allergies: string | null;
        medications: string | null;
        medicalConditions: string | null;
        medInfoConfirmedAt: string | null;
      }>;
      insuranceCards: Array<{
        __typename?: 'InsuranceCard';
        id: string;
        label: string;
        isDefaultCard: boolean;
        frontImage: {
          __typename?: 'Attachment';
          id: string;
          filename: string;
          downloadUrl: string;
        };
        backImage: {
          __typename?: 'Attachment';
          id: string;
          filename: string;
          downloadUrl: string;
        } | null;
      }> | null;
      shippingAddresses: Array<{
        __typename?: 'ShippingAddress';
        id: string;
        recipientName: string;
        addressType: AddressTypeEnum | null;
        street: string;
        street2: string | null;
        city: string;
        province: string;
        postalCode: string;
        deliveryInstructions: string | null;
        isDefaultAddress: boolean;
      }> | null;
      healthCareProvince: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
    } | null;
    role: {
      __typename?: 'Role';
      id: string;
      code: string;
      name: string;
      displayName: string;
    };
  } | null;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    preferredName: string | null;
    email: string;
    createdAt: string;
    referralCode: string | null;
    locale: string;
    phone: { __typename?: 'Phone'; e164: string; rawNumber: string | null };
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      displayName: string;
      code: string;
    };
  } | null;
};

export type AppointmentFragment = {
  __typename?: 'Appointment';
  id: string;
  acuityAppointmentId: number | null;
  appointmentTypeId: number | null;
  calendarName: string | null;
  calendarId: number | null;
  scheduledAt: string;
  durationInMinutes: number;
  bookingReason: string | null;
  location: string | null;
  videoEnabled: boolean;
  calendar: {
    __typename?: 'AcuityCalendar';
    clinician: {
      __typename?: 'SimpleUser';
      firstName: string | null;
      lastName: string | null;
    };
    province: { __typename?: 'ProvinceState'; name: string };
  } | null;
  consult: {
    __typename?: 'Consult';
    id: string;
    attended: boolean;
    nextStep: NextStepEnum | null;
    otherNextStep: string | null;
  } | null;
  cancelOperation: { __typename?: 'Operation'; canPerform: boolean };
  rescheduleOperation: { __typename?: 'Operation'; canPerform: boolean };
};

export type PatientWorkflowStepFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  completedAt: string | null;
  dueAt: string | null;
  createdAt: string;
  type: string;
  completionParams: any | null;
  subject:
    | {
        __typename?: 'AcuityAppointment';
        id: string;
        scheduledAt: string;
        consult: {
          __typename?: 'Consult';
          id: string;
          attended: boolean;
          nextStep: NextStepEnum | null;
          otherNextStep: string | null;
        } | null;
      }
    | { __typename?: 'Assessment' }
    | {
        __typename?: 'LabRequisition';
        id: string;
        attachments: Array<{
          __typename?: 'Attachment';
          id: string;
          downloadUrl: string;
          filename: string;
        }>;
      }
    | {
        __typename?: 'Prescription';
        id: string;
        prescriptionLength: number | null;
      }
    | { __typename?: 'Questionnaire' }
    | { __typename?: 'ShortQuestionnaire' }
    | { __typename?: 'Unhandled' }
    | null;
};

export type ShippingAddressFragment = {
  __typename?: 'ShippingAddress';
  id: string;
  recipientName: string;
  addressType: AddressTypeEnum | null;
  street: string;
  street2: string | null;
  city: string;
  province: string;
  postalCode: string;
  deliveryInstructions: string | null;
  isDefaultAddress: boolean;
};

export type GetMePatientQueryVariables = Exact<{ [key: string]: never }>;

export type GetMePatientQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    preferredName: string | null;
    email: string;
    createdAt: string;
    locale: string;
    hasAttendedConsult: boolean;
    smsEnabled: boolean;
    voicemailConsent: boolean;
    additionalTreatmentsAvailable: boolean;
    phone: { __typename?: 'Phone'; e164: string; rawNumber: string | null };
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      displayName: string;
      code: string;
    };
    lastTreatments: Array<{
      __typename?: 'Treatment';
      id: string;
      type: TreatmentType;
      medicationStatus: MedicationStatusEnum;
      createdAt: string;
      updatedAt: string;
      stoppedAt: string | null;
      stoppedReason: string | null;
      eligibility: string | null;
      user: { __typename?: 'User'; id: string };
      stoppedBy: {
        __typename?: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
    }>;
    treatmentEligibilities: Array<{
      __typename?: 'TreatmentEligibility';
      id: string;
      eligibility: TreatmentEligibilityEnum;
      reason: string | null;
      userId: string;
      treatmentType: TreatmentType;
      treatmentId: string | null;
      createdAt: string;
      updatedAt: string;
    }>;
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
      desiredTreatments: Array<TreatmentTypeEnum>;
      healthCareNumber: string | null;
      financialExpectation: string | null;
      allergies: string | null;
      asyncPrep: boolean;
      medications: string | null;
      medicalConditions: string | null;
      hasPaymentMethod: boolean;
      stripeCustomerId: string | null;
      ccLastFour: number | null;
      healthSpendingAccount: boolean | null;
      verificationStatus: StripeVerificationSessionStatusEnum | null;
      dateVerified: string | null;
      phoneVerifiedAt: string | null;
      shippingAddresses: Array<{
        __typename?: 'ShippingAddress';
        id: string;
        recipientName: string;
        addressType: AddressTypeEnum | null;
        street: string;
        street2: string | null;
        city: string;
        province: string;
        postalCode: string;
        deliveryInstructions: string | null;
        isDefaultAddress: boolean;
      }> | null;
      medicalHistoryVersions: Array<{
        __typename?: 'MedicalHistoryVersion';
        allergies: string | null;
        medications: string | null;
        medicalConditions: string | null;
        medInfoConfirmedAt: string | null;
      }>;
      province: {
        __typename?: 'ProvinceState';
        id: string;
        code: string;
        country: { __typename?: 'Country'; code: string };
      } | null;
      healthCareProvince: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
      insuranceCardPhotos: Array<{
        __typename?: 'Attachment';
        id: string;
        downloadUrl: string;
        filename: string;
      }>;
      insuranceCards: Array<{
        __typename?: 'InsuranceCard';
        id: string;
        label: string;
        isDefaultCard: boolean;
        frontImage: {
          __typename?: 'Attachment';
          id: string;
          filename: string;
          downloadUrl: string;
        };
        backImage: {
          __typename?: 'Attachment';
          id: string;
          filename: string;
          downloadUrl: string;
        } | null;
      }> | null;
    } | null;
    currentBrandMembership: {
      __typename?: 'BrandMembership';
      id: string;
      name: string;
      refillProcess: RefillProcessEnum;
      initialPrice: {
        __typename?: 'InitialPrice';
        id: string;
        amountInCents: number;
      } | null;
    };
    assessments: {
      __typename?: 'AssessmentConnection';
      nodes: Array<{
        __typename?: 'Assessment';
        id: string;
        data: any | null;
        type: string | null;
        completedAt: string | null;
        score: any | null;
        scoringErrors: any | null;
      }>;
    };
    appointments: {
      __typename?: 'AppointmentConnection';
      nodes: Array<{
        __typename?: 'Appointment';
        id: string;
        acuityAppointmentId: number | null;
        appointmentTypeId: number | null;
        calendarName: string | null;
        calendarId: number | null;
        scheduledAt: string;
        durationInMinutes: number;
        bookingReason: string | null;
        location: string | null;
        videoEnabled: boolean;
        calendar: {
          __typename?: 'AcuityCalendar';
          clinician: {
            __typename?: 'SimpleUser';
            firstName: string | null;
            lastName: string | null;
          };
          province: { __typename?: 'ProvinceState'; name: string };
        } | null;
        consult: {
          __typename?: 'Consult';
          id: string;
          attended: boolean;
          nextStep: NextStepEnum | null;
          otherNextStep: string | null;
        } | null;
        cancelOperation: { __typename?: 'Operation'; canPerform: boolean };
        rescheduleOperation: { __typename?: 'Operation'; canPerform: boolean };
      }>;
    };
    questionnaires: Array<{
      __typename?: 'Questionnaire';
      id: string;
      results: any;
    }> | null;
    prescriptions: {
      __typename?: 'PrescriptionConnection';
      edges: Array<{
        __typename?: 'PrescriptionEdge';
        node: {
          __typename?: 'Prescription';
          id: string;
          prescriptionType: string | null;
          medication: string | null;
          fulfillmentMethod: string | null;
          sentAt: string | null;
          dosage: string | null;
          numOfPills: number | null;
          additionalInfo: any | null;
        };
      }>;
    };
    workflowSteps: Array<{
      __typename?: 'WorkflowStep';
      id: string;
      completedAt: string | null;
      dueAt: string | null;
      createdAt: string;
      type: string;
      completionParams: any | null;
      subject:
        | {
            __typename?: 'AcuityAppointment';
            id: string;
            scheduledAt: string;
            consult: {
              __typename?: 'Consult';
              id: string;
              attended: boolean;
              nextStep: NextStepEnum | null;
              otherNextStep: string | null;
            } | null;
          }
        | { __typename?: 'Assessment' }
        | {
            __typename?: 'LabRequisition';
            id: string;
            attachments: Array<{
              __typename?: 'Attachment';
              id: string;
              downloadUrl: string;
              filename: string;
            }>;
          }
        | {
            __typename?: 'Prescription';
            id: string;
            prescriptionLength: number | null;
          }
        | { __typename?: 'Questionnaire' }
        | { __typename?: 'ShortQuestionnaire' }
        | { __typename?: 'Unhandled' }
        | null;
    }>;
  } | null;
};

export type GetMyPrescriptionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  onlyPrep?: InputMaybe<Scalars['Boolean']['input']>;
  brandID?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetMyPrescriptionsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    prescriptions: {
      __typename?: 'PrescriptionConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor: string | null;
        hasNextPage: boolean;
      };
      edges: Array<{
        __typename?: 'PrescriptionEdge';
        node: {
          __typename?: 'Prescription';
          id: string;
          createdAt: string;
          dueAt: string | null;
          readByPatientAt: string | null;
          fulfillmentMethod: string | null;
          prescriptionType: string | null;
          prescriptionLength: number | null;
          prepType: string | null;
          medication: string | null;
          treatmentStartAt: string | null;
          prescribedDate: string | null;
          bridging: boolean | null;
          dosage: string | null;
          dosingRegimen: DosingRegimenEnum | null;
          numOfPills: number | null;
          additionalInfo: any | null;
          sentAt: string | null;
          updateOperation: {
            __typename?: 'Operation';
            canPerform: boolean;
            invalidReasons: Array<string>;
          };
          deleteOperation: {
            __typename?: 'Operation';
            canPerform: boolean;
            invalidReasons: Array<string>;
          };
          brand: { __typename?: 'Brand'; id: string; name: string } | null;
          sentBy: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
          } | null;
          message: {
            __typename?: 'Message';
            id: string;
            conversationId: string;
          } | null;
        };
      }>;
    };
  } | null;
};

export type GetMyLabRequisitionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMyLabRequisitionsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    pendingLabRequisitions: {
      __typename?: 'LabRequisitionConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor: string | null;
        hasNextPage: boolean;
      };
      edges: Array<{
        __typename?: 'LabRequisitionEdge';
        node: {
          __typename?: 'LabRequisition';
          id: string;
          readByPatientAt: string | null;
          requisitionType: string | null;
          sentAt: string;
          notes: string | null;
          createdAt: string;
          formLink: string | null;
          attachments: Array<{
            __typename?: 'Attachment';
            id: string;
            downloadUrl: string;
            filename: string;
          }>;
          sentBy: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
          feedbackStep: { __typename?: 'WorkflowStep'; id: string } | null;
          updateOperation: {
            __typename?: 'Operation';
            canPerform: boolean;
            invalidReasons: Array<string>;
          };
          deleteOperation: {
            __typename?: 'Operation';
            canPerform: boolean;
            invalidReasons: Array<string>;
          };
        };
      }>;
    };
    completedLabRequisitions: {
      __typename?: 'LabRequisitionConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor: string | null;
        hasNextPage: boolean;
      };
      edges: Array<{
        __typename?: 'LabRequisitionEdge';
        node: {
          __typename?: 'LabRequisition';
          id: string;
          readByPatientAt: string | null;
          requisitionType: string | null;
          sentAt: string;
          notes: string | null;
          createdAt: string;
          formLink: string | null;
          attachments: Array<{
            __typename?: 'Attachment';
            id: string;
            downloadUrl: string;
            filename: string;
          }>;
          sentBy: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
          feedbackStep: { __typename?: 'WorkflowStep'; id: string } | null;
          updateOperation: {
            __typename?: 'Operation';
            canPerform: boolean;
            invalidReasons: Array<string>;
          };
          deleteOperation: {
            __typename?: 'Operation';
            canPerform: boolean;
            invalidReasons: Array<string>;
          };
        };
      }>;
    };
  } | null;
};

export type GetMyIncompleteWorkflowStepsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyIncompleteWorkflowStepsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    incompleteWorkflowSteps: Array<{
      __typename?: 'WorkflowStep';
      id: string;
      type: string;
      dueAt: string | null;
      completedAt: string | null;
      createdAt: string;
      updatedAt: string;
      completionParams: any | null;
      user: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          financialExpectation: string | null;
        } | null;
      };
      subject:
        | { __typename?: 'AcuityAppointment'; scheduledAt: string; id: string }
        | { __typename?: 'Assessment' }
        | {
            __typename?: 'LabRequisition';
            id: string;
            requisitionType: string | null;
            readByPatientAt: string | null;
            attachments: Array<{
              __typename?: 'Attachment';
              id: string;
              downloadUrl: string;
            }>;
            message: {
              __typename?: 'Message';
              id: string;
              conversationId: string;
            } | null;
          }
        | {
            __typename?: 'Prescription';
            id: string;
            prescriptionLength: number | null;
            prepType: string | null;
            message: {
              __typename?: 'Message';
              id: string;
              conversationId: string;
            } | null;
          }
        | { __typename?: 'Questionnaire' }
        | {
            __typename?: 'ShortQuestionnaire';
            id: string;
            createdAt: string;
            results: any;
          }
        | { __typename?: 'Unhandled' }
        | null;
    }>;
  } | null;
};

export type BrandMembershipQueryVariables = Exact<{ [key: string]: never }>;

export type BrandMembershipQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    currentBrandMembership: {
      __typename?: 'BrandMembership';
      id: string;
      canUseClinicalMessages: boolean;
      availableBookingTypes: Array<{
        __typename?: 'AvailableBookingTypes';
        id: string;
        bookingType: AcuityBooking | null;
        workflowStepId: string | null;
      }>;
    };
  } | null;
};

export type ScreenInPatientMutationVariables = Exact<{
  input: ScreenInPatientInput;
}>;

export type ScreenInPatientMutation = {
  __typename?: 'Mutation';
  screenInPatient: {
    __typename?: 'ScreenInPatientPayload';
    errors: Array<string>;
  } | null;
};

export type MyShortQuestionnairesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MyShortQuestionnairesQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    shortQuestionnaires: Array<{
      __typename?: 'ShortQuestionnaire';
      id: string;
      createdAt: string;
      results: any;
    }>;
  } | null;
};

export type QuestionnaireAcceptableAnswerFragment = {
  __typename?: 'QuestionnaireAcceptableAnswer';
  label: string;
  value: string | null;
};

type QuestionnaireQuestion_QuestionnaireCheckboxQuestion_Fragment = {
  __typename?: 'QuestionnaireCheckboxQuestion';
  minSelections: number;
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  acceptableAnswers: Array<{
    __typename?: 'QuestionnaireAcceptableAnswer';
    label: string;
    value: string | null;
  }>;
  resources: Array<{
    __typename?: 'QuestionnaireQuestionResource';
    value: string;
    type: QuestionnaireQuestionResourceTypeEnum;
    label: string | null;
    title: string | null;
  }> | null;
};

type QuestionnaireQuestion_QuestionnaireDateInputQuestion_Fragment = {
  __typename?: 'QuestionnaireDateInputQuestion';
  allowsFutureDates: boolean;
  allowsPastDates: boolean;
  required: boolean;
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  resources: Array<{
    __typename?: 'QuestionnaireQuestionResource';
    value: string;
    type: QuestionnaireQuestionResourceTypeEnum;
    label: string | null;
    title: string | null;
  }> | null;
};

type QuestionnaireQuestion_QuestionnaireNumberInputQuestion_Fragment = {
  __typename?: 'QuestionnaireNumberInputQuestion';
  allowsDecimals: boolean;
  max: number | null;
  min: number | null;
  required: boolean;
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  resources: Array<{
    __typename?: 'QuestionnaireQuestionResource';
    value: string;
    type: QuestionnaireQuestionResourceTypeEnum;
    label: string | null;
    title: string | null;
  }> | null;
};

type QuestionnaireQuestion_QuestionnaireRadioQuestion_Fragment = {
  __typename?: 'QuestionnaireRadioQuestion';
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  acceptableAnswers: Array<{
    __typename?: 'QuestionnaireAcceptableAnswer';
    label: string;
    value: string | null;
  }>;
  resources: Array<{
    __typename?: 'QuestionnaireQuestionResource';
    value: string;
    type: QuestionnaireQuestionResourceTypeEnum;
    label: string | null;
    title: string | null;
  }> | null;
};

type QuestionnaireQuestion_QuestionnaireTextAreaQuestion_Fragment = {
  __typename?: 'QuestionnaireTextAreaQuestion';
  maxLength: number;
  minLength: number;
  supportingText: string | null;
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  resources: Array<{
    __typename?: 'QuestionnaireQuestionResource';
    value: string;
    type: QuestionnaireQuestionResourceTypeEnum;
    label: string | null;
    title: string | null;
  }> | null;
};

type QuestionnaireQuestion_QuestionnaireTextInputQuestion_Fragment = {
  __typename?: 'QuestionnaireTextInputQuestion';
  maxLength: number;
  minLength: number;
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  resources: Array<{
    __typename?: 'QuestionnaireQuestionResource';
    value: string;
    type: QuestionnaireQuestionResourceTypeEnum;
    label: string | null;
    title: string | null;
  }> | null;
};

export type QuestionnaireQuestionFragment =
  | QuestionnaireQuestion_QuestionnaireCheckboxQuestion_Fragment
  | QuestionnaireQuestion_QuestionnaireDateInputQuestion_Fragment
  | QuestionnaireQuestion_QuestionnaireNumberInputQuestion_Fragment
  | QuestionnaireQuestion_QuestionnaireRadioQuestion_Fragment
  | QuestionnaireQuestion_QuestionnaireTextAreaQuestion_Fragment
  | QuestionnaireQuestion_QuestionnaireTextInputQuestion_Fragment;

export type QuestionnaireActionFragment = {
  __typename?: 'QuestionnaireAction';
  label: string;
  to: string;
  variant: ActionVariantEnum;
};

export type QuestionnaireAnswerFragment = {
  __typename?: 'QuestionnaireAnswer';
  id: string;
  values: Array<string>;
  question:
    | { __typename?: 'QuestionnaireCheckboxQuestion'; id: string }
    | { __typename?: 'QuestionnaireDateInputQuestion'; id: string }
    | { __typename?: 'QuestionnaireNumberInputQuestion'; id: string }
    | { __typename?: 'QuestionnaireRadioQuestion'; id: string }
    | { __typename?: 'QuestionnaireTextAreaQuestion'; id: string }
    | { __typename?: 'QuestionnaireTextInputQuestion'; id: string };
};

export type QuestionnairePageFragment = {
  __typename?: 'QuestionnairePage';
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  questions: Array<
    | {
        __typename?: 'QuestionnaireCheckboxQuestion';
        minSelections: number;
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        acceptableAnswers: Array<{
          __typename?: 'QuestionnaireAcceptableAnswer';
          label: string;
          value: string | null;
        }>;
        resources: Array<{
          __typename?: 'QuestionnaireQuestionResource';
          value: string;
          type: QuestionnaireQuestionResourceTypeEnum;
          label: string | null;
          title: string | null;
        }> | null;
      }
    | {
        __typename?: 'QuestionnaireDateInputQuestion';
        allowsFutureDates: boolean;
        allowsPastDates: boolean;
        required: boolean;
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        resources: Array<{
          __typename?: 'QuestionnaireQuestionResource';
          value: string;
          type: QuestionnaireQuestionResourceTypeEnum;
          label: string | null;
          title: string | null;
        }> | null;
      }
    | {
        __typename?: 'QuestionnaireNumberInputQuestion';
        allowsDecimals: boolean;
        max: number | null;
        min: number | null;
        required: boolean;
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        resources: Array<{
          __typename?: 'QuestionnaireQuestionResource';
          value: string;
          type: QuestionnaireQuestionResourceTypeEnum;
          label: string | null;
          title: string | null;
        }> | null;
      }
    | {
        __typename?: 'QuestionnaireRadioQuestion';
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        acceptableAnswers: Array<{
          __typename?: 'QuestionnaireAcceptableAnswer';
          label: string;
          value: string | null;
        }>;
        resources: Array<{
          __typename?: 'QuestionnaireQuestionResource';
          value: string;
          type: QuestionnaireQuestionResourceTypeEnum;
          label: string | null;
          title: string | null;
        }> | null;
      }
    | {
        __typename?: 'QuestionnaireTextAreaQuestion';
        maxLength: number;
        minLength: number;
        supportingText: string | null;
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        resources: Array<{
          __typename?: 'QuestionnaireQuestionResource';
          value: string;
          type: QuestionnaireQuestionResourceTypeEnum;
          label: string | null;
          title: string | null;
        }> | null;
      }
    | {
        __typename?: 'QuestionnaireTextInputQuestion';
        maxLength: number;
        minLength: number;
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        resources: Array<{
          __typename?: 'QuestionnaireQuestionResource';
          value: string;
          type: QuestionnaireQuestionResourceTypeEnum;
          label: string | null;
          title: string | null;
        }> | null;
      }
  >;
  actions: Array<{
    __typename?: 'QuestionnaireAction';
    label: string;
    to: string;
    variant: ActionVariantEnum;
  }>;
};

export type QuestionnaireOutcomeFragment = {
  __typename?: 'QuestionnaireOutcome';
  id: string;
  name: string;
  title: string | null;
  description: string | null;
  redirectTo: string | null;
  actions: Array<{
    __typename?: 'QuestionnaireAction';
    label: string;
    to: string;
    variant: ActionVariantEnum;
  }>;
};

export type QuestionnaireResponseFragment = {
  __typename?: 'QuestionnaireResponse';
  completed: boolean;
  id: string;
  canGoBack: boolean;
  answers: Array<{
    __typename?: 'QuestionnaireAnswer';
    id: string;
    values: Array<string>;
    question:
      | { __typename?: 'QuestionnaireCheckboxQuestion'; id: string }
      | { __typename?: 'QuestionnaireDateInputQuestion'; id: string }
      | { __typename?: 'QuestionnaireNumberInputQuestion'; id: string }
      | { __typename?: 'QuestionnaireRadioQuestion'; id: string }
      | { __typename?: 'QuestionnaireTextAreaQuestion'; id: string }
      | { __typename?: 'QuestionnaireTextInputQuestion'; id: string };
  }>;
  currentStep:
    | {
        __typename: 'QuestionnaireOutcome';
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        redirectTo: string | null;
        actions: Array<{
          __typename?: 'QuestionnaireAction';
          label: string;
          to: string;
          variant: ActionVariantEnum;
        }>;
      }
    | {
        __typename: 'QuestionnairePage';
        id: string;
        name: string;
        title: string | null;
        description: string | null;
        questions: Array<
          | {
              __typename?: 'QuestionnaireCheckboxQuestion';
              minSelections: number;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              acceptableAnswers: Array<{
                __typename?: 'QuestionnaireAcceptableAnswer';
                label: string;
                value: string | null;
              }>;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireDateInputQuestion';
              allowsFutureDates: boolean;
              allowsPastDates: boolean;
              required: boolean;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireNumberInputQuestion';
              allowsDecimals: boolean;
              max: number | null;
              min: number | null;
              required: boolean;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireRadioQuestion';
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              acceptableAnswers: Array<{
                __typename?: 'QuestionnaireAcceptableAnswer';
                label: string;
                value: string | null;
              }>;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireTextAreaQuestion';
              maxLength: number;
              minLength: number;
              supportingText: string | null;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
          | {
              __typename?: 'QuestionnaireTextInputQuestion';
              maxLength: number;
              minLength: number;
              id: string;
              name: string;
              title: string | null;
              description: string | null;
              resources: Array<{
                __typename?: 'QuestionnaireQuestionResource';
                value: string;
                type: QuestionnaireQuestionResourceTypeEnum;
                label: string | null;
                title: string | null;
              }> | null;
            }
        >;
        actions: Array<{
          __typename?: 'QuestionnaireAction';
          label: string;
          to: string;
          variant: ActionVariantEnum;
        }>;
      }
    | null;
  definition: {
    __typename?: 'QuestionnaireDefinition';
    description: string | null;
    id: string;
    name: string;
    title: string | null;
  };
};

export type GetQuestionnaireResponseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetQuestionnaireResponseQuery = {
  __typename?: 'Query';
  questionnaireResponse: {
    __typename?: 'QuestionnaireResponse';
    completed: boolean;
    id: string;
    canGoBack: boolean;
    answers: Array<{
      __typename?: 'QuestionnaireAnswer';
      id: string;
      values: Array<string>;
      question:
        | { __typename?: 'QuestionnaireCheckboxQuestion'; id: string }
        | { __typename?: 'QuestionnaireDateInputQuestion'; id: string }
        | { __typename?: 'QuestionnaireNumberInputQuestion'; id: string }
        | { __typename?: 'QuestionnaireRadioQuestion'; id: string }
        | { __typename?: 'QuestionnaireTextAreaQuestion'; id: string }
        | { __typename?: 'QuestionnaireTextInputQuestion'; id: string };
    }>;
    currentStep:
      | {
          __typename: 'QuestionnaireOutcome';
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          redirectTo: string | null;
          actions: Array<{
            __typename?: 'QuestionnaireAction';
            label: string;
            to: string;
            variant: ActionVariantEnum;
          }>;
        }
      | {
          __typename: 'QuestionnairePage';
          id: string;
          name: string;
          title: string | null;
          description: string | null;
          questions: Array<
            | {
                __typename?: 'QuestionnaireCheckboxQuestion';
                minSelections: number;
                id: string;
                name: string;
                title: string | null;
                description: string | null;
                acceptableAnswers: Array<{
                  __typename?: 'QuestionnaireAcceptableAnswer';
                  label: string;
                  value: string | null;
                }>;
                resources: Array<{
                  __typename?: 'QuestionnaireQuestionResource';
                  value: string;
                  type: QuestionnaireQuestionResourceTypeEnum;
                  label: string | null;
                  title: string | null;
                }> | null;
              }
            | {
                __typename?: 'QuestionnaireDateInputQuestion';
                allowsFutureDates: boolean;
                allowsPastDates: boolean;
                required: boolean;
                id: string;
                name: string;
                title: string | null;
                description: string | null;
                resources: Array<{
                  __typename?: 'QuestionnaireQuestionResource';
                  value: string;
                  type: QuestionnaireQuestionResourceTypeEnum;
                  label: string | null;
                  title: string | null;
                }> | null;
              }
            | {
                __typename?: 'QuestionnaireNumberInputQuestion';
                allowsDecimals: boolean;
                max: number | null;
                min: number | null;
                required: boolean;
                id: string;
                name: string;
                title: string | null;
                description: string | null;
                resources: Array<{
                  __typename?: 'QuestionnaireQuestionResource';
                  value: string;
                  type: QuestionnaireQuestionResourceTypeEnum;
                  label: string | null;
                  title: string | null;
                }> | null;
              }
            | {
                __typename?: 'QuestionnaireRadioQuestion';
                id: string;
                name: string;
                title: string | null;
                description: string | null;
                acceptableAnswers: Array<{
                  __typename?: 'QuestionnaireAcceptableAnswer';
                  label: string;
                  value: string | null;
                }>;
                resources: Array<{
                  __typename?: 'QuestionnaireQuestionResource';
                  value: string;
                  type: QuestionnaireQuestionResourceTypeEnum;
                  label: string | null;
                  title: string | null;
                }> | null;
              }
            | {
                __typename?: 'QuestionnaireTextAreaQuestion';
                maxLength: number;
                minLength: number;
                supportingText: string | null;
                id: string;
                name: string;
                title: string | null;
                description: string | null;
                resources: Array<{
                  __typename?: 'QuestionnaireQuestionResource';
                  value: string;
                  type: QuestionnaireQuestionResourceTypeEnum;
                  label: string | null;
                  title: string | null;
                }> | null;
              }
            | {
                __typename?: 'QuestionnaireTextInputQuestion';
                maxLength: number;
                minLength: number;
                id: string;
                name: string;
                title: string | null;
                description: string | null;
                resources: Array<{
                  __typename?: 'QuestionnaireQuestionResource';
                  value: string;
                  type: QuestionnaireQuestionResourceTypeEnum;
                  label: string | null;
                  title: string | null;
                }> | null;
              }
          >;
          actions: Array<{
            __typename?: 'QuestionnaireAction';
            label: string;
            to: string;
            variant: ActionVariantEnum;
          }>;
        }
      | null;
    definition: {
      __typename?: 'QuestionnaireDefinition';
      description: string | null;
      id: string;
      name: string;
      title: string | null;
    };
  } | null;
};

export type StartQuestionnaireMutationVariables = Exact<{
  input: StartQuestionnaireInput;
}>;

export type StartQuestionnaireMutation = {
  __typename?: 'Mutation';
  startQuestionnaire: {
    __typename?: 'StartQuestionnairePayload';
    errors: Array<string>;
    patientResponse: {
      __typename?: 'QuestionnaireResponse';
      id: string;
    } | null;
  } | null;
};

export type SubmitQuestionnaireAnswersMutationVariables = Exact<{
  input: SubmitQuestionnaireAnswersInput;
}>;

export type SubmitQuestionnaireAnswersMutation = {
  __typename?: 'Mutation';
  submitQuestionnaireAnswers: {
    __typename?: 'SubmitQuestionnaireAnswersPayload';
    errors: Array<string>;
  } | null;
};

export type GoToPreviousStepOnQuestionnaireMutationVariables = Exact<{
  input: GoBackToPreviousQuestionnaireStepInput;
}>;

export type GoToPreviousStepOnQuestionnaireMutation = {
  __typename?: 'Mutation';
  goBackToPreviousQuestionnaireStep: {
    __typename?: 'GoBackToPreviousQuestionnaireStepPayload';
    errors: Array<string>;
  } | null;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  __typename?: 'Query';
  roles: Array<{
    __typename?: 'Role';
    id: string;
    code: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
  }>;
};

export type CreateShippingAddressMutationVariables = Exact<{
  input: CreateShippingAddressInput;
}>;

export type CreateShippingAddressMutation = {
  __typename?: 'Mutation';
  createShippingAddress: {
    __typename?: 'CreateShippingAddressPayload';
    errors: Array<string>;
    shippingAddress: { __typename?: 'ShippingAddress'; id: string } | null;
  } | null;
};

export type UpdateShippingAddressMutationVariables = Exact<{
  input: UpdateShippingAddressInput;
}>;

export type UpdateShippingAddressMutation = {
  __typename?: 'Mutation';
  updateShippingAddress: {
    __typename?: 'UpdateShippingAddressPayload';
    errors: Array<string>;
  } | null;
};

export type DeleteShippingAddressMutationVariables = Exact<{
  input: DeleteShippingAddressInput;
}>;

export type DeleteShippingAddressMutation = {
  __typename?: 'Mutation';
  deleteShippingAddress: {
    __typename?: 'DeleteShippingAddressPayload';
    errors: Array<any>;
  } | null;
};

export type CoreShortQuestionnaireFragment = {
  __typename?: 'ShortQuestionnaire';
  id: string;
  createdAt: string;
  results: any;
};

export type GetShortQuestionnaireStepsQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['ID']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetShortQuestionnaireStepsQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        type: string;
        dueAt: string | null;
        completedAt: string | null;
        createdAt: string;
        updatedAt: string;
        completionParams: any | null;
        user: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            financialExpectation: string | null;
          } | null;
        };
        subject:
          | {
              __typename?: 'AcuityAppointment';
              scheduledAt: string;
              id: string;
            }
          | { __typename?: 'Assessment' }
          | {
              __typename?: 'LabRequisition';
              id: string;
              requisitionType: string | null;
              readByPatientAt: string | null;
              attachments: Array<{
                __typename?: 'Attachment';
                id: string;
                downloadUrl: string;
              }>;
              message: {
                __typename?: 'Message';
                id: string;
                conversationId: string;
              } | null;
            }
          | {
              __typename?: 'Prescription';
              id: string;
              prescriptionLength: number | null;
              prepType: string | null;
              message: {
                __typename?: 'Message';
                id: string;
                conversationId: string;
              } | null;
            }
          | { __typename?: 'Questionnaire' }
          | {
              __typename?: 'ShortQuestionnaire';
              id: string;
              createdAt: string;
              results: any;
            }
          | { __typename?: 'Unhandled' }
          | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type SignupPatientMutationVariables = Exact<{
  input: SignupPatientInput;
}>;

export type SignupPatientMutation = {
  __typename?: 'Mutation';
  signupPatient: {
    __typename?: 'SignupPatientPayload';
    jwt: string | null;
    hints: Array<string> | null;
    errors: Array<string> | null;
    patient: { __typename?: 'User'; id: string } | null;
  } | null;
};

export type GetStripeVerificationSessionQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GetStripeVerificationSessionQuery = {
  __typename?: 'Query';
  stripeVerificationSession: {
    __typename?: 'StripeVerificationSession';
    id: string;
    status: StripeVerificationSessionStatusEnum;
    images: Array<string>;
  } | null;
};

export type CreateStripeVerificationSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateStripeVerificationSessionMutation = {
  __typename?: 'Mutation';
  createStripeVerificationSession: {
    __typename?: 'CreateStripeVerificationSessionPayload';
    clientSecret: string | null;
    errors: Array<string>;
  } | null;
};

export type StopTreatmentMutationVariables = Exact<{
  input: StopTreatmentInput;
}>;

export type StopTreatmentMutation = {
  __typename?: 'Mutation';
  stopTreatment: {
    __typename?: 'StopTreatmentPayload';
    errors: Array<string>;
  } | null;
};

export type PauseTreatmentMutationVariables = Exact<{
  input: PauseTreatmentInput;
}>;

export type PauseTreatmentMutation = {
  __typename?: 'Mutation';
  pauseTreatment: {
    __typename?: 'PauseTreatmentPayload';
    errors: Array<string>;
  } | null;
};

export type MarkTreatmentIneligibleMutationVariables = Exact<{
  input: MarkTreatmentIneligibleInput;
}>;

export type MarkTreatmentIneligibleMutation = {
  __typename?: 'Mutation';
  markTreatmentIneligible: {
    __typename?: 'MarkTreatmentIneligiblePayload';
    errors: Array<string>;
    treatmentEligibility: {
      __typename?: 'TreatmentEligibility';
      id: string;
      eligibility: TreatmentEligibilityEnum;
      reason: string | null;
      userId: string;
      treatmentType: TreatmentType;
      treatmentId: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type StartTreatmentMutationVariables = Exact<{
  input: StartTreatmentInput;
}>;

export type StartTreatmentMutation = {
  __typename?: 'Mutation';
  startTreatment: {
    __typename?: 'StartTreatmentPayload';
    errors: Array<string>;
  } | null;
};

export type GetTreatmentQueryVariables = Exact<{
  treatmentType: TreatmentType;
}>;

export type GetTreatmentQuery = {
  __typename?: 'Query';
  treatment: {
    __typename?: 'Treatment';
    id: string;
    type: TreatmentType;
    medicationStatus: MedicationStatusEnum;
    createdAt: string;
    updatedAt: string;
    stoppedAt: string | null;
    stoppedReason: string | null;
    eligibility: string | null;
    user: { __typename?: 'User'; id: string };
    stoppedBy: {
      __typename?: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    } | null;
  } | null;
};

export type TreatmentFragment = {
  __typename?: 'Treatment';
  id: string;
  type: TreatmentType;
  medicationStatus: MedicationStatusEnum;
  createdAt: string;
  updatedAt: string;
  stoppedAt: string | null;
  stoppedReason: string | null;
  eligibility: string | null;
  user: { __typename?: 'User'; id: string };
  stoppedBy: {
    __typename?: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
};

export type TreatmentEligibilityFragment = {
  __typename?: 'TreatmentEligibility';
  id: string;
  eligibility: TreatmentEligibilityEnum;
  reason: string | null;
  userId: string;
  treatmentType: TreatmentType;
  treatmentId: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePillCounterMutationVariables = Exact<{
  input: UpdatePillCounterInput;
}>;

export type UpdatePillCounterMutation = {
  __typename?: 'Mutation';
  updatePillCounter: {
    __typename?: 'UpdatePillCounterPayload';
    errors: Array<string>;
    pillCounter: {
      __typename?: 'PillCount';
      remainingPills: number;
      runsOutOn: string;
    };
  } | null;
};

export type CoreUserFieldsFragment = {
  __typename?: 'SimpleUser';
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    financialExpectation: string | null;
  } | null;
};

export type GetCliniciansQueryVariables = Exact<{
  roleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type GetCliniciansQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        acuityCalendars: Array<{
          __typename?: 'AcuityCalendar';
          id: string;
          province: {
            __typename?: 'ProvinceState';
            id: string;
            name: string;
            code: string;
            supported: boolean;
          };
        }>;
      };
    }>;
  };
};

export type GetProvidersQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProvidersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string | null;
        lastName: string | null;
        deactivatedAt: string | null;
        role: {
          __typename?: 'Role';
          id: string;
          name: string;
          displayName: string;
          code: string;
        };
        credentials: Array<{
          __typename?: 'Credential';
          id: string;
          designation: string;
          registrationNumber: string;
          province: { __typename?: 'ProvinceState'; code: string; id: string };
        }>;
        acuityCalendars: Array<{
          __typename?: 'AcuityCalendar';
          id: string;
          acuityCalendarId: string;
          province: {
            __typename?: 'ProvinceState';
            id: string;
            name: string;
            code: string;
            supported: boolean;
          };
          acuityAccount: {
            __typename?: 'AcuityAccount';
            id: string;
            email: string;
            brand: { __typename?: 'Brand'; id: string; name: string };
          } | null;
        }>;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type GetProvidersForDropdownQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProvidersForDropdownQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        role: { __typename?: 'Role'; id: string; name: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type CredentialFragment = {
  __typename?: 'Credential';
  id: string;
  designation: string;
  registrationNumber: string;
  province: { __typename?: 'ProvinceState'; code: string; id: string };
};

export type ProviderUserFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  deactivatedAt: string | null;
  role: {
    __typename?: 'Role';
    id: string;
    name: string;
    displayName: string;
    code: string;
  };
  credentials: Array<{
    __typename?: 'Credential';
    id: string;
    designation: string;
    registrationNumber: string;
    province: { __typename?: 'ProvinceState'; code: string; id: string };
  }>;
  acuityCalendars: Array<{
    __typename?: 'AcuityCalendar';
    id: string;
    acuityCalendarId: string;
    province: {
      __typename?: 'ProvinceState';
      id: string;
      name: string;
      code: string;
      supported: boolean;
    };
    acuityAccount: {
      __typename?: 'AcuityAccount';
      id: string;
      email: string;
      brand: { __typename?: 'Brand'; id: string; name: string };
    } | null;
  }>;
};

export type GetProviderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProviderQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    deactivatedAt: string | null;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      displayName: string;
      code: string;
    };
    credentials: Array<{
      __typename?: 'Credential';
      id: string;
      designation: string;
      registrationNumber: string;
      province: { __typename?: 'ProvinceState'; code: string; id: string };
    }>;
    acuityCalendars: Array<{
      __typename?: 'AcuityCalendar';
      id: string;
      acuityCalendarId: string;
      province: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      };
      acuityAccount: {
        __typename?: 'AcuityAccount';
        id: string;
        email: string;
        brand: { __typename?: 'Brand'; id: string; name: string };
      } | null;
    }>;
  } | null;
};

export type PatientQueryUserFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  createdAt: string;
  deactivatedAt: string | null;
  emrId: string | null;
  locale: string;
  smsEnabled: boolean;
  voicemailConsent: boolean;
  phone: { __typename?: 'Phone'; e164: string; rawNumber: string | null };
  brands: Array<{
    __typename?: 'BrandMembership';
    id: string;
    name: string;
    createdAt: string;
    brand: { __typename?: 'Brand'; id: string };
  }>;
  profile: {
    __typename?: 'Profile';
    id: string;
    dateOfBirth: string | null;
    healthCareNumber: string | null;
    financialExpectation: string | null;
    province: {
      __typename?: 'ProvinceState';
      id: string;
      name: string;
      code: string;
      supported: boolean;
    } | null;
    healthCareProvince: {
      __typename?: 'ProvinceState';
      id: string;
      name: string;
      code: string;
      supported: boolean;
    } | null;
  } | null;
};

export type GetPatientsQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  brandId?: InputMaybe<Scalars['ID']['input']>;
  provinceId?: InputMaybe<Scalars['ID']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPatientsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string | null;
        lastName: string | null;
        preferredName: string | null;
        createdAt: string;
        deactivatedAt: string | null;
        emrId: string | null;
        locale: string;
        smsEnabled: boolean;
        voicemailConsent: boolean;
        phone: { __typename?: 'Phone'; e164: string; rawNumber: string | null };
        brands: Array<{
          __typename?: 'BrandMembership';
          id: string;
          name: string;
          createdAt: string;
          brand: { __typename?: 'Brand'; id: string };
        }>;
        profile: {
          __typename?: 'Profile';
          id: string;
          dateOfBirth: string | null;
          healthCareNumber: string | null;
          financialExpectation: string | null;
          province: {
            __typename?: 'ProvinceState';
            id: string;
            name: string;
            code: string;
            supported: boolean;
          } | null;
          healthCareProvince: {
            __typename?: 'ProvinceState';
            id: string;
            name: string;
            code: string;
            supported: boolean;
          } | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type FreddieQuestionnairesFragment = {
  __typename?: 'Questionnaire';
  id: string;
  createdAt: string;
  updatedAt: string;
  submitted: boolean;
  results: any;
};

export type CycleWorkflowStepFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  createdAt: string;
  completedAt: string | null;
  type: string;
  user: { __typename?: 'SimpleUser'; id: string };
  parentStep: {
    __typename?: 'WorkflowStep';
    id: string;
    completedAt: string | null;
    completionParams: any | null;
    workflowStepRelations: Array<{
      __typename?: 'WorkflowStepRelation';
      id: string;
      workflowable:
        | { __typename?: 'AcuityAppointment' }
        | { __typename?: 'Assessment' }
        | { __typename?: 'LabRequisition' }
        | {
            __typename?: 'Prescription';
            id: string;
            createdAt: string;
            dueAt: string | null;
            prescriptionType: string | null;
            prescribedDate: string | null;
            prescriptionLength: number | null;
            bridging: boolean | null;
            prepType: string | null;
          }
        | { __typename?: 'Questionnaire' }
        | { __typename?: 'ShortQuestionnaire' }
        | { __typename?: 'Unhandled' };
    }>;
  } | null;
  subject:
    | { __typename?: 'AcuityAppointment' }
    | { __typename?: 'Assessment' }
    | {
        __typename?: 'LabRequisition';
        id: string;
        requisitionType: string | null;
        notes: string | null;
        readByPatientAt: string | null;
        sentAt: string;
      }
    | {
        __typename?: 'Prescription';
        id: string;
        createdAt: string;
        dueAt: string | null;
        prescriptionType: string | null;
        prescribedDate: string | null;
        prescriptionLength: number | null;
        bridging: boolean | null;
        prepType: string | null;
      }
    | { __typename?: 'Questionnaire' }
    | {
        __typename?: 'ShortQuestionnaire';
        id: string;
        createdAt: string;
        results: any;
      }
    | { __typename?: 'Unhandled' }
    | null;
};

export type CycleAcuityAppointmentFragment = {
  __typename?: 'AcuityAppointment';
  id: string;
  createdAt: string;
  scheduledAt: string;
  updatedAt: string;
  durationInMinutes: number;
  calendar: {
    __typename?: 'AcuityCalendar';
    id: string;
    clinician: {
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
    };
  } | null;
  consult: {
    __typename?: 'Consult';
    id: string;
    bridgingNeeded: boolean | null;
    prepRxNextStep: string | null;
    labReqNextStep: string | null;
    otherRxNextStep: Array<string> | null;
    additionalInfo: Array<string> | null;
    nextStep: NextStepEnum | null;
    notes: string | null;
  } | null;
  patient: { __typename?: 'SimpleUser'; id: string } | null;
};

export type PatientBrandMembershipFragment = {
  __typename?: 'BrandMembership';
  id: string;
  name: string;
  createdAt: string;
  initialPaymentReceivedAt: string | null;
  brand: { __typename?: 'Brand'; id: string };
  initialPrice: {
    __typename?: 'InitialPrice';
    id: string;
    name: string | null;
    amountInCents: number;
    stripePriceId: string;
    effectiveStartDate: string;
    effectiveEndDate: string | null;
    brandId: number;
  } | null;
};

export type ClinicalTrialFragment = {
  __typename?: 'ClinicalTrial';
  id: string;
  name: string;
  title: string;
  active: boolean;
};

export type FreddiePatientConsultFragment = {
  __typename?: 'Consult';
  id: string;
  attended: boolean;
  initialConsult: boolean | null;
  nextStep: NextStepEnum | null;
  otherNextStep: string | null;
  expectedLabDate: string | null;
  financialExpectation: string | null;
  notes: string | null;
  updatedAt: string;
  prepStatus: boolean | null;
  prepStatusReason: Array<string> | null;
  prepOnDemand: string | null;
  labReqNextStep: string | null;
  prepRxNextStep: string | null;
  otherRxNextStep: Array<string> | null;
  bridgingNeeded: boolean | null;
  additionalInfo: Array<string> | null;
  prepTypeLikelyToPrescribe: string | null;
  consultCompletedStatusReason: string | null;
  consultNotCompletedDescription: string | null;
};

export type PatientAcuityAppointmentInfoFragment = {
  __typename?: 'AcuityAppointment';
  id: string;
  acuityAppointmentId: number | null;
  appointmentTypeId: number | null;
  appointmentTypeName: string | null;
  scheduledAt: string;
  durationInMinutes: number;
  forms: any | null;
  reasonForAppointment: string | null;
  bookingReasonDescription: string | null;
  location: string | null;
  videoEnabled: boolean;
  brand: { __typename?: 'Brand'; id: string } | null;
  calendar: {
    __typename?: 'AcuityCalendar';
    id: string;
    clinician: {
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
    };
  } | null;
  consult: {
    __typename?: 'Consult';
    id: string;
    attended: boolean;
    initialConsult: boolean | null;
    nextStep: NextStepEnum | null;
    otherNextStep: string | null;
    expectedLabDate: string | null;
    financialExpectation: string | null;
    notes: string | null;
    updatedAt: string;
    prepStatus: boolean | null;
    prepStatusReason: Array<string> | null;
    prepOnDemand: string | null;
    labReqNextStep: string | null;
    prepRxNextStep: string | null;
    otherRxNextStep: Array<string> | null;
    bridgingNeeded: boolean | null;
    additionalInfo: Array<string> | null;
    prepTypeLikelyToPrescribe: string | null;
    consultCompletedStatusReason: string | null;
    consultNotCompletedDescription: string | null;
  } | null;
  patient: {
    __typename?: 'SimpleUser';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      financialExpectation: string | null;
    } | null;
  } | null;
  postConsultWorkflowStep: {
    __typename?: 'WorkflowStep';
    id: string;
    completedAt: string | null;
  } | null;
};

export type PatientAppointmentInfoFragment = {
  __typename?: 'Appointment';
  id: string;
  acuityAppointmentId: number | null;
  appointmentTypeId: number | null;
  appointmentTypeName: string | null;
  scheduledAt: string;
  durationInMinutes: number;
  forms: any | null;
  reasonForAppointment: string | null;
  bookingReasonDescription: string | null;
  location: string | null;
  videoEnabled: boolean;
  brand: { __typename?: 'Brand'; id: string } | null;
  calendar: {
    __typename?: 'AcuityCalendar';
    id: string;
    clinician: {
      __typename?: 'SimpleUser';
      id: string;
      firstName: string | null;
      lastName: string | null;
    };
  } | null;
  consult: {
    __typename?: 'Consult';
    id: string;
    attended: boolean;
    initialConsult: boolean | null;
    nextStep: NextStepEnum | null;
    otherNextStep: string | null;
    expectedLabDate: string | null;
    financialExpectation: string | null;
    notes: string | null;
    updatedAt: string;
    prepStatus: boolean | null;
    prepStatusReason: Array<string> | null;
    prepOnDemand: string | null;
    labReqNextStep: string | null;
    prepRxNextStep: string | null;
    otherRxNextStep: Array<string> | null;
    bridgingNeeded: boolean | null;
    additionalInfo: Array<string> | null;
    prepTypeLikelyToPrescribe: string | null;
    consultCompletedStatusReason: string | null;
    consultNotCompletedDescription: string | null;
  } | null;
  patient: {
    __typename?: 'SimpleUser';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      financialExpectation: string | null;
    } | null;
  } | null;
  postConsultWorkflowStep: {
    __typename?: 'WorkflowStep';
    id: string;
    completedAt: string | null;
  } | null;
};

export type GetPatientQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  positionedBefore?: InputMaybe<Scalars['ISO8601Date']['input']>;
  positionedAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  numberOfMedicalRecords?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPatientQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    preferredName: string | null;
    createdAt: string;
    deactivatedAt: string | null;
    locale: string;
    smsEnabled: boolean;
    voicemailConsent: boolean;
    zendeskId: string | null;
    emrId: string | null;
    phone: { __typename?: 'Phone'; e164: string; rawNumber: string | null };
    vitals: {
      __typename?: 'VitalConnection';
      nodes: Array<{
        __typename?: 'BloodPressure';
        systolic: number;
        diastolic: number;
        isBaseline: boolean;
        timingWithMedication: string | null;
        id: string;
        createdAt: string | null;
        updatedAt: string | null;
        details: any;
        measuredAt: string | null;
        type: string;
        enteredBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        };
      } | null> | null;
    };
    brands: Array<{
      __typename?: 'BrandMembership';
      id: string;
      name: string;
      createdAt: string;
      initialPaymentReceivedAt: string | null;
      brand: { __typename?: 'Brand'; id: string };
      initialPrice: {
        __typename?: 'InitialPrice';
        id: string;
        name: string | null;
        amountInCents: number;
        stripePriceId: string;
        effectiveStartDate: string;
        effectiveEndDate: string | null;
        brandId: number;
      } | null;
    }>;
    lastTreatments: Array<{
      __typename?: 'Treatment';
      id: string;
      type: TreatmentType;
      medicationStatus: MedicationStatusEnum;
      createdAt: string;
      updatedAt: string;
      stoppedAt: string | null;
      stoppedReason: string | null;
      eligibility: string | null;
      user: { __typename?: 'User'; id: string };
      stoppedBy: {
        __typename?: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
    }>;
    treatmentEligibilities: Array<{
      __typename?: 'TreatmentEligibility';
      id: string;
      eligibility: TreatmentEligibilityEnum;
      reason: string | null;
      userId: string;
      treatmentType: TreatmentType;
      treatmentId: string | null;
      createdAt: string;
      updatedAt: string;
    }>;
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
      healthCareNumber: string | null;
      financialExpectation: string | null;
      hasPaymentMethod: boolean;
      stripeCustomerId: string | null;
      ccLastFour: number | null;
      stripeVerificationSessionId: string | null;
      verificationStatus: StripeVerificationSessionStatusEnum | null;
      dateVerified: string | null;
      allergies: string | null;
      medications: string | null;
      medicalConditions: string | null;
      medInfoConfirmedAt: string | null;
      deliveryPreference: DeliveryPreferenceEnum;
      shippingConfirmedAt: string | null;
      insuranceCardConfirmedAt: string | null;
      province: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
      healthCareProvince: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
      medicalHistoryVersions: Array<{
        __typename?: 'MedicalHistoryVersion';
        allergies: string | null;
        medications: string | null;
        medicalConditions: string | null;
        medInfoConfirmedAt: string | null;
      }>;
      lastStripePaymentMethod: {
        __typename?: 'PaymentMethod';
        id: string;
      } | null;
      lastAffirmPaymentMethod: {
        __typename?: 'PaymentMethod';
        id: string;
        externalId: string;
      } | null;
      shippingAddresses: Array<{
        __typename?: 'ShippingAddress';
        id: string;
        recipientName: string;
        addressType: AddressTypeEnum | null;
        street: string;
        street2: string | null;
        city: string;
        province: string;
        postalCode: string;
        deliveryInstructions: string | null;
        isDefaultAddress: boolean;
      }> | null;
      insuranceCardPhotos: Array<{
        __typename?: 'Attachment';
        downloadUrl: string;
        filename: string;
      }>;
      insuranceCards: Array<{
        __typename?: 'InsuranceCard';
        id: string;
        label: string;
        isDefaultCard: boolean;
        frontImage: { __typename?: 'Attachment'; downloadUrl: string };
        backImage: { __typename?: 'Attachment'; downloadUrl: string } | null;
      }> | null;
    } | null;
    questionnaires: Array<{
      __typename?: 'Questionnaire';
      id: string;
      createdAt: string;
      updatedAt: string;
      submitted: boolean;
      results: any;
    }> | null;
    labRequisitions: {
      __typename?: 'LabRequisitionConnection';
      nodes: Array<{
        __typename?: 'LabRequisition';
        id: string;
        readByPatientAt: string | null;
        requisitionType: string | null;
        sentAt: string;
        notes: string | null;
        createdAt: string;
        formLink: string | null;
        attachments: Array<{
          __typename?: 'Attachment';
          id: string;
          downloadUrl: string;
          filename: string;
        }>;
        sentBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        };
        feedbackStep: { __typename?: 'WorkflowStep'; id: string } | null;
        updateOperation: {
          __typename?: 'Operation';
          canPerform: boolean;
          invalidReasons: Array<string>;
        };
        deleteOperation: {
          __typename?: 'Operation';
          canPerform: boolean;
          invalidReasons: Array<string>;
        };
      }>;
    };
    prescriptions: {
      __typename?: 'PrescriptionConnection';
      nodes: Array<{
        __typename?: 'Prescription';
        id: string;
        createdAt: string;
        dueAt: string | null;
        readByPatientAt: string | null;
        fulfillmentMethod: string | null;
        prescriptionType: string | null;
        prescriptionLength: number | null;
        prepType: string | null;
        medication: string | null;
        treatmentStartAt: string | null;
        prescribedDate: string | null;
        bridging: boolean | null;
        dosage: string | null;
        dosingRegimen: DosingRegimenEnum | null;
        numOfPills: number | null;
        additionalInfo: any | null;
        sentAt: string | null;
        updateOperation: {
          __typename?: 'Operation';
          canPerform: boolean;
          invalidReasons: Array<string>;
        };
        deleteOperation: {
          __typename?: 'Operation';
          canPerform: boolean;
          invalidReasons: Array<string>;
        };
        brand: { __typename?: 'Brand'; id: string; name: string } | null;
        sentBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        } | null;
        message: {
          __typename?: 'Message';
          id: string;
          conversationId: string;
        } | null;
      }>;
    };
    appointments: {
      __typename?: 'AppointmentConnection';
      nodes: Array<{
        __typename?: 'Appointment';
        id: string;
        acuityAppointmentId: number | null;
        appointmentTypeId: number | null;
        appointmentTypeName: string | null;
        scheduledAt: string;
        durationInMinutes: number;
        forms: any | null;
        reasonForAppointment: string | null;
        bookingReasonDescription: string | null;
        location: string | null;
        videoEnabled: boolean;
        brand: { __typename?: 'Brand'; id: string } | null;
        calendar: {
          __typename?: 'AcuityCalendar';
          id: string;
          clinician: {
            __typename?: 'SimpleUser';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        } | null;
        consult: {
          __typename?: 'Consult';
          id: string;
          attended: boolean;
          initialConsult: boolean | null;
          nextStep: NextStepEnum | null;
          otherNextStep: string | null;
          expectedLabDate: string | null;
          financialExpectation: string | null;
          notes: string | null;
          updatedAt: string;
          prepStatus: boolean | null;
          prepStatusReason: Array<string> | null;
          prepOnDemand: string | null;
          labReqNextStep: string | null;
          prepRxNextStep: string | null;
          otherRxNextStep: Array<string> | null;
          bridgingNeeded: boolean | null;
          additionalInfo: Array<string> | null;
          prepTypeLikelyToPrescribe: string | null;
          consultCompletedStatusReason: string | null;
          consultNotCompletedDescription: string | null;
        } | null;
        patient: {
          __typename?: 'SimpleUser';
          id: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            financialExpectation: string | null;
          } | null;
        } | null;
        postConsultWorkflowStep: {
          __typename?: 'WorkflowStep';
          id: string;
          completedAt: string | null;
        } | null;
      }>;
    };
    shortQuestionnaires: Array<{
      __typename?: 'ShortQuestionnaire';
      id: string;
      createdAt: string;
      results: any;
    }>;
    assessments: {
      __typename?: 'AssessmentConnection';
      nodes: Array<{
        __typename?: 'Assessment';
        id: string;
        type: string | null;
        completedAt: string | null;
        data: any | null;
        score: any | null;
        scoringErrors: any | null;
        maxPossibleScore: number;
        patientScore: number;
      }>;
    };
    journeyItems: Array<
      | {
          __typename: 'AcuityAppointment';
          id: string;
          positionedAt: string;
          createdAt: string;
          completedAt: string | null;
          scheduledAt: string;
          updatedAt: string;
          durationInMinutes: number;
          calendar: {
            __typename?: 'AcuityCalendar';
            id: string;
            clinician: {
              __typename?: 'SimpleUser';
              id: string;
              firstName: string | null;
              lastName: string | null;
            };
          } | null;
          consult: {
            __typename?: 'Consult';
            id: string;
            bridgingNeeded: boolean | null;
            prepRxNextStep: string | null;
            labReqNextStep: string | null;
            otherRxNextStep: Array<string> | null;
            additionalInfo: Array<string> | null;
            nextStep: NextStepEnum | null;
            notes: string | null;
          } | null;
          patient: { __typename?: 'SimpleUser'; id: string } | null;
        }
      | {
          __typename: 'AcuityAppointmentEvent';
          id: string;
          positionedAt: string;
          createdAt: string;
          completedAt: string | null;
        }
      | {
          __typename: 'Appointment';
          id: string;
          positionedAt: string;
          createdAt: string;
          completedAt: string | null;
        }
      | {
          __typename: 'Cycle';
          id: string;
          positionedAt: string;
          createdAt: string;
          completedAt: string | null;
          workflowSteps: Array<{
            __typename?: 'WorkflowStep';
            id: string;
            createdAt: string;
            completedAt: string | null;
            type: string;
            user: { __typename?: 'SimpleUser'; id: string };
            parentStep: {
              __typename?: 'WorkflowStep';
              id: string;
              completedAt: string | null;
              completionParams: any | null;
              workflowStepRelations: Array<{
                __typename?: 'WorkflowStepRelation';
                id: string;
                workflowable:
                  | { __typename?: 'AcuityAppointment' }
                  | { __typename?: 'Assessment' }
                  | { __typename?: 'LabRequisition' }
                  | {
                      __typename?: 'Prescription';
                      id: string;
                      createdAt: string;
                      dueAt: string | null;
                      prescriptionType: string | null;
                      prescribedDate: string | null;
                      prescriptionLength: number | null;
                      bridging: boolean | null;
                      prepType: string | null;
                    }
                  | { __typename?: 'Questionnaire' }
                  | { __typename?: 'ShortQuestionnaire' }
                  | { __typename?: 'Unhandled' };
              }>;
            } | null;
            subject:
              | { __typename?: 'AcuityAppointment' }
              | { __typename?: 'Assessment' }
              | {
                  __typename?: 'LabRequisition';
                  id: string;
                  requisitionType: string | null;
                  notes: string | null;
                  readByPatientAt: string | null;
                  sentAt: string;
                }
              | {
                  __typename?: 'Prescription';
                  id: string;
                  createdAt: string;
                  dueAt: string | null;
                  prescriptionType: string | null;
                  prescribedDate: string | null;
                  prescriptionLength: number | null;
                  bridging: boolean | null;
                  prepType: string | null;
                }
              | { __typename?: 'Questionnaire' }
              | {
                  __typename?: 'ShortQuestionnaire';
                  id: string;
                  createdAt: string;
                  results: any;
                }
              | { __typename?: 'Unhandled' }
              | null;
          }>;
        }
      | {
          __typename: 'WorkflowStep';
          id: string;
          type: string;
          positionedAt: string;
          createdAt: string;
          completedAt: string | null;
        }
    >;
  } | null;
};

export type GetPatientSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPatientSummaryQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    phone: { __typename?: 'Phone'; e164: string; rawNumber: string | null };
    profile: {
      __typename?: 'Profile';
      id: string;
      financialExpectation: string | null;
      province: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type GetFreddiePatientSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFreddiePatientSummaryQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    locale: string;
    phone: { __typename?: 'Phone'; e164: string; rawNumber: string | null };
    profile: {
      __typename?: 'Profile';
      id: string;
      dateOfBirth: string | null;
      healthCareNumber: string | null;
      province: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
      healthCareProvince: {
        __typename?: 'ProvinceState';
        id: string;
        name: string;
        code: string;
        supported: boolean;
      } | null;
    } | null;
    prescriptions: {
      __typename?: 'PrescriptionConnection';
      nodes: Array<{
        __typename?: 'Prescription';
        id: string;
        createdAt: string;
        dueAt: string | null;
        readByPatientAt: string | null;
        fulfillmentMethod: string | null;
        prescriptionType: string | null;
        prescriptionLength: number | null;
        prepType: string | null;
        medication: string | null;
        treatmentStartAt: string | null;
        prescribedDate: string | null;
        bridging: boolean | null;
        dosage: string | null;
        dosingRegimen: DosingRegimenEnum | null;
        numOfPills: number | null;
        additionalInfo: any | null;
        sentAt: string | null;
        updateOperation: {
          __typename?: 'Operation';
          canPerform: boolean;
          invalidReasons: Array<string>;
        };
        deleteOperation: {
          __typename?: 'Operation';
          canPerform: boolean;
          invalidReasons: Array<string>;
        };
        brand: { __typename?: 'Brand'; id: string; name: string } | null;
        sentBy: {
          __typename?: 'SimpleUser';
          id: string;
          firstName: string | null;
          lastName: string | null;
        } | null;
        message: {
          __typename?: 'Message';
          id: string;
          conversationId: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetPatientWithAppointmentInfoQueryVariables = Exact<{
  appointmentId: Scalars['ID']['input'];
}>;

export type GetPatientWithAppointmentInfoQuery = {
  __typename?: 'Query';
  appointment: {
    __typename?: 'AcuityAppointment';
    id: string;
    acuityAppointmentId: number | null;
    appointmentTypeId: number | null;
    appointmentTypeName: string | null;
    scheduledAt: string;
    durationInMinutes: number;
    forms: any | null;
    reasonForAppointment: string | null;
    bookingReasonDescription: string | null;
    location: string | null;
    videoEnabled: boolean;
    patient: {
      __typename?: 'SimpleUser';
      id: string;
      email: string;
      firstName: string | null;
      lastName: string | null;
      preferredName: string | null;
      emrId: string | null;
      voicemailConsent: boolean;
      prescriptions: {
        __typename?: 'PrescriptionConnection';
        nodes: Array<{ __typename?: 'Prescription'; createdAt: string }>;
      };
      phone: {
        __typename?: 'Phone';
        e164: string;
        rawNumber: string | null;
      } | null;
      profile: {
        __typename?: 'Profile';
        dateOfBirth: string | null;
        dateVerified: string | null;
        verificationStatus: StripeVerificationSessionStatusEnum | null;
        healthCareNumber: string | null;
        id: string;
        financialExpectation: string | null;
        province: {
          __typename?: 'ProvinceState';
          id: string;
          name: string;
          code: string;
          supported: boolean;
        } | null;
        healthCareProvince: {
          __typename?: 'ProvinceState';
          id: string;
          name: string;
          code: string;
          supported: boolean;
        } | null;
      } | null;
      questionnaires: Array<{
        __typename?: 'Questionnaire';
        id: string;
        createdAt: string;
        updatedAt: string;
        submitted: boolean;
        results: any;
      }> | null;
    } | null;
    brand: { __typename?: 'Brand'; id: string } | null;
    calendar: {
      __typename?: 'AcuityCalendar';
      id: string;
      clinician: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
      };
    } | null;
    consult: {
      __typename?: 'Consult';
      id: string;
      attended: boolean;
      initialConsult: boolean | null;
      nextStep: NextStepEnum | null;
      otherNextStep: string | null;
      expectedLabDate: string | null;
      financialExpectation: string | null;
      notes: string | null;
      updatedAt: string;
      prepStatus: boolean | null;
      prepStatusReason: Array<string> | null;
      prepOnDemand: string | null;
      labReqNextStep: string | null;
      prepRxNextStep: string | null;
      otherRxNextStep: Array<string> | null;
      bridgingNeeded: boolean | null;
      additionalInfo: Array<string> | null;
      prepTypeLikelyToPrescribe: string | null;
      consultCompletedStatusReason: string | null;
      consultNotCompletedDescription: string | null;
    } | null;
    postConsultWorkflowStep: {
      __typename?: 'WorkflowStep';
      id: string;
      completedAt: string | null;
    } | null;
  };
};

export type GetPatientTreatmentOverviewQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPatientTreatmentOverviewQuery = {
  __typename?: 'Query';
  patientTreatmentOverview: {
    __typename?: 'PatientTreatmentOverview';
    onboardingComplete: boolean;
    onboardingDashboardItems: Array<{
      __typename?: 'DashboardItem';
      name: string | null;
      treatmentType: TreatmentType | null;
      workflowStep: {
        __typename?: 'WorkflowStep';
        id: string;
        type: string;
        completedAt: string | null;
        createdAt: string;
      } | null;
    }> | null;
    treatmentDashboardItems: Array<{
      __typename?: 'DashboardItem';
      name: string | null;
      treatmentType: TreatmentType | null;
      workflowStep: {
        __typename?: 'WorkflowStep';
        id: string;
        type: string;
        completedAt: string | null;
      } | null;
    }> | null;
    verificationDashboardItem: {
      __typename?: 'DashboardItem';
      name: string | null;
    } | null;
  } | null;
};

export type GetPatientChapterCoverQueryVariables = Exact<{
  treatmentType: TreatmentType;
}>;

export type GetPatientChapterCoverQuery = {
  __typename?: 'Query';
  patientChapterCover: {
    __typename?: 'PatientChapterCover';
    type: string | null;
    workflowStep: {
      __typename?: 'WorkflowStep';
      id: string;
      completedAt: string | null;
      type: string;
    } | null;
  } | null;
};

export type ActivateUserAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ActivateUserAccountMutation = {
  __typename?: 'Mutation';
  activateUserAccount: {
    __typename?: 'ActivateUserAccountPayload';
    errors: Array<any>;
  } | null;
};

export type DeactivateUserAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeactivateUserAccountMutation = {
  __typename?: 'Mutation';
  deactivateUserAccount: {
    __typename?: 'DeactivateUserAccountPayload';
    errors: Array<any>;
  } | null;
};

export type UpdatePatientMutationVariables = Exact<{
  input: UpdatePatientInput;
}>;

export type UpdatePatientMutation = {
  __typename?: 'Mutation';
  updatePatient: {
    __typename?: 'UpdatePatientPayload';
    errors: Array<string> | null;
  } | null;
};

export type CreateProviderMutationVariables = Exact<{
  input: CreateProviderInput;
}>;

export type CreateProviderMutation = {
  __typename?: 'Mutation';
  createProvider: {
    __typename?: 'CreateProviderPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateProviderMutationVariables = Exact<{
  input: UpdateProviderInput;
}>;

export type UpdateProviderMutation = {
  __typename?: 'Mutation';
  updateProvider: {
    __typename?: 'UpdateProviderPayload';
    errors: Array<string>;
  } | null;
};

export type CreateAssessmentMutationVariables = Exact<{
  input: CreateAssessmentInput;
}>;

export type CreateAssessmentMutation = {
  __typename?: 'Mutation';
  createAssessment: {
    __typename?: 'CreateAssessmentPayload';
    errors: Array<string>;
    assessment: {
      __typename?: 'Assessment';
      id: string;
      type: string | null;
    } | null;
  } | null;
};

export type UpdateAssessmentMutationVariables = Exact<{
  input: UpdateAssessmentInput;
}>;

export type UpdateAssessmentMutation = {
  __typename?: 'Mutation';
  updateAssessment: {
    __typename?: 'UpdateAssessmentPayload';
    errors: Array<string>;
    assessment: {
      __typename?: 'Assessment';
      id: string;
      type: string | null;
      completedAt: string | null;
      data: any | null;
    } | null;
  } | null;
};

export type CompleteAssessmentMutationVariables = Exact<{
  input: CompleteAssessmentInput;
}>;

export type CompleteAssessmentMutation = {
  __typename?: 'Mutation';
  completeAssessment: {
    __typename?: 'CompleteAssessmentPayload';
    errors: Array<string>;
    assessment: {
      __typename?: 'Assessment';
      id: string;
      type: string | null;
      completedAt: string | null;
      data: any | null;
    };
  } | null;
};

export type ValidateEmailMutationVariables = Exact<{
  input: ValidateEmailInput;
}>;

export type ValidateEmailMutation = {
  __typename?: 'Mutation';
  validateEmail: {
    __typename?: 'ValidateEmailPayload';
    errors: Array<string>;
    result: {
      __typename?: 'EmailValidation';
      local: string | null;
      suggestion: string | null;
      verdict: string | null;
    } | null;
  } | null;
};

export type CreateVitalMutationVariables = Exact<{
  input: CreateVitalInput;
}>;

export type CreateVitalMutation = {
  __typename?: 'Mutation';
  createVital: {
    __typename?: 'CreateVitalPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateVitalMutationVariables = Exact<{
  input: UpdateVitalInput;
}>;

export type UpdateVitalMutation = {
  __typename?: 'Mutation';
  updateVital: {
    __typename?: 'UpdateVitalPayload';
    errors: Array<string>;
  } | null;
};

export type MyBloodPressureFragment = {
  __typename: 'BloodPressure';
  id: string;
  type: string;
  measuredAt: string | null;
  systolic: number;
  diastolic: number;
};

export type GetMyVitalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyVitalsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    vitals: {
      __typename?: 'VitalConnection';
      edges: Array<{
        __typename?: 'VitalEdge';
        node: {
          __typename: 'BloodPressure';
          id: string;
          type: string;
          measuredAt: string | null;
          systolic: number;
          diastolic: number;
        } | null;
      } | null> | null;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor: string | null;
        hasNextPage: boolean;
      };
    };
  } | null;
};

export type WorkflowStepBasicDataFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  completionParams: any | null;
  completedAt: string | null;
  canceledAt: string | null;
};

export type GetUsersWorkflowStepsQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['ID']['input']>;
  stepType?: InputMaybe<Scalars['String']['input']>;
  completedById?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetUsersWorkflowStepsQuery = {
  __typename?: 'Query';
  workflowSteps: {
    __typename?: 'WorkflowStepConnection';
    edges: Array<{
      __typename?: 'WorkflowStepEdge';
      node: {
        __typename?: 'WorkflowStep';
        id: string;
        type: string;
        parentStep: {
          __typename?: 'WorkflowStep';
          id: string;
          subject:
            | { __typename?: 'AcuityAppointment'; id: string }
            | { __typename?: 'Assessment' }
            | { __typename?: 'LabRequisition' }
            | { __typename?: 'Prescription' }
            | { __typename?: 'Questionnaire' }
            | { __typename?: 'ShortQuestionnaire' }
            | { __typename?: 'Unhandled' }
            | null;
        } | null;
      };
    }>;
    nodes: Array<{
      __typename?: 'WorkflowStep';
      id: string;
      completionParams: any | null;
      completedAt: string | null;
      canceledAt: string | null;
    }>;
  };
};

export type CoreWorkflowStepFieldsFragment = {
  __typename?: 'WorkflowStep';
  id: string;
  type: string;
  dueAt: string | null;
  completedAt: string | null;
  createdAt: string;
  updatedAt: string;
  completionParams: any | null;
  user: {
    __typename?: 'SimpleUser';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      financialExpectation: string | null;
    } | null;
  };
  subject:
    | { __typename?: 'AcuityAppointment'; scheduledAt: string; id: string }
    | { __typename?: 'Assessment' }
    | {
        __typename?: 'LabRequisition';
        id: string;
        requisitionType: string | null;
        readByPatientAt: string | null;
        attachments: Array<{
          __typename?: 'Attachment';
          id: string;
          downloadUrl: string;
        }>;
        message: {
          __typename?: 'Message';
          id: string;
          conversationId: string;
        } | null;
      }
    | {
        __typename?: 'Prescription';
        id: string;
        prescriptionLength: number | null;
        prepType: string | null;
        message: {
          __typename?: 'Message';
          id: string;
          conversationId: string;
        } | null;
      }
    | { __typename?: 'Questionnaire' }
    | {
        __typename?: 'ShortQuestionnaire';
        id: string;
        createdAt: string;
        results: any;
      }
    | { __typename?: 'Unhandled' }
    | null;
};

export type WorkflowStepPrescriptionResponseFragment = {
  __typename?: 'Prescription';
  id: string;
  medication: string | null;
  numOfPills: number | null;
  dosage: string | null;
  prescriptionType: string | null;
  fulfillmentMethod: string | null;
  isBpMonitorNeeded: boolean | null;
  additionalInfo: any | null;
};

export type GetMyWorkflowStepsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyWorkflowStepsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    currentBrandMembership: {
      __typename?: 'BrandMembership';
      id: string;
      screenedOutStep: {
        __typename?: 'WorkflowStep';
        id: string;
        type: string;
      } | null;
      availableBookingTypes: Array<{
        __typename?: 'AvailableBookingTypes';
        id: string;
        bookingType: AcuityBooking | null;
        workflowStepId: string | null;
      }>;
    };
    workflowSteps: Array<{
      __typename?: 'WorkflowStep';
      id: string;
      type: string;
      dueAt: string | null;
      completedAt: string | null;
      createdAt: string;
      updatedAt: string;
      completionParams: any | null;
      user: {
        __typename?: 'SimpleUser';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          financialExpectation: string | null;
        } | null;
      };
      subject:
        | { __typename?: 'AcuityAppointment'; scheduledAt: string; id: string }
        | { __typename?: 'Assessment' }
        | {
            __typename?: 'LabRequisition';
            id: string;
            requisitionType: string | null;
            readByPatientAt: string | null;
            attachments: Array<{
              __typename?: 'Attachment';
              id: string;
              downloadUrl: string;
            }>;
            message: {
              __typename?: 'Message';
              id: string;
              conversationId: string;
            } | null;
          }
        | {
            __typename?: 'Prescription';
            id: string;
            prescriptionLength: number | null;
            prepType: string | null;
            message: {
              __typename?: 'Message';
              id: string;
              conversationId: string;
            } | null;
          }
        | { __typename?: 'Questionnaire' }
        | {
            __typename?: 'ShortQuestionnaire';
            id: string;
            createdAt: string;
            results: any;
          }
        | { __typename?: 'Unhandled' }
        | null;
    }>;
  } | null;
};

export type GetWorkflowStepQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetWorkflowStepQuery = {
  __typename?: 'Query';
  workflowStep: {
    __typename?: 'WorkflowStep';
    id: string;
    completionParams: any | null;
    workflowStepRelations: Array<{
      __typename?: 'WorkflowStepRelation';
      id: string;
      workflowable:
        | { __typename?: 'AcuityAppointment' }
        | { __typename?: 'Assessment' }
        | { __typename?: 'LabRequisition' }
        | {
            __typename?: 'Prescription';
            id: string;
            medication: string | null;
            numOfPills: number | null;
            dosage: string | null;
            prescriptionType: string | null;
            fulfillmentMethod: string | null;
            isBpMonitorNeeded: boolean | null;
            additionalInfo: any | null;
          }
        | { __typename?: 'Questionnaire' }
        | { __typename?: 'ShortQuestionnaire' }
        | { __typename?: 'Unhandled' };
    }>;
  };
};

export type CompleteWorkflowStepMutationVariables = Exact<{
  input: CompleteWorkflowStepInput;
}>;

export type CompleteWorkflowStepMutation = {
  __typename?: 'Mutation';
  completeWorkflowStep: {
    __typename?: 'CompleteWorkflowStepPayload';
    errors: Array<string>;
  } | null;
};

export type CompleteWorkflowStepsMutationVariables = Exact<{
  input: CompleteWorkflowStepsInput;
}>;

export type CompleteWorkflowStepsMutation = {
  __typename?: 'Mutation';
  completeWorkflowSteps: {
    __typename?: 'CompleteWorkflowStepsPayload';
    errors: Array<string>;
  } | null;
};

export type UpdateWorkflowStepMutationVariables = Exact<{
  input: UpdateWorkflowStepInput;
}>;

export type UpdateWorkflowStepMutation = {
  __typename?: 'Mutation';
  updateWorkflowStep: {
    __typename?: 'UpdateWorkflowStepPayload';
    errors: Array<string>;
  } | null;
};

export type OverrideWorkflowStepMutationVariables = Exact<{
  input: OverrideWorkflowStepInput;
}>;

export type OverrideWorkflowStepMutation = {
  __typename?: 'Mutation';
  overrideWorkflowStep: {
    __typename?: 'OverrideWorkflowStepPayload';
    errors: Array<string>;
  } | null;
};

export const ProvinceFieldsFragmentDoc = gql`
  fragment ProvinceFields on ProvinceState {
    id
    code
  }
`;
export const BrandFieldsFragmentDoc = gql`
  fragment BrandFields on Brand {
    id
    name
  }
`;
export const AcuityAppointmentTypeFragmentDoc = gql`
  fragment AcuityAppointmentType on AcuityAppointmentType {
    id
    acuityAppointmentTypeId
    bookingType
    locale
    province {
      ...ProvinceFields
    }
    brand {
      ...BrandFields
    }
    acuityAccount {
      id
    }
  }
  ${ProvinceFieldsFragmentDoc}
  ${BrandFieldsFragmentDoc}
`;
export const AgreementClientPartsFragmentDoc = gql`
  fragment AgreementClientParts on AgreementClient {
    id
    version
    content
  }
`;
export const AgreementPrivacyPartsFragmentDoc = gql`
  fragment AgreementPrivacyParts on AgreementPrivacy {
    id
    version
    url
  }
`;
export const AgreementTermsOfUsePartsFragmentDoc = gql`
  fragment AgreementTermsOfUseParts on AgreementTermsOfUse {
    id
    version
    url
  }
`;
export const AgreementFreddieAsyncPartsFragmentDoc = gql`
  fragment AgreementFreddieAsyncParts on AgreementFreddieAsync {
    id
    version
    agreementContent
  }
`;
export const AgreementFreddieDoxyPepAsyncPartsFragmentDoc = gql`
  fragment AgreementFreddieDoxyPepAsyncParts on AgreementFreddieDoxyPepAsync {
    id
    version
    agreementContent
  }
`;
export const PatientViewAssessmentFragmentDoc = gql`
  fragment PatientViewAssessment on Assessment {
    id
    type
    completedAt
    data
    score
    scoringErrors
    maxPossibleScore
    patientScore
  }
`;
export const CoreParticipantFieldsFragmentDoc = gql`
  fragment CoreParticipantFields on SimpleUser {
    id
    firstName
    lastName
    preferredName
    role {
      id
      code
    }
  }
`;
export const CoreConversationFieldsFragmentDoc = gql`
  fragment CoreConversationFields on Conversation {
    id
    subject
    readByPatient
    readByCurrentUser
    confidential
    status
    resolvedAt
    resolvedBy {
      id
      firstName
      lastName
    }
    lastMessageSentAt
    lastMessage {
      createdAt
      sender {
        ...CoreParticipantFields
      }
      recipient {
        ...CoreParticipantFields
      }
    }
    lastReadByPatientAt
    brand {
      ...BrandFields
    }
    status
  }
  ${CoreParticipantFieldsFragmentDoc}
  ${BrandFieldsFragmentDoc}
`;
export const CoreMessageFieldsFragmentDoc = gql`
  fragment CoreMessageFields on Message {
    id
    subject
    content
    unreadCount
    createdAt
    deletedAt
    attachments {
      id
      filename
      downloadUrl
      deleteOperation {
        canPerform
        invalidReasons
      }
    }
    deleteOperation {
      canPerform
      invalidReasons
    }
    updateOperation {
      canPerform
      invalidReasons
    }
    sender {
      ...CoreParticipantFields
    }
    recipient {
      ...CoreParticipantFields
    }
    readReceipts {
      readBy {
        id
      }
    }
    updatedAt
  }
  ${CoreParticipantFieldsFragmentDoc}
`;
export const AttachmentFragmentDoc = gql`
  fragment Attachment on Attachment {
    id
    downloadUrl
    filename
  }
`;
export const LabRequisitionFragmentDoc = gql`
  fragment LabRequisition on LabRequisition {
    id
    readByPatientAt
    requisitionType
    sentAt
    notes
    createdAt
    formLink
    attachments {
      ...Attachment
    }
    sentBy {
      id
      firstName
      lastName
    }
    feedbackStep {
      id
    }
    updateOperation {
      canPerform
      invalidReasons
    }
    deleteOperation {
      canPerform
      invalidReasons
    }
  }
  ${AttachmentFragmentDoc}
`;
export const LabRequisitionConnectionFragmentDoc = gql`
  fragment LabRequisitionConnection on LabRequisitionConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...LabRequisition
      }
    }
  }
  ${LabRequisitionFragmentDoc}
`;
export const MedicalHistoryVersionFragmentDoc = gql`
  fragment MedicalHistoryVersion on MedicalHistoryVersion {
    allergies
    medications
    medicalConditions
    medInfoConfirmedAt
  }
`;
export const PlatformSettingsFragmentDoc = gql`
  fragment PlatformSettings on PlatformSettings {
    cancellationThresholdHours
    rescheduleThresholdHours
  }
`;
export const PrescriptionFragmentDoc = gql`
  fragment Prescription on Prescription {
    id
    createdAt
    dueAt
    readByPatientAt
    fulfillmentMethod
    prescriptionType
    prescriptionLength
    prepType
    medication
    treatmentStartAt
    prescribedDate
    bridging
    dosage
    dosingRegimen
    numOfPills
    additionalInfo
    sentAt
    updateOperation {
      canPerform
      invalidReasons
    }
    deleteOperation {
      canPerform
      invalidReasons
    }
    brand {
      id
      name
    }
    sentBy {
      id
      firstName
      lastName
    }
    message {
      id
      conversationId
    }
  }
`;
export const QuestionnaireFragmentDoc = gql`
  fragment Questionnaire on Questionnaire {
    id
    results
  }
`;
export const RoleFragmentDoc = gql`
  fragment Role on Role {
    id
    code
    name
    displayName
    createdAt
    updatedAt
  }
`;
export const ProvinceFragmentDoc = gql`
  fragment Province on ProvinceState {
    id
    name
    code
    supported
  }
`;
export const FreddiePatientConsultFragmentDoc = gql`
  fragment FreddiePatientConsult on Consult {
    id
    attended
    initialConsult
    nextStep
    otherNextStep
    expectedLabDate
    financialExpectation
    notes
    updatedAt
    prepStatus
    prepStatusReason
    prepOnDemand
    labReqNextStep
    prepRxNextStep
    otherRxNextStep
    bridgingNeeded
    additionalInfo
    prepTypeLikelyToPrescribe
    consultCompletedStatusReason
    consultNotCompletedDescription
  }
`;
export const AppointmentNodeFragmentDoc = gql`
  fragment AppointmentNode on Appointment {
    id
    durationInMinutes
    scheduledAt
    reasonForAppointment
    bookingReason
    bookingReasonDescription
    calendar {
      id
      province {
        id
        name
        code
      }
      clinician {
        id
      }
    }
    patient {
      id
      firstName
      lastName
      locale
      emrId
      phoneNumber
      phone {
        rawNumber
        e164
      }
      preferredName
      voicemailConsent
      profile {
        dateOfBirth
        financialExpectation
        dateVerified
        verificationStatus
        healthCareNumber
        province {
          ...Province
        }
        healthCareProvince {
          ...Province
        }
      }
      questionnaires {
        id
        results
      }
    }
    brand {
      id
    }
    consult {
      ...FreddiePatientConsult
    }
    workflowSteps {
      id
      type
      children {
        id
        type
        completedAt
        completionParams
      }
    }
    postConsultWorkflowStep {
      id
      type
      completedAt
    }
  }
  ${ProvinceFragmentDoc}
  ${FreddiePatientConsultFragmentDoc}
`;
export const MiniAppointmentNodeFragmentDoc = gql`
  fragment MiniAppointmentNode on Appointment {
    id
    appointmentTypeName
    durationInMinutes
    scheduledAt
    videoEnabled
    calendar {
      id
      province {
        code
      }
      clinician {
        id
      }
    }
    patient {
      id
      firstName
      lastName
    }
    brand {
      id
    }
    consult {
      id
    }
    postConsultWorkflowStep {
      id
      completedAt
    }
  }
`;
export const PatientAppointmentPartsFragmentDoc = gql`
  fragment PatientAppointmentParts on AcuityAppointment {
    id
    appointmentTypeId
    acuityAppointmentId
    appointmentType {
      lateReschedulePrice {
        id
        amountInCents
      }
    }
    brand {
      id
      name
    }
    cancelOperation {
      canPerform
    }
    rescheduleOperation {
      canPerform
    }
    durationInMinutes
    lateReschedulePrice {
      id
      amountInCents
    }
    lateCancellationPrice {
      id
      amountInCents
    }
    location
    reasonForAppointment
    scheduledAt
    videoEnabled
  }
`;
export const BookingSessionFragmentDoc = gql`
  fragment BookingSession on BookingSession {
    id
    availableDates
    coupon {
      name
      description
    }
    appointmentType {
      durationInMinutes
      name
      price {
        amountInCents
      }
      availableContactTypes
      contactTypeSelectionEnabled
    }
    appointment {
      ...PatientAppointmentParts
    }
  }
  ${PatientAppointmentPartsFragmentDoc}
`;
export const AssessmentScalePartsFragmentDoc = gql`
  fragment AssessmentScaleParts on Assessment {
    id
    type
    completedAt
    patientScore
    maxPossibleScore
    score
    result {
      sections {
        answers {
          question
          response
          score
        }
        name
        score
        skipped
      }
      totalScore
    }
  }
`;
export const PostConsultWorkflowStepFragmentDoc = gql`
  fragment PostConsultWorkflowStep on WorkflowStep {
    id
    dueAt
    user {
      id
      firstName
      lastName
      email
      profile {
        id
        financialExpectation
        province {
          name
        }
      }
    }
    subject {
      ... on AcuityAppointment {
        id
        scheduledAt
        consult {
          id
          attended
          nextStep
          otherNextStep
          initialConsult
          notes
          additionalInfo
          consultNotCompletedDescription
          labReqNextStep
          prepRxNextStep
          otherRxNextStep
        }
        calendar {
          clinician {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const CoreLabRequisitionFragmentDoc = gql`
  fragment CoreLabRequisition on LabRequisition {
    id
    createdAt
    requisitionType
  }
`;
export const LabRequisitionWorkflowStepFragmentDoc = gql`
  fragment LabRequisitionWorkflowStep on WorkflowStep {
    id
    parentStep {
      id
    }
    dueAt
    user {
      id
      firstName
      lastName
      email
      profile {
        id
        dateOfBirth
      }
      prescriptions {
        nodes {
          createdAt
        }
      }
    }
    subject {
      ...CoreLabRequisition
    }
  }
  ${CoreLabRequisitionFragmentDoc}
`;
export const LabRequisitionWorflowStepFragmentDoc = gql`
  fragment LabRequisitionWorflowStep on WorkflowStep {
    id
    parentStep {
      id
    }
    dueAt
    user {
      id
      firstName
      lastName
      email
      profile {
        id
        dateOfBirth
      }
      prescriptions {
        nodes {
          createdAt
        }
      }
    }
    subject {
      ...CoreLabRequisition
    }
  }
  ${CoreLabRequisitionFragmentDoc}
`;
export const LabFeedbackWorkflowStepFragmentDoc = gql`
  fragment LabFeedbackWorkflowStep on WorkflowStep {
    id
    completionParams
    createdAt
    completedAt
    parentStep {
      id
      createdAt
      completedAt
      completionParams
    }
    user {
      id
      firstName
      lastName
      email
      profile {
        id
        dateOfBirth
      }
    }
  }
`;
export const MedicationRequestFragmentDoc = gql`
  fragment MedicationRequest on FHIRMedicationRequest {
    id
    status
    intent
    medication
    authoredOn
    quantity
  }
`;
export const QuestionnaireAcceptableAnswerFragmentDoc = gql`
  fragment QuestionnaireAcceptableAnswer on QuestionnaireAcceptableAnswer {
    label
    value
  }
`;
export const QuestionnaireQuestionFragmentDoc = gql`
  fragment QuestionnaireQuestion on QuestionnaireQuestion {
    id
    name
    title
    description
    resources {
      ... on QuestionnaireQuestionResource {
        value
        type
        label
        title
      }
    }
    ... on QuestionnaireCheckboxQuestion {
      acceptableAnswers {
        ...QuestionnaireAcceptableAnswer
      }
      minSelections
    }
    ... on QuestionnaireRadioQuestion {
      acceptableAnswers {
        ...QuestionnaireAcceptableAnswer
      }
    }
    ... on QuestionnaireTextInputQuestion {
      maxLength
      minLength
    }
    ... on QuestionnaireTextAreaQuestion {
      maxLength
      minLength
      supportingText
    }
    ... on QuestionnaireDateInputQuestion {
      allowsFutureDates
      allowsPastDates
      required
    }
    ... on QuestionnaireNumberInputQuestion {
      allowsDecimals
      max
      min
      required
    }
  }
  ${QuestionnaireAcceptableAnswerFragmentDoc}
`;
export const QuestionnaireResponsePartsFragmentDoc = gql`
  fragment QuestionnaireResponseParts on QuestionnaireResponse {
    id
    createdAt
    completedAt
    canceledAt
    definition {
      id
      name
      title
      description
    }
    answers {
      id
      question {
        ...QuestionnaireQuestion
      }
      values
    }
  }
  ${QuestionnaireQuestionFragmentDoc}
`;
export const PaymentMethodPartsFragmentDoc = gql`
  fragment PaymentMethodParts on PaymentMethod {
    id
    isDefault
    isExpired
    externalId
    paymentMethodType
    brand
    expMonth
    expYear
    last4
  }
`;
export const PaymentTransactionPartsFragmentDoc = gql`
  fragment PaymentTransactionParts on PaymentTransaction {
    id
    externalId
    status
    chargeType
    paymentMethod {
      ...PaymentMethodParts
    }
  }
  ${PaymentMethodPartsFragmentDoc}
`;
export const PrescriptionWorkflowStepFragmentDoc = gql`
  fragment PrescriptionWorkflowStep on WorkflowStep {
    id
    dueAt
    user {
      id
      firstName
      lastName
      email
      profile {
        id
        dateOfBirth
      }
    }
    subject {
      ... on Prescription {
        id
        createdAt
        prescriptionType
        prescribedDate
        prescriptionLength
      }
    }
  }
`;
export const AppointmentFragmentDoc = gql`
  fragment Appointment on Appointment {
    id
    acuityAppointmentId
    appointmentTypeId
    calendarName
    calendarId
    scheduledAt
    durationInMinutes
    calendar {
      clinician {
        firstName
        lastName
      }
      province {
        name
      }
    }
    consult {
      id
      attended
      nextStep
      otherNextStep
    }
    bookingReason
    location
    videoEnabled
    cancelOperation {
      canPerform
    }
    rescheduleOperation {
      canPerform
    }
  }
`;
export const PatientWorkflowStepFragmentDoc = gql`
  fragment PatientWorkflowStep on WorkflowStep {
    id
    completedAt
    dueAt
    createdAt
    type
    completionParams
    subject {
      ... on AcuityAppointment {
        id
        scheduledAt
        consult {
          id
          attended
          nextStep
          otherNextStep
        }
      }
      ... on LabRequisition {
        id
        attachments {
          ...Attachment
        }
      }
      ... on Prescription {
        id
        prescriptionLength
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export const ShippingAddressFragmentDoc = gql`
  fragment ShippingAddress on ShippingAddress {
    id
    recipientName
    addressType
    street
    street2
    city
    province
    postalCode
    deliveryInstructions
    isDefaultAddress
  }
`;
export const QuestionnaireAnswerFragmentDoc = gql`
  fragment QuestionnaireAnswer on QuestionnaireAnswer {
    id
    question {
      id
    }
    values
  }
`;
export const QuestionnaireActionFragmentDoc = gql`
  fragment QuestionnaireAction on QuestionnaireAction {
    label
    to
    variant
  }
`;
export const QuestionnairePageFragmentDoc = gql`
  fragment QuestionnairePage on QuestionnairePage {
    id
    name
    title
    description
    questions {
      ...QuestionnaireQuestion
    }
    actions {
      ...QuestionnaireAction
    }
  }
  ${QuestionnaireQuestionFragmentDoc}
  ${QuestionnaireActionFragmentDoc}
`;
export const QuestionnaireOutcomeFragmentDoc = gql`
  fragment QuestionnaireOutcome on QuestionnaireOutcome {
    id
    name
    title
    description
    redirectTo
    actions {
      ...QuestionnaireAction
    }
  }
  ${QuestionnaireActionFragmentDoc}
`;
export const QuestionnaireResponseFragmentDoc = gql`
  fragment QuestionnaireResponse on QuestionnaireResponse {
    answers {
      ...QuestionnaireAnswer
    }
    completed
    currentStep {
      __typename
      ...QuestionnairePage
      ...QuestionnaireOutcome
    }
    definition {
      description
      id
      name
      title
    }
    id
    canGoBack
  }
  ${QuestionnaireAnswerFragmentDoc}
  ${QuestionnairePageFragmentDoc}
  ${QuestionnaireOutcomeFragmentDoc}
`;
export const TreatmentFragmentDoc = gql`
  fragment Treatment on Treatment {
    id
    type
    medicationStatus
    createdAt
    updatedAt
    user {
      id
    }
    stoppedBy {
      id
      firstName
      lastName
    }
    stoppedAt
    stoppedReason
    eligibility
  }
`;
export const TreatmentEligibilityFragmentDoc = gql`
  fragment TreatmentEligibility on TreatmentEligibility {
    id
    eligibility
    reason
    userId
    treatmentType
    treatmentId
    createdAt
    updatedAt
  }
`;
export const CredentialFragmentDoc = gql`
  fragment Credential on Credential {
    id
    province {
      code
      id
    }
    designation
    registrationNumber
  }
`;
export const AcuityAccountFieldsFragmentDoc = gql`
  fragment AcuityAccountFields on AcuityAccount {
    id
    email
    brand {
      ...BrandFields
    }
  }
  ${BrandFieldsFragmentDoc}
`;
export const AcuityCalendarFragmentDoc = gql`
  fragment AcuityCalendar on AcuityCalendar {
    id
    province {
      ...Province
    }
    acuityCalendarId
    acuityAccount {
      ...AcuityAccountFields
    }
  }
  ${ProvinceFragmentDoc}
  ${AcuityAccountFieldsFragmentDoc}
`;
export const ProviderUserFragmentDoc = gql`
  fragment ProviderUser on User {
    id
    email
    firstName
    lastName
    deactivatedAt
    role {
      id
      name
      displayName
      code
    }
    credentials {
      ...Credential
    }
    acuityCalendars {
      ...AcuityCalendar
    }
  }
  ${CredentialFragmentDoc}
  ${AcuityCalendarFragmentDoc}
`;
export const PatientQueryUserFragmentDoc = gql`
  fragment PatientQueryUser on User {
    id
    email
    firstName
    lastName
    preferredName
    phone {
      e164
      rawNumber
    }
    createdAt
    deactivatedAt
    emrId
    locale
    smsEnabled
    voicemailConsent
    brands {
      id
      name
      brand {
        id
      }
      createdAt
    }
    profile {
      id
      province {
        ...Province
      }
      healthCareProvince {
        ...Province
      }
      dateOfBirth
      healthCareNumber
      financialExpectation
    }
  }
  ${ProvinceFragmentDoc}
`;
export const FreddieQuestionnairesFragmentDoc = gql`
  fragment FreddieQuestionnaires on Questionnaire {
    id
    createdAt
    updatedAt
    submitted
    results
  }
`;
export const CoreShortQuestionnaireFragmentDoc = gql`
  fragment CoreShortQuestionnaire on ShortQuestionnaire {
    id
    createdAt
    results
  }
`;
export const CycleWorkflowStepFragmentDoc = gql`
  fragment CycleWorkflowStep on WorkflowStep {
    id
    createdAt
    completedAt
    type
    user {
      id
    }
    parentStep {
      id
      completedAt
      completionParams
      workflowStepRelations {
        id
        workflowable {
          ... on Prescription {
            id
            createdAt
            dueAt
            prescriptionType
            prescribedDate
            prescriptionLength
            bridging
            prepType
          }
        }
      }
    }
    subject {
      ... on Prescription {
        id
        createdAt
        dueAt
        prescriptionType
        prescribedDate
        prescriptionLength
        bridging
        prepType
      }
      ... on LabRequisition {
        id
        requisitionType
        notes
        readByPatientAt
        sentAt
      }
      ...CoreShortQuestionnaire
    }
  }
  ${CoreShortQuestionnaireFragmentDoc}
`;
export const CycleAcuityAppointmentFragmentDoc = gql`
  fragment CycleAcuityAppointment on AcuityAppointment {
    id
    createdAt
    scheduledAt
    updatedAt
    durationInMinutes
    calendar {
      id
      clinician {
        id
        firstName
        lastName
      }
    }
    consult {
      id
      bridgingNeeded
      prepRxNextStep
      labReqNextStep
      otherRxNextStep
      additionalInfo
      nextStep
      notes
    }
    patient {
      id
    }
  }
`;
export const PatientBrandMembershipFragmentDoc = gql`
  fragment PatientBrandMembership on BrandMembership {
    id
    name
    brand {
      id
    }
    createdAt
    initialPrice {
      ... on InitialPrice {
        id
        name
        amountInCents
        stripePriceId
        effectiveStartDate
        effectiveEndDate
        brandId
      }
    }
    initialPaymentReceivedAt
  }
`;
export const ClinicalTrialFragmentDoc = gql`
  fragment ClinicalTrial on ClinicalTrial {
    id
    name
    title
    active
  }
`;
export const PatientAcuityAppointmentInfoFragmentDoc = gql`
  fragment PatientAcuityAppointmentInfo on AcuityAppointment {
    id
    acuityAppointmentId
    appointmentTypeId
    appointmentTypeName
    scheduledAt
    durationInMinutes
    forms
    reasonForAppointment
    bookingReasonDescription
    location
    videoEnabled
    brand {
      id
    }
    calendar {
      id
      clinician {
        id
        firstName
        lastName
      }
    }
    consult {
      ...FreddiePatientConsult
    }
    patient {
      id
      profile {
        id
        financialExpectation
      }
    }
    postConsultWorkflowStep {
      ... on WorkflowStep {
        id
        completedAt
      }
    }
  }
  ${FreddiePatientConsultFragmentDoc}
`;
export const PatientAppointmentInfoFragmentDoc = gql`
  fragment PatientAppointmentInfo on Appointment {
    id
    acuityAppointmentId
    appointmentTypeId
    appointmentTypeName
    scheduledAt
    durationInMinutes
    forms
    reasonForAppointment
    bookingReasonDescription
    location
    videoEnabled
    brand {
      id
    }
    calendar {
      id
      clinician {
        id
        firstName
        lastName
      }
    }
    consult {
      ...FreddiePatientConsult
    }
    patient {
      id
      profile {
        id
        financialExpectation
      }
    }
    postConsultWorkflowStep {
      ... on WorkflowStep {
        id
        completedAt
      }
    }
  }
  ${FreddiePatientConsultFragmentDoc}
`;
export const MyBloodPressureFragmentDoc = gql`
  fragment MyBloodPressure on BloodPressure {
    id
    __typename
    type
    measuredAt
    systolic
    diastolic
  }
`;
export const WorkflowStepBasicDataFragmentDoc = gql`
  fragment WorkflowStepBasicData on WorkflowStep {
    id
    completionParams
    completedAt
    canceledAt
  }
`;
export const CoreUserFieldsFragmentDoc = gql`
  fragment CoreUserFields on SimpleUser {
    id
    firstName
    lastName
    email
    profile {
      id
      financialExpectation
    }
  }
`;
export const CoreWorkflowStepFieldsFragmentDoc = gql`
  fragment CoreWorkflowStepFields on WorkflowStep {
    id
    type
    dueAt
    completedAt
    createdAt
    updatedAt
    completionParams
    user {
      ...CoreUserFields
    }
    subject {
      ...CoreShortQuestionnaire
      ... on Prescription {
        id
        prescriptionLength
        prepType
        message {
          id
          conversationId
        }
      }
      ... on LabRequisition {
        id
        requisitionType
        attachments {
          id
          downloadUrl
        }
        message {
          id
          conversationId
        }
        readByPatientAt
      }
      ... on AcuityAppointment {
        scheduledAt
        id
      }
    }
  }
  ${CoreUserFieldsFragmentDoc}
  ${CoreShortQuestionnaireFragmentDoc}
`;
export const WorkflowStepPrescriptionResponseFragmentDoc = gql`
  fragment WorkflowStepPrescriptionResponse on Prescription {
    id
    medication
    numOfPills
    dosage
    prescriptionType
    fulfillmentMethod
    isBpMonitorNeeded
    additionalInfo
  }
`;
export const InitiateRefillDocument = gql`
  mutation InitiateRefill($input: InitiateRefillInput!) {
    initiateRefill(input: $input) {
      errors
    }
  }
`;
export type InitiateRefillMutationFn = Apollo.MutationFunction<
  InitiateRefillMutation,
  InitiateRefillMutationVariables
>;

/**
 * __useInitiateRefillMutation__
 *
 * To run a mutation, you first call `useInitiateRefillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateRefillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateRefillMutation, { data, loading, error }] = useInitiateRefillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateRefillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateRefillMutation,
    InitiateRefillMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateRefillMutation,
    InitiateRefillMutationVariables
  >(InitiateRefillDocument, options);
}
export type InitiateRefillMutationHookResult = ReturnType<
  typeof useInitiateRefillMutation
>;
export type InitiateRefillMutationResult =
  Apollo.MutationResult<InitiateRefillMutation>;
export type InitiateRefillMutationOptions = Apollo.BaseMutationOptions<
  InitiateRefillMutation,
  InitiateRefillMutationVariables
>;
export const OnboardAdditionalTreatmentDocument = gql`
  mutation OnboardAdditionalTreatment(
    $input: OnboardAdditionalTreatmentInput!
  ) {
    onboardAdditionalTreatment(input: $input) {
      errors
      questionnaireStepId
    }
  }
`;
export type OnboardAdditionalTreatmentMutationFn = Apollo.MutationFunction<
  OnboardAdditionalTreatmentMutation,
  OnboardAdditionalTreatmentMutationVariables
>;

/**
 * __useOnboardAdditionalTreatmentMutation__
 *
 * To run a mutation, you first call `useOnboardAdditionalTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardAdditionalTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardAdditionalTreatmentMutation, { data, loading, error }] = useOnboardAdditionalTreatmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardAdditionalTreatmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardAdditionalTreatmentMutation,
    OnboardAdditionalTreatmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OnboardAdditionalTreatmentMutation,
    OnboardAdditionalTreatmentMutationVariables
  >(OnboardAdditionalTreatmentDocument, options);
}
export type OnboardAdditionalTreatmentMutationHookResult = ReturnType<
  typeof useOnboardAdditionalTreatmentMutation
>;
export type OnboardAdditionalTreatmentMutationResult =
  Apollo.MutationResult<OnboardAdditionalTreatmentMutation>;
export type OnboardAdditionalTreatmentMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardAdditionalTreatmentMutation,
    OnboardAdditionalTreatmentMutationVariables
  >;
export const ReferralProgramRegisterDocument = gql`
  mutation ReferralProgramRegister($input: ReferralProgramRegisterInput!) {
    referralProgramRegister(input: $input) {
      referralCode
      errors
    }
  }
`;
export type ReferralProgramRegisterMutationFn = Apollo.MutationFunction<
  ReferralProgramRegisterMutation,
  ReferralProgramRegisterMutationVariables
>;

/**
 * __useReferralProgramRegisterMutation__
 *
 * To run a mutation, you first call `useReferralProgramRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReferralProgramRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [referralProgramRegisterMutation, { data, loading, error }] = useReferralProgramRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReferralProgramRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReferralProgramRegisterMutation,
    ReferralProgramRegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReferralProgramRegisterMutation,
    ReferralProgramRegisterMutationVariables
  >(ReferralProgramRegisterDocument, options);
}
export type ReferralProgramRegisterMutationHookResult = ReturnType<
  typeof useReferralProgramRegisterMutation
>;
export type ReferralProgramRegisterMutationResult =
  Apollo.MutationResult<ReferralProgramRegisterMutation>;
export type ReferralProgramRegisterMutationOptions = Apollo.BaseMutationOptions<
  ReferralProgramRegisterMutation,
  ReferralProgramRegisterMutationVariables
>;
export const ResetTreatmentAndQuestionnaireDocument = gql`
  mutation resetTreatmentAndQuestionnaire(
    $input: ResetTreatmentAndQuestionnaireInput!
  ) {
    resetTreatmentAndQuestionnaire(input: $input) {
      response {
        id
      }
      errors
    }
  }
`;
export type ResetTreatmentAndQuestionnaireMutationFn = Apollo.MutationFunction<
  ResetTreatmentAndQuestionnaireMutation,
  ResetTreatmentAndQuestionnaireMutationVariables
>;

/**
 * __useResetTreatmentAndQuestionnaireMutation__
 *
 * To run a mutation, you first call `useResetTreatmentAndQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTreatmentAndQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTreatmentAndQuestionnaireMutation, { data, loading, error }] = useResetTreatmentAndQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetTreatmentAndQuestionnaireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetTreatmentAndQuestionnaireMutation,
    ResetTreatmentAndQuestionnaireMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetTreatmentAndQuestionnaireMutation,
    ResetTreatmentAndQuestionnaireMutationVariables
  >(ResetTreatmentAndQuestionnaireDocument, options);
}
export type ResetTreatmentAndQuestionnaireMutationHookResult = ReturnType<
  typeof useResetTreatmentAndQuestionnaireMutation
>;
export type ResetTreatmentAndQuestionnaireMutationResult =
  Apollo.MutationResult<ResetTreatmentAndQuestionnaireMutation>;
export type ResetTreatmentAndQuestionnaireMutationOptions =
  Apollo.BaseMutationOptions<
    ResetTreatmentAndQuestionnaireMutation,
    ResetTreatmentAndQuestionnaireMutationVariables
  >;
export const StartPatientRefillDocument = gql`
  mutation StartPatientRefill($input: StartPatientRefillInput!) {
    startPatientRefill(input: $input) {
      errors
      questionnaireStepId
    }
  }
`;
export type StartPatientRefillMutationFn = Apollo.MutationFunction<
  StartPatientRefillMutation,
  StartPatientRefillMutationVariables
>;

/**
 * __useStartPatientRefillMutation__
 *
 * To run a mutation, you first call `useStartPatientRefillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPatientRefillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPatientRefillMutation, { data, loading, error }] = useStartPatientRefillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPatientRefillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartPatientRefillMutation,
    StartPatientRefillMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartPatientRefillMutation,
    StartPatientRefillMutationVariables
  >(StartPatientRefillDocument, options);
}
export type StartPatientRefillMutationHookResult = ReturnType<
  typeof useStartPatientRefillMutation
>;
export type StartPatientRefillMutationResult =
  Apollo.MutationResult<StartPatientRefillMutation>;
export type StartPatientRefillMutationOptions = Apollo.BaseMutationOptions<
  StartPatientRefillMutation,
  StartPatientRefillMutationVariables
>;
export const GetAcuityAccountsDocument = gql`
  query GetAcuityAccounts {
    acuityAccounts {
      ...AcuityAccountFields
    }
  }
  ${AcuityAccountFieldsFragmentDoc}
`;

/**
 * __useGetAcuityAccountsQuery__
 *
 * To run a query within a React component, call `useGetAcuityAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcuityAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcuityAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcuityAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAcuityAccountsQuery,
    GetAcuityAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAcuityAccountsQuery,
    GetAcuityAccountsQueryVariables
  >(GetAcuityAccountsDocument, options);
}
export function useGetAcuityAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAcuityAccountsQuery,
    GetAcuityAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAcuityAccountsQuery,
    GetAcuityAccountsQueryVariables
  >(GetAcuityAccountsDocument, options);
}
export function useGetAcuityAccountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAcuityAccountsQuery,
        GetAcuityAccountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAcuityAccountsQuery,
    GetAcuityAccountsQueryVariables
  >(GetAcuityAccountsDocument, options);
}
export type GetAcuityAccountsQueryHookResult = ReturnType<
  typeof useGetAcuityAccountsQuery
>;
export type GetAcuityAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAcuityAccountsLazyQuery
>;
export type GetAcuityAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetAcuityAccountsSuspenseQuery
>;
export type GetAcuityAccountsQueryResult = Apollo.QueryResult<
  GetAcuityAccountsQuery,
  GetAcuityAccountsQueryVariables
>;
export const GetAcuityAppointmentTypesDocument = gql`
  query GetAcuityAppointmentTypes {
    acuityAppointmentTypes {
      ...AcuityAppointmentType
    }
  }
  ${AcuityAppointmentTypeFragmentDoc}
`;

/**
 * __useGetAcuityAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useGetAcuityAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcuityAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcuityAppointmentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcuityAppointmentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAcuityAppointmentTypesQuery,
    GetAcuityAppointmentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAcuityAppointmentTypesQuery,
    GetAcuityAppointmentTypesQueryVariables
  >(GetAcuityAppointmentTypesDocument, options);
}
export function useGetAcuityAppointmentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAcuityAppointmentTypesQuery,
    GetAcuityAppointmentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAcuityAppointmentTypesQuery,
    GetAcuityAppointmentTypesQueryVariables
  >(GetAcuityAppointmentTypesDocument, options);
}
export function useGetAcuityAppointmentTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAcuityAppointmentTypesQuery,
        GetAcuityAppointmentTypesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAcuityAppointmentTypesQuery,
    GetAcuityAppointmentTypesQueryVariables
  >(GetAcuityAppointmentTypesDocument, options);
}
export type GetAcuityAppointmentTypesQueryHookResult = ReturnType<
  typeof useGetAcuityAppointmentTypesQuery
>;
export type GetAcuityAppointmentTypesLazyQueryHookResult = ReturnType<
  typeof useGetAcuityAppointmentTypesLazyQuery
>;
export type GetAcuityAppointmentTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetAcuityAppointmentTypesSuspenseQuery
>;
export type GetAcuityAppointmentTypesQueryResult = Apollo.QueryResult<
  GetAcuityAppointmentTypesQuery,
  GetAcuityAppointmentTypesQueryVariables
>;
export const DeleteAcuityAppointmentTypeDocument = gql`
  mutation DeleteAcuityAppointmentType($id: ID!) {
    deleteAcuityAppointmentType(input: { acuityAppointmentTypeId: $id }) {
      errors
    }
  }
`;
export type DeleteAcuityAppointmentTypeMutationFn = Apollo.MutationFunction<
  DeleteAcuityAppointmentTypeMutation,
  DeleteAcuityAppointmentTypeMutationVariables
>;

/**
 * __useDeleteAcuityAppointmentTypeMutation__
 *
 * To run a mutation, you first call `useDeleteAcuityAppointmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAcuityAppointmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAcuityAppointmentTypeMutation, { data, loading, error }] = useDeleteAcuityAppointmentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAcuityAppointmentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAcuityAppointmentTypeMutation,
    DeleteAcuityAppointmentTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAcuityAppointmentTypeMutation,
    DeleteAcuityAppointmentTypeMutationVariables
  >(DeleteAcuityAppointmentTypeDocument, options);
}
export type DeleteAcuityAppointmentTypeMutationHookResult = ReturnType<
  typeof useDeleteAcuityAppointmentTypeMutation
>;
export type DeleteAcuityAppointmentTypeMutationResult =
  Apollo.MutationResult<DeleteAcuityAppointmentTypeMutation>;
export type DeleteAcuityAppointmentTypeMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAcuityAppointmentTypeMutation,
    DeleteAcuityAppointmentTypeMutationVariables
  >;
export const CreateAcuityAppointmentTypeDocument = gql`
  mutation CreateAcuityAppointmentType($input: CreateAcuityAppointmentInput!) {
    createAcuityAppointmentType(input: $input) {
      errors
    }
  }
`;
export type CreateAcuityAppointmentTypeMutationFn = Apollo.MutationFunction<
  CreateAcuityAppointmentTypeMutation,
  CreateAcuityAppointmentTypeMutationVariables
>;

/**
 * __useCreateAcuityAppointmentTypeMutation__
 *
 * To run a mutation, you first call `useCreateAcuityAppointmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAcuityAppointmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAcuityAppointmentTypeMutation, { data, loading, error }] = useCreateAcuityAppointmentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAcuityAppointmentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAcuityAppointmentTypeMutation,
    CreateAcuityAppointmentTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAcuityAppointmentTypeMutation,
    CreateAcuityAppointmentTypeMutationVariables
  >(CreateAcuityAppointmentTypeDocument, options);
}
export type CreateAcuityAppointmentTypeMutationHookResult = ReturnType<
  typeof useCreateAcuityAppointmentTypeMutation
>;
export type CreateAcuityAppointmentTypeMutationResult =
  Apollo.MutationResult<CreateAcuityAppointmentTypeMutation>;
export type CreateAcuityAppointmentTypeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAcuityAppointmentTypeMutation,
    CreateAcuityAppointmentTypeMutationVariables
  >;
export const UpdateAcuityAppointmentTypeDocument = gql`
  mutation UpdateAcuityAppointmentType($input: UpdateAcuityAppointmentInput!) {
    updateAcuityAppointmentType(input: $input) {
      errors
    }
  }
`;
export type UpdateAcuityAppointmentTypeMutationFn = Apollo.MutationFunction<
  UpdateAcuityAppointmentTypeMutation,
  UpdateAcuityAppointmentTypeMutationVariables
>;

/**
 * __useUpdateAcuityAppointmentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAcuityAppointmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAcuityAppointmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAcuityAppointmentTypeMutation, { data, loading, error }] = useUpdateAcuityAppointmentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAcuityAppointmentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAcuityAppointmentTypeMutation,
    UpdateAcuityAppointmentTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAcuityAppointmentTypeMutation,
    UpdateAcuityAppointmentTypeMutationVariables
  >(UpdateAcuityAppointmentTypeDocument, options);
}
export type UpdateAcuityAppointmentTypeMutationHookResult = ReturnType<
  typeof useUpdateAcuityAppointmentTypeMutation
>;
export type UpdateAcuityAppointmentTypeMutationResult =
  Apollo.MutationResult<UpdateAcuityAppointmentTypeMutation>;
export type UpdateAcuityAppointmentTypeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAcuityAppointmentTypeMutation,
    UpdateAcuityAppointmentTypeMutationVariables
  >;
export const GetCalendarsForUserDocument = gql`
  query GetCalendarsForUser($id: ID!) {
    user(id: $id) {
      email
      acuityCalendars {
        ...AcuityCalendar
      }
    }
  }
  ${AcuityCalendarFragmentDoc}
`;

/**
 * __useGetCalendarsForUserQuery__
 *
 * To run a query within a React component, call `useGetCalendarsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarsForUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCalendarsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarsForUserQuery,
    GetCalendarsForUserQueryVariables
  > &
    (
      | { variables: GetCalendarsForUserQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCalendarsForUserQuery,
    GetCalendarsForUserQueryVariables
  >(GetCalendarsForUserDocument, options);
}
export function useGetCalendarsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarsForUserQuery,
    GetCalendarsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarsForUserQuery,
    GetCalendarsForUserQueryVariables
  >(GetCalendarsForUserDocument, options);
}
export function useGetCalendarsForUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCalendarsForUserQuery,
        GetCalendarsForUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCalendarsForUserQuery,
    GetCalendarsForUserQueryVariables
  >(GetCalendarsForUserDocument, options);
}
export type GetCalendarsForUserQueryHookResult = ReturnType<
  typeof useGetCalendarsForUserQuery
>;
export type GetCalendarsForUserLazyQueryHookResult = ReturnType<
  typeof useGetCalendarsForUserLazyQuery
>;
export type GetCalendarsForUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCalendarsForUserSuspenseQuery
>;
export type GetCalendarsForUserQueryResult = Apollo.QueryResult<
  GetCalendarsForUserQuery,
  GetCalendarsForUserQueryVariables
>;
export const CreateCalendarDocument = gql`
  mutation CreateCalendar($input: CreateAcuityCalendarInput!) {
    createAcuityCalendar(input: $input) {
      errors
    }
  }
`;
export type CreateCalendarMutationFn = Apollo.MutationFunction<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarMutation, { data, loading, error }] = useCreateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarMutation,
    CreateCalendarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarMutation,
    CreateCalendarMutationVariables
  >(CreateCalendarDocument, options);
}
export type CreateCalendarMutationHookResult = ReturnType<
  typeof useCreateCalendarMutation
>;
export type CreateCalendarMutationResult =
  Apollo.MutationResult<CreateCalendarMutation>;
export type CreateCalendarMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;
export const DeleteCalendarDocument = gql`
  mutation DeleteCalendar($id: ID!) {
    deleteAcuityCalendar(input: { acuityCalendarId: $id }) {
      errors
    }
  }
`;
export type DeleteCalendarMutationFn = Apollo.MutationFunction<
  DeleteCalendarMutation,
  DeleteCalendarMutationVariables
>;

/**
 * __useDeleteCalendarMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarMutation, { data, loading, error }] = useDeleteCalendarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCalendarMutation,
    DeleteCalendarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCalendarMutation,
    DeleteCalendarMutationVariables
  >(DeleteCalendarDocument, options);
}
export type DeleteCalendarMutationHookResult = ReturnType<
  typeof useDeleteCalendarMutation
>;
export type DeleteCalendarMutationResult =
  Apollo.MutationResult<DeleteCalendarMutation>;
export type DeleteCalendarMutationOptions = Apollo.BaseMutationOptions<
  DeleteCalendarMutation,
  DeleteCalendarMutationVariables
>;
export const UpdateCalendarDocument = gql`
  mutation UpdateCalendar($input: UpdateAcuityCalendarInput!) {
    updateAcuityCalendar(input: $input) {
      errors
    }
  }
`;
export type UpdateCalendarMutationFn = Apollo.MutationFunction<
  UpdateCalendarMutation,
  UpdateCalendarMutationVariables
>;

/**
 * __useUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarMutation, { data, loading, error }] = useUpdateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarMutation,
    UpdateCalendarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarMutation,
    UpdateCalendarMutationVariables
  >(UpdateCalendarDocument, options);
}
export type UpdateCalendarMutationHookResult = ReturnType<
  typeof useUpdateCalendarMutation
>;
export type UpdateCalendarMutationResult =
  Apollo.MutationResult<UpdateCalendarMutation>;
export type UpdateCalendarMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarMutation,
  UpdateCalendarMutationVariables
>;
export const PendingAgreementsDocument = gql`
  query PendingAgreements {
    me {
      id
      pendingAgreements {
        __typename
        ...AgreementClientParts
        ...AgreementPrivacyParts
        ...AgreementTermsOfUseParts
        ...AgreementFreddieAsyncParts
        ...AgreementFreddieDoxyPepAsyncParts
      }
    }
  }
  ${AgreementClientPartsFragmentDoc}
  ${AgreementPrivacyPartsFragmentDoc}
  ${AgreementTermsOfUsePartsFragmentDoc}
  ${AgreementFreddieAsyncPartsFragmentDoc}
  ${AgreementFreddieDoxyPepAsyncPartsFragmentDoc}
`;

/**
 * __usePendingAgreementsQuery__
 *
 * To run a query within a React component, call `usePendingAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingAgreementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PendingAgreementsQuery,
    PendingAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PendingAgreementsQuery,
    PendingAgreementsQueryVariables
  >(PendingAgreementsDocument, options);
}
export function usePendingAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingAgreementsQuery,
    PendingAgreementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingAgreementsQuery,
    PendingAgreementsQueryVariables
  >(PendingAgreementsDocument, options);
}
export function usePendingAgreementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PendingAgreementsQuery,
        PendingAgreementsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PendingAgreementsQuery,
    PendingAgreementsQueryVariables
  >(PendingAgreementsDocument, options);
}
export type PendingAgreementsQueryHookResult = ReturnType<
  typeof usePendingAgreementsQuery
>;
export type PendingAgreementsLazyQueryHookResult = ReturnType<
  typeof usePendingAgreementsLazyQuery
>;
export type PendingAgreementsSuspenseQueryHookResult = ReturnType<
  typeof usePendingAgreementsSuspenseQuery
>;
export type PendingAgreementsQueryResult = Apollo.QueryResult<
  PendingAgreementsQuery,
  PendingAgreementsQueryVariables
>;
export const CreatePatientAgreementsDocument = gql`
  mutation CreatePatientAgreements($input: CreatePatientAgreementsInput!) {
    createPatientAgreements(input: $input) {
      errors
    }
  }
`;
export type CreatePatientAgreementsMutationFn = Apollo.MutationFunction<
  CreatePatientAgreementsMutation,
  CreatePatientAgreementsMutationVariables
>;

/**
 * __useCreatePatientAgreementsMutation__
 *
 * To run a mutation, you first call `useCreatePatientAgreementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientAgreementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientAgreementsMutation, { data, loading, error }] = useCreatePatientAgreementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientAgreementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePatientAgreementsMutation,
    CreatePatientAgreementsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePatientAgreementsMutation,
    CreatePatientAgreementsMutationVariables
  >(CreatePatientAgreementsDocument, options);
}
export type CreatePatientAgreementsMutationHookResult = ReturnType<
  typeof useCreatePatientAgreementsMutation
>;
export type CreatePatientAgreementsMutationResult =
  Apollo.MutationResult<CreatePatientAgreementsMutation>;
export type CreatePatientAgreementsMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientAgreementsMutation,
  CreatePatientAgreementsMutationVariables
>;
export const GetAvailableAppointmentDatesDocument = gql`
  query GetAvailableAppointmentDates(
    $date: ISO8601Date
    $province: Province!
    $bookingReason: String
    $workflowStepId: ID
    $appointmentTypeId: ID
    $numberOfMonths: Int
  ) {
    availableAppointmentDates(
      numberOfMonths: $numberOfMonths
      filter: {
        appointmentTypeId: $appointmentTypeId
        bookingReason: $bookingReason
        workflowStepId: $workflowStepId
        date: $date
        province: $province
      }
    ) {
      date
      times {
        slotsAvailable
        time
        providerId
      }
    }
  }
`;

/**
 * __useGetAvailableAppointmentDatesQuery__
 *
 * To run a query within a React component, call `useGetAvailableAppointmentDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableAppointmentDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableAppointmentDatesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      province: // value for 'province'
 *      bookingReason: // value for 'bookingReason'
 *      workflowStepId: // value for 'workflowStepId'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *      numberOfMonths: // value for 'numberOfMonths'
 *   },
 * });
 */
export function useGetAvailableAppointmentDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableAppointmentDatesQuery,
    GetAvailableAppointmentDatesQueryVariables
  > &
    (
      | {
          variables: GetAvailableAppointmentDatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableAppointmentDatesQuery,
    GetAvailableAppointmentDatesQueryVariables
  >(GetAvailableAppointmentDatesDocument, options);
}
export function useGetAvailableAppointmentDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableAppointmentDatesQuery,
    GetAvailableAppointmentDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableAppointmentDatesQuery,
    GetAvailableAppointmentDatesQueryVariables
  >(GetAvailableAppointmentDatesDocument, options);
}
export function useGetAvailableAppointmentDatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAvailableAppointmentDatesQuery,
        GetAvailableAppointmentDatesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAvailableAppointmentDatesQuery,
    GetAvailableAppointmentDatesQueryVariables
  >(GetAvailableAppointmentDatesDocument, options);
}
export type GetAvailableAppointmentDatesQueryHookResult = ReturnType<
  typeof useGetAvailableAppointmentDatesQuery
>;
export type GetAvailableAppointmentDatesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableAppointmentDatesLazyQuery
>;
export type GetAvailableAppointmentDatesSuspenseQueryHookResult = ReturnType<
  typeof useGetAvailableAppointmentDatesSuspenseQuery
>;
export type GetAvailableAppointmentDatesQueryResult = Apollo.QueryResult<
  GetAvailableAppointmentDatesQuery,
  GetAvailableAppointmentDatesQueryVariables
>;
export const GetAvailableAppointmentTimesDocument = gql`
  query GetAvailableAppointmentTimes(
    $date: ISO8601Date
    $province: Province
    $bookingReason: String
    $appointmentTypeId: ID
    $workflowStepId: ID
  ) {
    availableAppointmentTimes(
      filter: {
        appointmentTypeId: $appointmentTypeId
        bookingReason: $bookingReason
        date: $date
        province: $province
        workflowStepId: $workflowStepId
      }
    ) {
      slotsAvailable
      time
      providerId
    }
  }
`;

/**
 * __useGetAvailableAppointmentTimesQuery__
 *
 * To run a query within a React component, call `useGetAvailableAppointmentTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableAppointmentTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableAppointmentTimesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      province: // value for 'province'
 *      bookingReason: // value for 'bookingReason'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *      workflowStepId: // value for 'workflowStepId'
 *   },
 * });
 */
export function useGetAvailableAppointmentTimesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableAppointmentTimesQuery,
    GetAvailableAppointmentTimesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableAppointmentTimesQuery,
    GetAvailableAppointmentTimesQueryVariables
  >(GetAvailableAppointmentTimesDocument, options);
}
export function useGetAvailableAppointmentTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableAppointmentTimesQuery,
    GetAvailableAppointmentTimesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableAppointmentTimesQuery,
    GetAvailableAppointmentTimesQueryVariables
  >(GetAvailableAppointmentTimesDocument, options);
}
export function useGetAvailableAppointmentTimesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAvailableAppointmentTimesQuery,
        GetAvailableAppointmentTimesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAvailableAppointmentTimesQuery,
    GetAvailableAppointmentTimesQueryVariables
  >(GetAvailableAppointmentTimesDocument, options);
}
export type GetAvailableAppointmentTimesQueryHookResult = ReturnType<
  typeof useGetAvailableAppointmentTimesQuery
>;
export type GetAvailableAppointmentTimesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableAppointmentTimesLazyQuery
>;
export type GetAvailableAppointmentTimesSuspenseQueryHookResult = ReturnType<
  typeof useGetAvailableAppointmentTimesSuspenseQuery
>;
export type GetAvailableAppointmentTimesQueryResult = Apollo.QueryResult<
  GetAvailableAppointmentTimesQuery,
  GetAvailableAppointmentTimesQueryVariables
>;
export const GetAppointmentsDocument = gql`
  query GetAppointments(
    $scheduledBefore: ISO8601DateTime
    $scheduledAfter: ISO8601DateTime
    $calendarId: ID
    $provinceId: ID
    $patientId: ID
    $clinicianId: ID
  ) {
    appointments(
      filter: {
        scheduledBefore: $scheduledBefore
        scheduledAfter: $scheduledAfter
        calendarId: $calendarId
        provinceId: $provinceId
        patientId: $patientId
        clinicianId: $clinicianId
      }
    ) {
      edges {
        ... on AppointmentEdge {
          node {
            ...AppointmentNode
          }
        }
      }
    }
  }
  ${AppointmentNodeFragmentDoc}
`;

/**
 * __useGetAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsQuery({
 *   variables: {
 *      scheduledBefore: // value for 'scheduledBefore'
 *      scheduledAfter: // value for 'scheduledAfter'
 *      calendarId: // value for 'calendarId'
 *      provinceId: // value for 'provinceId'
 *      patientId: // value for 'patientId'
 *      clinicianId: // value for 'clinicianId'
 *   },
 * });
 */
export function useGetAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(
    GetAppointmentsDocument,
    options,
  );
}
export function useGetAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >(GetAppointmentsDocument, options);
}
export function useGetAppointmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAppointmentsQuery,
        GetAppointmentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >(GetAppointmentsDocument, options);
}
export type GetAppointmentsQueryHookResult = ReturnType<
  typeof useGetAppointmentsQuery
>;
export type GetAppointmentsLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentsLazyQuery
>;
export type GetAppointmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetAppointmentsSuspenseQuery
>;
export type GetAppointmentsQueryResult = Apollo.QueryResult<
  GetAppointmentsQuery,
  GetAppointmentsQueryVariables
>;
export const GetAppointmentsListDocument = gql`
  query GetAppointmentsList(
    $scheduledBefore: ISO8601DateTime
    $scheduledAfter: ISO8601DateTime
    $calendarId: ID
    $provinceId: ID
    $patientId: ID
    $clinicianId: ID
    $countryCode: String
  ) {
    appointments(
      filter: {
        scheduledBefore: $scheduledBefore
        scheduledAfter: $scheduledAfter
        calendarId: $calendarId
        provinceId: $provinceId
        patientId: $patientId
        clinicianId: $clinicianId
        countryCode: $countryCode
      }
    ) {
      edges {
        ... on AppointmentEdge {
          node {
            ...MiniAppointmentNode
          }
        }
      }
    }
  }
  ${MiniAppointmentNodeFragmentDoc}
`;

/**
 * __useGetAppointmentsListQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsListQuery({
 *   variables: {
 *      scheduledBefore: // value for 'scheduledBefore'
 *      scheduledAfter: // value for 'scheduledAfter'
 *      calendarId: // value for 'calendarId'
 *      provinceId: // value for 'provinceId'
 *      patientId: // value for 'patientId'
 *      clinicianId: // value for 'clinicianId'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetAppointmentsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppointmentsListQuery,
    GetAppointmentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentsListQuery,
    GetAppointmentsListQueryVariables
  >(GetAppointmentsListDocument, options);
}
export function useGetAppointmentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentsListQuery,
    GetAppointmentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentsListQuery,
    GetAppointmentsListQueryVariables
  >(GetAppointmentsListDocument, options);
}
export function useGetAppointmentsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAppointmentsListQuery,
        GetAppointmentsListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAppointmentsListQuery,
    GetAppointmentsListQueryVariables
  >(GetAppointmentsListDocument, options);
}
export type GetAppointmentsListQueryHookResult = ReturnType<
  typeof useGetAppointmentsListQuery
>;
export type GetAppointmentsListLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentsListLazyQuery
>;
export type GetAppointmentsListSuspenseQueryHookResult = ReturnType<
  typeof useGetAppointmentsListSuspenseQuery
>;
export type GetAppointmentsListQueryResult = Apollo.QueryResult<
  GetAppointmentsListQuery,
  GetAppointmentsListQueryVariables
>;
export const GetAvailableTimeslotsForBookingSessionDocument = gql`
  query GetAvailableTimeslotsForBookingSession(
    $bookingSessionId: ID!
    $bookingDate: ISO8601Date!
  ) {
    availableTimeSlotsForBookingSession(
      bookingSessionId: $bookingSessionId
      bookingDate: $bookingDate
    ) {
      time
      providerId
    }
  }
`;

/**
 * __useGetAvailableTimeslotsForBookingSessionQuery__
 *
 * To run a query within a React component, call `useGetAvailableTimeslotsForBookingSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTimeslotsForBookingSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTimeslotsForBookingSessionQuery({
 *   variables: {
 *      bookingSessionId: // value for 'bookingSessionId'
 *      bookingDate: // value for 'bookingDate'
 *   },
 * });
 */
export function useGetAvailableTimeslotsForBookingSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  > &
    (
      | {
          variables: GetAvailableTimeslotsForBookingSessionQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  >(GetAvailableTimeslotsForBookingSessionDocument, options);
}
export function useGetAvailableTimeslotsForBookingSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  >(GetAvailableTimeslotsForBookingSessionDocument, options);
}
export function useGetAvailableTimeslotsForBookingSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAvailableTimeslotsForBookingSessionQuery,
        GetAvailableTimeslotsForBookingSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  >(GetAvailableTimeslotsForBookingSessionDocument, options);
}
export type GetAvailableTimeslotsForBookingSessionQueryHookResult = ReturnType<
  typeof useGetAvailableTimeslotsForBookingSessionQuery
>;
export type GetAvailableTimeslotsForBookingSessionLazyQueryHookResult =
  ReturnType<typeof useGetAvailableTimeslotsForBookingSessionLazyQuery>;
export type GetAvailableTimeslotsForBookingSessionSuspenseQueryHookResult =
  ReturnType<typeof useGetAvailableTimeslotsForBookingSessionSuspenseQuery>;
export type GetAvailableTimeslotsForBookingSessionQueryResult =
  Apollo.QueryResult<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  >;
export const BookAppointmentDocument = gql`
  mutation BookAppointment($input: BookAppointmentInput!) {
    bookAppointment(input: $input) {
      errors
    }
  }
`;
export type BookAppointmentMutationFn = Apollo.MutationFunction<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookAppointmentMutation,
    BookAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookAppointmentMutation,
    BookAppointmentMutationVariables
  >(BookAppointmentDocument, options);
}
export type BookAppointmentMutationHookResult = ReturnType<
  typeof useBookAppointmentMutation
>;
export type BookAppointmentMutationResult =
  Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;
export const CancelAppointmentDocument = gql`
  mutation CancelAppointment($input: CancelAppointmentInput!) {
    cancelAppointment(input: $input) {
      errors
    }
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, options);
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>;
export type CancelAppointmentMutationResult =
  Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const RescheduleAppointmentDocument = gql`
  mutation RescheduleAppointment($input: RescheduleAppointmentInput!) {
    rescheduleAppointment(input: $input) {
      errors
    }
  }
`;
export type RescheduleAppointmentMutationFn = Apollo.MutationFunction<
  RescheduleAppointmentMutation,
  RescheduleAppointmentMutationVariables
>;

/**
 * __useRescheduleAppointmentMutation__
 *
 * To run a mutation, you first call `useRescheduleAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAppointmentMutation, { data, loading, error }] = useRescheduleAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleAppointmentMutation,
    RescheduleAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleAppointmentMutation,
    RescheduleAppointmentMutationVariables
  >(RescheduleAppointmentDocument, options);
}
export type RescheduleAppointmentMutationHookResult = ReturnType<
  typeof useRescheduleAppointmentMutation
>;
export type RescheduleAppointmentMutationResult =
  Apollo.MutationResult<RescheduleAppointmentMutation>;
export type RescheduleAppointmentMutationOptions = Apollo.BaseMutationOptions<
  RescheduleAppointmentMutation,
  RescheduleAppointmentMutationVariables
>;
export const StartBookingSessionDocument = gql`
  mutation StartBookingSession($input: StartBookingSessionInput!) {
    startBookingSession(input: $input) {
      bookingSession {
        ...BookingSession
      }
      errors
    }
  }
  ${BookingSessionFragmentDoc}
`;
export type StartBookingSessionMutationFn = Apollo.MutationFunction<
  StartBookingSessionMutation,
  StartBookingSessionMutationVariables
>;

/**
 * __useStartBookingSessionMutation__
 *
 * To run a mutation, you first call `useStartBookingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartBookingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startBookingSessionMutation, { data, loading, error }] = useStartBookingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartBookingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartBookingSessionMutation,
    StartBookingSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartBookingSessionMutation,
    StartBookingSessionMutationVariables
  >(StartBookingSessionDocument, options);
}
export type StartBookingSessionMutationHookResult = ReturnType<
  typeof useStartBookingSessionMutation
>;
export type StartBookingSessionMutationResult =
  Apollo.MutationResult<StartBookingSessionMutation>;
export type StartBookingSessionMutationOptions = Apollo.BaseMutationOptions<
  StartBookingSessionMutation,
  StartBookingSessionMutationVariables
>;
export const BookAppointmentWithBookingSessionDocument = gql`
  mutation BookAppointmentWithBookingSession(
    $input: BookAppointmentWithBookingSessionInput!
  ) {
    bookAppointmentWithBookingSession(input: $input) {
      bookingSession {
        appointment {
          id
        }
      }
      errors
    }
  }
`;
export type BookAppointmentWithBookingSessionMutationFn =
  Apollo.MutationFunction<
    BookAppointmentWithBookingSessionMutation,
    BookAppointmentWithBookingSessionMutationVariables
  >;

/**
 * __useBookAppointmentWithBookingSessionMutation__
 *
 * To run a mutation, you first call `useBookAppointmentWithBookingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentWithBookingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentWithBookingSessionMutation, { data, loading, error }] = useBookAppointmentWithBookingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAppointmentWithBookingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookAppointmentWithBookingSessionMutation,
    BookAppointmentWithBookingSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookAppointmentWithBookingSessionMutation,
    BookAppointmentWithBookingSessionMutationVariables
  >(BookAppointmentWithBookingSessionDocument, options);
}
export type BookAppointmentWithBookingSessionMutationHookResult = ReturnType<
  typeof useBookAppointmentWithBookingSessionMutation
>;
export type BookAppointmentWithBookingSessionMutationResult =
  Apollo.MutationResult<BookAppointmentWithBookingSessionMutation>;
export type BookAppointmentWithBookingSessionMutationOptions =
  Apollo.BaseMutationOptions<
    BookAppointmentWithBookingSessionMutation,
    BookAppointmentWithBookingSessionMutationVariables
  >;
export const PatientAppointmentDocument = gql`
  query PatientAppointment($id: ID!) {
    appointment(id: $id) {
      ...PatientAppointmentParts
    }
    platformSettings {
      ...PlatformSettings
    }
  }
  ${PatientAppointmentPartsFragmentDoc}
  ${PlatformSettingsFragmentDoc}
`;

/**
 * __usePatientAppointmentQuery__
 *
 * To run a query within a React component, call `usePatientAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientAppointmentQuery,
    PatientAppointmentQueryVariables
  > &
    (
      | { variables: PatientAppointmentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PatientAppointmentQuery,
    PatientAppointmentQueryVariables
  >(PatientAppointmentDocument, options);
}
export function usePatientAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientAppointmentQuery,
    PatientAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PatientAppointmentQuery,
    PatientAppointmentQueryVariables
  >(PatientAppointmentDocument, options);
}
export function usePatientAppointmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PatientAppointmentQuery,
        PatientAppointmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PatientAppointmentQuery,
    PatientAppointmentQueryVariables
  >(PatientAppointmentDocument, options);
}
export type PatientAppointmentQueryHookResult = ReturnType<
  typeof usePatientAppointmentQuery
>;
export type PatientAppointmentLazyQueryHookResult = ReturnType<
  typeof usePatientAppointmentLazyQuery
>;
export type PatientAppointmentSuspenseQueryHookResult = ReturnType<
  typeof usePatientAppointmentSuspenseQuery
>;
export type PatientAppointmentQueryResult = Apollo.QueryResult<
  PatientAppointmentQuery,
  PatientAppointmentQueryVariables
>;
export const GetRecentAssessmentScalesDocument = gql`
  query GetRecentAssessmentScales($patientId: ID!) {
    assessments(
      recentScales: true
      filter: {
        patientId: $patientId
        assessmentType: [
          "Assessments::Frida::AsrsAssessment"
          "Assessments::Frida::Gad7Assessment"
          "Assessments::Frida::Phq9Assessment"
        ]
      }
    ) {
      nodes {
        ...AssessmentScaleParts
      }
    }
  }
  ${AssessmentScalePartsFragmentDoc}
`;

/**
 * __useGetRecentAssessmentScalesQuery__
 *
 * To run a query within a React component, call `useGetRecentAssessmentScalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentAssessmentScalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentAssessmentScalesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetRecentAssessmentScalesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecentAssessmentScalesQuery,
    GetRecentAssessmentScalesQueryVariables
  > &
    (
      | { variables: GetRecentAssessmentScalesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecentAssessmentScalesQuery,
    GetRecentAssessmentScalesQueryVariables
  >(GetRecentAssessmentScalesDocument, options);
}
export function useGetRecentAssessmentScalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentAssessmentScalesQuery,
    GetRecentAssessmentScalesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecentAssessmentScalesQuery,
    GetRecentAssessmentScalesQueryVariables
  >(GetRecentAssessmentScalesDocument, options);
}
export function useGetRecentAssessmentScalesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetRecentAssessmentScalesQuery,
        GetRecentAssessmentScalesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRecentAssessmentScalesQuery,
    GetRecentAssessmentScalesQueryVariables
  >(GetRecentAssessmentScalesDocument, options);
}
export type GetRecentAssessmentScalesQueryHookResult = ReturnType<
  typeof useGetRecentAssessmentScalesQuery
>;
export type GetRecentAssessmentScalesLazyQueryHookResult = ReturnType<
  typeof useGetRecentAssessmentScalesLazyQuery
>;
export type GetRecentAssessmentScalesSuspenseQueryHookResult = ReturnType<
  typeof useGetRecentAssessmentScalesSuspenseQuery
>;
export type GetRecentAssessmentScalesQueryResult = Apollo.QueryResult<
  GetRecentAssessmentScalesQuery,
  GetRecentAssessmentScalesQueryVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      user {
        id
      }
      jwt
      errors
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      errors
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const CreatePasswordResetUrlDocument = gql`
  mutation CreatePasswordResetUrl($input: CreatePasswordResetUrlInput!) {
    createPasswordResetUrl(input: $input) {
      url
      errors
    }
  }
`;
export type CreatePasswordResetUrlMutationFn = Apollo.MutationFunction<
  CreatePasswordResetUrlMutation,
  CreatePasswordResetUrlMutationVariables
>;

/**
 * __useCreatePasswordResetUrlMutation__
 *
 * To run a mutation, you first call `useCreatePasswordResetUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordResetUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordResetUrlMutation, { data, loading, error }] = useCreatePasswordResetUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordResetUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePasswordResetUrlMutation,
    CreatePasswordResetUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePasswordResetUrlMutation,
    CreatePasswordResetUrlMutationVariables
  >(CreatePasswordResetUrlDocument, options);
}
export type CreatePasswordResetUrlMutationHookResult = ReturnType<
  typeof useCreatePasswordResetUrlMutation
>;
export type CreatePasswordResetUrlMutationResult =
  Apollo.MutationResult<CreatePasswordResetUrlMutation>;
export type CreatePasswordResetUrlMutationOptions = Apollo.BaseMutationOptions<
  CreatePasswordResetUrlMutation,
  CreatePasswordResetUrlMutationVariables
>;
export const RecoverPasswordDocument = gql`
  mutation RecoverPassword($input: RecoverPasswordInput!) {
    recoverPassword(input: $input) {
      errors
    }
  }
`;
export type RecoverPasswordMutationFn = Apollo.MutationFunction<
  RecoverPasswordMutation,
  RecoverPasswordMutationVariables
>;

/**
 * __useRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverPasswordMutation, { data, loading, error }] = useRecoverPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecoverPasswordMutation,
    RecoverPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RecoverPasswordMutation,
    RecoverPasswordMutationVariables
  >(RecoverPasswordDocument, options);
}
export type RecoverPasswordMutationHookResult = ReturnType<
  typeof useRecoverPasswordMutation
>;
export type RecoverPasswordMutationResult =
  Apollo.MutationResult<RecoverPasswordMutation>;
export type RecoverPasswordMutationOptions = Apollo.BaseMutationOptions<
  RecoverPasswordMutation,
  RecoverPasswordMutationVariables
>;
export const CreatePasswordDocument = gql`
  mutation CreatePassword($input: CreatePasswordInput!) {
    createPassword(input: $input) {
      errors
    }
  }
`;
export type CreatePasswordMutationFn = Apollo.MutationFunction<
  CreatePasswordMutation,
  CreatePasswordMutationVariables
>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePasswordMutation,
    CreatePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePasswordMutation,
    CreatePasswordMutationVariables
  >(CreatePasswordDocument, options);
}
export type CreatePasswordMutationHookResult = ReturnType<
  typeof useCreatePasswordMutation
>;
export type CreatePasswordMutationResult =
  Apollo.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = Apollo.BaseMutationOptions<
  CreatePasswordMutation,
  CreatePasswordMutationVariables
>;
export const CreateConsultDocument = gql`
  mutation CreateConsult($input: CreateConsultInput!) {
    createConsult(input: $input) {
      errors
    }
  }
`;
export type CreateConsultMutationFn = Apollo.MutationFunction<
  CreateConsultMutation,
  CreateConsultMutationVariables
>;

/**
 * __useCreateConsultMutation__
 *
 * To run a mutation, you first call `useCreateConsultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsultMutation, { data, loading, error }] = useCreateConsultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConsultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConsultMutation,
    CreateConsultMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConsultMutation,
    CreateConsultMutationVariables
  >(CreateConsultDocument, options);
}
export type CreateConsultMutationHookResult = ReturnType<
  typeof useCreateConsultMutation
>;
export type CreateConsultMutationResult =
  Apollo.MutationResult<CreateConsultMutation>;
export type CreateConsultMutationOptions = Apollo.BaseMutationOptions<
  CreateConsultMutation,
  CreateConsultMutationVariables
>;
export const GetPostConsultActionsDocument = gql`
  query GetPostConsultActions(
    $afterCursor: String
    $dueBefore: ISO8601DateTime
    $consultAttended: Boolean
    $provinceId: ID
  ) {
    postConsultActions(
      first: 20
      after: $afterCursor
      filter: {
        dueBefore: $dueBefore
        consultAttended: $consultAttended
        provinceId: $provinceId
        completedById: null
      }
    ) {
      edges {
        node {
          ...PostConsultWorkflowStep
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PostConsultWorkflowStepFragmentDoc}
`;

/**
 * __useGetPostConsultActionsQuery__
 *
 * To run a query within a React component, call `useGetPostConsultActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostConsultActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostConsultActionsQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      dueBefore: // value for 'dueBefore'
 *      consultAttended: // value for 'consultAttended'
 *      provinceId: // value for 'provinceId'
 *   },
 * });
 */
export function useGetPostConsultActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPostConsultActionsQuery,
    GetPostConsultActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPostConsultActionsQuery,
    GetPostConsultActionsQueryVariables
  >(GetPostConsultActionsDocument, options);
}
export function useGetPostConsultActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPostConsultActionsQuery,
    GetPostConsultActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPostConsultActionsQuery,
    GetPostConsultActionsQueryVariables
  >(GetPostConsultActionsDocument, options);
}
export function useGetPostConsultActionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPostConsultActionsQuery,
        GetPostConsultActionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPostConsultActionsQuery,
    GetPostConsultActionsQueryVariables
  >(GetPostConsultActionsDocument, options);
}
export type GetPostConsultActionsQueryHookResult = ReturnType<
  typeof useGetPostConsultActionsQuery
>;
export type GetPostConsultActionsLazyQueryHookResult = ReturnType<
  typeof useGetPostConsultActionsLazyQuery
>;
export type GetPostConsultActionsSuspenseQueryHookResult = ReturnType<
  typeof useGetPostConsultActionsSuspenseQuery
>;
export type GetPostConsultActionsQueryResult = Apollo.QueryResult<
  GetPostConsultActionsQuery,
  GetPostConsultActionsQueryVariables
>;
export const GetConversationsDocument = gql`
  query GetConversations(
    $afterCursor: String
    $unreadById: ID
    $resolvedById: ID
    $confidential: Boolean
    $sortOrder: SortOrderEnum
    $brandIds: [ID!]
    $provinceIds: [ID!]
    $resolved: Boolean
    $roleIds: [ID!]
    $participantIds: [ID!]
    $anyParticipantIds: [ID!]
    $status: [ConversationStatus!]
  ) {
    conversations(
      first: 20
      after: $afterCursor
      filter: {
        resolvedById: $resolvedById
        unreadById: $unreadById
        confidential: $confidential
        brandIds: $brandIds
        provinceIds: $provinceIds
        resolved: $resolved
        roleIds: $roleIds
        participantIds: $participantIds
        anyParticipantIds: $anyParticipantIds
        status: $status
      }
      orderBy: { lastMessageSentAt: $sortOrder }
    ) {
      edges {
        node {
          ...CoreConversationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CoreConversationFieldsFragmentDoc}
`;

/**
 * __useGetConversationsQuery__
 *
 * To run a query within a React component, call `useGetConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      unreadById: // value for 'unreadById'
 *      resolvedById: // value for 'resolvedById'
 *      confidential: // value for 'confidential'
 *      sortOrder: // value for 'sortOrder'
 *      brandIds: // value for 'brandIds'
 *      provinceIds: // value for 'provinceIds'
 *      resolved: // value for 'resolved'
 *      roleIds: // value for 'roleIds'
 *      participantIds: // value for 'participantIds'
 *      anyParticipantIds: // value for 'anyParticipantIds'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationsQuery, GetConversationsQueryVariables>(
    GetConversationsDocument,
    options,
  );
}
export function useGetConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >(GetConversationsDocument, options);
}
export function useGetConversationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetConversationsQuery,
        GetConversationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >(GetConversationsDocument, options);
}
export type GetConversationsQueryHookResult = ReturnType<
  typeof useGetConversationsQuery
>;
export type GetConversationsLazyQueryHookResult = ReturnType<
  typeof useGetConversationsLazyQuery
>;
export type GetConversationsSuspenseQueryHookResult = ReturnType<
  typeof useGetConversationsSuspenseQuery
>;
export type GetConversationsQueryResult = Apollo.QueryResult<
  GetConversationsQuery,
  GetConversationsQueryVariables
>;
export const GetConversationsWithModifiedPaginationDocument = gql`
  query GetConversationsWithModifiedPagination(
    $afterCursor: String
    $beforeCursor: String
    $last: Int
    $first: Int
    $unreadById: ID
    $resolvedById: ID
    $confidential: Boolean
    $sortOrder: SortOrderEnum
    $brandIds: [ID!]
    $provinceIds: [ID!]
    $resolved: Boolean
    $roleIds: [ID!]
    $participantIds: [ID!]
    $anyParticipantIds: [ID!]
    $status: [ConversationStatus!]
  ) {
    conversationsNoMerging(
      first: $first
      last: $last
      after: $afterCursor
      before: $beforeCursor
      filter: {
        resolvedById: $resolvedById
        unreadById: $unreadById
        confidential: $confidential
        brandIds: $brandIds
        provinceIds: $provinceIds
        resolved: $resolved
        roleIds: $roleIds
        participantIds: $participantIds
        anyParticipantIds: $anyParticipantIds
        status: $status
      }
      orderBy: { lastMessageSentAt: $sortOrder }
    ) {
      edges {
        node {
          ...CoreConversationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${CoreConversationFieldsFragmentDoc}
`;

/**
 * __useGetConversationsWithModifiedPaginationQuery__
 *
 * To run a query within a React component, call `useGetConversationsWithModifiedPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsWithModifiedPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsWithModifiedPaginationQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      beforeCursor: // value for 'beforeCursor'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      unreadById: // value for 'unreadById'
 *      resolvedById: // value for 'resolvedById'
 *      confidential: // value for 'confidential'
 *      sortOrder: // value for 'sortOrder'
 *      brandIds: // value for 'brandIds'
 *      provinceIds: // value for 'provinceIds'
 *      resolved: // value for 'resolved'
 *      roleIds: // value for 'roleIds'
 *      participantIds: // value for 'participantIds'
 *      anyParticipantIds: // value for 'anyParticipantIds'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetConversationsWithModifiedPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >(GetConversationsWithModifiedPaginationDocument, options);
}
export function useGetConversationsWithModifiedPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >(GetConversationsWithModifiedPaginationDocument, options);
}
export function useGetConversationsWithModifiedPaginationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetConversationsWithModifiedPaginationQuery,
        GetConversationsWithModifiedPaginationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >(GetConversationsWithModifiedPaginationDocument, options);
}
export type GetConversationsWithModifiedPaginationQueryHookResult = ReturnType<
  typeof useGetConversationsWithModifiedPaginationQuery
>;
export type GetConversationsWithModifiedPaginationLazyQueryHookResult =
  ReturnType<typeof useGetConversationsWithModifiedPaginationLazyQuery>;
export type GetConversationsWithModifiedPaginationSuspenseQueryHookResult =
  ReturnType<typeof useGetConversationsWithModifiedPaginationSuspenseQuery>;
export type GetConversationsWithModifiedPaginationQueryResult =
  Apollo.QueryResult<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >;
export const GetPersonalConversationsDocument = gql`
  query GetPersonalConversations(
    $afterCursor: String
    $unreadById: ID
    $brandIds: [ID!]
    $provinceIds: [ID!]
    $participantIds: [ID!]
    $anyParticipantIds: [ID!]
    $roleIds: [ID!]
    $status: [ConversationStatus!]
  ) {
    conversations(
      first: 20
      after: $afterCursor
      filter: {
        unreadById: $unreadById
        participantIds: $participantIds
        brandIds: $brandIds
        provinceIds: $provinceIds
        anyParticipantIds: $anyParticipantIds
        roleIds: $roleIds
        status: $status
      }
    ) {
      edges {
        node {
          ...CoreConversationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CoreConversationFieldsFragmentDoc}
`;

/**
 * __useGetPersonalConversationsQuery__
 *
 * To run a query within a React component, call `useGetPersonalConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalConversationsQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      unreadById: // value for 'unreadById'
 *      brandIds: // value for 'brandIds'
 *      provinceIds: // value for 'provinceIds'
 *      participantIds: // value for 'participantIds'
 *      anyParticipantIds: // value for 'anyParticipantIds'
 *      roleIds: // value for 'roleIds'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetPersonalConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPersonalConversationsQuery,
    GetPersonalConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPersonalConversationsQuery,
    GetPersonalConversationsQueryVariables
  >(GetPersonalConversationsDocument, options);
}
export function useGetPersonalConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonalConversationsQuery,
    GetPersonalConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPersonalConversationsQuery,
    GetPersonalConversationsQueryVariables
  >(GetPersonalConversationsDocument, options);
}
export function useGetPersonalConversationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPersonalConversationsQuery,
        GetPersonalConversationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPersonalConversationsQuery,
    GetPersonalConversationsQueryVariables
  >(GetPersonalConversationsDocument, options);
}
export type GetPersonalConversationsQueryHookResult = ReturnType<
  typeof useGetPersonalConversationsQuery
>;
export type GetPersonalConversationsLazyQueryHookResult = ReturnType<
  typeof useGetPersonalConversationsLazyQuery
>;
export type GetPersonalConversationsSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonalConversationsSuspenseQuery
>;
export type GetPersonalConversationsQueryResult = Apollo.QueryResult<
  GetPersonalConversationsQuery,
  GetPersonalConversationsQueryVariables
>;
export const GetConversationDocument = gql`
  query GetConversation($id: ID!) {
    conversation(id: $id) {
      ...CoreConversationFields
      messages {
        ...CoreMessageFields
      }
      participants {
        ...CoreParticipantFields
      }
    }
  }
  ${CoreConversationFieldsFragmentDoc}
  ${CoreMessageFieldsFragmentDoc}
  ${CoreParticipantFieldsFragmentDoc}
`;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  > &
    (
      | { variables: GetConversationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options,
  );
}
export function useGetConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationQuery,
    GetConversationQueryVariables
  >(GetConversationDocument, options);
}
export function useGetConversationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetConversationQuery,
        GetConversationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConversationQuery,
    GetConversationQueryVariables
  >(GetConversationDocument, options);
}
export type GetConversationQueryHookResult = ReturnType<
  typeof useGetConversationQuery
>;
export type GetConversationLazyQueryHookResult = ReturnType<
  typeof useGetConversationLazyQuery
>;
export type GetConversationSuspenseQueryHookResult = ReturnType<
  typeof useGetConversationSuspenseQuery
>;
export type GetConversationQueryResult = Apollo.QueryResult<
  GetConversationQuery,
  GetConversationQueryVariables
>;
export const CreateMessageDocument = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        id
        conversationId
      }
      errors
    }
  }
`;
export type CreateMessageMutationFn = Apollo.MutationFunction<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >(CreateMessageDocument, options);
}
export type CreateMessageMutationHookResult = ReturnType<
  typeof useCreateMessageMutation
>;
export type CreateMessageMutationResult =
  Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const MarkConversationAsReadDocument = gql`
  mutation MarkConversationAsRead($input: MarkConversationAsReadInput!) {
    markConversationAsRead(input: $input) {
      errors
    }
  }
`;
export type MarkConversationAsReadMutationFn = Apollo.MutationFunction<
  MarkConversationAsReadMutation,
  MarkConversationAsReadMutationVariables
>;

/**
 * __useMarkConversationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkConversationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConversationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConversationAsReadMutation, { data, loading, error }] = useMarkConversationAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkConversationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkConversationAsReadMutation,
    MarkConversationAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkConversationAsReadMutation,
    MarkConversationAsReadMutationVariables
  >(MarkConversationAsReadDocument, options);
}
export type MarkConversationAsReadMutationHookResult = ReturnType<
  typeof useMarkConversationAsReadMutation
>;
export type MarkConversationAsReadMutationResult =
  Apollo.MutationResult<MarkConversationAsReadMutation>;
export type MarkConversationAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkConversationAsReadMutation,
  MarkConversationAsReadMutationVariables
>;
export const ReadMessageDocument = gql`
  mutation ReadMessage($input: MarkMessageAsReadInput!) {
    markMessageAsRead(input: $input) {
      errors
    }
  }
`;
export type ReadMessageMutationFn = Apollo.MutationFunction<
  ReadMessageMutation,
  ReadMessageMutationVariables
>;

/**
 * __useReadMessageMutation__
 *
 * To run a mutation, you first call `useReadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessageMutation, { data, loading, error }] = useReadMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadMessageMutation,
    ReadMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReadMessageMutation, ReadMessageMutationVariables>(
    ReadMessageDocument,
    options,
  );
}
export type ReadMessageMutationHookResult = ReturnType<
  typeof useReadMessageMutation
>;
export type ReadMessageMutationResult =
  Apollo.MutationResult<ReadMessageMutation>;
export type ReadMessageMutationOptions = Apollo.BaseMutationOptions<
  ReadMessageMutation,
  ReadMessageMutationVariables
>;
export const DeleteAttachmentDocument = gql`
  mutation DeleteAttachment($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      errors
    }
  }
`;
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables
>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAttachmentMutation,
    DeleteAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAttachmentMutation,
    DeleteAttachmentMutationVariables
  >(DeleteAttachmentDocument, options);
}
export type DeleteAttachmentMutationHookResult = ReturnType<
  typeof useDeleteAttachmentMutation
>;
export type DeleteAttachmentMutationResult =
  Apollo.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables
>;
export const GetMessageStatisticsDocument = gql`
  query GetMessageStatistics {
    messageStatistics {
      conversations {
        myUnreadCount
      }
    }
  }
`;

/**
 * __useGetMessageStatisticsQuery__
 *
 * To run a query within a React component, call `useGetMessageStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMessageStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMessageStatisticsQuery,
    GetMessageStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessageStatisticsQuery,
    GetMessageStatisticsQueryVariables
  >(GetMessageStatisticsDocument, options);
}
export function useGetMessageStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageStatisticsQuery,
    GetMessageStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessageStatisticsQuery,
    GetMessageStatisticsQueryVariables
  >(GetMessageStatisticsDocument, options);
}
export function useGetMessageStatisticsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMessageStatisticsQuery,
        GetMessageStatisticsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMessageStatisticsQuery,
    GetMessageStatisticsQueryVariables
  >(GetMessageStatisticsDocument, options);
}
export type GetMessageStatisticsQueryHookResult = ReturnType<
  typeof useGetMessageStatisticsQuery
>;
export type GetMessageStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetMessageStatisticsLazyQuery
>;
export type GetMessageStatisticsSuspenseQueryHookResult = ReturnType<
  typeof useGetMessageStatisticsSuspenseQuery
>;
export type GetMessageStatisticsQueryResult = Apollo.QueryResult<
  GetMessageStatisticsQuery,
  GetMessageStatisticsQueryVariables
>;
export const GetPatientConversationsDocument = gql`
  query GetPatientConversations($otherParticipantId: ID!, $brandIds: [ID!]) {
    conversations(
      filter: {
        participantIds: [$otherParticipantId]
        status: [recent, in_progress, waiting_on_patient, resolved]
        brandIds: $brandIds
      }
    ) {
      edges {
        node {
          ...CoreConversationFields
        }
      }
    }
  }
  ${CoreConversationFieldsFragmentDoc}
`;

/**
 * __useGetPatientConversationsQuery__
 *
 * To run a query within a React component, call `useGetPatientConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientConversationsQuery({
 *   variables: {
 *      otherParticipantId: // value for 'otherParticipantId'
 *      brandIds: // value for 'brandIds'
 *   },
 * });
 */
export function useGetPatientConversationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientConversationsQuery,
    GetPatientConversationsQueryVariables
  > &
    (
      | { variables: GetPatientConversationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientConversationsQuery,
    GetPatientConversationsQueryVariables
  >(GetPatientConversationsDocument, options);
}
export function useGetPatientConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientConversationsQuery,
    GetPatientConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientConversationsQuery,
    GetPatientConversationsQueryVariables
  >(GetPatientConversationsDocument, options);
}
export function useGetPatientConversationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientConversationsQuery,
        GetPatientConversationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPatientConversationsQuery,
    GetPatientConversationsQueryVariables
  >(GetPatientConversationsDocument, options);
}
export type GetPatientConversationsQueryHookResult = ReturnType<
  typeof useGetPatientConversationsQuery
>;
export type GetPatientConversationsLazyQueryHookResult = ReturnType<
  typeof useGetPatientConversationsLazyQuery
>;
export type GetPatientConversationsSuspenseQueryHookResult = ReturnType<
  typeof useGetPatientConversationsSuspenseQuery
>;
export type GetPatientConversationsQueryResult = Apollo.QueryResult<
  GetPatientConversationsQuery,
  GetPatientConversationsQueryVariables
>;
export const GetMyConversationsDocument = gql`
  query GetMyConversations($unreadById: ID, $status: [ConversationStatus!]) {
    me {
      id
      myConversations(filter: { unreadById: $unreadById, status: $status }) {
        edges {
          node {
            ...CoreConversationFields
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${CoreConversationFieldsFragmentDoc}
`;

/**
 * __useGetMyConversationsQuery__
 *
 * To run a query within a React component, call `useGetMyConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyConversationsQuery({
 *   variables: {
 *      unreadById: // value for 'unreadById'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetMyConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyConversationsQuery,
    GetMyConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyConversationsQuery,
    GetMyConversationsQueryVariables
  >(GetMyConversationsDocument, options);
}
export function useGetMyConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyConversationsQuery,
    GetMyConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyConversationsQuery,
    GetMyConversationsQueryVariables
  >(GetMyConversationsDocument, options);
}
export function useGetMyConversationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyConversationsQuery,
        GetMyConversationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyConversationsQuery,
    GetMyConversationsQueryVariables
  >(GetMyConversationsDocument, options);
}
export type GetMyConversationsQueryHookResult = ReturnType<
  typeof useGetMyConversationsQuery
>;
export type GetMyConversationsLazyQueryHookResult = ReturnType<
  typeof useGetMyConversationsLazyQuery
>;
export type GetMyConversationsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyConversationsSuspenseQuery
>;
export type GetMyConversationsQueryResult = Apollo.QueryResult<
  GetMyConversationsQuery,
  GetMyConversationsQueryVariables
>;
export const UpdateConversationDocument = gql`
  mutation UpdateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      errors
    }
  }
`;
export type UpdateConversationMutationFn = Apollo.MutationFunction<
  UpdateConversationMutation,
  UpdateConversationMutationVariables
>;

/**
 * __useUpdateConversationMutation__
 *
 * To run a mutation, you first call `useUpdateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversationMutation, { data, loading, error }] = useUpdateConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConversationMutation,
    UpdateConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConversationMutation,
    UpdateConversationMutationVariables
  >(UpdateConversationDocument, options);
}
export type UpdateConversationMutationHookResult = ReturnType<
  typeof useUpdateConversationMutation
>;
export type UpdateConversationMutationResult =
  Apollo.MutationResult<UpdateConversationMutation>;
export type UpdateConversationMutationOptions = Apollo.BaseMutationOptions<
  UpdateConversationMutation,
  UpdateConversationMutationVariables
>;
export const GetMessageDocument = gql`
  query GetMessage($id: ID!) {
    message(id: $id) {
      id
      conversationId
    }
  }
`;

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageQuery,
    GetMessageQueryVariables
  > &
    (
      | { variables: GetMessageQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(
    GetMessageDocument,
    options,
  );
}
export function useGetMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageQuery,
    GetMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(
    GetMessageDocument,
    options,
  );
}
export function useGetMessageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMessageQuery,
        GetMessageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMessageQuery, GetMessageQueryVariables>(
    GetMessageDocument,
    options,
  );
}
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>;
export type GetMessageLazyQueryHookResult = ReturnType<
  typeof useGetMessageLazyQuery
>;
export type GetMessageSuspenseQueryHookResult = ReturnType<
  typeof useGetMessageSuspenseQuery
>;
export type GetMessageQueryResult = Apollo.QueryResult<
  GetMessageQuery,
  GetMessageQueryVariables
>;
export const DeleteMessageDocument = gql`
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      errors
    }
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<
  typeof useDeleteMessageMutation
>;
export type DeleteMessageMutationResult =
  Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const UpdateMessageDocument = gql`
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      errors
    }
  }
`;
export type UpdateMessageMutationFn = Apollo.MutationFunction<
  UpdateMessageMutation,
  UpdateMessageMutationVariables
>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessageMutation,
    UpdateMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMessageMutation,
    UpdateMessageMutationVariables
  >(UpdateMessageDocument, options);
}
export type UpdateMessageMutationHookResult = ReturnType<
  typeof useUpdateMessageMutation
>;
export type UpdateMessageMutationResult =
  Apollo.MutationResult<UpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessageMutation,
  UpdateMessageMutationVariables
>;
export const GetCountriesDocument = gql`
  query GetCountries {
    countries {
      id
      name
      code
      provinces {
        ...Province
      }
    }
  }
  ${ProvinceFragmentDoc}
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCountriesQuery,
        GetCountriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export type GetCountriesQueryHookResult = ReturnType<
  typeof useGetCountriesQuery
>;
export type GetCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCountriesLazyQuery
>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<
  typeof useGetCountriesSuspenseQuery
>;
export type GetCountriesQueryResult = Apollo.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const GetCredentialsForUserDocument = gql`
  query GetCredentialsForUser($id: ID!) {
    user(id: $id) {
      email
      credentials {
        id
        province {
          code
        }
        designation
        registrationNumber
      }
    }
  }
`;

/**
 * __useGetCredentialsForUserQuery__
 *
 * To run a query within a React component, call `useGetCredentialsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCredentialsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCredentialsForUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCredentialsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCredentialsForUserQuery,
    GetCredentialsForUserQueryVariables
  > &
    (
      | { variables: GetCredentialsForUserQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCredentialsForUserQuery,
    GetCredentialsForUserQueryVariables
  >(GetCredentialsForUserDocument, options);
}
export function useGetCredentialsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCredentialsForUserQuery,
    GetCredentialsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCredentialsForUserQuery,
    GetCredentialsForUserQueryVariables
  >(GetCredentialsForUserDocument, options);
}
export function useGetCredentialsForUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCredentialsForUserQuery,
        GetCredentialsForUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCredentialsForUserQuery,
    GetCredentialsForUserQueryVariables
  >(GetCredentialsForUserDocument, options);
}
export type GetCredentialsForUserQueryHookResult = ReturnType<
  typeof useGetCredentialsForUserQuery
>;
export type GetCredentialsForUserLazyQueryHookResult = ReturnType<
  typeof useGetCredentialsForUserLazyQuery
>;
export type GetCredentialsForUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCredentialsForUserSuspenseQuery
>;
export type GetCredentialsForUserQueryResult = Apollo.QueryResult<
  GetCredentialsForUserQuery,
  GetCredentialsForUserQueryVariables
>;
export const CreateCredentialDocument = gql`
  mutation CreateCredential($input: CreateCredentialInput!) {
    createCredential(input: $input) {
      errors
    }
  }
`;
export type CreateCredentialMutationFn = Apollo.MutationFunction<
  CreateCredentialMutation,
  CreateCredentialMutationVariables
>;

/**
 * __useCreateCredentialMutation__
 *
 * To run a mutation, you first call `useCreateCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCredentialMutation, { data, loading, error }] = useCreateCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCredentialMutation,
    CreateCredentialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCredentialMutation,
    CreateCredentialMutationVariables
  >(CreateCredentialDocument, options);
}
export type CreateCredentialMutationHookResult = ReturnType<
  typeof useCreateCredentialMutation
>;
export type CreateCredentialMutationResult =
  Apollo.MutationResult<CreateCredentialMutation>;
export type CreateCredentialMutationOptions = Apollo.BaseMutationOptions<
  CreateCredentialMutation,
  CreateCredentialMutationVariables
>;
export const DeleteCredentialDocument = gql`
  mutation DeleteCredential($id: ID!) {
    deleteCredential(input: { credentialId: $id }) {
      errors
    }
  }
`;
export type DeleteCredentialMutationFn = Apollo.MutationFunction<
  DeleteCredentialMutation,
  DeleteCredentialMutationVariables
>;

/**
 * __useDeleteCredentialMutation__
 *
 * To run a mutation, you first call `useDeleteCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCredentialMutation, { data, loading, error }] = useDeleteCredentialMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables
  >(DeleteCredentialDocument, options);
}
export type DeleteCredentialMutationHookResult = ReturnType<
  typeof useDeleteCredentialMutation
>;
export type DeleteCredentialMutationResult =
  Apollo.MutationResult<DeleteCredentialMutation>;
export type DeleteCredentialMutationOptions = Apollo.BaseMutationOptions<
  DeleteCredentialMutation,
  DeleteCredentialMutationVariables
>;
export const UpdateCredentialDocument = gql`
  mutation UpdateCredential($input: UpdateCredentialInput!) {
    updateCredential(input: $input) {
      errors
    }
  }
`;
export type UpdateCredentialMutationFn = Apollo.MutationFunction<
  UpdateCredentialMutation,
  UpdateCredentialMutationVariables
>;

/**
 * __useUpdateCredentialMutation__
 *
 * To run a mutation, you first call `useUpdateCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCredentialMutation, { data, loading, error }] = useUpdateCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCredentialMutation,
    UpdateCredentialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCredentialMutation,
    UpdateCredentialMutationVariables
  >(UpdateCredentialDocument, options);
}
export type UpdateCredentialMutationHookResult = ReturnType<
  typeof useUpdateCredentialMutation
>;
export type UpdateCredentialMutationResult =
  Apollo.MutationResult<UpdateCredentialMutation>;
export type UpdateCredentialMutationOptions = Apollo.BaseMutationOptions<
  UpdateCredentialMutation,
  UpdateCredentialMutationVariables
>;
export const CreateInsuranceCardDocument = gql`
  mutation CreateInsuranceCard($input: CreateInsuranceCardInput!) {
    createInsuranceCard(input: $input) {
      errors
    }
  }
`;
export type CreateInsuranceCardMutationFn = Apollo.MutationFunction<
  CreateInsuranceCardMutation,
  CreateInsuranceCardMutationVariables
>;

/**
 * __useCreateInsuranceCardMutation__
 *
 * To run a mutation, you first call `useCreateInsuranceCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuranceCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuranceCardMutation, { data, loading, error }] = useCreateInsuranceCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuranceCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInsuranceCardMutation,
    CreateInsuranceCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInsuranceCardMutation,
    CreateInsuranceCardMutationVariables
  >(CreateInsuranceCardDocument, options);
}
export type CreateInsuranceCardMutationHookResult = ReturnType<
  typeof useCreateInsuranceCardMutation
>;
export type CreateInsuranceCardMutationResult =
  Apollo.MutationResult<CreateInsuranceCardMutation>;
export type CreateInsuranceCardMutationOptions = Apollo.BaseMutationOptions<
  CreateInsuranceCardMutation,
  CreateInsuranceCardMutationVariables
>;
export const UpdateInsuranceCardDocument = gql`
  mutation UpdateInsuranceCard($input: UpdateInsuranceCardInput!) {
    updateInsuranceCard(input: $input) {
      errors
    }
  }
`;
export type UpdateInsuranceCardMutationFn = Apollo.MutationFunction<
  UpdateInsuranceCardMutation,
  UpdateInsuranceCardMutationVariables
>;

/**
 * __useUpdateInsuranceCardMutation__
 *
 * To run a mutation, you first call `useUpdateInsuranceCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuranceCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuranceCardMutation, { data, loading, error }] = useUpdateInsuranceCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsuranceCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInsuranceCardMutation,
    UpdateInsuranceCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInsuranceCardMutation,
    UpdateInsuranceCardMutationVariables
  >(UpdateInsuranceCardDocument, options);
}
export type UpdateInsuranceCardMutationHookResult = ReturnType<
  typeof useUpdateInsuranceCardMutation
>;
export type UpdateInsuranceCardMutationResult =
  Apollo.MutationResult<UpdateInsuranceCardMutation>;
export type UpdateInsuranceCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateInsuranceCardMutation,
  UpdateInsuranceCardMutationVariables
>;
export const DeleteInsuranceCardDocument = gql`
  mutation DeleteInsuranceCard($input: DeleteInsuranceCardInput!) {
    deleteInsuranceCard(input: $input) {
      errors
    }
  }
`;
export type DeleteInsuranceCardMutationFn = Apollo.MutationFunction<
  DeleteInsuranceCardMutation,
  DeleteInsuranceCardMutationVariables
>;

/**
 * __useDeleteInsuranceCardMutation__
 *
 * To run a mutation, you first call `useDeleteInsuranceCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuranceCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuranceCardMutation, { data, loading, error }] = useDeleteInsuranceCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInsuranceCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInsuranceCardMutation,
    DeleteInsuranceCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInsuranceCardMutation,
    DeleteInsuranceCardMutationVariables
  >(DeleteInsuranceCardDocument, options);
}
export type DeleteInsuranceCardMutationHookResult = ReturnType<
  typeof useDeleteInsuranceCardMutation
>;
export type DeleteInsuranceCardMutationResult =
  Apollo.MutationResult<DeleteInsuranceCardMutation>;
export type DeleteInsuranceCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteInsuranceCardMutation,
  DeleteInsuranceCardMutationVariables
>;
export const GetJunoFormSubmissionDocument = gql`
  query GetJunoFormSubmission($fdid: Int!) {
    junoFormSubmission(fdid: $fdid) {
      fdid
      html
    }
  }
`;

/**
 * __useGetJunoFormSubmissionQuery__
 *
 * To run a query within a React component, call `useGetJunoFormSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJunoFormSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJunoFormSubmissionQuery({
 *   variables: {
 *      fdid: // value for 'fdid'
 *   },
 * });
 */
export function useGetJunoFormSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJunoFormSubmissionQuery,
    GetJunoFormSubmissionQueryVariables
  > &
    (
      | { variables: GetJunoFormSubmissionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJunoFormSubmissionQuery,
    GetJunoFormSubmissionQueryVariables
  >(GetJunoFormSubmissionDocument, options);
}
export function useGetJunoFormSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJunoFormSubmissionQuery,
    GetJunoFormSubmissionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJunoFormSubmissionQuery,
    GetJunoFormSubmissionQueryVariables
  >(GetJunoFormSubmissionDocument, options);
}
export function useGetJunoFormSubmissionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetJunoFormSubmissionQuery,
        GetJunoFormSubmissionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJunoFormSubmissionQuery,
    GetJunoFormSubmissionQueryVariables
  >(GetJunoFormSubmissionDocument, options);
}
export type GetJunoFormSubmissionQueryHookResult = ReturnType<
  typeof useGetJunoFormSubmissionQuery
>;
export type GetJunoFormSubmissionLazyQueryHookResult = ReturnType<
  typeof useGetJunoFormSubmissionLazyQuery
>;
export type GetJunoFormSubmissionSuspenseQueryHookResult = ReturnType<
  typeof useGetJunoFormSubmissionSuspenseQuery
>;
export type GetJunoFormSubmissionQueryResult = Apollo.QueryResult<
  GetJunoFormSubmissionQuery,
  GetJunoFormSubmissionQueryVariables
>;
export const CreateLabRequisitionDocument = gql`
  mutation CreateLabRequisition($input: CreateLabRequisitionInput!) {
    createLabRequisition(input: $input) {
      errors
    }
  }
`;
export type CreateLabRequisitionMutationFn = Apollo.MutationFunction<
  CreateLabRequisitionMutation,
  CreateLabRequisitionMutationVariables
>;

/**
 * __useCreateLabRequisitionMutation__
 *
 * To run a mutation, you first call `useCreateLabRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabRequisitionMutation, { data, loading, error }] = useCreateLabRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLabRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLabRequisitionMutation,
    CreateLabRequisitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLabRequisitionMutation,
    CreateLabRequisitionMutationVariables
  >(CreateLabRequisitionDocument, options);
}
export type CreateLabRequisitionMutationHookResult = ReturnType<
  typeof useCreateLabRequisitionMutation
>;
export type CreateLabRequisitionMutationResult =
  Apollo.MutationResult<CreateLabRequisitionMutation>;
export type CreateLabRequisitionMutationOptions = Apollo.BaseMutationOptions<
  CreateLabRequisitionMutation,
  CreateLabRequisitionMutationVariables
>;
export const UpdateLabRequisitionDocument = gql`
  mutation UpdateLabRequisition($input: UpdateLabRequisitionInput!) {
    updateLabRequisition(input: $input) {
      labRequisition {
        ...LabRequisition
      }
      errors
    }
  }
  ${LabRequisitionFragmentDoc}
`;
export type UpdateLabRequisitionMutationFn = Apollo.MutationFunction<
  UpdateLabRequisitionMutation,
  UpdateLabRequisitionMutationVariables
>;

/**
 * __useUpdateLabRequisitionMutation__
 *
 * To run a mutation, you first call `useUpdateLabRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabRequisitionMutation, { data, loading, error }] = useUpdateLabRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLabRequisitionMutation,
    UpdateLabRequisitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLabRequisitionMutation,
    UpdateLabRequisitionMutationVariables
  >(UpdateLabRequisitionDocument, options);
}
export type UpdateLabRequisitionMutationHookResult = ReturnType<
  typeof useUpdateLabRequisitionMutation
>;
export type UpdateLabRequisitionMutationResult =
  Apollo.MutationResult<UpdateLabRequisitionMutation>;
export type UpdateLabRequisitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateLabRequisitionMutation,
  UpdateLabRequisitionMutationVariables
>;
export const UpdateLabRequisitionViewedAtDocument = gql`
  mutation UpdateLabRequisitionViewedAt(
    $input: UpdateLabRequisitionViewedAtInput!
  ) {
    updateLabRequisitionViewedAt(input: $input) {
      errors
    }
  }
`;
export type UpdateLabRequisitionViewedAtMutationFn = Apollo.MutationFunction<
  UpdateLabRequisitionViewedAtMutation,
  UpdateLabRequisitionViewedAtMutationVariables
>;

/**
 * __useUpdateLabRequisitionViewedAtMutation__
 *
 * To run a mutation, you first call `useUpdateLabRequisitionViewedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabRequisitionViewedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabRequisitionViewedAtMutation, { data, loading, error }] = useUpdateLabRequisitionViewedAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabRequisitionViewedAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLabRequisitionViewedAtMutation,
    UpdateLabRequisitionViewedAtMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLabRequisitionViewedAtMutation,
    UpdateLabRequisitionViewedAtMutationVariables
  >(UpdateLabRequisitionViewedAtDocument, options);
}
export type UpdateLabRequisitionViewedAtMutationHookResult = ReturnType<
  typeof useUpdateLabRequisitionViewedAtMutation
>;
export type UpdateLabRequisitionViewedAtMutationResult =
  Apollo.MutationResult<UpdateLabRequisitionViewedAtMutation>;
export type UpdateLabRequisitionViewedAtMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLabRequisitionViewedAtMutation,
    UpdateLabRequisitionViewedAtMutationVariables
  >;
export const DeleteLabRequisitionDocument = gql`
  mutation DeleteLabRequisition($input: DeleteLabRequisitionInput!) {
    deleteLabRequisition(input: $input) {
      errors
    }
  }
`;
export type DeleteLabRequisitionMutationFn = Apollo.MutationFunction<
  DeleteLabRequisitionMutation,
  DeleteLabRequisitionMutationVariables
>;

/**
 * __useDeleteLabRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteLabRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabRequisitionMutation, { data, loading, error }] = useDeleteLabRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLabRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLabRequisitionMutation,
    DeleteLabRequisitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLabRequisitionMutation,
    DeleteLabRequisitionMutationVariables
  >(DeleteLabRequisitionDocument, options);
}
export type DeleteLabRequisitionMutationHookResult = ReturnType<
  typeof useDeleteLabRequisitionMutation
>;
export type DeleteLabRequisitionMutationResult =
  Apollo.MutationResult<DeleteLabRequisitionMutation>;
export type DeleteLabRequisitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteLabRequisitionMutation,
  DeleteLabRequisitionMutationVariables
>;
export const GetFulfillLabRequisitionStepsDocument = gql`
  query GetFulfillLabRequisitionSteps($afterCursor: String) {
    workflowSteps(
      first: 20
      after: $afterCursor
      filter: { stepType: "Steps::FulfillLabRequisition", completedById: null }
    ) {
      edges {
        node {
          ...LabRequisitionWorkflowStep
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${LabRequisitionWorkflowStepFragmentDoc}
`;

/**
 * __useGetFulfillLabRequisitionStepsQuery__
 *
 * To run a query within a React component, call `useGetFulfillLabRequisitionStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulfillLabRequisitionStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulfillLabRequisitionStepsQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetFulfillLabRequisitionStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFulfillLabRequisitionStepsQuery,
    GetFulfillLabRequisitionStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFulfillLabRequisitionStepsQuery,
    GetFulfillLabRequisitionStepsQueryVariables
  >(GetFulfillLabRequisitionStepsDocument, options);
}
export function useGetFulfillLabRequisitionStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFulfillLabRequisitionStepsQuery,
    GetFulfillLabRequisitionStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFulfillLabRequisitionStepsQuery,
    GetFulfillLabRequisitionStepsQueryVariables
  >(GetFulfillLabRequisitionStepsDocument, options);
}
export function useGetFulfillLabRequisitionStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFulfillLabRequisitionStepsQuery,
        GetFulfillLabRequisitionStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFulfillLabRequisitionStepsQuery,
    GetFulfillLabRequisitionStepsQueryVariables
  >(GetFulfillLabRequisitionStepsDocument, options);
}
export type GetFulfillLabRequisitionStepsQueryHookResult = ReturnType<
  typeof useGetFulfillLabRequisitionStepsQuery
>;
export type GetFulfillLabRequisitionStepsLazyQueryHookResult = ReturnType<
  typeof useGetFulfillLabRequisitionStepsLazyQuery
>;
export type GetFulfillLabRequisitionStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetFulfillLabRequisitionStepsSuspenseQuery
>;
export type GetFulfillLabRequisitionStepsQueryResult = Apollo.QueryResult<
  GetFulfillLabRequisitionStepsQuery,
  GetFulfillLabRequisitionStepsQueryVariables
>;
export const GetReviewLabRequisitionStepsDocument = gql`
  query GetReviewLabRequisitionSteps($patientId: ID, $afterCursor: String) {
    workflowSteps(
      first: 20
      after: $afterCursor
      filter: {
        stepType: "Steps::ReviewLabRequisition"
        completedById: null
        patientId: $patientId
      }
    ) {
      edges {
        node {
          ...LabRequisitionWorflowStep
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${LabRequisitionWorflowStepFragmentDoc}
`;

/**
 * __useGetReviewLabRequisitionStepsQuery__
 *
 * To run a query within a React component, call `useGetReviewLabRequisitionStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewLabRequisitionStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewLabRequisitionStepsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetReviewLabRequisitionStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReviewLabRequisitionStepsQuery,
    GetReviewLabRequisitionStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReviewLabRequisitionStepsQuery,
    GetReviewLabRequisitionStepsQueryVariables
  >(GetReviewLabRequisitionStepsDocument, options);
}
export function useGetReviewLabRequisitionStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReviewLabRequisitionStepsQuery,
    GetReviewLabRequisitionStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReviewLabRequisitionStepsQuery,
    GetReviewLabRequisitionStepsQueryVariables
  >(GetReviewLabRequisitionStepsDocument, options);
}
export function useGetReviewLabRequisitionStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetReviewLabRequisitionStepsQuery,
        GetReviewLabRequisitionStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetReviewLabRequisitionStepsQuery,
    GetReviewLabRequisitionStepsQueryVariables
  >(GetReviewLabRequisitionStepsDocument, options);
}
export type GetReviewLabRequisitionStepsQueryHookResult = ReturnType<
  typeof useGetReviewLabRequisitionStepsQuery
>;
export type GetReviewLabRequisitionStepsLazyQueryHookResult = ReturnType<
  typeof useGetReviewLabRequisitionStepsLazyQuery
>;
export type GetReviewLabRequisitionStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetReviewLabRequisitionStepsSuspenseQuery
>;
export type GetReviewLabRequisitionStepsQueryResult = Apollo.QueryResult<
  GetReviewLabRequisitionStepsQuery,
  GetReviewLabRequisitionStepsQueryVariables
>;
export const GetLabFeedbacksDocument = gql`
  query GetLabFeedbacks($patientId: ID!, $afterCursor: String) {
    workflowSteps(
      first: 20
      after: $afterCursor
      filter: {
        stepType: "Steps::FulfillLabRequisition"
        patientId: $patientId
      }
    ) {
      edges {
        node {
          ...LabFeedbackWorkflowStep
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${LabFeedbackWorkflowStepFragmentDoc}
`;

/**
 * __useGetLabFeedbacksQuery__
 *
 * To run a query within a React component, call `useGetLabFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabFeedbacksQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetLabFeedbacksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabFeedbacksQuery,
    GetLabFeedbacksQueryVariables
  > &
    (
      | { variables: GetLabFeedbacksQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabFeedbacksQuery, GetLabFeedbacksQueryVariables>(
    GetLabFeedbacksDocument,
    options,
  );
}
export function useGetLabFeedbacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabFeedbacksQuery,
    GetLabFeedbacksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabFeedbacksQuery,
    GetLabFeedbacksQueryVariables
  >(GetLabFeedbacksDocument, options);
}
export function useGetLabFeedbacksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLabFeedbacksQuery,
        GetLabFeedbacksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLabFeedbacksQuery,
    GetLabFeedbacksQueryVariables
  >(GetLabFeedbacksDocument, options);
}
export type GetLabFeedbacksQueryHookResult = ReturnType<
  typeof useGetLabFeedbacksQuery
>;
export type GetLabFeedbacksLazyQueryHookResult = ReturnType<
  typeof useGetLabFeedbacksLazyQuery
>;
export type GetLabFeedbacksSuspenseQueryHookResult = ReturnType<
  typeof useGetLabFeedbacksSuspenseQuery
>;
export type GetLabFeedbacksQueryResult = Apollo.QueryResult<
  GetLabFeedbacksQuery,
  GetLabFeedbacksQueryVariables
>;
export const GetCopyLabFeedbackStepsDocument = gql`
  query GetCopyLabFeedbackSteps($patientId: ID, $afterCursor: String) {
    workflowSteps(
      first: 20
      after: $afterCursor
      filter: {
        stepType: "Steps::CopyLabFeedbackToEmr"
        completedById: null
        patientId: $patientId
      }
    ) {
      edges {
        node {
          ...LabFeedbackWorkflowStep
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${LabFeedbackWorkflowStepFragmentDoc}
`;

/**
 * __useGetCopyLabFeedbackStepsQuery__
 *
 * To run a query within a React component, call `useGetCopyLabFeedbackStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCopyLabFeedbackStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCopyLabFeedbackStepsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetCopyLabFeedbackStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCopyLabFeedbackStepsQuery,
    GetCopyLabFeedbackStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCopyLabFeedbackStepsQuery,
    GetCopyLabFeedbackStepsQueryVariables
  >(GetCopyLabFeedbackStepsDocument, options);
}
export function useGetCopyLabFeedbackStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCopyLabFeedbackStepsQuery,
    GetCopyLabFeedbackStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCopyLabFeedbackStepsQuery,
    GetCopyLabFeedbackStepsQueryVariables
  >(GetCopyLabFeedbackStepsDocument, options);
}
export function useGetCopyLabFeedbackStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCopyLabFeedbackStepsQuery,
        GetCopyLabFeedbackStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCopyLabFeedbackStepsQuery,
    GetCopyLabFeedbackStepsQueryVariables
  >(GetCopyLabFeedbackStepsDocument, options);
}
export type GetCopyLabFeedbackStepsQueryHookResult = ReturnType<
  typeof useGetCopyLabFeedbackStepsQuery
>;
export type GetCopyLabFeedbackStepsLazyQueryHookResult = ReturnType<
  typeof useGetCopyLabFeedbackStepsLazyQuery
>;
export type GetCopyLabFeedbackStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetCopyLabFeedbackStepsSuspenseQuery
>;
export type GetCopyLabFeedbackStepsQueryResult = Apollo.QueryResult<
  GetCopyLabFeedbackStepsQuery,
  GetCopyLabFeedbackStepsQueryVariables
>;
export const GetFhirMedicationRequestsDocument = gql`
  query GetFHIRMedicationRequests($patientId: ID!) {
    medicationRequests(patientId: $patientId) {
      ...MedicationRequest
    }
  }
  ${MedicationRequestFragmentDoc}
`;

/**
 * __useGetFhirMedicationRequestsQuery__
 *
 * To run a query within a React component, call `useGetFhirMedicationRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFhirMedicationRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFhirMedicationRequestsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetFhirMedicationRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFhirMedicationRequestsQuery,
    GetFhirMedicationRequestsQueryVariables
  > &
    (
      | { variables: GetFhirMedicationRequestsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFhirMedicationRequestsQuery,
    GetFhirMedicationRequestsQueryVariables
  >(GetFhirMedicationRequestsDocument, options);
}
export function useGetFhirMedicationRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFhirMedicationRequestsQuery,
    GetFhirMedicationRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFhirMedicationRequestsQuery,
    GetFhirMedicationRequestsQueryVariables
  >(GetFhirMedicationRequestsDocument, options);
}
export function useGetFhirMedicationRequestsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFhirMedicationRequestsQuery,
        GetFhirMedicationRequestsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFhirMedicationRequestsQuery,
    GetFhirMedicationRequestsQueryVariables
  >(GetFhirMedicationRequestsDocument, options);
}
export type GetFhirMedicationRequestsQueryHookResult = ReturnType<
  typeof useGetFhirMedicationRequestsQuery
>;
export type GetFhirMedicationRequestsLazyQueryHookResult = ReturnType<
  typeof useGetFhirMedicationRequestsLazyQuery
>;
export type GetFhirMedicationRequestsSuspenseQueryHookResult = ReturnType<
  typeof useGetFhirMedicationRequestsSuspenseQuery
>;
export type GetFhirMedicationRequestsQueryResult = Apollo.QueryResult<
  GetFhirMedicationRequestsQuery,
  GetFhirMedicationRequestsQueryVariables
>;
export const PatientQuestionnairesDocument = gql`
  query PatientQuestionnaires($patientId: ID!) {
    workflowSteps(
      first: 50
      filter: {
        stepType: "Steps::FulfillLabRequisition"
        patientId: $patientId
      }
    ) {
      nodes {
        ...LabFeedbackWorkflowStep
      }
    }
    questionnaireResponses(filter: { patientId: $patientId }) {
      nodes {
        ...QuestionnaireResponseParts
      }
    }
    user(id: $patientId) {
      questionnaires {
        ...FreddieQuestionnaires
      }
      shortQuestionnaires {
        ...CoreShortQuestionnaire
      }
    }
  }
  ${LabFeedbackWorkflowStepFragmentDoc}
  ${QuestionnaireResponsePartsFragmentDoc}
  ${FreddieQuestionnairesFragmentDoc}
  ${CoreShortQuestionnaireFragmentDoc}
`;

/**
 * __usePatientQuestionnairesQuery__
 *
 * To run a query within a React component, call `usePatientQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuestionnairesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientQuestionnairesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientQuestionnairesQuery,
    PatientQuestionnairesQueryVariables
  > &
    (
      | { variables: PatientQuestionnairesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PatientQuestionnairesQuery,
    PatientQuestionnairesQueryVariables
  >(PatientQuestionnairesDocument, options);
}
export function usePatientQuestionnairesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientQuestionnairesQuery,
    PatientQuestionnairesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PatientQuestionnairesQuery,
    PatientQuestionnairesQueryVariables
  >(PatientQuestionnairesDocument, options);
}
export function usePatientQuestionnairesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PatientQuestionnairesQuery,
        PatientQuestionnairesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PatientQuestionnairesQuery,
    PatientQuestionnairesQueryVariables
  >(PatientQuestionnairesDocument, options);
}
export type PatientQuestionnairesQueryHookResult = ReturnType<
  typeof usePatientQuestionnairesQuery
>;
export type PatientQuestionnairesLazyQueryHookResult = ReturnType<
  typeof usePatientQuestionnairesLazyQuery
>;
export type PatientQuestionnairesSuspenseQueryHookResult = ReturnType<
  typeof usePatientQuestionnairesSuspenseQuery
>;
export type PatientQuestionnairesQueryResult = Apollo.QueryResult<
  PatientQuestionnairesQuery,
  PatientQuestionnairesQueryVariables
>;
export const CreateStripeSetupIntentDocument = gql`
  mutation CreateStripeSetupIntent($input: CreateStripeSetupIntentInput!) {
    createStripeSetupIntent(input: $input) {
      clientSecret
      errors
    }
  }
`;
export type CreateStripeSetupIntentMutationFn = Apollo.MutationFunction<
  CreateStripeSetupIntentMutation,
  CreateStripeSetupIntentMutationVariables
>;

/**
 * __useCreateStripeSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateStripeSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSetupIntentMutation, { data, loading, error }] = useCreateStripeSetupIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeSetupIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeSetupIntentMutation,
    CreateStripeSetupIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeSetupIntentMutation,
    CreateStripeSetupIntentMutationVariables
  >(CreateStripeSetupIntentDocument, options);
}
export type CreateStripeSetupIntentMutationHookResult = ReturnType<
  typeof useCreateStripeSetupIntentMutation
>;
export type CreateStripeSetupIntentMutationResult =
  Apollo.MutationResult<CreateStripeSetupIntentMutation>;
export type CreateStripeSetupIntentMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeSetupIntentMutation,
  CreateStripeSetupIntentMutationVariables
>;
export const CompleteInitialPaymentAndBookingDocument = gql`
  mutation CompleteInitialPaymentAndBooking(
    $input: CompleteInitialPaymentAndBookingInput!
  ) {
    completeInitialPaymentAndBooking(input: $input) {
      errors
    }
  }
`;
export type CompleteInitialPaymentAndBookingMutationFn =
  Apollo.MutationFunction<
    CompleteInitialPaymentAndBookingMutation,
    CompleteInitialPaymentAndBookingMutationVariables
  >;

/**
 * __useCompleteInitialPaymentAndBookingMutation__
 *
 * To run a mutation, you first call `useCompleteInitialPaymentAndBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInitialPaymentAndBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInitialPaymentAndBookingMutation, { data, loading, error }] = useCompleteInitialPaymentAndBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteInitialPaymentAndBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteInitialPaymentAndBookingMutation,
    CompleteInitialPaymentAndBookingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteInitialPaymentAndBookingMutation,
    CompleteInitialPaymentAndBookingMutationVariables
  >(CompleteInitialPaymentAndBookingDocument, options);
}
export type CompleteInitialPaymentAndBookingMutationHookResult = ReturnType<
  typeof useCompleteInitialPaymentAndBookingMutation
>;
export type CompleteInitialPaymentAndBookingMutationResult =
  Apollo.MutationResult<CompleteInitialPaymentAndBookingMutation>;
export type CompleteInitialPaymentAndBookingMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteInitialPaymentAndBookingMutation,
    CompleteInitialPaymentAndBookingMutationVariables
  >;
export const CreateStripeInvoiceDocument = gql`
  mutation CreateStripeInvoice($input: CreateStripeInvoiceInput!) {
    createStripeInvoice(input: $input) {
      invoiceId
      errors
    }
  }
`;
export type CreateStripeInvoiceMutationFn = Apollo.MutationFunction<
  CreateStripeInvoiceMutation,
  CreateStripeInvoiceMutationVariables
>;

/**
 * __useCreateStripeInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateStripeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeInvoiceMutation, { data, loading, error }] = useCreateStripeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeInvoiceMutation,
    CreateStripeInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeInvoiceMutation,
    CreateStripeInvoiceMutationVariables
  >(CreateStripeInvoiceDocument, options);
}
export type CreateStripeInvoiceMutationHookResult = ReturnType<
  typeof useCreateStripeInvoiceMutation
>;
export type CreateStripeInvoiceMutationResult =
  Apollo.MutationResult<CreateStripeInvoiceMutation>;
export type CreateStripeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeInvoiceMutation,
  CreateStripeInvoiceMutationVariables
>;
export const GenerateLoanLinkDocument = gql`
  mutation GenerateLoanLink($input: GenerateLoanLinkInput!) {
    generateLoanLink(input: $input) {
      response
      errors
    }
  }
`;
export type GenerateLoanLinkMutationFn = Apollo.MutationFunction<
  GenerateLoanLinkMutation,
  GenerateLoanLinkMutationVariables
>;

/**
 * __useGenerateLoanLinkMutation__
 *
 * To run a mutation, you first call `useGenerateLoanLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLoanLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLoanLinkMutation, { data, loading, error }] = useGenerateLoanLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateLoanLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateLoanLinkMutation,
    GenerateLoanLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateLoanLinkMutation,
    GenerateLoanLinkMutationVariables
  >(GenerateLoanLinkDocument, options);
}
export type GenerateLoanLinkMutationHookResult = ReturnType<
  typeof useGenerateLoanLinkMutation
>;
export type GenerateLoanLinkMutationResult =
  Apollo.MutationResult<GenerateLoanLinkMutation>;
export type GenerateLoanLinkMutationOptions = Apollo.BaseMutationOptions<
  GenerateLoanLinkMutation,
  GenerateLoanLinkMutationVariables
>;
export const CreateInitialPaymentTransactionDocument = gql`
  mutation CreateInitialPaymentTransaction(
    $input: CreateInitialPaymentTransactionInput!
  ) {
    createInitialPaymentTransaction(input: $input) {
      paymentTransaction {
        ...PaymentTransactionParts
      }
      errors
    }
  }
  ${PaymentTransactionPartsFragmentDoc}
`;
export type CreateInitialPaymentTransactionMutationFn = Apollo.MutationFunction<
  CreateInitialPaymentTransactionMutation,
  CreateInitialPaymentTransactionMutationVariables
>;

/**
 * __useCreateInitialPaymentTransactionMutation__
 *
 * To run a mutation, you first call `useCreateInitialPaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInitialPaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInitialPaymentTransactionMutation, { data, loading, error }] = useCreateInitialPaymentTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInitialPaymentTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInitialPaymentTransactionMutation,
    CreateInitialPaymentTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInitialPaymentTransactionMutation,
    CreateInitialPaymentTransactionMutationVariables
  >(CreateInitialPaymentTransactionDocument, options);
}
export type CreateInitialPaymentTransactionMutationHookResult = ReturnType<
  typeof useCreateInitialPaymentTransactionMutation
>;
export type CreateInitialPaymentTransactionMutationResult =
  Apollo.MutationResult<CreateInitialPaymentTransactionMutation>;
export type CreateInitialPaymentTransactionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInitialPaymentTransactionMutation,
    CreateInitialPaymentTransactionMutationVariables
  >;
export const GetMyPaymentMethodsDocument = gql`
  query GetMyPaymentMethods {
    me {
      id
      profile {
        id
        lastStripePaymentMethod {
          ...PaymentMethodParts
        }
        paymentMethods {
          ...PaymentMethodParts
        }
      }
      currentBrandMembership {
        affirmInitialLoanId
        initialPaymentReceivedAt
        initialPrice {
          amountInCents
        }
      }
    }
  }
  ${PaymentMethodPartsFragmentDoc}
`;

/**
 * __useGetMyPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetMyPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPaymentMethodsQuery,
    GetMyPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyPaymentMethodsQuery,
    GetMyPaymentMethodsQueryVariables
  >(GetMyPaymentMethodsDocument, options);
}
export function useGetMyPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPaymentMethodsQuery,
    GetMyPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPaymentMethodsQuery,
    GetMyPaymentMethodsQueryVariables
  >(GetMyPaymentMethodsDocument, options);
}
export function useGetMyPaymentMethodsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyPaymentMethodsQuery,
        GetMyPaymentMethodsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyPaymentMethodsQuery,
    GetMyPaymentMethodsQueryVariables
  >(GetMyPaymentMethodsDocument, options);
}
export type GetMyPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetMyPaymentMethodsQuery
>;
export type GetMyPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetMyPaymentMethodsLazyQuery
>;
export type GetMyPaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyPaymentMethodsSuspenseQuery
>;
export type GetMyPaymentMethodsQueryResult = Apollo.QueryResult<
  GetMyPaymentMethodsQuery,
  GetMyPaymentMethodsQueryVariables
>;
export const DeletePaymentMethodDocument = gql`
  mutation DeletePaymentMethod($input: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      errors
    }
  }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >(DeletePaymentMethodDocument, options);
}
export type DeletePaymentMethodMutationHookResult = ReturnType<
  typeof useDeletePaymentMethodMutation
>;
export type DeletePaymentMethodMutationResult =
  Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const AppointmentPaymentMethodsDocument = gql`
  query AppointmentPaymentMethods {
    me {
      id
      profile {
        id
        lastStripePaymentMethod {
          ...PaymentMethodParts
        }
      }
    }
    platformSettings {
      ...PlatformSettings
    }
  }
  ${PaymentMethodPartsFragmentDoc}
  ${PlatformSettingsFragmentDoc}
`;

/**
 * __useAppointmentPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useAppointmentPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AppointmentPaymentMethodsQuery,
    AppointmentPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppointmentPaymentMethodsQuery,
    AppointmentPaymentMethodsQueryVariables
  >(AppointmentPaymentMethodsDocument, options);
}
export function useAppointmentPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentPaymentMethodsQuery,
    AppointmentPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentPaymentMethodsQuery,
    AppointmentPaymentMethodsQueryVariables
  >(AppointmentPaymentMethodsDocument, options);
}
export function useAppointmentPaymentMethodsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AppointmentPaymentMethodsQuery,
        AppointmentPaymentMethodsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AppointmentPaymentMethodsQuery,
    AppointmentPaymentMethodsQueryVariables
  >(AppointmentPaymentMethodsDocument, options);
}
export type AppointmentPaymentMethodsQueryHookResult = ReturnType<
  typeof useAppointmentPaymentMethodsQuery
>;
export type AppointmentPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useAppointmentPaymentMethodsLazyQuery
>;
export type AppointmentPaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useAppointmentPaymentMethodsSuspenseQuery
>;
export type AppointmentPaymentMethodsQueryResult = Apollo.QueryResult<
  AppointmentPaymentMethodsQuery,
  AppointmentPaymentMethodsQueryVariables
>;
export const SendPhoneNumberVerificationCodeDocument = gql`
  mutation SendPhoneNumberVerificationCode(
    $input: SendPhoneNumberVerificationCodeInput!
  ) {
    sendPhoneNumberVerificationCode(input: $input) {
      errors
    }
  }
`;
export type SendPhoneNumberVerificationCodeMutationFn = Apollo.MutationFunction<
  SendPhoneNumberVerificationCodeMutation,
  SendPhoneNumberVerificationCodeMutationVariables
>;

/**
 * __useSendPhoneNumberVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendPhoneNumberVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneNumberVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneNumberVerificationCodeMutation, { data, loading, error }] = useSendPhoneNumberVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPhoneNumberVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPhoneNumberVerificationCodeMutation,
    SendPhoneNumberVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPhoneNumberVerificationCodeMutation,
    SendPhoneNumberVerificationCodeMutationVariables
  >(SendPhoneNumberVerificationCodeDocument, options);
}
export type SendPhoneNumberVerificationCodeMutationHookResult = ReturnType<
  typeof useSendPhoneNumberVerificationCodeMutation
>;
export type SendPhoneNumberVerificationCodeMutationResult =
  Apollo.MutationResult<SendPhoneNumberVerificationCodeMutation>;
export type SendPhoneNumberVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    SendPhoneNumberVerificationCodeMutation,
    SendPhoneNumberVerificationCodeMutationVariables
  >;
export const VerifyPhoneNumberVerificationCodeDocument = gql`
  mutation VerifyPhoneNumberVerificationCode(
    $input: VerifyPhoneNumberVerificationCodeInput!
  ) {
    verifyPhoneNumberVerificationCode(input: $input) {
      errors
    }
  }
`;
export type VerifyPhoneNumberVerificationCodeMutationFn =
  Apollo.MutationFunction<
    VerifyPhoneNumberVerificationCodeMutation,
    VerifyPhoneNumberVerificationCodeMutationVariables
  >;

/**
 * __useVerifyPhoneNumberVerificationCodeMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneNumberVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneNumberVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneNumberVerificationCodeMutation, { data, loading, error }] = useVerifyPhoneNumberVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPhoneNumberVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPhoneNumberVerificationCodeMutation,
    VerifyPhoneNumberVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPhoneNumberVerificationCodeMutation,
    VerifyPhoneNumberVerificationCodeMutationVariables
  >(VerifyPhoneNumberVerificationCodeDocument, options);
}
export type VerifyPhoneNumberVerificationCodeMutationHookResult = ReturnType<
  typeof useVerifyPhoneNumberVerificationCodeMutation
>;
export type VerifyPhoneNumberVerificationCodeMutationResult =
  Apollo.MutationResult<VerifyPhoneNumberVerificationCodeMutation>;
export type VerifyPhoneNumberVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    VerifyPhoneNumberVerificationCodeMutation,
    VerifyPhoneNumberVerificationCodeMutationVariables
  >;
export const GetPlatformSettingsDocument = gql`
  query GetPlatformSettings {
    platformSettings {
      ...PlatformSettings
    }
  }
  ${PlatformSettingsFragmentDoc}
`;

/**
 * __useGetPlatformSettingsQuery__
 *
 * To run a query within a React component, call `useGetPlatformSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlatformSettingsQuery,
    GetPlatformSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlatformSettingsQuery,
    GetPlatformSettingsQueryVariables
  >(GetPlatformSettingsDocument, options);
}
export function useGetPlatformSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlatformSettingsQuery,
    GetPlatformSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlatformSettingsQuery,
    GetPlatformSettingsQueryVariables
  >(GetPlatformSettingsDocument, options);
}
export function useGetPlatformSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPlatformSettingsQuery,
        GetPlatformSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPlatformSettingsQuery,
    GetPlatformSettingsQueryVariables
  >(GetPlatformSettingsDocument, options);
}
export type GetPlatformSettingsQueryHookResult = ReturnType<
  typeof useGetPlatformSettingsQuery
>;
export type GetPlatformSettingsLazyQueryHookResult = ReturnType<
  typeof useGetPlatformSettingsLazyQuery
>;
export type GetPlatformSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetPlatformSettingsSuspenseQuery
>;
export type GetPlatformSettingsQueryResult = Apollo.QueryResult<
  GetPlatformSettingsQuery,
  GetPlatformSettingsQueryVariables
>;
export const CreatePrescriptionDocument = gql`
  mutation CreatePrescription($input: CreatePrescriptionInput!) {
    createPrescription(input: $input) {
      errors
    }
  }
`;
export type CreatePrescriptionMutationFn = Apollo.MutationFunction<
  CreatePrescriptionMutation,
  CreatePrescriptionMutationVariables
>;

/**
 * __useCreatePrescriptionMutation__
 *
 * To run a mutation, you first call `useCreatePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrescriptionMutation, { data, loading, error }] = useCreatePrescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePrescriptionMutation,
    CreatePrescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePrescriptionMutation,
    CreatePrescriptionMutationVariables
  >(CreatePrescriptionDocument, options);
}
export type CreatePrescriptionMutationHookResult = ReturnType<
  typeof useCreatePrescriptionMutation
>;
export type CreatePrescriptionMutationResult =
  Apollo.MutationResult<CreatePrescriptionMutation>;
export type CreatePrescriptionMutationOptions = Apollo.BaseMutationOptions<
  CreatePrescriptionMutation,
  CreatePrescriptionMutationVariables
>;
export const GetPrescriptionDocument = gql`
  query GetPrescription($id: ID!) {
    prescription(id: $id) {
      ...Prescription
    }
  }
  ${PrescriptionFragmentDoc}
`;

/**
 * __useGetPrescriptionQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrescriptionQuery,
    GetPrescriptionQueryVariables
  > &
    (
      | { variables: GetPrescriptionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrescriptionQuery, GetPrescriptionQueryVariables>(
    GetPrescriptionDocument,
    options,
  );
}
export function useGetPrescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrescriptionQuery,
    GetPrescriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPrescriptionQuery,
    GetPrescriptionQueryVariables
  >(GetPrescriptionDocument, options);
}
export function useGetPrescriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPrescriptionQuery,
        GetPrescriptionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPrescriptionQuery,
    GetPrescriptionQueryVariables
  >(GetPrescriptionDocument, options);
}
export type GetPrescriptionQueryHookResult = ReturnType<
  typeof useGetPrescriptionQuery
>;
export type GetPrescriptionLazyQueryHookResult = ReturnType<
  typeof useGetPrescriptionLazyQuery
>;
export type GetPrescriptionSuspenseQueryHookResult = ReturnType<
  typeof useGetPrescriptionSuspenseQuery
>;
export type GetPrescriptionQueryResult = Apollo.QueryResult<
  GetPrescriptionQuery,
  GetPrescriptionQueryVariables
>;
export const GetPrescriptionStepsDocument = gql`
  query GetPrescriptionSteps($afterCursor: String, $patientId: ID) {
    workflowSteps(
      first: 20
      after: $afterCursor
      filter: {
        stepType: "Steps::FulfillPrescription"
        completedById: null
        patientId: $patientId
      }
    ) {
      edges {
        node {
          ...PrescriptionWorkflowStep
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PrescriptionWorkflowStepFragmentDoc}
`;

/**
 * __useGetPrescriptionStepsQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionStepsQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPrescriptionStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPrescriptionStepsQuery,
    GetPrescriptionStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPrescriptionStepsQuery,
    GetPrescriptionStepsQueryVariables
  >(GetPrescriptionStepsDocument, options);
}
export function useGetPrescriptionStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrescriptionStepsQuery,
    GetPrescriptionStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPrescriptionStepsQuery,
    GetPrescriptionStepsQueryVariables
  >(GetPrescriptionStepsDocument, options);
}
export function useGetPrescriptionStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPrescriptionStepsQuery,
        GetPrescriptionStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPrescriptionStepsQuery,
    GetPrescriptionStepsQueryVariables
  >(GetPrescriptionStepsDocument, options);
}
export type GetPrescriptionStepsQueryHookResult = ReturnType<
  typeof useGetPrescriptionStepsQuery
>;
export type GetPrescriptionStepsLazyQueryHookResult = ReturnType<
  typeof useGetPrescriptionStepsLazyQuery
>;
export type GetPrescriptionStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetPrescriptionStepsSuspenseQuery
>;
export type GetPrescriptionStepsQueryResult = Apollo.QueryResult<
  GetPrescriptionStepsQuery,
  GetPrescriptionStepsQueryVariables
>;
export const CreateFridaPrescriptionsDocument = gql`
  mutation createFridaPrescriptions($input: CreateFridaPrescriptionsInput!) {
    createFridaPrescriptions(input: $input) {
      errors {
        frontEndId
        messages
      }
      prescriptions {
        id
      }
    }
  }
`;
export type CreateFridaPrescriptionsMutationFn = Apollo.MutationFunction<
  CreateFridaPrescriptionsMutation,
  CreateFridaPrescriptionsMutationVariables
>;

/**
 * __useCreateFridaPrescriptionsMutation__
 *
 * To run a mutation, you first call `useCreateFridaPrescriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFridaPrescriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFridaPrescriptionsMutation, { data, loading, error }] = useCreateFridaPrescriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFridaPrescriptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFridaPrescriptionsMutation,
    CreateFridaPrescriptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFridaPrescriptionsMutation,
    CreateFridaPrescriptionsMutationVariables
  >(CreateFridaPrescriptionsDocument, options);
}
export type CreateFridaPrescriptionsMutationHookResult = ReturnType<
  typeof useCreateFridaPrescriptionsMutation
>;
export type CreateFridaPrescriptionsMutationResult =
  Apollo.MutationResult<CreateFridaPrescriptionsMutation>;
export type CreateFridaPrescriptionsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateFridaPrescriptionsMutation,
    CreateFridaPrescriptionsMutationVariables
  >;
export const UpdatePrescriptionDocument = gql`
  mutation updatePrescription($input: UpdatePrescriptionInput!) {
    updatePrescription(input: $input) {
      prescription {
        ...Prescription
      }
      errors
    }
  }
  ${PrescriptionFragmentDoc}
`;
export type UpdatePrescriptionMutationFn = Apollo.MutationFunction<
  UpdatePrescriptionMutation,
  UpdatePrescriptionMutationVariables
>;

/**
 * __useUpdatePrescriptionMutation__
 *
 * To run a mutation, you first call `useUpdatePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrescriptionMutation, { data, loading, error }] = useUpdatePrescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrescriptionMutation,
    UpdatePrescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrescriptionMutation,
    UpdatePrescriptionMutationVariables
  >(UpdatePrescriptionDocument, options);
}
export type UpdatePrescriptionMutationHookResult = ReturnType<
  typeof useUpdatePrescriptionMutation
>;
export type UpdatePrescriptionMutationResult =
  Apollo.MutationResult<UpdatePrescriptionMutation>;
export type UpdatePrescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrescriptionMutation,
  UpdatePrescriptionMutationVariables
>;
export const DeletePrescriptionDocument = gql`
  mutation deletePrescription($input: DeletePrescriptionInput!) {
    deletePrescription(input: $input) {
      errors
    }
  }
`;
export type DeletePrescriptionMutationFn = Apollo.MutationFunction<
  DeletePrescriptionMutation,
  DeletePrescriptionMutationVariables
>;

/**
 * __useDeletePrescriptionMutation__
 *
 * To run a mutation, you first call `useDeletePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrescriptionMutation, { data, loading, error }] = useDeletePrescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePrescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePrescriptionMutation,
    DeletePrescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePrescriptionMutation,
    DeletePrescriptionMutationVariables
  >(DeletePrescriptionDocument, options);
}
export type DeletePrescriptionMutationHookResult = ReturnType<
  typeof useDeletePrescriptionMutation
>;
export type DeletePrescriptionMutationResult =
  Apollo.MutationResult<DeletePrescriptionMutation>;
export type DeletePrescriptionMutationOptions = Apollo.BaseMutationOptions<
  DeletePrescriptionMutation,
  DeletePrescriptionMutationVariables
>;
export const GetPatientProfileDocument = gql`
  query GetPatientProfile {
    me {
      id
      firstName
      lastName
      preferredName
      locale
      createdAt
      brands {
        id
        name
        brand {
          id
          name
        }
      }
      phoneNumber
      profile {
        id
        dateOfBirth
        hasPaymentMethod
        financialExpectation
        stripeCustomerId
        deliveryPreference
        allergies
        asyncPrep
        medications
        medicalConditions
        verificationStatus
        healthSpendingAccount
        medicalHistoryVersions {
          ...MedicalHistoryVersion
        }
        insuranceCards {
          id
          label
          isDefaultCard
          frontImage {
            id
            filename
            downloadUrl
          }
          backImage {
            id
            filename
            downloadUrl
          }
        }
        shippingAddresses {
          ... on ShippingAddress {
            id
            recipientName
            addressType
            street
            street2
            addressType
            city
            province
            postalCode
            deliveryInstructions
            isDefaultAddress
          }
        }
        healthCareProvince {
          ...Province
        }
      }
      role {
        id
        code
        name
        displayName
      }
    }
  }
  ${MedicalHistoryVersionFragmentDoc}
  ${ProvinceFragmentDoc}
`;

/**
 * __useGetPatientProfileQuery__
 *
 * To run a query within a React component, call `useGetPatientProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPatientProfileQuery,
    GetPatientProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientProfileQuery,
    GetPatientProfileQueryVariables
  >(GetPatientProfileDocument, options);
}
export function useGetPatientProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientProfileQuery,
    GetPatientProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientProfileQuery,
    GetPatientProfileQueryVariables
  >(GetPatientProfileDocument, options);
}
export function useGetPatientProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientProfileQuery,
        GetPatientProfileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPatientProfileQuery,
    GetPatientProfileQueryVariables
  >(GetPatientProfileDocument, options);
}
export type GetPatientProfileQueryHookResult = ReturnType<
  typeof useGetPatientProfileQuery
>;
export type GetPatientProfileLazyQueryHookResult = ReturnType<
  typeof useGetPatientProfileLazyQuery
>;
export type GetPatientProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetPatientProfileSuspenseQuery
>;
export type GetPatientProfileQueryResult = Apollo.QueryResult<
  GetPatientProfileQuery,
  GetPatientProfileQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      firstName
      lastName
      preferredName
      email
      createdAt
      referralCode
      phone {
        e164
        rawNumber
      }
      locale
      role {
        id
        name
        displayName
        code
      }
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export function useGetMeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<
  typeof useGetMeSuspenseQuery
>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetMePatientDocument = gql`
  query GetMePatient {
    me {
      id
      firstName
      lastName
      preferredName
      email
      createdAt
      phone {
        e164
        rawNumber
      }
      locale
      hasAttendedConsult
      smsEnabled
      voicemailConsent
      additionalTreatmentsAvailable
      role {
        id
        name
        displayName
        code
      }
      lastTreatments {
        ...Treatment
      }
      treatmentEligibilities {
        ...TreatmentEligibility
      }
      profile {
        id
        dateOfBirth
        desiredTreatments
        healthCareNumber
        financialExpectation
        allergies
        asyncPrep
        medications
        medicalConditions
        hasPaymentMethod
        stripeCustomerId
        ccLastFour
        healthSpendingAccount
        shippingAddresses {
          ...ShippingAddress
        }
        medicalHistoryVersions {
          ...MedicalHistoryVersion
        }
        province {
          id
          code
          country {
            code
          }
        }
        healthCareProvince {
          ...Province
        }
        insuranceCardPhotos {
          id
          downloadUrl
          filename
        }
        insuranceCards {
          id
          label
          isDefaultCard
          frontImage {
            id
            filename
            downloadUrl
          }
          backImage {
            id
            filename
            downloadUrl
          }
        }
        verificationStatus
        dateVerified
        phoneVerifiedAt
      }
      currentBrandMembership {
        id
        name
        refillProcess
        initialPrice {
          id
          amountInCents
        }
      }
      assessments {
        nodes {
          id
          data
          type
          completedAt
          score
          scoringErrors
        }
      }
      appointments {
        nodes {
          ...Appointment
        }
      }
      questionnaires {
        id
        results
      }
      prescriptions {
        edges {
          node {
            id
            prescriptionType
            medication
            fulfillmentMethod
            sentAt
            dosage
            numOfPills
            additionalInfo
          }
        }
      }
      workflowSteps {
        ... on WorkflowStep {
          id
          completedAt
          dueAt
          createdAt
          type
          completionParams
          subject {
            ... on AcuityAppointment {
              id
              scheduledAt
              consult {
                id
                attended
                nextStep
                otherNextStep
              }
            }
            ... on LabRequisition {
              id
              attachments {
                ...Attachment
              }
            }
            ... on Prescription {
              id
              prescriptionLength
            }
          }
        }
      }
    }
  }
  ${TreatmentFragmentDoc}
  ${TreatmentEligibilityFragmentDoc}
  ${ShippingAddressFragmentDoc}
  ${MedicalHistoryVersionFragmentDoc}
  ${ProvinceFragmentDoc}
  ${AppointmentFragmentDoc}
  ${AttachmentFragmentDoc}
`;

/**
 * __useGetMePatientQuery__
 *
 * To run a query within a React component, call `useGetMePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMePatientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMePatientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMePatientQuery,
    GetMePatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMePatientQuery, GetMePatientQueryVariables>(
    GetMePatientDocument,
    options,
  );
}
export function useGetMePatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMePatientQuery,
    GetMePatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMePatientQuery, GetMePatientQueryVariables>(
    GetMePatientDocument,
    options,
  );
}
export function useGetMePatientSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMePatientQuery,
        GetMePatientQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMePatientQuery, GetMePatientQueryVariables>(
    GetMePatientDocument,
    options,
  );
}
export type GetMePatientQueryHookResult = ReturnType<
  typeof useGetMePatientQuery
>;
export type GetMePatientLazyQueryHookResult = ReturnType<
  typeof useGetMePatientLazyQuery
>;
export type GetMePatientSuspenseQueryHookResult = ReturnType<
  typeof useGetMePatientSuspenseQuery
>;
export type GetMePatientQueryResult = Apollo.QueryResult<
  GetMePatientQuery,
  GetMePatientQueryVariables
>;
export const GetMyPrescriptionsDocument = gql`
  query GetMyPrescriptions(
    $first: Int
    $afterCursor: String
    $onlyPrep: Boolean
    $brandID: ID
  ) {
    me {
      id
      prescriptions(
        first: $first
        after: $afterCursor
        filter: { onlyPrep: $onlyPrep, brandId: $brandID }
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Prescription
          }
        }
      }
    }
  }
  ${PrescriptionFragmentDoc}
`;

/**
 * __useGetMyPrescriptionsQuery__
 *
 * To run a query within a React component, call `useGetMyPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPrescriptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      afterCursor: // value for 'afterCursor'
 *      onlyPrep: // value for 'onlyPrep'
 *      brandID: // value for 'brandID'
 *   },
 * });
 */
export function useGetMyPrescriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPrescriptionsQuery,
    GetMyPrescriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyPrescriptionsQuery,
    GetMyPrescriptionsQueryVariables
  >(GetMyPrescriptionsDocument, options);
}
export function useGetMyPrescriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPrescriptionsQuery,
    GetMyPrescriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPrescriptionsQuery,
    GetMyPrescriptionsQueryVariables
  >(GetMyPrescriptionsDocument, options);
}
export function useGetMyPrescriptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyPrescriptionsQuery,
        GetMyPrescriptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyPrescriptionsQuery,
    GetMyPrescriptionsQueryVariables
  >(GetMyPrescriptionsDocument, options);
}
export type GetMyPrescriptionsQueryHookResult = ReturnType<
  typeof useGetMyPrescriptionsQuery
>;
export type GetMyPrescriptionsLazyQueryHookResult = ReturnType<
  typeof useGetMyPrescriptionsLazyQuery
>;
export type GetMyPrescriptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyPrescriptionsSuspenseQuery
>;
export type GetMyPrescriptionsQueryResult = Apollo.QueryResult<
  GetMyPrescriptionsQuery,
  GetMyPrescriptionsQueryVariables
>;
export const GetMyLabRequisitionsDocument = gql`
  query GetMyLabRequisitions($first: Int, $afterCursor: String) {
    me {
      id
      pendingLabRequisitions: labRequisitions(
        first: $first
        after: $afterCursor
        filter: { completed: false }
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...LabRequisition
          }
        }
      }
      completedLabRequisitions: labRequisitions(
        first: $first
        after: $afterCursor
        filter: { completed: true }
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...LabRequisition
          }
        }
      }
    }
  }
  ${LabRequisitionFragmentDoc}
`;

/**
 * __useGetMyLabRequisitionsQuery__
 *
 * To run a query within a React component, call `useGetMyLabRequisitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLabRequisitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLabRequisitionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetMyLabRequisitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyLabRequisitionsQuery,
    GetMyLabRequisitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyLabRequisitionsQuery,
    GetMyLabRequisitionsQueryVariables
  >(GetMyLabRequisitionsDocument, options);
}
export function useGetMyLabRequisitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLabRequisitionsQuery,
    GetMyLabRequisitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyLabRequisitionsQuery,
    GetMyLabRequisitionsQueryVariables
  >(GetMyLabRequisitionsDocument, options);
}
export function useGetMyLabRequisitionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyLabRequisitionsQuery,
        GetMyLabRequisitionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyLabRequisitionsQuery,
    GetMyLabRequisitionsQueryVariables
  >(GetMyLabRequisitionsDocument, options);
}
export type GetMyLabRequisitionsQueryHookResult = ReturnType<
  typeof useGetMyLabRequisitionsQuery
>;
export type GetMyLabRequisitionsLazyQueryHookResult = ReturnType<
  typeof useGetMyLabRequisitionsLazyQuery
>;
export type GetMyLabRequisitionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyLabRequisitionsSuspenseQuery
>;
export type GetMyLabRequisitionsQueryResult = Apollo.QueryResult<
  GetMyLabRequisitionsQuery,
  GetMyLabRequisitionsQueryVariables
>;
export const GetMyIncompleteWorkflowStepsDocument = gql`
  query GetMyIncompleteWorkflowSteps {
    me {
      id
      incompleteWorkflowSteps {
        ...CoreWorkflowStepFields
      }
    }
  }
  ${CoreWorkflowStepFieldsFragmentDoc}
`;

/**
 * __useGetMyIncompleteWorkflowStepsQuery__
 *
 * To run a query within a React component, call `useGetMyIncompleteWorkflowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyIncompleteWorkflowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyIncompleteWorkflowStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyIncompleteWorkflowStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyIncompleteWorkflowStepsQuery,
    GetMyIncompleteWorkflowStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyIncompleteWorkflowStepsQuery,
    GetMyIncompleteWorkflowStepsQueryVariables
  >(GetMyIncompleteWorkflowStepsDocument, options);
}
export function useGetMyIncompleteWorkflowStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyIncompleteWorkflowStepsQuery,
    GetMyIncompleteWorkflowStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyIncompleteWorkflowStepsQuery,
    GetMyIncompleteWorkflowStepsQueryVariables
  >(GetMyIncompleteWorkflowStepsDocument, options);
}
export function useGetMyIncompleteWorkflowStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyIncompleteWorkflowStepsQuery,
        GetMyIncompleteWorkflowStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyIncompleteWorkflowStepsQuery,
    GetMyIncompleteWorkflowStepsQueryVariables
  >(GetMyIncompleteWorkflowStepsDocument, options);
}
export type GetMyIncompleteWorkflowStepsQueryHookResult = ReturnType<
  typeof useGetMyIncompleteWorkflowStepsQuery
>;
export type GetMyIncompleteWorkflowStepsLazyQueryHookResult = ReturnType<
  typeof useGetMyIncompleteWorkflowStepsLazyQuery
>;
export type GetMyIncompleteWorkflowStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyIncompleteWorkflowStepsSuspenseQuery
>;
export type GetMyIncompleteWorkflowStepsQueryResult = Apollo.QueryResult<
  GetMyIncompleteWorkflowStepsQuery,
  GetMyIncompleteWorkflowStepsQueryVariables
>;
export const BrandMembershipDocument = gql`
  query BrandMembership {
    me {
      id
      currentBrandMembership {
        id
        canUseClinicalMessages
        availableBookingTypes {
          id
          bookingType
          workflowStepId
        }
      }
    }
  }
`;

/**
 * __useBrandMembershipQuery__
 *
 * To run a query within a React component, call `useBrandMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandMembershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandMembershipQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BrandMembershipQuery,
    BrandMembershipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandMembershipQuery, BrandMembershipQueryVariables>(
    BrandMembershipDocument,
    options,
  );
}
export function useBrandMembershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrandMembershipQuery,
    BrandMembershipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrandMembershipQuery,
    BrandMembershipQueryVariables
  >(BrandMembershipDocument, options);
}
export function useBrandMembershipSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BrandMembershipQuery,
        BrandMembershipQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BrandMembershipQuery,
    BrandMembershipQueryVariables
  >(BrandMembershipDocument, options);
}
export type BrandMembershipQueryHookResult = ReturnType<
  typeof useBrandMembershipQuery
>;
export type BrandMembershipLazyQueryHookResult = ReturnType<
  typeof useBrandMembershipLazyQuery
>;
export type BrandMembershipSuspenseQueryHookResult = ReturnType<
  typeof useBrandMembershipSuspenseQuery
>;
export type BrandMembershipQueryResult = Apollo.QueryResult<
  BrandMembershipQuery,
  BrandMembershipQueryVariables
>;
export const ScreenInPatientDocument = gql`
  mutation ScreenInPatient($input: ScreenInPatientInput!) {
    screenInPatient(input: $input) {
      errors
    }
  }
`;
export type ScreenInPatientMutationFn = Apollo.MutationFunction<
  ScreenInPatientMutation,
  ScreenInPatientMutationVariables
>;

/**
 * __useScreenInPatientMutation__
 *
 * To run a mutation, you first call `useScreenInPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScreenInPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [screenInPatientMutation, { data, loading, error }] = useScreenInPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScreenInPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScreenInPatientMutation,
    ScreenInPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScreenInPatientMutation,
    ScreenInPatientMutationVariables
  >(ScreenInPatientDocument, options);
}
export type ScreenInPatientMutationHookResult = ReturnType<
  typeof useScreenInPatientMutation
>;
export type ScreenInPatientMutationResult =
  Apollo.MutationResult<ScreenInPatientMutation>;
export type ScreenInPatientMutationOptions = Apollo.BaseMutationOptions<
  ScreenInPatientMutation,
  ScreenInPatientMutationVariables
>;
export const MyShortQuestionnairesDocument = gql`
  query MyShortQuestionnaires {
    me {
      id
      shortQuestionnaires {
        ...CoreShortQuestionnaire
      }
    }
  }
  ${CoreShortQuestionnaireFragmentDoc}
`;

/**
 * __useMyShortQuestionnairesQuery__
 *
 * To run a query within a React component, call `useMyShortQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyShortQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyShortQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyShortQuestionnairesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyShortQuestionnairesQuery,
    MyShortQuestionnairesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyShortQuestionnairesQuery,
    MyShortQuestionnairesQueryVariables
  >(MyShortQuestionnairesDocument, options);
}
export function useMyShortQuestionnairesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyShortQuestionnairesQuery,
    MyShortQuestionnairesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyShortQuestionnairesQuery,
    MyShortQuestionnairesQueryVariables
  >(MyShortQuestionnairesDocument, options);
}
export function useMyShortQuestionnairesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyShortQuestionnairesQuery,
        MyShortQuestionnairesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyShortQuestionnairesQuery,
    MyShortQuestionnairesQueryVariables
  >(MyShortQuestionnairesDocument, options);
}
export type MyShortQuestionnairesQueryHookResult = ReturnType<
  typeof useMyShortQuestionnairesQuery
>;
export type MyShortQuestionnairesLazyQueryHookResult = ReturnType<
  typeof useMyShortQuestionnairesLazyQuery
>;
export type MyShortQuestionnairesSuspenseQueryHookResult = ReturnType<
  typeof useMyShortQuestionnairesSuspenseQuery
>;
export type MyShortQuestionnairesQueryResult = Apollo.QueryResult<
  MyShortQuestionnairesQuery,
  MyShortQuestionnairesQueryVariables
>;
export const GetQuestionnaireResponseDocument = gql`
  query GetQuestionnaireResponse($id: ID!) {
    questionnaireResponse(id: $id) {
      ...QuestionnaireResponse
    }
  }
  ${QuestionnaireResponseFragmentDoc}
`;

/**
 * __useGetQuestionnaireResponseQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireResponseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionnaireResponseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionnaireResponseQuery,
    GetQuestionnaireResponseQueryVariables
  > &
    (
      | { variables: GetQuestionnaireResponseQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetQuestionnaireResponseQuery,
    GetQuestionnaireResponseQueryVariables
  >(GetQuestionnaireResponseDocument, options);
}
export function useGetQuestionnaireResponseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionnaireResponseQuery,
    GetQuestionnaireResponseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetQuestionnaireResponseQuery,
    GetQuestionnaireResponseQueryVariables
  >(GetQuestionnaireResponseDocument, options);
}
export function useGetQuestionnaireResponseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetQuestionnaireResponseQuery,
        GetQuestionnaireResponseQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetQuestionnaireResponseQuery,
    GetQuestionnaireResponseQueryVariables
  >(GetQuestionnaireResponseDocument, options);
}
export type GetQuestionnaireResponseQueryHookResult = ReturnType<
  typeof useGetQuestionnaireResponseQuery
>;
export type GetQuestionnaireResponseLazyQueryHookResult = ReturnType<
  typeof useGetQuestionnaireResponseLazyQuery
>;
export type GetQuestionnaireResponseSuspenseQueryHookResult = ReturnType<
  typeof useGetQuestionnaireResponseSuspenseQuery
>;
export type GetQuestionnaireResponseQueryResult = Apollo.QueryResult<
  GetQuestionnaireResponseQuery,
  GetQuestionnaireResponseQueryVariables
>;
export const StartQuestionnaireDocument = gql`
  mutation StartQuestionnaire($input: StartQuestionnaireInput!) {
    startQuestionnaire(input: $input) {
      errors
      patientResponse {
        id
      }
    }
  }
`;
export type StartQuestionnaireMutationFn = Apollo.MutationFunction<
  StartQuestionnaireMutation,
  StartQuestionnaireMutationVariables
>;

/**
 * __useStartQuestionnaireMutation__
 *
 * To run a mutation, you first call `useStartQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startQuestionnaireMutation, { data, loading, error }] = useStartQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartQuestionnaireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartQuestionnaireMutation,
    StartQuestionnaireMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartQuestionnaireMutation,
    StartQuestionnaireMutationVariables
  >(StartQuestionnaireDocument, options);
}
export type StartQuestionnaireMutationHookResult = ReturnType<
  typeof useStartQuestionnaireMutation
>;
export type StartQuestionnaireMutationResult =
  Apollo.MutationResult<StartQuestionnaireMutation>;
export type StartQuestionnaireMutationOptions = Apollo.BaseMutationOptions<
  StartQuestionnaireMutation,
  StartQuestionnaireMutationVariables
>;
export const SubmitQuestionnaireAnswersDocument = gql`
  mutation SubmitQuestionnaireAnswers(
    $input: SubmitQuestionnaireAnswersInput!
  ) {
    submitQuestionnaireAnswers(input: $input) {
      errors
    }
  }
`;
export type SubmitQuestionnaireAnswersMutationFn = Apollo.MutationFunction<
  SubmitQuestionnaireAnswersMutation,
  SubmitQuestionnaireAnswersMutationVariables
>;

/**
 * __useSubmitQuestionnaireAnswersMutation__
 *
 * To run a mutation, you first call `useSubmitQuestionnaireAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuestionnaireAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuestionnaireAnswersMutation, { data, loading, error }] = useSubmitQuestionnaireAnswersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitQuestionnaireAnswersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitQuestionnaireAnswersMutation,
    SubmitQuestionnaireAnswersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitQuestionnaireAnswersMutation,
    SubmitQuestionnaireAnswersMutationVariables
  >(SubmitQuestionnaireAnswersDocument, options);
}
export type SubmitQuestionnaireAnswersMutationHookResult = ReturnType<
  typeof useSubmitQuestionnaireAnswersMutation
>;
export type SubmitQuestionnaireAnswersMutationResult =
  Apollo.MutationResult<SubmitQuestionnaireAnswersMutation>;
export type SubmitQuestionnaireAnswersMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitQuestionnaireAnswersMutation,
    SubmitQuestionnaireAnswersMutationVariables
  >;
export const GoToPreviousStepOnQuestionnaireDocument = gql`
  mutation GoToPreviousStepOnQuestionnaire(
    $input: GoBackToPreviousQuestionnaireStepInput!
  ) {
    goBackToPreviousQuestionnaireStep(input: $input) {
      errors
    }
  }
`;
export type GoToPreviousStepOnQuestionnaireMutationFn = Apollo.MutationFunction<
  GoToPreviousStepOnQuestionnaireMutation,
  GoToPreviousStepOnQuestionnaireMutationVariables
>;

/**
 * __useGoToPreviousStepOnQuestionnaireMutation__
 *
 * To run a mutation, you first call `useGoToPreviousStepOnQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoToPreviousStepOnQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goToPreviousStepOnQuestionnaireMutation, { data, loading, error }] = useGoToPreviousStepOnQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoToPreviousStepOnQuestionnaireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GoToPreviousStepOnQuestionnaireMutation,
    GoToPreviousStepOnQuestionnaireMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GoToPreviousStepOnQuestionnaireMutation,
    GoToPreviousStepOnQuestionnaireMutationVariables
  >(GoToPreviousStepOnQuestionnaireDocument, options);
}
export type GoToPreviousStepOnQuestionnaireMutationHookResult = ReturnType<
  typeof useGoToPreviousStepOnQuestionnaireMutation
>;
export type GoToPreviousStepOnQuestionnaireMutationResult =
  Apollo.MutationResult<GoToPreviousStepOnQuestionnaireMutation>;
export type GoToPreviousStepOnQuestionnaireMutationOptions =
  Apollo.BaseMutationOptions<
    GoToPreviousStepOnQuestionnaireMutation,
    GoToPreviousStepOnQuestionnaireMutationVariables
  >;
export const GetRolesDocument = gql`
  query GetRoles {
    roles {
      ...Role
    }
  }
  ${RoleFragmentDoc}
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options,
  );
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRolesQuery,
    GetRolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options,
  );
}
export function useGetRolesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options,
  );
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<
  typeof useGetRolesLazyQuery
>;
export type GetRolesSuspenseQueryHookResult = ReturnType<
  typeof useGetRolesSuspenseQuery
>;
export type GetRolesQueryResult = Apollo.QueryResult<
  GetRolesQuery,
  GetRolesQueryVariables
>;
export const CreateShippingAddressDocument = gql`
  mutation CreateShippingAddress($input: CreateShippingAddressInput!) {
    createShippingAddress(input: $input) {
      errors
      shippingAddress {
        id
      }
    }
  }
`;
export type CreateShippingAddressMutationFn = Apollo.MutationFunction<
  CreateShippingAddressMutation,
  CreateShippingAddressMutationVariables
>;

/**
 * __useCreateShippingAddressMutation__
 *
 * To run a mutation, you first call `useCreateShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShippingAddressMutation, { data, loading, error }] = useCreateShippingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShippingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShippingAddressMutation,
    CreateShippingAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShippingAddressMutation,
    CreateShippingAddressMutationVariables
  >(CreateShippingAddressDocument, options);
}
export type CreateShippingAddressMutationHookResult = ReturnType<
  typeof useCreateShippingAddressMutation
>;
export type CreateShippingAddressMutationResult =
  Apollo.MutationResult<CreateShippingAddressMutation>;
export type CreateShippingAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateShippingAddressMutation,
  CreateShippingAddressMutationVariables
>;
export const UpdateShippingAddressDocument = gql`
  mutation UpdateShippingAddress($input: UpdateShippingAddressInput!) {
    updateShippingAddress(input: $input) {
      errors
    }
  }
`;
export type UpdateShippingAddressMutationFn = Apollo.MutationFunction<
  UpdateShippingAddressMutation,
  UpdateShippingAddressMutationVariables
>;

/**
 * __useUpdateShippingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingAddressMutation, { data, loading, error }] = useUpdateShippingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShippingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShippingAddressMutation,
    UpdateShippingAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShippingAddressMutation,
    UpdateShippingAddressMutationVariables
  >(UpdateShippingAddressDocument, options);
}
export type UpdateShippingAddressMutationHookResult = ReturnType<
  typeof useUpdateShippingAddressMutation
>;
export type UpdateShippingAddressMutationResult =
  Apollo.MutationResult<UpdateShippingAddressMutation>;
export type UpdateShippingAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateShippingAddressMutation,
  UpdateShippingAddressMutationVariables
>;
export const DeleteShippingAddressDocument = gql`
  mutation DeleteShippingAddress($input: DeleteShippingAddressInput!) {
    deleteShippingAddress(input: $input) {
      errors
    }
  }
`;
export type DeleteShippingAddressMutationFn = Apollo.MutationFunction<
  DeleteShippingAddressMutation,
  DeleteShippingAddressMutationVariables
>;

/**
 * __useDeleteShippingAddressMutation__
 *
 * To run a mutation, you first call `useDeleteShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShippingAddressMutation, { data, loading, error }] = useDeleteShippingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteShippingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShippingAddressMutation,
    DeleteShippingAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteShippingAddressMutation,
    DeleteShippingAddressMutationVariables
  >(DeleteShippingAddressDocument, options);
}
export type DeleteShippingAddressMutationHookResult = ReturnType<
  typeof useDeleteShippingAddressMutation
>;
export type DeleteShippingAddressMutationResult =
  Apollo.MutationResult<DeleteShippingAddressMutation>;
export type DeleteShippingAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteShippingAddressMutation,
  DeleteShippingAddressMutationVariables
>;
export const GetShortQuestionnaireStepsDocument = gql`
  query GetShortQuestionnaireSteps($patientId: ID, $afterCursor: String) {
    workflowSteps(
      first: 20
      after: $afterCursor
      filter: {
        stepType: "Steps::CopyShortQuestionnaireToEMR"
        completedById: null
        patientId: $patientId
      }
    ) {
      edges {
        node {
          ...CoreWorkflowStepFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CoreWorkflowStepFieldsFragmentDoc}
`;

/**
 * __useGetShortQuestionnaireStepsQuery__
 *
 * To run a query within a React component, call `useGetShortQuestionnaireStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortQuestionnaireStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortQuestionnaireStepsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetShortQuestionnaireStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShortQuestionnaireStepsQuery,
    GetShortQuestionnaireStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetShortQuestionnaireStepsQuery,
    GetShortQuestionnaireStepsQueryVariables
  >(GetShortQuestionnaireStepsDocument, options);
}
export function useGetShortQuestionnaireStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShortQuestionnaireStepsQuery,
    GetShortQuestionnaireStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShortQuestionnaireStepsQuery,
    GetShortQuestionnaireStepsQueryVariables
  >(GetShortQuestionnaireStepsDocument, options);
}
export function useGetShortQuestionnaireStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetShortQuestionnaireStepsQuery,
        GetShortQuestionnaireStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetShortQuestionnaireStepsQuery,
    GetShortQuestionnaireStepsQueryVariables
  >(GetShortQuestionnaireStepsDocument, options);
}
export type GetShortQuestionnaireStepsQueryHookResult = ReturnType<
  typeof useGetShortQuestionnaireStepsQuery
>;
export type GetShortQuestionnaireStepsLazyQueryHookResult = ReturnType<
  typeof useGetShortQuestionnaireStepsLazyQuery
>;
export type GetShortQuestionnaireStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetShortQuestionnaireStepsSuspenseQuery
>;
export type GetShortQuestionnaireStepsQueryResult = Apollo.QueryResult<
  GetShortQuestionnaireStepsQuery,
  GetShortQuestionnaireStepsQueryVariables
>;
export const SignupPatientDocument = gql`
  mutation SignupPatient($input: SignupPatientInput!) {
    signupPatient(input: $input) {
      jwt
      hints
      patient {
        id
      }
      errors
    }
  }
`;
export type SignupPatientMutationFn = Apollo.MutationFunction<
  SignupPatientMutation,
  SignupPatientMutationVariables
>;

/**
 * __useSignupPatientMutation__
 *
 * To run a mutation, you first call `useSignupPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupPatientMutation, { data, loading, error }] = useSignupPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupPatientMutation,
    SignupPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupPatientMutation,
    SignupPatientMutationVariables
  >(SignupPatientDocument, options);
}
export type SignupPatientMutationHookResult = ReturnType<
  typeof useSignupPatientMutation
>;
export type SignupPatientMutationResult =
  Apollo.MutationResult<SignupPatientMutation>;
export type SignupPatientMutationOptions = Apollo.BaseMutationOptions<
  SignupPatientMutation,
  SignupPatientMutationVariables
>;
export const GetStripeVerificationSessionDocument = gql`
  query GetStripeVerificationSession($userId: ID!) {
    stripeVerificationSession(userId: $userId) {
      id
      status
      images
    }
  }
`;

/**
 * __useGetStripeVerificationSessionQuery__
 *
 * To run a query within a React component, call `useGetStripeVerificationSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeVerificationSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeVerificationSessionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStripeVerificationSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStripeVerificationSessionQuery,
    GetStripeVerificationSessionQueryVariables
  > &
    (
      | {
          variables: GetStripeVerificationSessionQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStripeVerificationSessionQuery,
    GetStripeVerificationSessionQueryVariables
  >(GetStripeVerificationSessionDocument, options);
}
export function useGetStripeVerificationSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeVerificationSessionQuery,
    GetStripeVerificationSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStripeVerificationSessionQuery,
    GetStripeVerificationSessionQueryVariables
  >(GetStripeVerificationSessionDocument, options);
}
export function useGetStripeVerificationSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetStripeVerificationSessionQuery,
        GetStripeVerificationSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStripeVerificationSessionQuery,
    GetStripeVerificationSessionQueryVariables
  >(GetStripeVerificationSessionDocument, options);
}
export type GetStripeVerificationSessionQueryHookResult = ReturnType<
  typeof useGetStripeVerificationSessionQuery
>;
export type GetStripeVerificationSessionLazyQueryHookResult = ReturnType<
  typeof useGetStripeVerificationSessionLazyQuery
>;
export type GetStripeVerificationSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetStripeVerificationSessionSuspenseQuery
>;
export type GetStripeVerificationSessionQueryResult = Apollo.QueryResult<
  GetStripeVerificationSessionQuery,
  GetStripeVerificationSessionQueryVariables
>;
export const CreateStripeVerificationSessionDocument = gql`
  mutation CreateStripeVerificationSession {
    createStripeVerificationSession(input: {}) {
      clientSecret
      errors
    }
  }
`;
export type CreateStripeVerificationSessionMutationFn = Apollo.MutationFunction<
  CreateStripeVerificationSessionMutation,
  CreateStripeVerificationSessionMutationVariables
>;

/**
 * __useCreateStripeVerificationSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeVerificationSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeVerificationSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeVerificationSessionMutation, { data, loading, error }] = useCreateStripeVerificationSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeVerificationSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeVerificationSessionMutation,
    CreateStripeVerificationSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeVerificationSessionMutation,
    CreateStripeVerificationSessionMutationVariables
  >(CreateStripeVerificationSessionDocument, options);
}
export type CreateStripeVerificationSessionMutationHookResult = ReturnType<
  typeof useCreateStripeVerificationSessionMutation
>;
export type CreateStripeVerificationSessionMutationResult =
  Apollo.MutationResult<CreateStripeVerificationSessionMutation>;
export type CreateStripeVerificationSessionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateStripeVerificationSessionMutation,
    CreateStripeVerificationSessionMutationVariables
  >;
export const StopTreatmentDocument = gql`
  mutation StopTreatment($input: StopTreatmentInput!) {
    stopTreatment(input: $input) {
      errors
    }
  }
`;
export type StopTreatmentMutationFn = Apollo.MutationFunction<
  StopTreatmentMutation,
  StopTreatmentMutationVariables
>;

/**
 * __useStopTreatmentMutation__
 *
 * To run a mutation, you first call `useStopTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopTreatmentMutation, { data, loading, error }] = useStopTreatmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopTreatmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopTreatmentMutation,
    StopTreatmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StopTreatmentMutation,
    StopTreatmentMutationVariables
  >(StopTreatmentDocument, options);
}
export type StopTreatmentMutationHookResult = ReturnType<
  typeof useStopTreatmentMutation
>;
export type StopTreatmentMutationResult =
  Apollo.MutationResult<StopTreatmentMutation>;
export type StopTreatmentMutationOptions = Apollo.BaseMutationOptions<
  StopTreatmentMutation,
  StopTreatmentMutationVariables
>;
export const PauseTreatmentDocument = gql`
  mutation PauseTreatment($input: PauseTreatmentInput!) {
    pauseTreatment(input: $input) {
      errors
    }
  }
`;
export type PauseTreatmentMutationFn = Apollo.MutationFunction<
  PauseTreatmentMutation,
  PauseTreatmentMutationVariables
>;

/**
 * __usePauseTreatmentMutation__
 *
 * To run a mutation, you first call `usePauseTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseTreatmentMutation, { data, loading, error }] = usePauseTreatmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePauseTreatmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PauseTreatmentMutation,
    PauseTreatmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PauseTreatmentMutation,
    PauseTreatmentMutationVariables
  >(PauseTreatmentDocument, options);
}
export type PauseTreatmentMutationHookResult = ReturnType<
  typeof usePauseTreatmentMutation
>;
export type PauseTreatmentMutationResult =
  Apollo.MutationResult<PauseTreatmentMutation>;
export type PauseTreatmentMutationOptions = Apollo.BaseMutationOptions<
  PauseTreatmentMutation,
  PauseTreatmentMutationVariables
>;
export const MarkTreatmentIneligibleDocument = gql`
  mutation MarkTreatmentIneligible($input: MarkTreatmentIneligibleInput!) {
    markTreatmentIneligible(input: $input) {
      treatmentEligibility {
        ...TreatmentEligibility
      }
      errors
    }
  }
  ${TreatmentEligibilityFragmentDoc}
`;
export type MarkTreatmentIneligibleMutationFn = Apollo.MutationFunction<
  MarkTreatmentIneligibleMutation,
  MarkTreatmentIneligibleMutationVariables
>;

/**
 * __useMarkTreatmentIneligibleMutation__
 *
 * To run a mutation, you first call `useMarkTreatmentIneligibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTreatmentIneligibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTreatmentIneligibleMutation, { data, loading, error }] = useMarkTreatmentIneligibleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkTreatmentIneligibleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkTreatmentIneligibleMutation,
    MarkTreatmentIneligibleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkTreatmentIneligibleMutation,
    MarkTreatmentIneligibleMutationVariables
  >(MarkTreatmentIneligibleDocument, options);
}
export type MarkTreatmentIneligibleMutationHookResult = ReturnType<
  typeof useMarkTreatmentIneligibleMutation
>;
export type MarkTreatmentIneligibleMutationResult =
  Apollo.MutationResult<MarkTreatmentIneligibleMutation>;
export type MarkTreatmentIneligibleMutationOptions = Apollo.BaseMutationOptions<
  MarkTreatmentIneligibleMutation,
  MarkTreatmentIneligibleMutationVariables
>;
export const StartTreatmentDocument = gql`
  mutation StartTreatment($input: StartTreatmentInput!) {
    startTreatment(input: $input) {
      errors
    }
  }
`;
export type StartTreatmentMutationFn = Apollo.MutationFunction<
  StartTreatmentMutation,
  StartTreatmentMutationVariables
>;

/**
 * __useStartTreatmentMutation__
 *
 * To run a mutation, you first call `useStartTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTreatmentMutation, { data, loading, error }] = useStartTreatmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTreatmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartTreatmentMutation,
    StartTreatmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartTreatmentMutation,
    StartTreatmentMutationVariables
  >(StartTreatmentDocument, options);
}
export type StartTreatmentMutationHookResult = ReturnType<
  typeof useStartTreatmentMutation
>;
export type StartTreatmentMutationResult =
  Apollo.MutationResult<StartTreatmentMutation>;
export type StartTreatmentMutationOptions = Apollo.BaseMutationOptions<
  StartTreatmentMutation,
  StartTreatmentMutationVariables
>;
export const GetTreatmentDocument = gql`
  query GetTreatment($treatmentType: TreatmentType!) {
    treatment(treatmentType: $treatmentType) {
      ...Treatment
    }
  }
  ${TreatmentFragmentDoc}
`;

/**
 * __useGetTreatmentQuery__
 *
 * To run a query within a React component, call `useGetTreatmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTreatmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreatmentQuery({
 *   variables: {
 *      treatmentType: // value for 'treatmentType'
 *   },
 * });
 */
export function useGetTreatmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTreatmentQuery,
    GetTreatmentQueryVariables
  > &
    (
      | { variables: GetTreatmentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTreatmentQuery, GetTreatmentQueryVariables>(
    GetTreatmentDocument,
    options,
  );
}
export function useGetTreatmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTreatmentQuery,
    GetTreatmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTreatmentQuery, GetTreatmentQueryVariables>(
    GetTreatmentDocument,
    options,
  );
}
export function useGetTreatmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTreatmentQuery,
        GetTreatmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTreatmentQuery, GetTreatmentQueryVariables>(
    GetTreatmentDocument,
    options,
  );
}
export type GetTreatmentQueryHookResult = ReturnType<
  typeof useGetTreatmentQuery
>;
export type GetTreatmentLazyQueryHookResult = ReturnType<
  typeof useGetTreatmentLazyQuery
>;
export type GetTreatmentSuspenseQueryHookResult = ReturnType<
  typeof useGetTreatmentSuspenseQuery
>;
export type GetTreatmentQueryResult = Apollo.QueryResult<
  GetTreatmentQuery,
  GetTreatmentQueryVariables
>;
export const UpdatePillCounterDocument = gql`
  mutation UpdatePillCounter($input: UpdatePillCounterInput!) {
    updatePillCounter(input: $input) {
      pillCounter {
        remainingPills
        runsOutOn
      }
      errors
    }
  }
`;
export type UpdatePillCounterMutationFn = Apollo.MutationFunction<
  UpdatePillCounterMutation,
  UpdatePillCounterMutationVariables
>;

/**
 * __useUpdatePillCounterMutation__
 *
 * To run a mutation, you first call `useUpdatePillCounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePillCounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePillCounterMutation, { data, loading, error }] = useUpdatePillCounterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePillCounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePillCounterMutation,
    UpdatePillCounterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePillCounterMutation,
    UpdatePillCounterMutationVariables
  >(UpdatePillCounterDocument, options);
}
export type UpdatePillCounterMutationHookResult = ReturnType<
  typeof useUpdatePillCounterMutation
>;
export type UpdatePillCounterMutationResult =
  Apollo.MutationResult<UpdatePillCounterMutation>;
export type UpdatePillCounterMutationOptions = Apollo.BaseMutationOptions<
  UpdatePillCounterMutation,
  UpdatePillCounterMutationVariables
>;
export const GetCliniciansDocument = gql`
  query GetClinicians($roleIds: [ID!]) {
    users(filter: { roleIds: $roleIds }, first: 500) {
      edges {
        node {
          id
          firstName
          lastName
          acuityCalendars {
            id
            province {
              ...Province
            }
          }
        }
      }
    }
  }
  ${ProvinceFragmentDoc}
`;

/**
 * __useGetCliniciansQuery__
 *
 * To run a query within a React component, call `useGetCliniciansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCliniciansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCliniciansQuery({
 *   variables: {
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useGetCliniciansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCliniciansQuery,
    GetCliniciansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCliniciansQuery, GetCliniciansQueryVariables>(
    GetCliniciansDocument,
    options,
  );
}
export function useGetCliniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCliniciansQuery,
    GetCliniciansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCliniciansQuery, GetCliniciansQueryVariables>(
    GetCliniciansDocument,
    options,
  );
}
export function useGetCliniciansSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCliniciansQuery,
        GetCliniciansQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCliniciansQuery,
    GetCliniciansQueryVariables
  >(GetCliniciansDocument, options);
}
export type GetCliniciansQueryHookResult = ReturnType<
  typeof useGetCliniciansQuery
>;
export type GetCliniciansLazyQueryHookResult = ReturnType<
  typeof useGetCliniciansLazyQuery
>;
export type GetCliniciansSuspenseQueryHookResult = ReturnType<
  typeof useGetCliniciansSuspenseQuery
>;
export type GetCliniciansQueryResult = Apollo.QueryResult<
  GetCliniciansQuery,
  GetCliniciansQueryVariables
>;
export const GetProvidersDocument = gql`
  query GetProviders(
    $afterCursor: String
    $roleIds: [ID!]
    $searchText: String
  ) {
    users(
      first: 20
      after: $afterCursor
      filter: { roleIds: $roleIds, searchText: $searchText }
    ) {
      edges {
        node {
          ...ProviderUser
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${ProviderUserFragmentDoc}
`;

/**
 * __useGetProvidersQuery__
 *
 * To run a query within a React component, call `useGetProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      roleIds: // value for 'roleIds'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProvidersQuery,
    GetProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options,
  );
}
export function useGetProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProvidersQuery,
    GetProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options,
  );
}
export function useGetProvidersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetProvidersQuery,
        GetProvidersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options,
  );
}
export type GetProvidersQueryHookResult = ReturnType<
  typeof useGetProvidersQuery
>;
export type GetProvidersLazyQueryHookResult = ReturnType<
  typeof useGetProvidersLazyQuery
>;
export type GetProvidersSuspenseQueryHookResult = ReturnType<
  typeof useGetProvidersSuspenseQuery
>;
export type GetProvidersQueryResult = Apollo.QueryResult<
  GetProvidersQuery,
  GetProvidersQueryVariables
>;
export const GetProvidersForDropdownDocument = gql`
  query GetProvidersForDropdown(
    $afterCursor: String
    $roleIds: [ID!]
    $searchText: String
  ) {
    users(
      first: 200
      after: $afterCursor
      filter: { roleIds: $roleIds, searchText: $searchText, active: true }
      orderBy: { firstName: ASC }
    ) {
      edges {
        node {
          id
          firstName
          lastName
          role {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __useGetProvidersForDropdownQuery__
 *
 * To run a query within a React component, call `useGetProvidersForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersForDropdownQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      roleIds: // value for 'roleIds'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetProvidersForDropdownQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProvidersForDropdownQuery,
    GetProvidersForDropdownQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProvidersForDropdownQuery,
    GetProvidersForDropdownQueryVariables
  >(GetProvidersForDropdownDocument, options);
}
export function useGetProvidersForDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProvidersForDropdownQuery,
    GetProvidersForDropdownQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProvidersForDropdownQuery,
    GetProvidersForDropdownQueryVariables
  >(GetProvidersForDropdownDocument, options);
}
export function useGetProvidersForDropdownSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetProvidersForDropdownQuery,
        GetProvidersForDropdownQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProvidersForDropdownQuery,
    GetProvidersForDropdownQueryVariables
  >(GetProvidersForDropdownDocument, options);
}
export type GetProvidersForDropdownQueryHookResult = ReturnType<
  typeof useGetProvidersForDropdownQuery
>;
export type GetProvidersForDropdownLazyQueryHookResult = ReturnType<
  typeof useGetProvidersForDropdownLazyQuery
>;
export type GetProvidersForDropdownSuspenseQueryHookResult = ReturnType<
  typeof useGetProvidersForDropdownSuspenseQuery
>;
export type GetProvidersForDropdownQueryResult = Apollo.QueryResult<
  GetProvidersForDropdownQuery,
  GetProvidersForDropdownQueryVariables
>;
export const GetProviderDocument = gql`
  query GetProvider($id: ID!) {
    user(id: $id) {
      ...ProviderUser
    }
  }
  ${ProviderUserFragmentDoc}
`;

/**
 * __useGetProviderQuery__
 *
 * To run a query within a React component, call `useGetProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProviderQuery,
    GetProviderQueryVariables
  > &
    (
      | { variables: GetProviderQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProviderQuery, GetProviderQueryVariables>(
    GetProviderDocument,
    options,
  );
}
export function useGetProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProviderQuery,
    GetProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProviderQuery, GetProviderQueryVariables>(
    GetProviderDocument,
    options,
  );
}
export function useGetProviderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetProviderQuery,
        GetProviderQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProviderQuery, GetProviderQueryVariables>(
    GetProviderDocument,
    options,
  );
}
export type GetProviderQueryHookResult = ReturnType<typeof useGetProviderQuery>;
export type GetProviderLazyQueryHookResult = ReturnType<
  typeof useGetProviderLazyQuery
>;
export type GetProviderSuspenseQueryHookResult = ReturnType<
  typeof useGetProviderSuspenseQuery
>;
export type GetProviderQueryResult = Apollo.QueryResult<
  GetProviderQuery,
  GetProviderQueryVariables
>;
export const GetPatientsDocument = gql`
  query GetPatients(
    $afterCursor: String
    $searchText: String
    $active: Boolean
    $brandId: ID
    $provinceId: ID
    $countryCode: String
  ) {
    users(
      first: 20
      after: $afterCursor
      filter: {
        roleIds: [3]
        searchText: $searchText
        active: $active
        brandId: $brandId
        provinceId: $provinceId
        countryCode: $countryCode
      }
    ) {
      edges {
        node {
          ...PatientQueryUser
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PatientQueryUserFragmentDoc}
`;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *      searchText: // value for 'searchText'
 *      active: // value for 'active'
 *      brandId: // value for 'brandId'
 *      provinceId: // value for 'provinceId'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetPatientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPatientsQuery,
    GetPatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(
    GetPatientsDocument,
    options,
  );
}
export function useGetPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientsQuery,
    GetPatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(
    GetPatientsDocument,
    options,
  );
}
export function useGetPatientsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientsQuery,
        GetPatientsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPatientsQuery, GetPatientsQueryVariables>(
    GetPatientsDocument,
    options,
  );
}
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<
  typeof useGetPatientsLazyQuery
>;
export type GetPatientsSuspenseQueryHookResult = ReturnType<
  typeof useGetPatientsSuspenseQuery
>;
export type GetPatientsQueryResult = Apollo.QueryResult<
  GetPatientsQuery,
  GetPatientsQueryVariables
>;
export const GetPatientDocument = gql`
  query GetPatient(
    $id: ID!
    $positionedBefore: ISO8601Date
    $positionedAfter: ISO8601Date
    $numberOfMedicalRecords: Int
  ) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      preferredName
      phone {
        e164
        rawNumber
      }
      createdAt
      deactivatedAt
      locale
      smsEnabled
      voicemailConsent
      zendeskId
      emrId
      vitals {
        nodes {
          id
          createdAt
          updatedAt
          details
          enteredBy {
            id
            firstName
            lastName
          }
          measuredAt
          type
          ... on BloodPressure {
            systolic
            diastolic
            isBaseline
            timingWithMedication
          }
        }
      }
      brands {
        ...PatientBrandMembership
      }
      lastTreatments {
        ...Treatment
      }
      treatmentEligibilities {
        ...TreatmentEligibility
      }
      profile {
        id
        province {
          ...Province
        }
        healthCareProvince {
          ...Province
        }
        medicalHistoryVersions(
          filter: { numberOfMedicalRecords: $numberOfMedicalRecords }
        ) {
          ...MedicalHistoryVersion
        }
        dateOfBirth
        healthCareNumber
        financialExpectation
        hasPaymentMethod
        lastStripePaymentMethod {
          id
        }
        stripeCustomerId
        ccLastFour
        stripeVerificationSessionId
        verificationStatus
        dateVerified
        allergies
        medications
        medicalConditions
        medInfoConfirmedAt
        deliveryPreference
        lastAffirmPaymentMethod {
          id
          externalId
        }
        shippingAddresses {
          id
          recipientName
          addressType
          street
          street2
          addressType
          city
          province
          postalCode
          deliveryInstructions
          isDefaultAddress
        }
        shippingConfirmedAt
        insuranceCardPhotos {
          downloadUrl
          filename
        }
        insuranceCards {
          id
          label
          isDefaultCard
          frontImage {
            downloadUrl
          }
          backImage {
            downloadUrl
          }
        }
        insuranceCardConfirmedAt
      }
      questionnaires {
        ...FreddieQuestionnaires
      }
      labRequisitions {
        nodes {
          ...LabRequisition
        }
      }
      prescriptions {
        nodes {
          ...Prescription
        }
      }
      appointments {
        nodes {
          ...PatientAppointmentInfo
        }
      }
      shortQuestionnaires {
        ...CoreShortQuestionnaire
      }
      assessments {
        nodes {
          ... on Assessment {
            id
            type
            completedAt
            data
            score
            scoringErrors
            maxPossibleScore
            patientScore
          }
        }
      }
      journeyItems(
        filter: {
          positionedAfter: $positionedAfter
          positionedBefore: $positionedBefore
        }
      ) {
        id
        positionedAt
        createdAt
        completedAt
        __typename
        ... on Cycle {
          workflowSteps {
            ...CycleWorkflowStep
          }
        }
        ... on AcuityAppointment {
          ...CycleAcuityAppointment
        }
        ... on WorkflowStep {
          id
          type
        }
      }
    }
  }
  ${PatientBrandMembershipFragmentDoc}
  ${TreatmentFragmentDoc}
  ${TreatmentEligibilityFragmentDoc}
  ${ProvinceFragmentDoc}
  ${MedicalHistoryVersionFragmentDoc}
  ${FreddieQuestionnairesFragmentDoc}
  ${LabRequisitionFragmentDoc}
  ${PrescriptionFragmentDoc}
  ${PatientAppointmentInfoFragmentDoc}
  ${CoreShortQuestionnaireFragmentDoc}
  ${CycleWorkflowStepFragmentDoc}
  ${CycleAcuityAppointmentFragmentDoc}
`;

/**
 * __useGetPatientQuery__
 *
 * To run a query within a React component, call `useGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *      positionedBefore: // value for 'positionedBefore'
 *      positionedAfter: // value for 'positionedAfter'
 *      numberOfMedicalRecords: // value for 'numberOfMedicalRecords'
 *   },
 * });
 */
export function useGetPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientQuery,
    GetPatientQueryVariables
  > &
    (
      | { variables: GetPatientQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientQuery, GetPatientQueryVariables>(
    GetPatientDocument,
    options,
  );
}
export function useGetPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientQuery,
    GetPatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientQuery, GetPatientQueryVariables>(
    GetPatientDocument,
    options,
  );
}
export function useGetPatientSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientQuery,
        GetPatientQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPatientQuery, GetPatientQueryVariables>(
    GetPatientDocument,
    options,
  );
}
export type GetPatientQueryHookResult = ReturnType<typeof useGetPatientQuery>;
export type GetPatientLazyQueryHookResult = ReturnType<
  typeof useGetPatientLazyQuery
>;
export type GetPatientSuspenseQueryHookResult = ReturnType<
  typeof useGetPatientSuspenseQuery
>;
export type GetPatientQueryResult = Apollo.QueryResult<
  GetPatientQuery,
  GetPatientQueryVariables
>;
export const GetPatientSummaryDocument = gql`
  query GetPatientSummary($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phone {
        e164
        rawNumber
      }
      profile {
        id
        financialExpectation
        province {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetPatientSummaryQuery__
 *
 * To run a query within a React component, call `useGetPatientSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatientSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientSummaryQuery,
    GetPatientSummaryQueryVariables
  > &
    (
      | { variables: GetPatientSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientSummaryQuery,
    GetPatientSummaryQueryVariables
  >(GetPatientSummaryDocument, options);
}
export function useGetPatientSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientSummaryQuery,
    GetPatientSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientSummaryQuery,
    GetPatientSummaryQueryVariables
  >(GetPatientSummaryDocument, options);
}
export function useGetPatientSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientSummaryQuery,
        GetPatientSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPatientSummaryQuery,
    GetPatientSummaryQueryVariables
  >(GetPatientSummaryDocument, options);
}
export type GetPatientSummaryQueryHookResult = ReturnType<
  typeof useGetPatientSummaryQuery
>;
export type GetPatientSummaryLazyQueryHookResult = ReturnType<
  typeof useGetPatientSummaryLazyQuery
>;
export type GetPatientSummarySuspenseQueryHookResult = ReturnType<
  typeof useGetPatientSummarySuspenseQuery
>;
export type GetPatientSummaryQueryResult = Apollo.QueryResult<
  GetPatientSummaryQuery,
  GetPatientSummaryQueryVariables
>;
export const GetFreddiePatientSummaryDocument = gql`
  query GetFreddiePatientSummary($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      locale
      phone {
        e164
        rawNumber
      }
      profile {
        id
        dateOfBirth
        healthCareNumber
        province {
          ...Province
        }
        healthCareProvince {
          ...Province
        }
      }
      prescriptions {
        nodes {
          ...Prescription
        }
      }
    }
  }
  ${ProvinceFragmentDoc}
  ${PrescriptionFragmentDoc}
`;

/**
 * __useGetFreddiePatientSummaryQuery__
 *
 * To run a query within a React component, call `useGetFreddiePatientSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreddiePatientSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreddiePatientSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFreddiePatientSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFreddiePatientSummaryQuery,
    GetFreddiePatientSummaryQueryVariables
  > &
    (
      | { variables: GetFreddiePatientSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFreddiePatientSummaryQuery,
    GetFreddiePatientSummaryQueryVariables
  >(GetFreddiePatientSummaryDocument, options);
}
export function useGetFreddiePatientSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFreddiePatientSummaryQuery,
    GetFreddiePatientSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFreddiePatientSummaryQuery,
    GetFreddiePatientSummaryQueryVariables
  >(GetFreddiePatientSummaryDocument, options);
}
export function useGetFreddiePatientSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFreddiePatientSummaryQuery,
        GetFreddiePatientSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFreddiePatientSummaryQuery,
    GetFreddiePatientSummaryQueryVariables
  >(GetFreddiePatientSummaryDocument, options);
}
export type GetFreddiePatientSummaryQueryHookResult = ReturnType<
  typeof useGetFreddiePatientSummaryQuery
>;
export type GetFreddiePatientSummaryLazyQueryHookResult = ReturnType<
  typeof useGetFreddiePatientSummaryLazyQuery
>;
export type GetFreddiePatientSummarySuspenseQueryHookResult = ReturnType<
  typeof useGetFreddiePatientSummarySuspenseQuery
>;
export type GetFreddiePatientSummaryQueryResult = Apollo.QueryResult<
  GetFreddiePatientSummaryQuery,
  GetFreddiePatientSummaryQueryVariables
>;
export const GetPatientWithAppointmentInfoDocument = gql`
  query GetPatientWithAppointmentInfo($appointmentId: ID!) {
    appointment(id: $appointmentId) {
      ...PatientAcuityAppointmentInfo
      patient {
        id
        email
        firstName
        lastName
        preferredName
        emrId
        prescriptions {
          nodes {
            createdAt
          }
        }
        phone {
          e164
          rawNumber
        }
        voicemailConsent
        profile {
          ... on Profile {
            dateOfBirth
            dateVerified
            verificationStatus
            healthCareNumber
            province {
              id
              name
              code
              supported
            }
            healthCareProvince {
              id
              name
              code
              supported
            }
          }
        }
        questionnaires {
          ...FreddieQuestionnaires
        }
      }
    }
  }
  ${PatientAcuityAppointmentInfoFragmentDoc}
  ${FreddieQuestionnairesFragmentDoc}
`;

/**
 * __useGetPatientWithAppointmentInfoQuery__
 *
 * To run a query within a React component, call `useGetPatientWithAppointmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWithAppointmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWithAppointmentInfoQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useGetPatientWithAppointmentInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientWithAppointmentInfoQuery,
    GetPatientWithAppointmentInfoQueryVariables
  > &
    (
      | {
          variables: GetPatientWithAppointmentInfoQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientWithAppointmentInfoQuery,
    GetPatientWithAppointmentInfoQueryVariables
  >(GetPatientWithAppointmentInfoDocument, options);
}
export function useGetPatientWithAppointmentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientWithAppointmentInfoQuery,
    GetPatientWithAppointmentInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientWithAppointmentInfoQuery,
    GetPatientWithAppointmentInfoQueryVariables
  >(GetPatientWithAppointmentInfoDocument, options);
}
export function useGetPatientWithAppointmentInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientWithAppointmentInfoQuery,
        GetPatientWithAppointmentInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPatientWithAppointmentInfoQuery,
    GetPatientWithAppointmentInfoQueryVariables
  >(GetPatientWithAppointmentInfoDocument, options);
}
export type GetPatientWithAppointmentInfoQueryHookResult = ReturnType<
  typeof useGetPatientWithAppointmentInfoQuery
>;
export type GetPatientWithAppointmentInfoLazyQueryHookResult = ReturnType<
  typeof useGetPatientWithAppointmentInfoLazyQuery
>;
export type GetPatientWithAppointmentInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetPatientWithAppointmentInfoSuspenseQuery
>;
export type GetPatientWithAppointmentInfoQueryResult = Apollo.QueryResult<
  GetPatientWithAppointmentInfoQuery,
  GetPatientWithAppointmentInfoQueryVariables
>;
export const GetPatientTreatmentOverviewDocument = gql`
  query GetPatientTreatmentOverview {
    patientTreatmentOverview {
      onboardingComplete
      onboardingDashboardItems {
        name
        treatmentType
        workflowStep {
          ... on WorkflowStep {
            id
            type
            completedAt
            createdAt
          }
        }
      }
      treatmentDashboardItems {
        name
        treatmentType
        workflowStep {
          ... on WorkflowStep {
            id
            type
            completedAt
          }
        }
      }
      verificationDashboardItem {
        name
      }
    }
  }
`;

/**
 * __useGetPatientTreatmentOverviewQuery__
 *
 * To run a query within a React component, call `useGetPatientTreatmentOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientTreatmentOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientTreatmentOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientTreatmentOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPatientTreatmentOverviewQuery,
    GetPatientTreatmentOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientTreatmentOverviewQuery,
    GetPatientTreatmentOverviewQueryVariables
  >(GetPatientTreatmentOverviewDocument, options);
}
export function useGetPatientTreatmentOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientTreatmentOverviewQuery,
    GetPatientTreatmentOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientTreatmentOverviewQuery,
    GetPatientTreatmentOverviewQueryVariables
  >(GetPatientTreatmentOverviewDocument, options);
}
export function useGetPatientTreatmentOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientTreatmentOverviewQuery,
        GetPatientTreatmentOverviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPatientTreatmentOverviewQuery,
    GetPatientTreatmentOverviewQueryVariables
  >(GetPatientTreatmentOverviewDocument, options);
}
export type GetPatientTreatmentOverviewQueryHookResult = ReturnType<
  typeof useGetPatientTreatmentOverviewQuery
>;
export type GetPatientTreatmentOverviewLazyQueryHookResult = ReturnType<
  typeof useGetPatientTreatmentOverviewLazyQuery
>;
export type GetPatientTreatmentOverviewSuspenseQueryHookResult = ReturnType<
  typeof useGetPatientTreatmentOverviewSuspenseQuery
>;
export type GetPatientTreatmentOverviewQueryResult = Apollo.QueryResult<
  GetPatientTreatmentOverviewQuery,
  GetPatientTreatmentOverviewQueryVariables
>;
export const GetPatientChapterCoverDocument = gql`
  query GetPatientChapterCover($treatmentType: TreatmentType!) {
    patientChapterCover(treatmentType: $treatmentType) {
      type
      workflowStep {
        ... on WorkflowStep {
          id
          completedAt
          type
        }
      }
    }
  }
`;

/**
 * __useGetPatientChapterCoverQuery__
 *
 * To run a query within a React component, call `useGetPatientChapterCoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientChapterCoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientChapterCoverQuery({
 *   variables: {
 *      treatmentType: // value for 'treatmentType'
 *   },
 * });
 */
export function useGetPatientChapterCoverQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientChapterCoverQuery,
    GetPatientChapterCoverQueryVariables
  > &
    (
      | { variables: GetPatientChapterCoverQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientChapterCoverQuery,
    GetPatientChapterCoverQueryVariables
  >(GetPatientChapterCoverDocument, options);
}
export function useGetPatientChapterCoverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientChapterCoverQuery,
    GetPatientChapterCoverQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientChapterCoverQuery,
    GetPatientChapterCoverQueryVariables
  >(GetPatientChapterCoverDocument, options);
}
export function useGetPatientChapterCoverSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPatientChapterCoverQuery,
        GetPatientChapterCoverQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPatientChapterCoverQuery,
    GetPatientChapterCoverQueryVariables
  >(GetPatientChapterCoverDocument, options);
}
export type GetPatientChapterCoverQueryHookResult = ReturnType<
  typeof useGetPatientChapterCoverQuery
>;
export type GetPatientChapterCoverLazyQueryHookResult = ReturnType<
  typeof useGetPatientChapterCoverLazyQuery
>;
export type GetPatientChapterCoverSuspenseQueryHookResult = ReturnType<
  typeof useGetPatientChapterCoverSuspenseQuery
>;
export type GetPatientChapterCoverQueryResult = Apollo.QueryResult<
  GetPatientChapterCoverQuery,
  GetPatientChapterCoverQueryVariables
>;
export const ActivateUserAccountDocument = gql`
  mutation ActivateUserAccount($id: ID!) {
    activateUserAccount(input: { id: $id }) {
      errors
    }
  }
`;
export type ActivateUserAccountMutationFn = Apollo.MutationFunction<
  ActivateUserAccountMutation,
  ActivateUserAccountMutationVariables
>;

/**
 * __useActivateUserAccountMutation__
 *
 * To run a mutation, you first call `useActivateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserAccountMutation, { data, loading, error }] = useActivateUserAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateUserAccountMutation,
    ActivateUserAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateUserAccountMutation,
    ActivateUserAccountMutationVariables
  >(ActivateUserAccountDocument, options);
}
export type ActivateUserAccountMutationHookResult = ReturnType<
  typeof useActivateUserAccountMutation
>;
export type ActivateUserAccountMutationResult =
  Apollo.MutationResult<ActivateUserAccountMutation>;
export type ActivateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  ActivateUserAccountMutation,
  ActivateUserAccountMutationVariables
>;
export const DeactivateUserAccountDocument = gql`
  mutation DeactivateUserAccount($id: ID!) {
    deactivateUserAccount(input: { id: $id }) {
      errors
    }
  }
`;
export type DeactivateUserAccountMutationFn = Apollo.MutationFunction<
  DeactivateUserAccountMutation,
  DeactivateUserAccountMutationVariables
>;

/**
 * __useDeactivateUserAccountMutation__
 *
 * To run a mutation, you first call `useDeactivateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserAccountMutation, { data, loading, error }] = useDeactivateUserAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateUserAccountMutation,
    DeactivateUserAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateUserAccountMutation,
    DeactivateUserAccountMutationVariables
  >(DeactivateUserAccountDocument, options);
}
export type DeactivateUserAccountMutationHookResult = ReturnType<
  typeof useDeactivateUserAccountMutation
>;
export type DeactivateUserAccountMutationResult =
  Apollo.MutationResult<DeactivateUserAccountMutation>;
export type DeactivateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserAccountMutation,
  DeactivateUserAccountMutationVariables
>;
export const UpdatePatientDocument = gql`
  mutation UpdatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      errors
    }
  }
`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >(UpdatePatientDocument, options);
}
export type UpdatePatientMutationHookResult = ReturnType<
  typeof useUpdatePatientMutation
>;
export type UpdatePatientMutationResult =
  Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;
export const CreateProviderDocument = gql`
  mutation CreateProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      errors
    }
  }
`;
export type CreateProviderMutationFn = Apollo.MutationFunction<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >(CreateProviderDocument, options);
}
export type CreateProviderMutationHookResult = ReturnType<
  typeof useCreateProviderMutation
>;
export type CreateProviderMutationResult =
  Apollo.MutationResult<CreateProviderMutation>;
export type CreateProviderMutationOptions = Apollo.BaseMutationOptions<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;
export const UpdateProviderDocument = gql`
  mutation UpdateProvider($input: UpdateProviderInput!) {
    updateProvider(input: $input) {
      errors
    }
  }
`;
export type UpdateProviderMutationFn = Apollo.MutationFunction<
  UpdateProviderMutation,
  UpdateProviderMutationVariables
>;

/**
 * __useUpdateProviderMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMutation, { data, loading, error }] = useUpdateProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProviderMutation,
    UpdateProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProviderMutation,
    UpdateProviderMutationVariables
  >(UpdateProviderDocument, options);
}
export type UpdateProviderMutationHookResult = ReturnType<
  typeof useUpdateProviderMutation
>;
export type UpdateProviderMutationResult =
  Apollo.MutationResult<UpdateProviderMutation>;
export type UpdateProviderMutationOptions = Apollo.BaseMutationOptions<
  UpdateProviderMutation,
  UpdateProviderMutationVariables
>;
export const CreateAssessmentDocument = gql`
  mutation CreateAssessment($input: CreateAssessmentInput!) {
    createAssessment(input: $input) {
      assessment {
        id
        type
      }
      errors
    }
  }
`;
export type CreateAssessmentMutationFn = Apollo.MutationFunction<
  CreateAssessmentMutation,
  CreateAssessmentMutationVariables
>;

/**
 * __useCreateAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentMutation, { data, loading, error }] = useCreateAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssessmentMutation,
    CreateAssessmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssessmentMutation,
    CreateAssessmentMutationVariables
  >(CreateAssessmentDocument, options);
}
export type CreateAssessmentMutationHookResult = ReturnType<
  typeof useCreateAssessmentMutation
>;
export type CreateAssessmentMutationResult =
  Apollo.MutationResult<CreateAssessmentMutation>;
export type CreateAssessmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssessmentMutation,
  CreateAssessmentMutationVariables
>;
export const UpdateAssessmentDocument = gql`
  mutation UpdateAssessment($input: UpdateAssessmentInput!) {
    updateAssessment(input: $input) {
      assessment {
        id
        type
        completedAt
        data
      }
      errors
    }
  }
`;
export type UpdateAssessmentMutationFn = Apollo.MutationFunction<
  UpdateAssessmentMutation,
  UpdateAssessmentMutationVariables
>;

/**
 * __useUpdateAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentMutation, { data, loading, error }] = useUpdateAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssessmentMutation,
    UpdateAssessmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssessmentMutation,
    UpdateAssessmentMutationVariables
  >(UpdateAssessmentDocument, options);
}
export type UpdateAssessmentMutationHookResult = ReturnType<
  typeof useUpdateAssessmentMutation
>;
export type UpdateAssessmentMutationResult =
  Apollo.MutationResult<UpdateAssessmentMutation>;
export type UpdateAssessmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssessmentMutation,
  UpdateAssessmentMutationVariables
>;
export const CompleteAssessmentDocument = gql`
  mutation CompleteAssessment($input: CompleteAssessmentInput!) {
    completeAssessment(input: $input) {
      assessment {
        id
        type
        completedAt
        data
      }
      errors
    }
  }
`;
export type CompleteAssessmentMutationFn = Apollo.MutationFunction<
  CompleteAssessmentMutation,
  CompleteAssessmentMutationVariables
>;

/**
 * __useCompleteAssessmentMutation__
 *
 * To run a mutation, you first call `useCompleteAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssessmentMutation, { data, loading, error }] = useCompleteAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAssessmentMutation,
    CompleteAssessmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteAssessmentMutation,
    CompleteAssessmentMutationVariables
  >(CompleteAssessmentDocument, options);
}
export type CompleteAssessmentMutationHookResult = ReturnType<
  typeof useCompleteAssessmentMutation
>;
export type CompleteAssessmentMutationResult =
  Apollo.MutationResult<CompleteAssessmentMutation>;
export type CompleteAssessmentMutationOptions = Apollo.BaseMutationOptions<
  CompleteAssessmentMutation,
  CompleteAssessmentMutationVariables
>;
export const ValidateEmailDocument = gql`
  mutation ValidateEmail($input: ValidateEmailInput!) {
    validateEmail(input: $input) {
      result {
        local
        suggestion
        verdict
      }
      errors
    }
  }
`;
export type ValidateEmailMutationFn = Apollo.MutationFunction<
  ValidateEmailMutation,
  ValidateEmailMutationVariables
>;

/**
 * __useValidateEmailMutation__
 *
 * To run a mutation, you first call `useValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailMutation, { data, loading, error }] = useValidateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateEmailMutation,
    ValidateEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateEmailMutation,
    ValidateEmailMutationVariables
  >(ValidateEmailDocument, options);
}
export type ValidateEmailMutationHookResult = ReturnType<
  typeof useValidateEmailMutation
>;
export type ValidateEmailMutationResult =
  Apollo.MutationResult<ValidateEmailMutation>;
export type ValidateEmailMutationOptions = Apollo.BaseMutationOptions<
  ValidateEmailMutation,
  ValidateEmailMutationVariables
>;
export const CreateVitalDocument = gql`
  mutation CreateVital($input: CreateVitalInput!) {
    createVital(input: $input) {
      errors
    }
  }
`;
export type CreateVitalMutationFn = Apollo.MutationFunction<
  CreateVitalMutation,
  CreateVitalMutationVariables
>;

/**
 * __useCreateVitalMutation__
 *
 * To run a mutation, you first call `useCreateVitalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVitalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVitalMutation, { data, loading, error }] = useCreateVitalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVitalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVitalMutation,
    CreateVitalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVitalMutation, CreateVitalMutationVariables>(
    CreateVitalDocument,
    options,
  );
}
export type CreateVitalMutationHookResult = ReturnType<
  typeof useCreateVitalMutation
>;
export type CreateVitalMutationResult =
  Apollo.MutationResult<CreateVitalMutation>;
export type CreateVitalMutationOptions = Apollo.BaseMutationOptions<
  CreateVitalMutation,
  CreateVitalMutationVariables
>;
export const UpdateVitalDocument = gql`
  mutation UpdateVital($input: UpdateVitalInput!) {
    updateVital(input: $input) {
      errors
    }
  }
`;
export type UpdateVitalMutationFn = Apollo.MutationFunction<
  UpdateVitalMutation,
  UpdateVitalMutationVariables
>;

/**
 * __useUpdateVitalMutation__
 *
 * To run a mutation, you first call `useUpdateVitalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVitalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVitalMutation, { data, loading, error }] = useUpdateVitalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVitalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVitalMutation,
    UpdateVitalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVitalMutation, UpdateVitalMutationVariables>(
    UpdateVitalDocument,
    options,
  );
}
export type UpdateVitalMutationHookResult = ReturnType<
  typeof useUpdateVitalMutation
>;
export type UpdateVitalMutationResult =
  Apollo.MutationResult<UpdateVitalMutation>;
export type UpdateVitalMutationOptions = Apollo.BaseMutationOptions<
  UpdateVitalMutation,
  UpdateVitalMutationVariables
>;
export const GetMyVitalsDocument = gql`
  query GetMyVitals {
    me {
      vitals {
        edges {
          node {
            ...MyBloodPressure
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${MyBloodPressureFragmentDoc}
`;

/**
 * __useGetMyVitalsQuery__
 *
 * To run a query within a React component, call `useGetMyVitalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVitalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVitalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyVitalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyVitalsQuery,
    GetMyVitalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyVitalsQuery, GetMyVitalsQueryVariables>(
    GetMyVitalsDocument,
    options,
  );
}
export function useGetMyVitalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyVitalsQuery,
    GetMyVitalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyVitalsQuery, GetMyVitalsQueryVariables>(
    GetMyVitalsDocument,
    options,
  );
}
export function useGetMyVitalsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyVitalsQuery,
        GetMyVitalsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyVitalsQuery, GetMyVitalsQueryVariables>(
    GetMyVitalsDocument,
    options,
  );
}
export type GetMyVitalsQueryHookResult = ReturnType<typeof useGetMyVitalsQuery>;
export type GetMyVitalsLazyQueryHookResult = ReturnType<
  typeof useGetMyVitalsLazyQuery
>;
export type GetMyVitalsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyVitalsSuspenseQuery
>;
export type GetMyVitalsQueryResult = Apollo.QueryResult<
  GetMyVitalsQuery,
  GetMyVitalsQueryVariables
>;
export const GetUsersWorkflowStepsDocument = gql`
  query GetUsersWorkflowSteps(
    $patientId: ID
    $stepType: String
    $completedById: ID
  ) {
    workflowSteps(
      first: 10
      filter: {
        patientId: $patientId
        stepType: $stepType
        completedById: $completedById
      }
    ) {
      edges {
        node {
          ... on WorkflowStep {
            id
            type
            parentStep {
              id
              subject {
                ... on AcuityAppointment {
                  id
                }
              }
            }
          }
        }
      }
      nodes {
        ...WorkflowStepBasicData
      }
    }
  }
  ${WorkflowStepBasicDataFragmentDoc}
`;

/**
 * __useGetUsersWorkflowStepsQuery__
 *
 * To run a query within a React component, call `useGetUsersWorkflowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWorkflowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWorkflowStepsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      stepType: // value for 'stepType'
 *      completedById: // value for 'completedById'
 *   },
 * });
 */
export function useGetUsersWorkflowStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersWorkflowStepsQuery,
    GetUsersWorkflowStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersWorkflowStepsQuery,
    GetUsersWorkflowStepsQueryVariables
  >(GetUsersWorkflowStepsDocument, options);
}
export function useGetUsersWorkflowStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersWorkflowStepsQuery,
    GetUsersWorkflowStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersWorkflowStepsQuery,
    GetUsersWorkflowStepsQueryVariables
  >(GetUsersWorkflowStepsDocument, options);
}
export function useGetUsersWorkflowStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUsersWorkflowStepsQuery,
        GetUsersWorkflowStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUsersWorkflowStepsQuery,
    GetUsersWorkflowStepsQueryVariables
  >(GetUsersWorkflowStepsDocument, options);
}
export type GetUsersWorkflowStepsQueryHookResult = ReturnType<
  typeof useGetUsersWorkflowStepsQuery
>;
export type GetUsersWorkflowStepsLazyQueryHookResult = ReturnType<
  typeof useGetUsersWorkflowStepsLazyQuery
>;
export type GetUsersWorkflowStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersWorkflowStepsSuspenseQuery
>;
export type GetUsersWorkflowStepsQueryResult = Apollo.QueryResult<
  GetUsersWorkflowStepsQuery,
  GetUsersWorkflowStepsQueryVariables
>;
export const GetMyWorkflowStepsDocument = gql`
  query GetMyWorkflowSteps {
    me {
      id
      currentBrandMembership {
        id
        screenedOutStep {
          id
          type
        }
        availableBookingTypes {
          id
          bookingType
          workflowStepId
        }
      }
      workflowSteps {
        ...CoreWorkflowStepFields
      }
    }
  }
  ${CoreWorkflowStepFieldsFragmentDoc}
`;

/**
 * __useGetMyWorkflowStepsQuery__
 *
 * To run a query within a React component, call `useGetMyWorkflowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWorkflowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWorkflowStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyWorkflowStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyWorkflowStepsQuery,
    GetMyWorkflowStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyWorkflowStepsQuery,
    GetMyWorkflowStepsQueryVariables
  >(GetMyWorkflowStepsDocument, options);
}
export function useGetMyWorkflowStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyWorkflowStepsQuery,
    GetMyWorkflowStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyWorkflowStepsQuery,
    GetMyWorkflowStepsQueryVariables
  >(GetMyWorkflowStepsDocument, options);
}
export function useGetMyWorkflowStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMyWorkflowStepsQuery,
        GetMyWorkflowStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyWorkflowStepsQuery,
    GetMyWorkflowStepsQueryVariables
  >(GetMyWorkflowStepsDocument, options);
}
export type GetMyWorkflowStepsQueryHookResult = ReturnType<
  typeof useGetMyWorkflowStepsQuery
>;
export type GetMyWorkflowStepsLazyQueryHookResult = ReturnType<
  typeof useGetMyWorkflowStepsLazyQuery
>;
export type GetMyWorkflowStepsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyWorkflowStepsSuspenseQuery
>;
export type GetMyWorkflowStepsQueryResult = Apollo.QueryResult<
  GetMyWorkflowStepsQuery,
  GetMyWorkflowStepsQueryVariables
>;
export const GetWorkflowStepDocument = gql`
  query GetWorkflowStep($id: ID!) {
    workflowStep(id: $id) {
      id
      completionParams
      workflowStepRelations {
        id
        workflowable {
          ...WorkflowStepPrescriptionResponse
        }
      }
    }
  }
  ${WorkflowStepPrescriptionResponseFragmentDoc}
`;

/**
 * __useGetWorkflowStepQuery__
 *
 * To run a query within a React component, call `useGetWorkflowStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkflowStepQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkflowStepQuery,
    GetWorkflowStepQueryVariables
  > &
    (
      | { variables: GetWorkflowStepQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkflowStepQuery, GetWorkflowStepQueryVariables>(
    GetWorkflowStepDocument,
    options,
  );
}
export function useGetWorkflowStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkflowStepQuery,
    GetWorkflowStepQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkflowStepQuery,
    GetWorkflowStepQueryVariables
  >(GetWorkflowStepDocument, options);
}
export function useGetWorkflowStepSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWorkflowStepQuery,
        GetWorkflowStepQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWorkflowStepQuery,
    GetWorkflowStepQueryVariables
  >(GetWorkflowStepDocument, options);
}
export type GetWorkflowStepQueryHookResult = ReturnType<
  typeof useGetWorkflowStepQuery
>;
export type GetWorkflowStepLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowStepLazyQuery
>;
export type GetWorkflowStepSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkflowStepSuspenseQuery
>;
export type GetWorkflowStepQueryResult = Apollo.QueryResult<
  GetWorkflowStepQuery,
  GetWorkflowStepQueryVariables
>;
export const CompleteWorkflowStepDocument = gql`
  mutation CompleteWorkflowStep($input: CompleteWorkflowStepInput!) {
    completeWorkflowStep(input: $input) {
      errors
    }
  }
`;
export type CompleteWorkflowStepMutationFn = Apollo.MutationFunction<
  CompleteWorkflowStepMutation,
  CompleteWorkflowStepMutationVariables
>;

/**
 * __useCompleteWorkflowStepMutation__
 *
 * To run a mutation, you first call `useCompleteWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeWorkflowStepMutation, { data, loading, error }] = useCompleteWorkflowStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteWorkflowStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteWorkflowStepMutation,
    CompleteWorkflowStepMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteWorkflowStepMutation,
    CompleteWorkflowStepMutationVariables
  >(CompleteWorkflowStepDocument, options);
}
export type CompleteWorkflowStepMutationHookResult = ReturnType<
  typeof useCompleteWorkflowStepMutation
>;
export type CompleteWorkflowStepMutationResult =
  Apollo.MutationResult<CompleteWorkflowStepMutation>;
export type CompleteWorkflowStepMutationOptions = Apollo.BaseMutationOptions<
  CompleteWorkflowStepMutation,
  CompleteWorkflowStepMutationVariables
>;
export const CompleteWorkflowStepsDocument = gql`
  mutation CompleteWorkflowSteps($input: CompleteWorkflowStepsInput!) {
    completeWorkflowSteps(input: $input) {
      errors
    }
  }
`;
export type CompleteWorkflowStepsMutationFn = Apollo.MutationFunction<
  CompleteWorkflowStepsMutation,
  CompleteWorkflowStepsMutationVariables
>;

/**
 * __useCompleteWorkflowStepsMutation__
 *
 * To run a mutation, you first call `useCompleteWorkflowStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteWorkflowStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeWorkflowStepsMutation, { data, loading, error }] = useCompleteWorkflowStepsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteWorkflowStepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteWorkflowStepsMutation,
    CompleteWorkflowStepsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteWorkflowStepsMutation,
    CompleteWorkflowStepsMutationVariables
  >(CompleteWorkflowStepsDocument, options);
}
export type CompleteWorkflowStepsMutationHookResult = ReturnType<
  typeof useCompleteWorkflowStepsMutation
>;
export type CompleteWorkflowStepsMutationResult =
  Apollo.MutationResult<CompleteWorkflowStepsMutation>;
export type CompleteWorkflowStepsMutationOptions = Apollo.BaseMutationOptions<
  CompleteWorkflowStepsMutation,
  CompleteWorkflowStepsMutationVariables
>;
export const UpdateWorkflowStepDocument = gql`
  mutation UpdateWorkflowStep($input: UpdateWorkflowStepInput!) {
    updateWorkflowStep(input: $input) {
      errors
    }
  }
`;
export type UpdateWorkflowStepMutationFn = Apollo.MutationFunction<
  UpdateWorkflowStepMutation,
  UpdateWorkflowStepMutationVariables
>;

/**
 * __useUpdateWorkflowStepMutation__
 *
 * To run a mutation, you first call `useUpdateWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkflowStepMutation, { data, loading, error }] = useUpdateWorkflowStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkflowStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkflowStepMutation,
    UpdateWorkflowStepMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkflowStepMutation,
    UpdateWorkflowStepMutationVariables
  >(UpdateWorkflowStepDocument, options);
}
export type UpdateWorkflowStepMutationHookResult = ReturnType<
  typeof useUpdateWorkflowStepMutation
>;
export type UpdateWorkflowStepMutationResult =
  Apollo.MutationResult<UpdateWorkflowStepMutation>;
export type UpdateWorkflowStepMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkflowStepMutation,
  UpdateWorkflowStepMutationVariables
>;
export const OverrideWorkflowStepDocument = gql`
  mutation OverrideWorkflowStep($input: OverrideWorkflowStepInput!) {
    overrideWorkflowStep(input: $input) {
      errors
    }
  }
`;
export type OverrideWorkflowStepMutationFn = Apollo.MutationFunction<
  OverrideWorkflowStepMutation,
  OverrideWorkflowStepMutationVariables
>;

/**
 * __useOverrideWorkflowStepMutation__
 *
 * To run a mutation, you first call `useOverrideWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideWorkflowStepMutation, { data, loading, error }] = useOverrideWorkflowStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverrideWorkflowStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverrideWorkflowStepMutation,
    OverrideWorkflowStepMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OverrideWorkflowStepMutation,
    OverrideWorkflowStepMutationVariables
  >(OverrideWorkflowStepDocument, options);
}
export type OverrideWorkflowStepMutationHookResult = ReturnType<
  typeof useOverrideWorkflowStepMutation
>;
export type OverrideWorkflowStepMutationResult =
  Apollo.MutationResult<OverrideWorkflowStepMutation>;
export type OverrideWorkflowStepMutationOptions = Apollo.BaseMutationOptions<
  OverrideWorkflowStepMutation,
  OverrideWorkflowStepMutationVariables
>;
const http = graphql.link('http://localhost:3001/graphql');

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateRefillMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { initiateRefill }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockInitiateRefillMutationHttp = (
  resolver: GraphQLResponseResolver<
    InitiateRefillMutation,
    InitiateRefillMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<InitiateRefillMutation, InitiateRefillMutationVariables>(
    'InitiateRefill',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockOnboardAdditionalTreatmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { onboardAdditionalTreatment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockOnboardAdditionalTreatmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    OnboardAdditionalTreatmentMutation,
    OnboardAdditionalTreatmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    OnboardAdditionalTreatmentMutation,
    OnboardAdditionalTreatmentMutationVariables
  >('OnboardAdditionalTreatment', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockReferralProgramRegisterMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { referralProgramRegister }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockReferralProgramRegisterMutationHttp = (
  resolver: GraphQLResponseResolver<
    ReferralProgramRegisterMutation,
    ReferralProgramRegisterMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    ReferralProgramRegisterMutation,
    ReferralProgramRegisterMutationVariables
  >('ReferralProgramRegister', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockResetTreatmentAndQuestionnaireMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { resetTreatmentAndQuestionnaire }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockResetTreatmentAndQuestionnaireMutationHttp = (
  resolver: GraphQLResponseResolver<
    ResetTreatmentAndQuestionnaireMutation,
    ResetTreatmentAndQuestionnaireMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    ResetTreatmentAndQuestionnaireMutation,
    ResetTreatmentAndQuestionnaireMutationVariables
  >('resetTreatmentAndQuestionnaire', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStartPatientRefillMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { startPatientRefill }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStartPatientRefillMutationHttp = (
  resolver: GraphQLResponseResolver<
    StartPatientRefillMutation,
    StartPatientRefillMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    StartPatientRefillMutation,
    StartPatientRefillMutationVariables
  >('StartPatientRefill', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAcuityAccountsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { acuityAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAcuityAccountsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetAcuityAccountsQuery,
    GetAcuityAccountsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetAcuityAccountsQuery, GetAcuityAccountsQueryVariables>(
    'GetAcuityAccounts',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAcuityAppointmentTypesQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { acuityAppointmentTypes }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAcuityAppointmentTypesQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetAcuityAppointmentTypesQuery,
    GetAcuityAppointmentTypesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetAcuityAppointmentTypesQuery,
    GetAcuityAppointmentTypesQueryVariables
  >('GetAcuityAppointmentTypes', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteAcuityAppointmentTypeMutationHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { deleteAcuityAppointmentType }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteAcuityAppointmentTypeMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteAcuityAppointmentTypeMutation,
    DeleteAcuityAppointmentTypeMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    DeleteAcuityAppointmentTypeMutation,
    DeleteAcuityAppointmentTypeMutationVariables
  >('DeleteAcuityAppointmentType', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateAcuityAppointmentTypeMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createAcuityAppointmentType }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateAcuityAppointmentTypeMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateAcuityAppointmentTypeMutation,
    CreateAcuityAppointmentTypeMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateAcuityAppointmentTypeMutation,
    CreateAcuityAppointmentTypeMutationVariables
  >('CreateAcuityAppointmentType', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateAcuityAppointmentTypeMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateAcuityAppointmentType }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateAcuityAppointmentTypeMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateAcuityAppointmentTypeMutation,
    UpdateAcuityAppointmentTypeMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdateAcuityAppointmentTypeMutation,
    UpdateAcuityAppointmentTypeMutationVariables
  >('UpdateAcuityAppointmentType', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCalendarsForUserQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetCalendarsForUserQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetCalendarsForUserQuery,
    GetCalendarsForUserQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetCalendarsForUserQuery, GetCalendarsForUserQueryVariables>(
    'GetCalendarsForUser',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCalendarMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createAcuityCalendar }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateCalendarMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateCalendarMutation,
    CreateCalendarMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreateCalendarMutation, CreateCalendarMutationVariables>(
    'CreateCalendar',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteCalendarMutationHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { deleteAcuityCalendar }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteCalendarMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteCalendarMutation,
    DeleteCalendarMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<DeleteCalendarMutation, DeleteCalendarMutationVariables>(
    'DeleteCalendar',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateCalendarMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateAcuityCalendar }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateCalendarMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateCalendarMutation,
    UpdateCalendarMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdateCalendarMutation, UpdateCalendarMutationVariables>(
    'UpdateCalendar',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPendingAgreementsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPendingAgreementsQueryHttp = (
  resolver: GraphQLResponseResolver<
    PendingAgreementsQuery,
    PendingAgreementsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<PendingAgreementsQuery, PendingAgreementsQueryVariables>(
    'PendingAgreements',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePatientAgreementsMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createPatientAgreements }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreatePatientAgreementsMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreatePatientAgreementsMutation,
    CreatePatientAgreementsMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreatePatientAgreementsMutation,
    CreatePatientAgreementsMutationVariables
  >('CreatePatientAgreements', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAvailableAppointmentDatesQueryHttp(
 *   ({ query, variables }) => {
 *     const { date, province, bookingReason, workflowStepId, appointmentTypeId, numberOfMonths } = variables;
 *     return HttpResponse.json({
 *       data: { availableAppointmentDates }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAvailableAppointmentDatesQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetAvailableAppointmentDatesQuery,
    GetAvailableAppointmentDatesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetAvailableAppointmentDatesQuery,
    GetAvailableAppointmentDatesQueryVariables
  >('GetAvailableAppointmentDates', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAvailableAppointmentTimesQueryHttp(
 *   ({ query, variables }) => {
 *     const { date, province, bookingReason, appointmentTypeId, workflowStepId } = variables;
 *     return HttpResponse.json({
 *       data: { availableAppointmentTimes }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAvailableAppointmentTimesQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetAvailableAppointmentTimesQuery,
    GetAvailableAppointmentTimesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetAvailableAppointmentTimesQuery,
    GetAvailableAppointmentTimesQueryVariables
  >('GetAvailableAppointmentTimes', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAppointmentsQueryHttp(
 *   ({ query, variables }) => {
 *     const { scheduledBefore, scheduledAfter, calendarId, provinceId, patientId, clinicianId } = variables;
 *     return HttpResponse.json({
 *       data: { appointments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAppointmentsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetAppointmentsQuery, GetAppointmentsQueryVariables>(
    'GetAppointments',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAppointmentsListQueryHttp(
 *   ({ query, variables }) => {
 *     const { scheduledBefore, scheduledAfter, calendarId, provinceId, patientId, clinicianId, countryCode } = variables;
 *     return HttpResponse.json({
 *       data: { appointments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAppointmentsListQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetAppointmentsListQuery,
    GetAppointmentsListQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetAppointmentsListQuery, GetAppointmentsListQueryVariables>(
    'GetAppointmentsList',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAvailableTimeslotsForBookingSessionQueryHttp(
 *   ({ query, variables }) => {
 *     const { bookingSessionId, bookingDate } = variables;
 *     return HttpResponse.json({
 *       data: { availableTimeSlotsForBookingSession }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAvailableTimeslotsForBookingSessionQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetAvailableTimeslotsForBookingSessionQuery,
    GetAvailableTimeslotsForBookingSessionQueryVariables
  >('GetAvailableTimeslotsForBookingSession', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBookAppointmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { bookAppointment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBookAppointmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    BookAppointmentMutation,
    BookAppointmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<BookAppointmentMutation, BookAppointmentMutationVariables>(
    'BookAppointment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelAppointmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { cancelAppointment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCancelAppointmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(
    'CancelAppointment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRescheduleAppointmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { rescheduleAppointment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRescheduleAppointmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    RescheduleAppointmentMutation,
    RescheduleAppointmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    RescheduleAppointmentMutation,
    RescheduleAppointmentMutationVariables
  >('RescheduleAppointment', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStartBookingSessionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { startBookingSession }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStartBookingSessionMutationHttp = (
  resolver: GraphQLResponseResolver<
    StartBookingSessionMutation,
    StartBookingSessionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    StartBookingSessionMutation,
    StartBookingSessionMutationVariables
  >('StartBookingSession', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBookAppointmentWithBookingSessionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { bookAppointmentWithBookingSession }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBookAppointmentWithBookingSessionMutationHttp = (
  resolver: GraphQLResponseResolver<
    BookAppointmentWithBookingSessionMutation,
    BookAppointmentWithBookingSessionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    BookAppointmentWithBookingSessionMutation,
    BookAppointmentWithBookingSessionMutationVariables
  >('BookAppointmentWithBookingSession', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPatientAppointmentQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { appointment, platformSettings }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPatientAppointmentQueryHttp = (
  resolver: GraphQLResponseResolver<
    PatientAppointmentQuery,
    PatientAppointmentQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<PatientAppointmentQuery, PatientAppointmentQueryVariables>(
    'PatientAppointment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetRecentAssessmentScalesQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId } = variables;
 *     return HttpResponse.json({
 *       data: { assessments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetRecentAssessmentScalesQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetRecentAssessmentScalesQuery,
    GetRecentAssessmentScalesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetRecentAssessmentScalesQuery,
    GetRecentAssessmentScalesQueryVariables
  >('GetRecentAssessmentScales', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLoginMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { login }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockLoginMutationHttp = (
  resolver: GraphQLResponseResolver<LoginMutation, LoginMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  http.mutation<LoginMutation, LoginMutationVariables>(
    'Login',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockResetPasswordMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { resetPassword }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockResetPasswordMutationHttp = (
  resolver: GraphQLResponseResolver<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    'ResetPassword',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePasswordResetUrlMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createPasswordResetUrl }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreatePasswordResetUrlMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreatePasswordResetUrlMutation,
    CreatePasswordResetUrlMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreatePasswordResetUrlMutation,
    CreatePasswordResetUrlMutationVariables
  >('CreatePasswordResetUrl', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRecoverPasswordMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { recoverPassword }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRecoverPasswordMutationHttp = (
  resolver: GraphQLResponseResolver<
    RecoverPasswordMutation,
    RecoverPasswordMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<RecoverPasswordMutation, RecoverPasswordMutationVariables>(
    'RecoverPassword',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePasswordMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createPassword }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreatePasswordMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreatePasswordMutation,
    CreatePasswordMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreatePasswordMutation, CreatePasswordMutationVariables>(
    'CreatePassword',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateConsultMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createConsult }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateConsultMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateConsultMutation,
    CreateConsultMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreateConsultMutation, CreateConsultMutationVariables>(
    'CreateConsult',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPostConsultActionsQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, dueBefore, consultAttended, provinceId } = variables;
 *     return HttpResponse.json({
 *       data: { postConsultActions }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPostConsultActionsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPostConsultActionsQuery,
    GetPostConsultActionsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPostConsultActionsQuery, GetPostConsultActionsQueryVariables>(
    'GetPostConsultActions',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetConversationsQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, unreadById, resolvedById, confidential, sortOrder, brandIds, provinceIds, resolved, roleIds, participantIds, anyParticipantIds, status } = variables;
 *     return HttpResponse.json({
 *       data: { conversations }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetConversationsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetConversationsQuery,
    GetConversationsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetConversationsQuery, GetConversationsQueryVariables>(
    'GetConversations',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetConversationsWithModifiedPaginationQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, beforeCursor, last, first, unreadById, resolvedById, confidential, sortOrder, brandIds, provinceIds, resolved, roleIds, participantIds, anyParticipantIds, status } = variables;
 *     return HttpResponse.json({
 *       data: { conversationsNoMerging }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetConversationsWithModifiedPaginationQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetConversationsWithModifiedPaginationQuery,
    GetConversationsWithModifiedPaginationQueryVariables
  >('GetConversationsWithModifiedPagination', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPersonalConversationsQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, unreadById, brandIds, provinceIds, participantIds, anyParticipantIds, roleIds, status } = variables;
 *     return HttpResponse.json({
 *       data: { conversations }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPersonalConversationsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPersonalConversationsQuery,
    GetPersonalConversationsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetPersonalConversationsQuery,
    GetPersonalConversationsQueryVariables
  >('GetPersonalConversations', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetConversationQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { conversation }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetConversationQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetConversationQuery,
    GetConversationQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetConversationQuery, GetConversationQueryVariables>(
    'GetConversation',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateMessageMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createMessage }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateMessageMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreateMessageMutation, CreateMessageMutationVariables>(
    'CreateMessage',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMarkConversationAsReadMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { markConversationAsRead }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMarkConversationAsReadMutationHttp = (
  resolver: GraphQLResponseResolver<
    MarkConversationAsReadMutation,
    MarkConversationAsReadMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    MarkConversationAsReadMutation,
    MarkConversationAsReadMutationVariables
  >('MarkConversationAsRead', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockReadMessageMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { markMessageAsRead }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockReadMessageMutationHttp = (
  resolver: GraphQLResponseResolver<
    ReadMessageMutation,
    ReadMessageMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<ReadMessageMutation, ReadMessageMutationVariables>(
    'ReadMessage',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteAttachmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { deleteAttachment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteAttachmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteAttachmentMutation,
    DeleteAttachmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(
    'DeleteAttachment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMessageStatisticsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { messageStatistics }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMessageStatisticsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMessageStatisticsQuery,
    GetMessageStatisticsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMessageStatisticsQuery, GetMessageStatisticsQueryVariables>(
    'GetMessageStatistics',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientConversationsQueryHttp(
 *   ({ query, variables }) => {
 *     const { otherParticipantId, brandIds } = variables;
 *     return HttpResponse.json({
 *       data: { conversations }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientConversationsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPatientConversationsQuery,
    GetPatientConversationsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetPatientConversationsQuery,
    GetPatientConversationsQueryVariables
  >('GetPatientConversations', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyConversationsQueryHttp(
 *   ({ query, variables }) => {
 *     const { unreadById, status } = variables;
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMyConversationsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMyConversationsQuery,
    GetMyConversationsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMyConversationsQuery, GetMyConversationsQueryVariables>(
    'GetMyConversations',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateConversationMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateConversation }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateConversationMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateConversationMutation,
    UpdateConversationMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdateConversationMutation,
    UpdateConversationMutationVariables
  >('UpdateConversation', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMessageQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { message }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMessageQueryHttp = (
  resolver: GraphQLResponseResolver<GetMessageQuery, GetMessageQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMessageQuery, GetMessageQueryVariables>(
    'GetMessage',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteMessageMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { deleteMessage }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteMessageMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<DeleteMessageMutation, DeleteMessageMutationVariables>(
    'DeleteMessage',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateMessageMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateMessage }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateMessageMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateMessageMutation,
    UpdateMessageMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdateMessageMutation, UpdateMessageMutationVariables>(
    'UpdateMessage',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCountriesQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { countries }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetCountriesQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetCountriesQuery, GetCountriesQueryVariables>(
    'GetCountries',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCredentialsForUserQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetCredentialsForUserQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetCredentialsForUserQuery,
    GetCredentialsForUserQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetCredentialsForUserQuery, GetCredentialsForUserQueryVariables>(
    'GetCredentialsForUser',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCredentialMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createCredential }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateCredentialMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateCredentialMutation,
    CreateCredentialMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreateCredentialMutation, CreateCredentialMutationVariables>(
    'CreateCredential',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteCredentialMutationHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { deleteCredential }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteCredentialMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<DeleteCredentialMutation, DeleteCredentialMutationVariables>(
    'DeleteCredential',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateCredentialMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateCredential }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateCredentialMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateCredentialMutation,
    UpdateCredentialMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdateCredentialMutation, UpdateCredentialMutationVariables>(
    'UpdateCredential',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateInsuranceCardMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createInsuranceCard }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateInsuranceCardMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateInsuranceCardMutation,
    CreateInsuranceCardMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateInsuranceCardMutation,
    CreateInsuranceCardMutationVariables
  >('CreateInsuranceCard', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateInsuranceCardMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateInsuranceCard }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateInsuranceCardMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateInsuranceCardMutation,
    UpdateInsuranceCardMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdateInsuranceCardMutation,
    UpdateInsuranceCardMutationVariables
  >('UpdateInsuranceCard', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteInsuranceCardMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { deleteInsuranceCard }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteInsuranceCardMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteInsuranceCardMutation,
    DeleteInsuranceCardMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    DeleteInsuranceCardMutation,
    DeleteInsuranceCardMutationVariables
  >('DeleteInsuranceCard', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetJunoFormSubmissionQueryHttp(
 *   ({ query, variables }) => {
 *     const { fdid } = variables;
 *     return HttpResponse.json({
 *       data: { junoFormSubmission }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetJunoFormSubmissionQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetJunoFormSubmissionQuery,
    GetJunoFormSubmissionQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetJunoFormSubmissionQuery, GetJunoFormSubmissionQueryVariables>(
    'GetJunoFormSubmission',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateLabRequisitionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createLabRequisition }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateLabRequisitionMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateLabRequisitionMutation,
    CreateLabRequisitionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateLabRequisitionMutation,
    CreateLabRequisitionMutationVariables
  >('CreateLabRequisition', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateLabRequisitionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateLabRequisition }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateLabRequisitionMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateLabRequisitionMutation,
    UpdateLabRequisitionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdateLabRequisitionMutation,
    UpdateLabRequisitionMutationVariables
  >('UpdateLabRequisition', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateLabRequisitionViewedAtMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateLabRequisitionViewedAt }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateLabRequisitionViewedAtMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateLabRequisitionViewedAtMutation,
    UpdateLabRequisitionViewedAtMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdateLabRequisitionViewedAtMutation,
    UpdateLabRequisitionViewedAtMutationVariables
  >('UpdateLabRequisitionViewedAt', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteLabRequisitionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { deleteLabRequisition }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteLabRequisitionMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteLabRequisitionMutation,
    DeleteLabRequisitionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    DeleteLabRequisitionMutation,
    DeleteLabRequisitionMutationVariables
  >('DeleteLabRequisition', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetFulfillLabRequisitionStepsQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetFulfillLabRequisitionStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetFulfillLabRequisitionStepsQuery,
    GetFulfillLabRequisitionStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetFulfillLabRequisitionStepsQuery,
    GetFulfillLabRequisitionStepsQueryVariables
  >('GetFulfillLabRequisitionSteps', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetReviewLabRequisitionStepsQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId, afterCursor } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetReviewLabRequisitionStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetReviewLabRequisitionStepsQuery,
    GetReviewLabRequisitionStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetReviewLabRequisitionStepsQuery,
    GetReviewLabRequisitionStepsQueryVariables
  >('GetReviewLabRequisitionSteps', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLabFeedbacksQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId, afterCursor } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetLabFeedbacksQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetLabFeedbacksQuery,
    GetLabFeedbacksQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetLabFeedbacksQuery, GetLabFeedbacksQueryVariables>(
    'GetLabFeedbacks',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCopyLabFeedbackStepsQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId, afterCursor } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetCopyLabFeedbackStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetCopyLabFeedbackStepsQuery,
    GetCopyLabFeedbackStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetCopyLabFeedbackStepsQuery,
    GetCopyLabFeedbackStepsQueryVariables
  >('GetCopyLabFeedbackSteps', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetFhirMedicationRequestsQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId } = variables;
 *     return HttpResponse.json({
 *       data: { medicationRequests }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetFhirMedicationRequestsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetFhirMedicationRequestsQuery,
    GetFhirMedicationRequestsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetFhirMedicationRequestsQuery,
    GetFhirMedicationRequestsQueryVariables
  >('GetFHIRMedicationRequests', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPatientQuestionnairesQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps, questionnaireResponses, user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPatientQuestionnairesQueryHttp = (
  resolver: GraphQLResponseResolver<
    PatientQuestionnairesQuery,
    PatientQuestionnairesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<PatientQuestionnairesQuery, PatientQuestionnairesQueryVariables>(
    'PatientQuestionnaires',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateStripeSetupIntentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createStripeSetupIntent }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateStripeSetupIntentMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateStripeSetupIntentMutation,
    CreateStripeSetupIntentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateStripeSetupIntentMutation,
    CreateStripeSetupIntentMutationVariables
  >('CreateStripeSetupIntent', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCompleteInitialPaymentAndBookingMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { completeInitialPaymentAndBooking }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCompleteInitialPaymentAndBookingMutationHttp = (
  resolver: GraphQLResponseResolver<
    CompleteInitialPaymentAndBookingMutation,
    CompleteInitialPaymentAndBookingMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CompleteInitialPaymentAndBookingMutation,
    CompleteInitialPaymentAndBookingMutationVariables
  >('CompleteInitialPaymentAndBooking', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateStripeInvoiceMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createStripeInvoice }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateStripeInvoiceMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateStripeInvoiceMutation,
    CreateStripeInvoiceMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateStripeInvoiceMutation,
    CreateStripeInvoiceMutationVariables
  >('CreateStripeInvoice', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGenerateLoanLinkMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { generateLoanLink }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGenerateLoanLinkMutationHttp = (
  resolver: GraphQLResponseResolver<
    GenerateLoanLinkMutation,
    GenerateLoanLinkMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<GenerateLoanLinkMutation, GenerateLoanLinkMutationVariables>(
    'GenerateLoanLink',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateInitialPaymentTransactionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createInitialPaymentTransaction }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateInitialPaymentTransactionMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateInitialPaymentTransactionMutation,
    CreateInitialPaymentTransactionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateInitialPaymentTransactionMutation,
    CreateInitialPaymentTransactionMutationVariables
  >('CreateInitialPaymentTransaction', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyPaymentMethodsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMyPaymentMethodsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMyPaymentMethodsQuery,
    GetMyPaymentMethodsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMyPaymentMethodsQuery, GetMyPaymentMethodsQueryVariables>(
    'GetMyPaymentMethods',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeletePaymentMethodMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { deletePaymentMethod }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeletePaymentMethodMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >('DeletePaymentMethod', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAppointmentPaymentMethodsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me, platformSettings }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAppointmentPaymentMethodsQueryHttp = (
  resolver: GraphQLResponseResolver<
    AppointmentPaymentMethodsQuery,
    AppointmentPaymentMethodsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    AppointmentPaymentMethodsQuery,
    AppointmentPaymentMethodsQueryVariables
  >('AppointmentPaymentMethods', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSendPhoneNumberVerificationCodeMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { sendPhoneNumberVerificationCode }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSendPhoneNumberVerificationCodeMutationHttp = (
  resolver: GraphQLResponseResolver<
    SendPhoneNumberVerificationCodeMutation,
    SendPhoneNumberVerificationCodeMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    SendPhoneNumberVerificationCodeMutation,
    SendPhoneNumberVerificationCodeMutationVariables
  >('SendPhoneNumberVerificationCode', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyPhoneNumberVerificationCodeMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { verifyPhoneNumberVerificationCode }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockVerifyPhoneNumberVerificationCodeMutationHttp = (
  resolver: GraphQLResponseResolver<
    VerifyPhoneNumberVerificationCodeMutation,
    VerifyPhoneNumberVerificationCodeMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    VerifyPhoneNumberVerificationCodeMutation,
    VerifyPhoneNumberVerificationCodeMutationVariables
  >('VerifyPhoneNumberVerificationCode', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPlatformSettingsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { platformSettings }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPlatformSettingsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPlatformSettingsQuery,
    GetPlatformSettingsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPlatformSettingsQuery, GetPlatformSettingsQueryVariables>(
    'GetPlatformSettings',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePrescriptionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createPrescription }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreatePrescriptionMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreatePrescriptionMutation,
    CreatePrescriptionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreatePrescriptionMutation,
    CreatePrescriptionMutationVariables
  >('CreatePrescription', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPrescriptionQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { prescription }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPrescriptionQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPrescriptionQuery,
    GetPrescriptionQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPrescriptionQuery, GetPrescriptionQueryVariables>(
    'GetPrescription',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPrescriptionStepsQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, patientId } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPrescriptionStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPrescriptionStepsQuery,
    GetPrescriptionStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPrescriptionStepsQuery, GetPrescriptionStepsQueryVariables>(
    'GetPrescriptionSteps',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateFridaPrescriptionsMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createFridaPrescriptions }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateFridaPrescriptionsMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateFridaPrescriptionsMutation,
    CreateFridaPrescriptionsMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateFridaPrescriptionsMutation,
    CreateFridaPrescriptionsMutationVariables
  >('createFridaPrescriptions', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePrescriptionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updatePrescription }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdatePrescriptionMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdatePrescriptionMutation,
    UpdatePrescriptionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdatePrescriptionMutation,
    UpdatePrescriptionMutationVariables
  >('updatePrescription', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeletePrescriptionMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { deletePrescription }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeletePrescriptionMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeletePrescriptionMutation,
    DeletePrescriptionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    DeletePrescriptionMutation,
    DeletePrescriptionMutationVariables
  >('deletePrescription', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientProfileQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientProfileQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPatientProfileQuery,
    GetPatientProfileQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPatientProfileQuery, GetPatientProfileQueryVariables>(
    'GetPatientProfile',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMeQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMeQueryHttp = (
  resolver: GraphQLResponseResolver<GetMeQuery, GetMeQueryVariables>,
  options?: RequestHandlerOptions,
) => http.query<GetMeQuery, GetMeQueryVariables>('GetMe', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMePatientQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMePatientQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMePatientQuery,
    GetMePatientQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMePatientQuery, GetMePatientQueryVariables>(
    'GetMePatient',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyPrescriptionsQueryHttp(
 *   ({ query, variables }) => {
 *     const { first, afterCursor, onlyPrep, brandID } = variables;
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMyPrescriptionsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMyPrescriptionsQuery,
    GetMyPrescriptionsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMyPrescriptionsQuery, GetMyPrescriptionsQueryVariables>(
    'GetMyPrescriptions',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyLabRequisitionsQueryHttp(
 *   ({ query, variables }) => {
 *     const { first, afterCursor } = variables;
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMyLabRequisitionsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMyLabRequisitionsQuery,
    GetMyLabRequisitionsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMyLabRequisitionsQuery, GetMyLabRequisitionsQueryVariables>(
    'GetMyLabRequisitions',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyIncompleteWorkflowStepsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMyIncompleteWorkflowStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMyIncompleteWorkflowStepsQuery,
    GetMyIncompleteWorkflowStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetMyIncompleteWorkflowStepsQuery,
    GetMyIncompleteWorkflowStepsQueryVariables
  >('GetMyIncompleteWorkflowSteps', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBrandMembershipQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBrandMembershipQueryHttp = (
  resolver: GraphQLResponseResolver<
    BrandMembershipQuery,
    BrandMembershipQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<BrandMembershipQuery, BrandMembershipQueryVariables>(
    'BrandMembership',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockScreenInPatientMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { screenInPatient }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockScreenInPatientMutationHttp = (
  resolver: GraphQLResponseResolver<
    ScreenInPatientMutation,
    ScreenInPatientMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<ScreenInPatientMutation, ScreenInPatientMutationVariables>(
    'ScreenInPatient',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMyShortQuestionnairesQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMyShortQuestionnairesQueryHttp = (
  resolver: GraphQLResponseResolver<
    MyShortQuestionnairesQuery,
    MyShortQuestionnairesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<MyShortQuestionnairesQuery, MyShortQuestionnairesQueryVariables>(
    'MyShortQuestionnaires',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetQuestionnaireResponseQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { questionnaireResponse }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetQuestionnaireResponseQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetQuestionnaireResponseQuery,
    GetQuestionnaireResponseQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetQuestionnaireResponseQuery,
    GetQuestionnaireResponseQueryVariables
  >('GetQuestionnaireResponse', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStartQuestionnaireMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { startQuestionnaire }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStartQuestionnaireMutationHttp = (
  resolver: GraphQLResponseResolver<
    StartQuestionnaireMutation,
    StartQuestionnaireMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    StartQuestionnaireMutation,
    StartQuestionnaireMutationVariables
  >('StartQuestionnaire', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitQuestionnaireAnswersMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { submitQuestionnaireAnswers }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSubmitQuestionnaireAnswersMutationHttp = (
  resolver: GraphQLResponseResolver<
    SubmitQuestionnaireAnswersMutation,
    SubmitQuestionnaireAnswersMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    SubmitQuestionnaireAnswersMutation,
    SubmitQuestionnaireAnswersMutationVariables
  >('SubmitQuestionnaireAnswers', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGoToPreviousStepOnQuestionnaireMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { goBackToPreviousQuestionnaireStep }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGoToPreviousStepOnQuestionnaireMutationHttp = (
  resolver: GraphQLResponseResolver<
    GoToPreviousStepOnQuestionnaireMutation,
    GoToPreviousStepOnQuestionnaireMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    GoToPreviousStepOnQuestionnaireMutation,
    GoToPreviousStepOnQuestionnaireMutationVariables
  >('GoToPreviousStepOnQuestionnaire', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetRolesQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { roles }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetRolesQueryHttp = (
  resolver: GraphQLResponseResolver<GetRolesQuery, GetRolesQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  http.query<GetRolesQuery, GetRolesQueryVariables>(
    'GetRoles',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateShippingAddressMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createShippingAddress }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateShippingAddressMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateShippingAddressMutation,
    CreateShippingAddressMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateShippingAddressMutation,
    CreateShippingAddressMutationVariables
  >('CreateShippingAddress', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateShippingAddressMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateShippingAddress }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateShippingAddressMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateShippingAddressMutation,
    UpdateShippingAddressMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdateShippingAddressMutation,
    UpdateShippingAddressMutationVariables
  >('UpdateShippingAddress', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteShippingAddressMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { deleteShippingAddress }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteShippingAddressMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeleteShippingAddressMutation,
    DeleteShippingAddressMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    DeleteShippingAddressMutation,
    DeleteShippingAddressMutationVariables
  >('DeleteShippingAddress', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetShortQuestionnaireStepsQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId, afterCursor } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetShortQuestionnaireStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetShortQuestionnaireStepsQuery,
    GetShortQuestionnaireStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetShortQuestionnaireStepsQuery,
    GetShortQuestionnaireStepsQueryVariables
  >('GetShortQuestionnaireSteps', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSignupPatientMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { signupPatient }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSignupPatientMutationHttp = (
  resolver: GraphQLResponseResolver<
    SignupPatientMutation,
    SignupPatientMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<SignupPatientMutation, SignupPatientMutationVariables>(
    'SignupPatient',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetStripeVerificationSessionQueryHttp(
 *   ({ query, variables }) => {
 *     const { userId } = variables;
 *     return HttpResponse.json({
 *       data: { stripeVerificationSession }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetStripeVerificationSessionQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetStripeVerificationSessionQuery,
    GetStripeVerificationSessionQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetStripeVerificationSessionQuery,
    GetStripeVerificationSessionQueryVariables
  >('GetStripeVerificationSession', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateStripeVerificationSessionMutationHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { createStripeVerificationSession }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateStripeVerificationSessionMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateStripeVerificationSessionMutation,
    CreateStripeVerificationSessionMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CreateStripeVerificationSessionMutation,
    CreateStripeVerificationSessionMutationVariables
  >('CreateStripeVerificationSession', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStopTreatmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { stopTreatment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStopTreatmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    StopTreatmentMutation,
    StopTreatmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<StopTreatmentMutation, StopTreatmentMutationVariables>(
    'StopTreatment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPauseTreatmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { pauseTreatment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPauseTreatmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    PauseTreatmentMutation,
    PauseTreatmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<PauseTreatmentMutation, PauseTreatmentMutationVariables>(
    'PauseTreatment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMarkTreatmentIneligibleMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { markTreatmentIneligible }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMarkTreatmentIneligibleMutationHttp = (
  resolver: GraphQLResponseResolver<
    MarkTreatmentIneligibleMutation,
    MarkTreatmentIneligibleMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    MarkTreatmentIneligibleMutation,
    MarkTreatmentIneligibleMutationVariables
  >('MarkTreatmentIneligible', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStartTreatmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { startTreatment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStartTreatmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    StartTreatmentMutation,
    StartTreatmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<StartTreatmentMutation, StartTreatmentMutationVariables>(
    'StartTreatment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTreatmentQueryHttp(
 *   ({ query, variables }) => {
 *     const { treatmentType } = variables;
 *     return HttpResponse.json({
 *       data: { treatment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetTreatmentQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetTreatmentQuery,
    GetTreatmentQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetTreatmentQuery, GetTreatmentQueryVariables>(
    'GetTreatment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePillCounterMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updatePillCounter }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdatePillCounterMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdatePillCounterMutation,
    UpdatePillCounterMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdatePillCounterMutation, UpdatePillCounterMutationVariables>(
    'UpdatePillCounter',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCliniciansQueryHttp(
 *   ({ query, variables }) => {
 *     const { roleIds } = variables;
 *     return HttpResponse.json({
 *       data: { users }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetCliniciansQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetCliniciansQuery,
    GetCliniciansQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetCliniciansQuery, GetCliniciansQueryVariables>(
    'GetClinicians',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProvidersQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, roleIds, searchText } = variables;
 *     return HttpResponse.json({
 *       data: { users }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProvidersQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetProvidersQuery,
    GetProvidersQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetProvidersQuery, GetProvidersQueryVariables>(
    'GetProviders',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProvidersForDropdownQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, roleIds, searchText } = variables;
 *     return HttpResponse.json({
 *       data: { users }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProvidersForDropdownQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetProvidersForDropdownQuery,
    GetProvidersForDropdownQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetProvidersForDropdownQuery,
    GetProvidersForDropdownQueryVariables
  >('GetProvidersForDropdown', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProviderQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProviderQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetProviderQuery,
    GetProviderQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetProviderQuery, GetProviderQueryVariables>(
    'GetProvider',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientsQueryHttp(
 *   ({ query, variables }) => {
 *     const { afterCursor, searchText, active, brandId, provinceId, countryCode } = variables;
 *     return HttpResponse.json({
 *       data: { users }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPatientsQuery,
    GetPatientsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPatientsQuery, GetPatientsQueryVariables>(
    'GetPatients',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientQueryHttp(
 *   ({ query, variables }) => {
 *     const { id, positionedBefore, positionedAfter, numberOfMedicalRecords } = variables;
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientQueryHttp = (
  resolver: GraphQLResponseResolver<GetPatientQuery, GetPatientQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPatientQuery, GetPatientQueryVariables>(
    'GetPatient',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientSummaryQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientSummaryQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPatientSummaryQuery,
    GetPatientSummaryQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPatientSummaryQuery, GetPatientSummaryQueryVariables>(
    'GetPatientSummary',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetFreddiePatientSummaryQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetFreddiePatientSummaryQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetFreddiePatientSummaryQuery,
    GetFreddiePatientSummaryQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetFreddiePatientSummaryQuery,
    GetFreddiePatientSummaryQueryVariables
  >('GetFreddiePatientSummary', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientWithAppointmentInfoQueryHttp(
 *   ({ query, variables }) => {
 *     const { appointmentId } = variables;
 *     return HttpResponse.json({
 *       data: { appointment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientWithAppointmentInfoQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPatientWithAppointmentInfoQuery,
    GetPatientWithAppointmentInfoQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetPatientWithAppointmentInfoQuery,
    GetPatientWithAppointmentInfoQueryVariables
  >('GetPatientWithAppointmentInfo', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientTreatmentOverviewQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { patientTreatmentOverview }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientTreatmentOverviewQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPatientTreatmentOverviewQuery,
    GetPatientTreatmentOverviewQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<
    GetPatientTreatmentOverviewQuery,
    GetPatientTreatmentOverviewQueryVariables
  >('GetPatientTreatmentOverview', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientChapterCoverQueryHttp(
 *   ({ query, variables }) => {
 *     const { treatmentType } = variables;
 *     return HttpResponse.json({
 *       data: { patientChapterCover }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetPatientChapterCoverQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetPatientChapterCoverQuery,
    GetPatientChapterCoverQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetPatientChapterCoverQuery, GetPatientChapterCoverQueryVariables>(
    'GetPatientChapterCover',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockActivateUserAccountMutationHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { activateUserAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockActivateUserAccountMutationHttp = (
  resolver: GraphQLResponseResolver<
    ActivateUserAccountMutation,
    ActivateUserAccountMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    ActivateUserAccountMutation,
    ActivateUserAccountMutationVariables
  >('ActivateUserAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeactivateUserAccountMutationHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { deactivateUserAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeactivateUserAccountMutationHttp = (
  resolver: GraphQLResponseResolver<
    DeactivateUserAccountMutation,
    DeactivateUserAccountMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    DeactivateUserAccountMutation,
    DeactivateUserAccountMutationVariables
  >('DeactivateUserAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePatientMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updatePatient }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdatePatientMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdatePatientMutation, UpdatePatientMutationVariables>(
    'UpdatePatient',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateProviderMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createProvider }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateProviderMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreateProviderMutation, CreateProviderMutationVariables>(
    'CreateProvider',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateProviderMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateProvider }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateProviderMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateProviderMutation,
    UpdateProviderMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdateProviderMutation, UpdateProviderMutationVariables>(
    'UpdateProvider',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateAssessmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createAssessment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateAssessmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateAssessmentMutation,
    CreateAssessmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreateAssessmentMutation, CreateAssessmentMutationVariables>(
    'CreateAssessment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateAssessmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateAssessment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateAssessmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateAssessmentMutation,
    UpdateAssessmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>(
    'UpdateAssessment',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCompleteAssessmentMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { completeAssessment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCompleteAssessmentMutationHttp = (
  resolver: GraphQLResponseResolver<
    CompleteAssessmentMutation,
    CompleteAssessmentMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CompleteAssessmentMutation,
    CompleteAssessmentMutationVariables
  >('CompleteAssessment', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockValidateEmailMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { validateEmail }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockValidateEmailMutationHttp = (
  resolver: GraphQLResponseResolver<
    ValidateEmailMutation,
    ValidateEmailMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<ValidateEmailMutation, ValidateEmailMutationVariables>(
    'ValidateEmail',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateVitalMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createVital }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateVitalMutationHttp = (
  resolver: GraphQLResponseResolver<
    CreateVitalMutation,
    CreateVitalMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<CreateVitalMutation, CreateVitalMutationVariables>(
    'CreateVital',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateVitalMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateVital }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateVitalMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateVitalMutation,
    UpdateVitalMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<UpdateVitalMutation, UpdateVitalMutationVariables>(
    'UpdateVital',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyVitalsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMyVitalsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMyVitalsQuery,
    GetMyVitalsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMyVitalsQuery, GetMyVitalsQueryVariables>(
    'GetMyVitals',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUsersWorkflowStepsQueryHttp(
 *   ({ query, variables }) => {
 *     const { patientId, stepType, completedById } = variables;
 *     return HttpResponse.json({
 *       data: { workflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetUsersWorkflowStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetUsersWorkflowStepsQuery,
    GetUsersWorkflowStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetUsersWorkflowStepsQuery, GetUsersWorkflowStepsQueryVariables>(
    'GetUsersWorkflowSteps',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyWorkflowStepsQueryHttp(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { me }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetMyWorkflowStepsQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetMyWorkflowStepsQuery,
    GetMyWorkflowStepsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetMyWorkflowStepsQuery, GetMyWorkflowStepsQueryVariables>(
    'GetMyWorkflowSteps',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetWorkflowStepQueryHttp(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { workflowStep }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetWorkflowStepQueryHttp = (
  resolver: GraphQLResponseResolver<
    GetWorkflowStepQuery,
    GetWorkflowStepQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.query<GetWorkflowStepQuery, GetWorkflowStepQueryVariables>(
    'GetWorkflowStep',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCompleteWorkflowStepMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { completeWorkflowStep }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCompleteWorkflowStepMutationHttp = (
  resolver: GraphQLResponseResolver<
    CompleteWorkflowStepMutation,
    CompleteWorkflowStepMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CompleteWorkflowStepMutation,
    CompleteWorkflowStepMutationVariables
  >('CompleteWorkflowStep', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCompleteWorkflowStepsMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { completeWorkflowSteps }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCompleteWorkflowStepsMutationHttp = (
  resolver: GraphQLResponseResolver<
    CompleteWorkflowStepsMutation,
    CompleteWorkflowStepsMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    CompleteWorkflowStepsMutation,
    CompleteWorkflowStepsMutationVariables
  >('CompleteWorkflowSteps', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateWorkflowStepMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateWorkflowStep }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateWorkflowStepMutationHttp = (
  resolver: GraphQLResponseResolver<
    UpdateWorkflowStepMutation,
    UpdateWorkflowStepMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    UpdateWorkflowStepMutation,
    UpdateWorkflowStepMutationVariables
  >('UpdateWorkflowStep', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockOverrideWorkflowStepMutationHttp(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { overrideWorkflowStep }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockOverrideWorkflowStepMutationHttp = (
  resolver: GraphQLResponseResolver<
    OverrideWorkflowStepMutation,
    OverrideWorkflowStepMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  http.mutation<
    OverrideWorkflowStepMutation,
    OverrideWorkflowStepMutationVariables
  >('OverrideWorkflowStep', resolver, options);
